import React, { Component } from 'react';
import Switch from 'react-switch/index';
import { fetchGroupPermissions } from '../../../../redux/modules/admin.users.module';
import styles from './styles.module.scss';
import classNames from 'classnames';
import GroupRepresentation from '@keycloak/keycloak-admin-client/lib/defs/groupRepresentation';

type Props = {
  /** Name of the resource to list the group permissions for */
  resourceName: string;
  /** List of available scopes */
  scopes: {
    id: string;
    displayName: string;
  }[];
  /** Resource type */
  resourceType: string;

  /* --- Groups --- */
  groupsData: GroupRepresentation[];
  /** Trigger loading the groups */
  loadGroups: (fetchPermissions: boolean) => void;

  /* --- Permissions */
  /** Add a group-wise permission */
  addGroupPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    group,
    refreshAction: () => void
  ) => void;
  /** Remove a group-wise permission */
  removeGroupPermission: (
    resourceName: string,
    scopeName: string,
    group,
    refreshAction: () => void
  ) => void;
  groupPermissions?: {
    [groupId: string]: {
      loading?: boolean;
      loaded?: boolean;
      error?: string;
      data?: {
        [resourceName: string]: {
          name: string;
        }[];
      };
    };
  };
};

export default class Groups extends Component<Props> {
  static defaultProps = {
    scopesView: true,
    scopesEdit: true,
  };

  componentDidMount() {
    const { loadGroups } = this.props;
    loadGroups(true);
  }

  hasGroupPermission(groupId, scopeName) {
    const { groupPermissions, resourceName } = this.props;
    if (!groupPermissions) return false;

    return ((groupPermissions[groupId]?.data || [])[resourceName] || [])
      .map((e) => e.name)
      .includes(scopeName);
  }

  render() {
    const {
      groupsData,
      resourceName,
      resourceType,
      addGroupPermission,
      removeGroupPermission,
      scopes,
    } = this.props;

    return (
      <div className={'groups-permissions-container ct-list'}>
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-flex-grow'}>
            <span>Group Name</span>
          </div>
          {scopes.map((scope) => (
            <div className={'ct-col ct-col-80px'}>
              <span>{scope.displayName}</span>
            </div>
          ))}
        </div>

        {groupsData &&
          groupsData
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((group) => (
              <div
                className={classNames('ct-row', styles.permissionsRow)}
                data-testingIdentifier={group?.name}
              >
                <div className={'ct-col ct-col-flex-grow-200px'}>
                  <span>{group.name}</span>
                </div>

                {scopes.map((scope) => (
                  <div className={'ct-col ct-col-80px'}>
                    <Switch
                      onChange={() => {
                        if (this.hasGroupPermission(group.id, scope.id)) {
                          removeGroupPermission(
                            resourceName,
                            scope.id,
                            group,
                            () => fetchGroupPermissions(group.id)
                          );
                        } else {
                          addGroupPermission(
                            resourceName,
                            resourceType,
                            scope.id,
                            group,
                            () => fetchGroupPermissions(group.id)
                          );
                        }
                      }}
                      checked={this.hasGroupPermission(group.id, scope.id)}
                      height={20}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                      onColor='#224e90'
                    />
                  </div>
                ))}
              </div>
            ))}
      </div>
    );
  }
}
