import { connect } from 'react-redux';
import NotebookWizard from './ui/NotebookWizard';
import {
  hideEditNotebook,
  saveEditNotebook,
} from '../../../../../redux/workbench/modules/notebook.module';
import { getNotebookByPath } from '../../../../../redux/workbench/selectors/notebook.selectors';
import { FormData, getNotebookName, getSelectedKernel } from './notebook.form';
import { getSessionByPath } from '../../../../../redux/workbench/selectors/sessions.selectors';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';
export function mapStateToProps(state) {
  const showEditNoteBook = state.workbench.showEditNotebook;
  const path = showEditNoteBook?.path;
  const notebook = getNotebookByPath(state, path);
  let kernel: JupyterNotebookFormat['metadata']['kernelspec'] =
    notebook?.content.metadata.kernelspec;
  if (!kernel) {
    // If the notebook is not currently in memory, check if there is a session from which we can get the kernel
    const session = getSessionByPath(state, path);
    if (session) {
      kernel = {
        name: session.kernel.name,
        display_name: '', // Sessions don't contain the display_name
      };
    }
  }
  // If the display name is not available (session or wrongly saved) we also have to look into the available kernels to get that info
  if (kernel && !kernel.display_name) {
    const kernelspecs = state.workbench.kernelspecs;
    const kernelspec = kernelspecs?.kernelspecs[kernel.name];
    kernel.display_name = kernelspec.spec.display_name;
  }

  const initialValues: FormData = {
    notebookName: showEditNoteBook?.name || '',
  };
  if (kernel) initialValues.kernel = kernel;
  return {
    // Can the wizard even work or is some crucial information missing?
    redirect: showEditNoteBook?.name === undefined,
    // Headline
    headline: 'Edit Notebook',
    // Notebook Name
    selectedNotebookName: getNotebookName(state),
    // Kernel
    selectedKernel: getSelectedKernel(state),
    // Same for Add, Edit, Paste
    isCreatingNoteBook: state.workbench.isCreatingNoteBook,
    initialValues,
  };
}

export const mapDispatchToProps: {
  onSave: (
    selectedNotebookName: string,
    kernelName: string,
    kernelDisplayName: string,
    altaSigmaMeta: Record<string, unknown>
  ) => void;
  onCancel: () => void;
} = {
  // Save button
  onSave: saveEditNotebook,
  // Cancel button
  onCancel: hideEditNotebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotebookWizard);
