import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';
import { pack } from 'd3-hierarchy';

import DataSeries from './DataSeries';

export default class BubbleChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        feature: PropTypes.string,
        importance: PropTypes.number,
      })
    ).isRequired,
    width: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    dataSeriesClassName: PropTypes.string.isRequired,
    bubbleClassName: PropTypes.string.isRequired,
    padding: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    maxNodes: PropTypes.number.isRequired,
    labelKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    width: 800,
    className: 'bubble-chart',
    dataSeriesClassName: 'bubble-chart-data-series',
    bubbleClassName: 'bubble-chart-bubble',
    padding: 15,
    colors: ['#F8FBFF', '#BFDDFF'],
    maxNodes: 25,
    labelKey: 'label',
    valueKey: 'value',
  };

  render() {
    const {
      data,
      width,
      colors,
      className,
      dataSeriesClassName,
      bubbleClassName,
      padding,
      maxNodes,
      labelKey,
      valueKey,
    } = this.props;

    const normalizedData = data
      .filter((e) => e.importance > 0)
      .map((element) => ({
        label: element[labelKey],
        value: element[valueKey],
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, maxNodes);

    const radius = scaleLinear().range([width / 20, width / 15]);

    const bubbles = pack().size([width, width]).padding(padding);

    const color = scaleLinear()
      .interpolate(interpolateRgb)
      .range([colors[0], colors[1]]);

    return (
      <div className={className}>
        <svg width={width} height={width}>
          <DataSeries
            className={dataSeriesClassName}
            bubbleClassName={bubbleClassName}
            data={normalizedData}
            bubbles={bubbles}
            color={color}
            radius={radius}
          />
        </svg>
      </div>
    );
  }
}
