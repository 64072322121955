import { cleanErrorObject } from 'common/dist/validation/helpers';

/**
 * Validation for the "sub form": add summand group
 * @param value
 */
import {
  TargetFunctionErrorType,
  TargetFunctionValueType,
} from './OptTargetFunction';
import {
  validateDescription,
  validateName,
} from '../../newDatapoolWizard/optChannels/validate';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import { MessageDescriptor } from 'react-intl';
import { numberRegex } from '../../newDatapoolWizard/optCommunications/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';
import _ from 'lodash';

const validateSumOver = (
  sumOver: TargetFunctionSummandGroup['sumOver']
): MessageDescriptor | null => {
  // The sumOver field is required
  if (!sumOver) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoSumOver;
  }
};

const validateSumOf = (
  sumOf: TargetFunctionSummandGroup['sumOf']
): MessageDescriptor | null => {
  // The sumOf field is required
  if (!sumOf) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoSumOf;
  }
};

export const validateFactor = (factor?: string): MessageDescriptor | null => {
  // The factor field is required
  if (!factor) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoFactor;
  }

  // Valid characters
  if (!numberRegex.test(factor)) {
    return moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrInvalidFactor;
  }

  return null;
};

// ---

export const validateTargetFunction = (
  value: TargetFunctionValueType
): TargetFunctionErrorType => {
  const error = {};

  // Is maximizeOrMinimize set?
  if (value && !value.minimizeOrMaximize) {
    error['minimizeOrMaximize'] =
      moduleOptimizationMessages.msgNewAugurStepTargetFunctionErrNoMinMax;
  }
  // Is there at least one summand group added?
  if (!value || (value.summandGroups || []).length === 0) {
    error[_globalValidationError] =
      moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoContactPolicies;
  } else {
    value?.summandGroups?.forEach((summandGroup) => {
      const summandGroupError = validateSingleSummandGroup(
        summandGroup,
        value?.summandGroups
      );
      if (!_.isEmpty(summandGroupError)) {
        error[summandGroup.id] = summandGroupError;
      }
    });
  }
  return cleanErrorObject(error);
};

export const validateSingleSummandGroup = (
  summandGroup: Partial<TargetFunctionSummandGroup>,
  allSummandGroups?: Partial<TargetFunctionSummandGroup>[]
): TargetFunctionErrorType => {
  const error = {};

  // Validate name
  error['name'] = validateName(
    summandGroup.name,
    (allSummandGroups || [])
      .filter((x) => x.id !== summandGroup.id)
      .map((ch) => ch.name)
  );
  // Validate description
  error['description'] = validateDescription(summandGroup.description);

  // Validate contactPolicyType
  error['sumOf'] = validateSumOf(summandGroup.sumOf);

  // Validate operator
  error['sumOver'] = validateSumOver(summandGroup.sumOver);

  // Validate factor
  error['factor'] = validateFactor(summandGroup.factor);

  return cleanErrorObject(error);
};
