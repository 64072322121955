export interface valueDoubleType {
  type: 'double';
  min: number;
  max: number;
}

export interface valueIntType {
  type: 'int';
  min: number;
  max: number;
}

export interface valueStringType {
  type: 'string';
  values: string[];
}

/**
 * @param valueType
 * @constructor
 */
export function ValueTypeToSpeaking(valueType: ValueType) {
  switch (valueType.type) {
    case 'double':
      return `a real number between ${valueType.min} and ${valueType.max}`;
    case 'int':
      return `an integer between ${valueType.min} and ${valueType.max}`;
    case 'string':
      return `a string from ${valueType.values.join(',')}`;
  }
}

export type ValueType = valueDoubleType | valueIntType | valueStringType;

export interface Parameter {
  name: string;
  speakingName: string;
  description: string;
  validValues: ValueType[];
  default: (number | string)[];
}

export interface availableAlgorithm {
  name: string;
  speakingName: string;
  description: string;
  parameters: Parameter[];
}

/**
 * Top level schema describing the available algorithms for tuning
 */
export interface ParameterTuningSchema {
  availableSearchStrategies: {
    name: string;
    speakingName: string;
    description: string;
  }[];
  availableAlgorithms: availableAlgorithm[];
}

export interface AlgorithmNames {
  speakingName: string;
  parameters: { [param: string]: string };
}

export interface SchemaNames {
  [algo: string]: AlgorithmNames;
}

/**
 * Make it easier to access the speaking Names, by directly accessing instead of searching the names
 * @param parameterTuningSchema
 * @constructor
 */
export function ToSchemaNames(
  parameterTuningSchema: ParameterTuningSchema
): SchemaNames {
  return Object.fromEntries(
    parameterTuningSchema.availableAlgorithms.map((algo) => [
      algo.name,
      {
        speakingName: algo.speakingName,
        parameters: {
          ...Object.fromEntries(
            algo.parameters.map((param) => [param.name, param.speakingName])
          ),
        },
      },
    ])
  );
}

/**
 * Output of the parameter tuning selection to write away
 */
export interface ParameterTuningOutput {
  searchStrategy: {
    name: string;
  };
  algorithms: {
    name: string;
    parameters: {
      name: string;
      values: (string | number)[];
    }[];
  }[];
}

// --- Parameter methods

/**
 * Split on "," and trim whitespace
 * @param input
 */
export function inputStringToList(input: string): string[] {
  return input.split(',').map((s) => s.trim());
}

/**
 * Add "," to list of values to turn into one string
 * @param list
 */
export function listToInputString(list: (string | number)[]): string {
  return list.join(',');
}

/**
 * Check a single input value against the ValueType for parameters
 * @param input
 * @param valueType
 */
export function isValid(input: string, valueType: ValueType): boolean {
  switch (valueType.type) {
    case 'double': {
      const max =
        valueType.max !== undefined ? valueType.max : Number.MAX_VALUE;
      const min =
        valueType.min !== undefined ? valueType.min : Number.MIN_VALUE;
      return (
        !Number.isNaN(Number(input)) &&
        min <= Number(input) &&
        Number(input) <= max
      );
    }
    case 'int': {
      const max =
        valueType.max !== undefined ? valueType.max : Number.MAX_SAFE_INTEGER;
      const min =
        valueType.min !== undefined ? valueType.min : Number.MIN_SAFE_INTEGER;
      return (
        Number.isInteger(Number(input)) &&
        min <= Number(input) &&
        Number(input) <= max
      );
    }
    case 'string': {
      return valueType.values.includes(input);
    }
  }
}
