import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { resetEventSource } from '../../../../redux/workbench/modules/connection.module';
import MainContainer from '../../../pages/main-container/MainContainer';
import Button from '../../../atoms/button/Button';
import { logoutLink } from '../../../organisms/header/profile-menu/TopMenu';
import GoTo from '../../../organisms/header/profile-menu/GoTo';

const ReloginRequired: FC<Record<string, any>> = (props) => {
  const dispatch = useDispatch();
  dispatch(resetEventSource());
  return (
    <MainContainer
      additionalClassname={'StartWorkbench ReloginRequired'}
      transparent={true}
      fullWidth={false}
    >
      <div className={'button-container'}>
        <div className={'start-server-parent'}>
          <div className={'start-server-headline-parent'}>
            <span className={'ReloginRequired--headline'}>
              Authentication against the Workbench failed.
              <br />
              Please Re-Login.
            </span>
          </div>
          <div
            className={
              'start-server-button-parent ReloginRequired--buttons-parent'
            }
          >
            {/* @ts-ignore */}
            <GoTo link={logoutLink} className='' onClick={() => {}}>
              <Button
                buttonColor={'orange'}
                withLink={false}
                buttonLabelId={'header.menu.logout'}
                buttonLabelDefault={'Logout'}
                onClick={() => {}}
              />
            </GoTo>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ReloginRequired;
