import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import * as datapool from '../../core/datapool'; // ?
import * as Api from '../../core/api';
import { sendNotification } from './notifications.module';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';

function mapTaxonomyToTree(taxonomy) {
  if (!taxonomy) return [];

  return taxonomy.map((node) => ({
    id: node.id,
    title: node.name,
    subtitle: node.id,
    children: mapTaxonomyToTree(node.sub),
  }));
}

function mapTreeToTaxonomy(tree) {
  if (!tree) return [];

  return tree.map((node) => ({
    id: node.id,
    name: node.title,
    sub: mapTreeToTaxonomy(node.children),
  }));
}

export const selectTab = createAction(
  'select tab on datapool details page',
  (tabId) => ({ tabId })
);

export const clear = createAction('clear datapool details page');

export const fetchDatapool = createAction(
  'fetch datapool',
  (habitatCode, datapoolCode) => ({ datapoolCode, habitatCode })
);

export const fetchDatapoolSuccess = createAction('fetch datapool - success');

export const fetchDatapoolFail = createAction('fetch datapool - fail');

export const fetchTaxonomy = createAction(
  'fetch taxonomy',
  (habitatCode, datapoolCode) => ({ datapoolCode, habitatCode })
);

export const fetchTaxonomySuccess = createAction('fetch taxonomy - success');

export const fetchTaxonomyFail = createAction('fetch taxonomy - fail');

export const saveTaxonomy = createAction(
  'save taxonomy',
  (habitatCode, datapoolCode, taxonomy) => ({
    datapoolCode,
    habitatCode,
    taxonomy,
  })
);

export const saveTaxonomySuccess = createAction('save taxonomy - success');

export const saveTaxonomyFail = createAction('save taxonomy - fail');

export const setTreeData = createAction('set tree data', (treeData) => ({
  treeData,
}));

export const setNewTaxonomy = createAction(
  'set new taxonomy',
  (newTaxonomy) => ({ newTaxonomy })
);

export const updateSelectedTables = createAction(
  'update selected tables',
  (selectedTables) => selectedTables
);

export const updateDatapool = createAction(
  'update datapool',
  (habitatCode, datapoolCode, datapoolUpdates, mergeMeta) => ({
    habitatCode,
    datapoolCode,
    datapoolUpdates,
    mergeMeta,
  })
);

export const reducer = {
  [selectTab]: (state, { tabId }) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      activeTabId: tabId,
    },
  }),

  [clear]: (state) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      activeTabId: state.datapoolDetails.tabs[0].id,
    },
  }),

  [fetchDatapool]: (state, { datapoolCode, habitatCode }) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      habitatCode,
      datapoolCode,
      loading: true,
      loaded: false,
      taxonomy: [],
    },
  }),

  [fetchDatapoolSuccess]: (state, payload) => {
    const moduleType = datapool.getModuleType(payload);
    return {
      ...state,
      datapoolDetails: {
        ...state.datapoolDetails,
        moduleType,
        loading: false,
        loaded: true,
        datapool: payload.datapool,
        habitatName: payload.habitatName,
      },
    };
  },

  [fetchDatapoolFail]: (state, error) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      loading: false,
      loaded: false,
      error,
    },
  }),

  [fetchTaxonomySuccess]: (state, payload) => {
    const moduleType = datapool.getModuleType(payload);
    return {
      ...state,
      datapoolDetails: {
        ...state.datapoolDetails,
        moduleType,
        taxonomy: payload,
        treeData: mapTaxonomyToTree(payload),
        initialTreeData: mapTaxonomyToTree(payload),
      },
    };
  },

  [saveTaxonomy]: (state) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      saving: true,
    },
  }),

  [saveTaxonomySuccess]: (state, payload) => {
    const moduleType = datapool.getModuleType(payload);
    return {
      ...state,
      datapoolDetails: {
        ...state.datapoolDetails,
        moduleType,
        initialTreeData: cloneDeep(state.datapoolDetails.treeData),
        saving: false,
      },
    };
  },

  [fetchTaxonomyFail]: (state, error) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      error,
    },
  }),

  [setTreeData]: (state, { treeData }) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      treeData,
      taxonomy: mapTreeToTaxonomy(treeData),
    },
  }),

  [setNewTaxonomy]: (state, { newTaxonomy }) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      newTaxonomy,
    },
  }),
  [updateSelectedTables]: (state, selectedTables) => ({
    ...state,
    datapoolDetails: {
      ...state.datapoolDetails,
      datapool: {
        ...state.datapoolDetails.datapool,
        selectedTables,
      },
    },
  }),
};

export function* fetchDatapoolSaga({ payload: { habitatCode, datapoolCode } }) {
  const { response, error } = yield call(
    Api.datapools.fetchDatapool,
    habitatCode,
    datapoolCode
  );

  if (response) {
    yield put(fetchDatapoolSuccess(response));
  } else {
    yield put(fetchDatapoolFail(error));
  }
}

export function* watchDatapoolFetch() {
  yield takeEvery(fetchDatapool.getType(), fetchDatapoolSaga);
}

export function* fetchTaxonomySaga({ payload: { habitatCode, datapoolCode } }) {
  const { response, error } = yield call(
    Api.datapools.fetchPredictionTargets,
    habitatCode,
    datapoolCode
  );

  if (response) {
    yield put(fetchTaxonomySuccess(response));
  } else {
    yield put(fetchTaxonomyFail(error));
  }
}

export function* watchTaxonomyFetch() {
  yield takeEvery(fetchTaxonomy.getType(), fetchTaxonomySaga);
}

export function* saveTaxonomySaga({
  payload: { habitatCode, datapoolCode, taxonomy },
}) {
  const { response, error } = yield call(
    Api.datapools.saveTaxonomy,
    habitatCode,
    datapoolCode,
    taxonomy
  );

  if (response) {
    yield put(saveTaxonomySuccess(taxonomy));
    yield put(
      sendNotification(
        'notifications.title.taxonomy_save_success',
        'notifications.description.taxonomy_save_success',
        eventType
      )
    );
  } else {
    yield put(saveTaxonomyFail(error));
    yield put(
      sendNotification(
        'notifications.title.taxonomy_save_fail',
        'notifications.description.taxonomy_save_fail',
        errorType
      )
    );
  }
}

export function* watchTaxonomySave() {
  yield takeEvery(saveTaxonomy.getType(), saveTaxonomySaga);
}

export function* updateDatapoolSaga({
  payload: { habitatCode, datapoolCode, datapoolUpdates, mergeMeta },
}) {
  const { response, error } = yield call(
    Api.datapools.updateDatapool,
    habitatCode,
    datapoolCode,
    datapoolUpdates,
    mergeMeta
  );
  if (response) {
    yield put(fetchDatapool(habitatCode, datapoolCode));
    yield put(
      sendNotification(
        'notifications.title.datapool_update_success',
        'notifications.description.datapool_update_success',
        eventType
      )
    );
  } else {
    // yield put(fetchDatapool(habitatCode, datapoolCode));
    yield put(
      sendNotification(
        'notifications.title.datapool_update_fail',
        'notifications.description.datapool_update_fail',
        errorType
      )
    );
  }
}

export function* watchUpdateDatapool() {
  yield takeEvery(updateDatapool.getType(), updateDatapoolSaga);
}
