import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import FieldError from '../../_tools/fieldError/FieldError';
import {
  getComparatorMessageDescriptor,
  kpiOptions,
  kpiOptionsGrouped,
  KpiOptionType,
  negateComparator,
} from 'common/dist/constants/keyPerformanceIndicators';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { fieldLearningKpi, fieldLearningThreshold } from './form';
import styles from './styles.module.scss';
import InfoBox from '../../atoms/info-box/InfoBox';

type Props = {
  learningKpi?: string;
  learningThreshold?: number;
  intl: IntlShape;
};

type OptionType = { label: string; value: string };

export class KpiThresholdSelect extends Component<Props> {
  static getComparator(kpi) {
    if (!kpi) {
      return '';
    }

    return (
      // @ts-ignore
      <FormattedMessage
        {...(getComparatorMessageDescriptor(kpi.comparator) || {})}
      />
    );
  }

  static getInputValidations(kpi) {
    if (!kpi) {
      return {};
    }

    return {
      min: kpi.min,
      max: kpi.max,
    };
  }

  static renderErrors() {
    return (
      <div className={styles.errorContainer}>
        <Field name='learningKpi' component={FieldError} />
        <Field name='learningThreshold' component={FieldError} />
      </div>
    );
  }

  renderThreshold(kpiOption: KpiOptionType) {
    return (
      <Field
        name={fieldLearningThreshold}
        component='input'
        type='number'
        className={styles.fieldThreshold}
        {...KpiThresholdSelect.getInputValidations(kpiOption)}
        parse={(value) => parseFloat(value)}
        step={0.05}
      />
    );
  }

  renderInfoBox(kpiOption: KpiOptionType, learningThreshold: number) {
    const { intl } = this.props;

    const formattedKpi = intl.formatMessage({ id: kpiOption.label });
    const formattedComparator = intl.formatMessage(
      // @ts-ignore
      getComparatorMessageDescriptor(negateComparator(kpiOption.comparator))
    );

    return (
      <div className={styles.infoBox}>
        <InfoBox>
          <FormattedMessage
            id={'augur.info_box.learning_threshold'}
            defaultMessage={
              'The threshold will be evaluated immediately after every Evaluation Job. ' +
              'If the <b>{formattedKpi}</b> is <b>{formattedComparator} {learningThreshold}</b>, a Training Job is ' +
              'triggered automatically to re-train the model of this Augur.'
            }
            values={{
              b: (chunks) => <b>{chunks}</b>,
              formattedKpi,
              formattedComparator,
              learningThreshold,
            }}
          />
        </InfoBox>
      </div>
    );
  }

  renderKpi({ options, input, meta, intl }) {
    const localizedOptions = options.map((option) => ({
      value: option.id,
      label: intl.formatMessage({ id: option.label }),
    }));

    return (
      <DropdownSelectInput
        className={styles.fieldKpi}
        value={localizedOptions.find((o) => o.value === input.value)}
        onChange={(option: OptionType) => input.onChange(option.value)}
        onFocus={input.onFocus}
        options={localizedOptions}
        placeholder={{
          id: 'common.scheduleSelect.select_placeholder',
        }}
        touched={meta.touched}
        onBlur={() => input.onBlur(input.value)}
        valid={!meta.error}
      />
    );
  }

  render() {
    const { learningKpi, learningThreshold, intl } = this.props;

    const kpiOption = kpiOptionsGrouped[learningKpi];
    const comparator = KpiThresholdSelect.getComparator(kpiOption);

    return (
      <div className={styles.relearningCondition}>
        <div className={styles.fieldsContainer}>
          <Field
            name={fieldLearningKpi}
            component={this.renderKpi}
            options={kpiOptions}
            intl={intl}
          />

          <div className={styles.comparator}>{comparator}</div>

          {kpiOption && this.renderThreshold(kpiOption)}
        </div>
        {KpiThresholdSelect.renderErrors()}
        {kpiOption &&
          !!learningThreshold &&
          this.renderInfoBox(kpiOption, learningThreshold)}
      </div>
    );
  }
}

export default injectIntl(KpiThresholdSelect);
