import React, { FC, useState } from 'react';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { fieldTargetFunction, targetFunctionForm } from './form';
import { Field, reduxForm } from 'redux-form';
import CancelSubmitButtonsWrapper from '../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import OptTargetFunction, {
  TargetFunctionValueType,
} from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/OptTargetFunction';
import Button from '../../../../../atoms/button/Button';
import { FiEdit } from 'react-icons/all';
import classNames from 'classnames';
import { AugurSettingsData } from 'common/dist/types/augurSettings';

type Props = {
  initialValues: {
    [fieldTargetFunction]: TargetFunctionValueType;
  };
  saveSettings: (settings: AugurSettingsData, merge: boolean) => void;
  habitatCode: string;
  datapoolCode: string;
};

const targetFunctionComponent = ({
  input,
  meta,
  datapoolCode,
  habitatCode,
  inEditMode,
}) => {
  if (!datapoolCode) return null;

  return (
    <OptTargetFunction
      input={input}
      meta={meta}
      datapoolCode={datapoolCode}
      habitatCode={habitatCode}
      inEditMode={inEditMode}
    />
  );
};

const TargetFunction: FC<Props & InjectedFormProps<FormValueType>> = (
  props
) => {
  const {
    pristine,
    reset,
    submitting,
    handleSubmit,
    saveSettings,
    datapoolCode,
    habitatCode,
    valid,
  } = props;

  const [inEditMode, setInEditMode] = useState(false);

  return (
    <div className={styles.optTargetFunction}>
      <div className={styles.headlineContainer}>
        <FormattedMessage
          {...moduleOptimizationMessages.msgAugurDetailsTargetFunctionTabName}
        >
          {(text) => <span className={styles.headline}>{text}</span>}
        </FormattedMessage>
        <Button
          buttonColor={'blue'}
          buttonLabelId={'no-id'}
          buttonLabelDefault={'Edit'}
          withLink={false}
          Icon={() => <FiEdit size={16} />}
          onClick={() => {
            setInEditMode(true);
          }}
          additionalClassNames={[
            classNames(styles.button, {
              [styles.invisible]: inEditMode,
            }),
          ]}
        />
      </div>
      <CancelSubmitButtonsWrapper
        savingButtonBusy={submitting}
        cancelDisabled={!inEditMode || submitting}
        saveDisabled={pristine || submitting || !valid}
        onCancel={() => {
          reset();
          setInEditMode(false);
        }}
        onSubmit={handleSubmit((values) => {
          saveSettings(
            {
              targetFunction: {
                summandGroups: values[fieldTargetFunction]?.summandGroups || [],
                minimizeOrMaximize:
                  values[fieldTargetFunction]?.minimizeOrMaximize,
              },
            },
            true
          );
          setInEditMode(false);
        })}
      >
        <Field
          name={fieldTargetFunction}
          component={targetFunctionComponent}
          datapoolCode={datapoolCode}
          habitatCode={habitatCode}
          inEditMode={inEditMode}
        />
      </CancelSubmitButtonsWrapper>
    </div>
  );
};

type FormValueType = {
  [fieldTargetFunction]: TargetFunctionValueType;
};
export default reduxForm<FormValueType>(targetFunctionForm)(TargetFunction);
