import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InfluencerBar from './InfluencerBar';
import Header from './Header';

import GraphWait from '../../../../../../../components/details/GraphWait';
import ToggleAllBar from './ToggleAllBar';
import { Feature } from 'common/dist/types/mlModel';
import _ from 'lodash';
import Button from '../../../../../../atoms/button/Button';
import { OrderByType, SortByType, sortFeatures } from './sorting';

type Props = {
  /** List of features from the evaluation report */
  features: Feature[];
  /** List of rejected features from the augur settings */
  rejectedFeatures: string[];
  /** List of forced features from the augur settings */
  forcedFeatures: string[];
  /** Search string to filter feature list */
  featuresSearchValue: string;
  /** Callback to save rejected and forced features */
  onSave: (rejected: string[], forced: string[]) => void;
  /** Flag indicating whether data is currently being saved */
  saving: boolean;
};

const InfluencersBarsChart: FC<Props> = ({
  features,
  rejectedFeatures,
  forcedFeatures,
  featuresSearchValue,
  onSave,
  saving,
}) => {
  const [rejected, setRejected] = useState(rejectedFeatures);
  const [forced, setForced] = useState(forcedFeatures);
  const [sortBy, setSortBy] = useState<SortByType>('importance');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');
  const unsavedChanges =
    !_.isEqual(rejected, rejectedFeatures) ||
    !_.isEqual(forced, forcedFeatures);

  if (_.isEmpty(features)) {
    return (
      <GraphWait jobType={'learning'}>
        <FormattedMessage
          id='augur.influencers.not_available'
          defaultMessage='Influencers are not available'
        />
      </GraphWait>
    );
  }

  const updateRejected = (features: string[], reject: boolean) => {
    setRejected(
      reject
        ? [...rejected, ...features]
        : rejected.filter((f) => !features.includes(f))
    );
  };

  const updateForced = (features: string[], force: boolean) => {
    setForced(
      force
        ? [...forced, ...features]
        : forced.filter((f) => !features.includes(f))
    );
  };

  // --- Transform the features
  // Apply filter
  const processedFeatures = features
    .filter(
      (f) =>
        f.feature &&
        f.feature.toLowerCase().indexOf(featuresSearchValue.toLowerCase()) !==
          -1
    )
    .map((f) => ({
      ...f,
      rejected: rejected.includes(f.feature),
      forced: forced.includes(f.feature),
    }))
    .sort((f1, f2) => sortFeatures(f1, f2, sortBy, orderBy));

  return (
    <div className='rejected-features'>
      <div className='rejected-features-content'>
        <Header
          sort={(sortBy, orderBy) => {
            setSortBy(sortBy);
            setOrderBy(orderBy);
          }}
          sortBy={sortBy}
          orderBy={orderBy}
        />

        {featuresSearchValue && (processedFeatures || []).length > 0 && (
          <ToggleAllBar
            visibleFeatures={processedFeatures.map(
              (feature) => feature.feature
            )}
            updateRejectedFeatures={updateRejected}
            updateForcedFeatures={updateForced}
          />
        )}

        {featuresSearchValue && (processedFeatures || []).length === 0 && (
          <div className='rejected-features-row no-features-for-query'>
            <span>
              No feature matches search query <i>{featuresSearchValue}</i>
            </span>
          </div>
        )}

        {processedFeatures.map((f) => (
          <InfluencerBar
            key={`feature-${f.feature}`}
            feature={f}
            updateRejectedFeatures={updateRejected}
            updateForcedFeatures={updateForced}
          />
        ))}
      </div>

      <div className={'Influencers--buttons'}>
        <Button
          buttonColor={'white'}
          withLink={false}
          buttonLabelId={'common.cancel'}
          buttonLabelDefault={'Cancel'}
          onClick={() => {
            setRejected(rejectedFeatures);
            setForced(forcedFeatures);
          }}
          disabled={!unsavedChanges}
        />

        <Button
          buttonColor={'green'}
          withLink={false}
          buttonLabelId={'common.save_changes'}
          buttonLabelDefault={'Save Changes'}
          onClick={() => onSave(rejected, forced)}
          disabled={!unsavedChanges}
          isBusy={saving}
        />
      </div>
    </div>
  );
};

export default InfluencersBarsChart;
