import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import BinaryNumerical from './icons/BinaryNumerical';
import { FiCircle } from 'react-icons/all';
import { EnrichedModule, moduleType } from 'common/dist/constants/module_list';
import NaturalLanguageTagging from './icons/NaturalLanguageTagging';
import Optimization from './icons/Optimization';

type Props = EnrichedModule & {
  onClick: () => void;
  isSelected?: boolean;
  /** Grey out the card because another card is selected? */
  explicitlyNotSelected?: boolean;
};

const ModuleCard: FC<Props> = (props) => {
  const { onClick, name, type, isSelected, explicitlyNotSelected } = props;

  const renderIcon = (type: string) => {
    switch (type) {
      case moduleType.BINARY_NUMERICAL:
        return <BinaryNumerical width={'36px'} height={'36px'} />;
      case moduleType.NATURAL_LANGUAGE_TAGGING:
        return <NaturalLanguageTagging width={'36px'} height={'36px'} />;
      case moduleType.CAMPAIGN_OPTIMIZATION:
        return <Optimization width={'36px'} height={'36px'} />;
      default:
        return <FiCircle size={'36px'} className={styles.defaultIcon} />;
    }
  };

  return (
    <div
      data-testingIdentifier={'Module_' + name}
      className={classNames(
        styles.moduleCard,
        { [styles.isSelected]: isSelected },
        { [styles.explicitlyNotSelected]: explicitlyNotSelected }
      )}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>{renderIcon(type)}</div>
      <div className={styles.nameContainer}>
        <span className={styles.name}>{name}</span>
      </div>
    </div>
  );
};

export default ModuleCard;
