import { Props } from './RunCodeCapsuleModal';
import { ConfigProps, FormErrors } from 'redux-form';
import { isQuantity } from 'common/dist/utils/kubernetes';

export const formName = 'runCodeCapsule';
export const fieldVersionNumber = 'versionNumber';
export const fieldParameters = 'parameters';
export const fieldResources = 'resources';
export const fieldNotebooks = 'notebooksToExecute';
export const fieldJobName = 'name';
export const fieldGpu = 'gpu';

export interface Data {
  [fieldVersionNumber]: string;
  [fieldParameters]: Record<string, string>;
  [fieldResources]: {
    cpu?: string;
    memory?: string;
    gpu?: string;
  };
  [fieldNotebooks]: string[];
  [fieldJobName]: string;
  [fieldGpu]?: string;
}

export type ErrorType = Record<string, string> | string;

type Errors = FormErrors<Data, ErrorType>;

export function validate(values: Data): Errors {
  const { resources, parameters, notebooksToExecute, versionNumber } = values;
  let errors: Errors = {};

  // --- Validate Resources
  if (resources) {
    const { cpu, memory, gpu } = resources;
    if (cpu) {
      const num = Number(cpu).valueOf();
      if (isNaN(num) || num < 0) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            cpu: 'Please enter valid CPU',
          },
        };
      }
    }
    if (memory) {
      if (!isQuantity(memory)) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            memory: 'Please enter valid Memory, for example: 2Gi',
          },
        };
      }
    }
    if (gpu) {
      const num = Number(gpu).valueOf();
      if (isNaN(num) || num < 0) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            gpu: 'Please enter valid GPU',
          },
        };
      }
    }
  }

  // --- Validate Parameters
  if (parameters) {
    // TODO
  }

  // --- Validate Notebooks to execute
  if (!notebooksToExecute || notebooksToExecute.length < 1) {
    errors = {
      ...errors,
      notebooksToExecute: 'Please select at least one notebook',
    };
  }

  // --- Validate Version number
  if (!versionNumber) {
    errors = {
      ...errors,
      versionNumber: 'Please select a version',
    };
  }

  return errors;
}

export const form: ConfigProps<Data, Props, ErrorType> = {
  form: formName,
  validate,
  // enableReinitialize, because depending on the versionNumber there are different initialValues
  enableReinitialize: true,
};
