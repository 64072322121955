import React, { FC } from 'react';
import { ResponsivePie } from '@nivo/pie';
import NumericSliderInput from '../../atoms/input-elements/numeric-slider-input/NumericSliderInput';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import InfoBox from '../../atoms/info-box/InfoBox';
import { Link } from 'react-router-dom';
import { ValueType } from './SamplingStrategySelect';

type Props = {
  error?: { id: string; values: object };
  touched?: boolean;
  value?: ValueType;
  onChange: (value: ValueType) => void;
  onBlur: (value: ValueType) => void;
  modelTuningLink: string;
  /** Render links in the info box or only the text? */
  withLinksInInfo?: boolean;
};

const AutomaticStrategy: FC<Props> = (props) => {
  const {
    error,
    value,
    touched,
    onChange,
    onBlur,
    modelTuningLink,
    withLinksInInfo,
  } = props;

  return (
    <div className={styles.automaticStrategy}>
      <FormattedMessage id={'no-id'} defaultMessage={'Select the sample size'}>
        {(text) => <span className={styles.label}>{text}</span>}
      </FormattedMessage>

      <div
        style={{
          width: 420,
          height: 200,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ResponsivePie
          theme={{
            fontSize: 14,
          }}
          data={[
            {
              id: 'Training',
              label: 'Training',
              value: (value?.trainingSplit * 100).toFixed(0),
            },
            {
              id: 'Evaluation',
              label: 'Evaluation',
              value: (100 - value?.trainingSplit * 100).toFixed(0),
            },
          ]}
          margin={{
            top: 40,
            right: 90,
            bottom: 40,
            left: 90,
          }}
          colors={(entry) => {
            switch (entry.id) {
              case 'Training':
                return '#224e90';
              case 'Evaluation':
                return '#a6aeb5';
              default:
                return '#ffffff';
            }
          }}
          sliceLabelsTextColor={{ from: 'color' }}
          radialLabelsTextColor={'#071b32'}
          radialLabel={(d) => `${d.id}: ${d.value}%`}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={2}
          // @ts-ignore
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        />
      </div>

      <NumericSliderInput
        id={'trainingSplit'}
        name={'trainingSplit'}
        touched={touched}
        error={error}
        hasLabel={false}
        valuePreview={(value: number) => ({ id: ' ', defaultValue: ' ' })}
        value={value.trainingSplit}
        onChange={(trainingSplit: number) =>
          onChange({ ...value, trainingSplit })
        }
        onBlur={(trainingSplit: number) => onBlur({ ...value, trainingSplit })}
        lowerBound={0.01}
        upperBound={0.99}
        stepSize={0.01}
      />

      <div className={styles.infoBox}>
        <InfoBox>
          <FormattedMessage
            id={'augur.info_box.sampling_strategy_auto'}
            defaultMessage={
              '<b>{trainingSplit}%</b> of the input data is used as training data. Depending on the settings of the {linkModelTuning}, a cross validation might be applied to the training data during the Training Job.\n' +
              '\n' +
              '<b>{evaluationSplit}%</b> of the input data is used as evaluation data. It is treated as a hold-out sample and will not be used during the Training job. This data will be used during the Evaluation Job to evaluate the Augur performance.\n' +
              '\n' +
              'The sample will be drawn random and stratified with respect to the label column of the input table.'
            }
            values={{
              b: (chunks) => <b>{chunks}</b>,
              trainingSplit: (value?.trainingSplit * 100).toFixed(0),
              evaluationSplit: (100 - value?.trainingSplit * 100).toFixed(0),
              linkModelTuning: withLinksInInfo ? (
                <Link to={modelTuningLink}>Model Tuning</Link>
              ) : (
                'Model Tuning'
              ),
            }}
          />
        </InfoBox>
      </div>
    </div>
  );
};

export default AutomaticStrategy;
