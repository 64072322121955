import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import Propensities from './Propensities';
import { updateDatapool } from '../../../../../../redux/modules/datapool.details.module';
import { fieldPropensities } from './form';

export function mapStateToProps(state: RootState) {
  const habitatCode = state.datapoolDetails?.datapool?.habitat?.code;
  const datapoolCode = state.datapoolDetails?.datapool?.code;

  return {
    initialValues: {
      [fieldPropensities]:
        state.datapoolDetails?.datapool?.meta?.propensities || [],
    },
    habitatCode,
    datapoolCode,
    communications: state.datapoolDetails?.datapool?.meta?.communications || [],
  };
}

export const mapDispatchToProps = {
  updateDatapool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Propensities);
