import React, { FC } from 'react';
import { FiRotateCw, FiTerminal } from 'react-icons/fi';
import { orchestrationRoutes } from '../routes';
import IconButton from '../../atoms/icon-button/IconButton';
import BackTo from '../../atoms/back-to/BackTo';
import { originName, OriginsType } from '../links';
import { useIntl } from 'react-intl';
import commonMessages from 'common/dist/messages/common';
import orchestrationMessages from 'common/dist/messages/orchestration';
import styles from '../details/styles.module.scss';
import Button from '../../atoms/button/Button';
import { StatusType } from 'common/dist/types/job';
import { JOB_GROUP_VARIANTS } from '../details/job-group-details/JobGroupDetails';

export type Props = {
  /** Contains the id of the site from which it was opened (to show the "<< Return to" button) */
  origin: OriginsType;
  jobCode: string;
  jobStatus: StatusType;
  cancelJob: (jobCode: string) => void;
  /** Fetch (+ refresh) the job details */
  fetchJobDetails: (jobCode: string) => void;
  isAdmin: boolean;
};

const JobDetailsHeader: FC<Props> = ({
  origin,
  jobCode,
  jobStatus,
  cancelJob,
  fetchJobDetails,
  isAdmin,
}) => {
  const showCancelButton = (
    [
      'waiting',
      'waiting-in-execution-queue',
      'triggered',
      'running',
    ] as StatusType[]
  ).includes(jobStatus);

  const intl = useIntl();

  return (
    <div className={styles.detailsHeader}>
      <div className={styles.backButton}>
        <BackTo
          linkTo={origin}
          label={intl.formatMessage(commonMessages.backTo, {
            origin: intl.formatMessage(originName(origin)),
          })}
        />
      </div>
      <div className={styles.headerButtonContainer}>
        <IconButton
          Icon={() => <FiRotateCw size={16} className={'refresh-icon'} />}
          onClick={() => fetchJobDetails(jobCode)}
          title={intl.formatMessage(commonMessages.refresh)}
        />
        {isAdmin && (
          <IconButton
            Icon={() => <FiTerminal size={18} className={'refresh-icon'} />}
            linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}/logs`}
            title={intl.formatMessage(
              orchestrationMessages.jobDetailsCancelButtonTitle
            )}
          />
        )}
        {showCancelButton && (
          <Button
            buttonColor={'orange'}
            withLink={false}
            buttonLabelDefault={intl.formatMessage(commonMessages.cancel)}
            onClick={() => cancelJob(jobCode)}
            additionalClassNames={[styles.headerButton]}
          />
        )}
      </div>
    </div>
  );
};

export default JobDetailsHeader;
