import React, { ChangeEvent, DragEvent, Component, Fragment } from 'react';
import { FiFile, FiFolder } from 'react-icons/fi';
import Busy from '../busy/Busy';
import styles from './styles.module.scss';
import classNames from 'classnames';
import _ from 'lodash';
import messagesAtoms from 'common/dist/messages/atoms';
import { FormattedMessage } from 'react-intl';

export interface Props {
  uploading?: boolean;
  onFilesAdded: (fileArray: File[]) => void;
  /** Accepted file extensions. This string is a comma-separated list of unique file type specifiers. E.g. .csv */
  acceptedFileTypes?: string;
  onBlur?: (...args: unknown[]) => void;
  multiple: boolean;
}

interface State {
  highlight: boolean;
}

export default class Dropzone extends Component<Props, State> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  directoryInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = { highlight: false };
    this.fileInputRef = React.createRef();
    this.directoryInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog(inputType: string) {
    const { uploading } = this.props;
    if (uploading) return;
    if (inputType === 'file') {
      this.fileInputRef.current?.click();
    } else if (inputType === 'directory') {
      this.directoryInputRef.current?.click();
    }
  }

  onFilesAdded(evt: ChangeEvent<HTMLInputElement>) {
    const { uploading, onFilesAdded } = this.props;
    if (uploading) return;

    const files = evt.target.files;
    const array = files ? this.fileListToArray(files) : [];
    onFilesAdded(array);
  }

  onDragOver(evt: DragEvent) {
    evt.preventDefault();

    if (this.props.uploading) return;

    this.setState({ highlight: true });
  }

  onDragLeave() {
    this.setState({ highlight: false });
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (this.props.uploading) return;

    const items = event.dataTransfer.items;
    Object.values(items).forEach(async (item) => {
      const files = await this.traverseFileTreeAsync(item.webkitGetAsEntry());
      this.props.onFilesAdded(files);
    });
    this.setState({ highlight: false });
  }

  fileListToArray(list: FileList): File[] {
    const array: File[] = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i) as File);
    }
    return array;
  }

  /**
   * Set the correct webkitRelativePath(s) in cases, where they are missing like drag&drop.
   * @param item
   * @param path
   */
  async traverseFileTreeAsync(
    item: FileSystemEntry,
    path?: string
  ): Promise<File[]> {
    const { acceptedFileTypes } = this.props;
    path = path || '';
    if (isFile(item)) {
      if (
        acceptedFileTypes &&
        !_.some(acceptedFileTypes.split(','), (ext) => item.name.endsWith(ext))
      ) {
        console.warn(
          `Dropped file does not match allowed file types: ${acceptedFileTypes}`
        );
        return [];
      }
      // Get file
      const file = await new Promise<File>((resolve, reject) =>
        item.file(resolve, reject)
      );
      // Chrome does not set the webkitRelativePath (and it's read-only) for directories that are drag and dropped
      if (!file.webkitRelativePath) {
        Object.defineProperty(file, 'webkitRelativePath', {
          value: path + item.name,
        });
      }
      return [file];
    } else if (isDirectory(item)) {
      // Get folder contents
      const dirReader = item.createReader();
      const entries = await new Promise<FileSystemEntry[]>((resolve, reject) =>
        dirReader.readEntries(resolve, reject)
      );
      const allInnerFiles = await Promise.all(
        Object.values(entries).map((entry) =>
          this.traverseFileTreeAsync(entry, path + item.name + '/')
        )
      );
      return allInnerFiles.flat();
    }
  }

  /**
   * input of type file is uncontrolled and the onChange is only triggered when files change (chrome,edge)
   * So to reset it totally (and allow you to pick the same files again), we need to manually set the value.
   * Strange because docs say this shouldn't be possible... https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#notes
   * Instead files should work, but doesn't.
   *
   * Only use this if you really need to. Instead, maybe converting this into a controlled component that manages a value all the time
   * may be cleaner. Then we could do this automatically when the value changes to null for example.
   */
  resetValues() {
    if (this.fileInputRef.current && this.directoryInputRef.current) {
      this.fileInputRef.current.value = '';
      // this.fileInputRef.current.files = null;
      this.directoryInputRef.current.value = '';
      // this.directoryInputRef.current.files = null;
    }
  }

  render() {
    const { uploading, acceptedFileTypes, onBlur, multiple } = this.props;
    return (
      <div
        className={classNames(styles.dropzoneContainer, {
          [styles.highlight]: this.state.highlight,
          [styles.multiple]: multiple,
        })}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        style={{ cursor: uploading ? 'default' : 'pointer' }}
      >
        {uploading ? (
          <Busy isBusy positionAbsolute />
        ) : (
          <Fragment>
            <div
              className={styles.dropzoneContainerFiles}
              onClick={() => this.openFileDialog('file')}
            >
              <input
                ref={this.fileInputRef}
                className={styles.fileInput}
                type='file'
                multiple={multiple}
                onChange={this.onFilesAdded}
                accept={acceptedFileTypes}
                // TODO this does not work (so the relevant field is never touched for example)
                onBlur={onBlur || (() => {})}
              />
              <FiFile className={styles.icon} />
              <span className={styles.uploadLabel}>
                {multiple ? (
                  <FormattedMessage {...messagesAtoms.dropzoneUploadFiles} />
                ) : (
                  <FormattedMessage {...messagesAtoms.dropzoneUploadFile} />
                )}
              </span>
            </div>

            {multiple && (
              <div
                className={styles.dropzoneContainerDirectories}
                onClick={() => this.openFileDialog('directory')}
              >
                <input
                  ref={this.directoryInputRef}
                  className={styles.fileInput}
                  type='file'
                  multiple
                  // directory={''}
                  // mozdirectory={''}
                  // @ts-ignore Non-standard, but implemented and should be the only one needed
                  webkitdirectory={''}
                  onChange={this.onFilesAdded}
                />
                <FiFolder className={styles.icon} />
                <span className={styles.uploadLabel}>
                  <FormattedMessage {...messagesAtoms.dropzoneUploadFolder} />
                </span>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const isFile = (entry: FileSystemEntry): entry is FileSystemFileEntry =>
  entry.isFile;

const isDirectory = (
  entry: FileSystemEntry
): entry is FileSystemDirectoryEntry => entry.isDirectory;
