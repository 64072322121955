import React, { FC } from 'react';
import { flow } from 'lodash';
import { FormattedMessage, IntlShape } from 'react-intl';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { Period } from 'common/dist/constants/schedulePeriods';
import styles from './styles.module.scss';

type Props = {
  periods: Period[];
  namespace: string;
  onValueChange: (value: string) => void;
  className: string;
  intl: IntlShape;
  input: {
    value: string;
    onChange: (periodId: string) => void;
  };
  disabled: boolean;
};

type OptionType = {
  label: string;
  value: string;
};

const PeriodField: FC<Props> = (props) => {
  const {
    periods,
    namespace,
    className,
    onValueChange,
    intl,
    input,
    disabled,
  } = props;

  const options: OptionType[] = periods.map((period) => ({
    value: period.id,
    label: intl.formatMessage({ id: period.label }),
  }));

  return (
    <div className={styles.fieldParent}>
      <FormattedMessage id={'no-id'} defaultMessage={'Schedule Mode'}>
        {(text) => <p className={styles.fieldLabel}>{text}</p>}
      </FormattedMessage>

      <DropdownSelectInput
        id={`${namespace}-period`}
        name={`${namespace}-period`}
        className={className}
        options={options}
        placeholder={{
          id: 'common.scheduleSelect.select_placeholder',
          defaultMessage: 'Select...',
        }}
        value={options.find((x) => x.value === input.value)}
        onChange={(option: OptionType) =>
          flow(input.onChange, onValueChange)(option.value)
        }
        disabled={disabled}
      />
    </div>
  );
};

export default PeriodField;
