import React, { Component, Fragment } from 'react';
import styles from './styles.module.scss';
import {
  FormattedMessage,
  injectIntl,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';
import { WizardPageProps } from './WizardPage';
import Button from '../../atoms/button/Button';
import { FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import { WizardStepProps } from './WizardStep';
import _ from 'lodash';
import classNames from 'classnames';
import { FiCheck } from 'react-icons/fi';
import notificationsMsgs from 'common/dist/messages/notifications';
import { error as errorType } from '../../../core/notifications';

export type Props<FormValues, ErrorType> = {
  // --- Child components
  children?: React.ReactElement<WizardPageProps>[];

  // --- Labels
  /** Default value: { defaultMessage: 'Submit' } */
  submitButtonLabel?: MessageDescriptor;
  /** Default value: { defaultMessage: 'Cancel' } */
  cancelButtonLabel?: MessageDescriptor;
  /** Default value: { defaultMessage: 'Next' } */
  nextButtonLabel?: MessageDescriptor;
  /** Default value: { defaultMessage: 'Previous' } */
  prevButtonLabel?: MessageDescriptor;
  /** Headline of the Wizard. Optional, if not set the wizard simply doesn't have a headline */
  wizardHeadline?: MessageDescriptor;
  /** Default value: { defaultMessage: 'Delete' } */
  deleteButtonLabel?: MessageDescriptor;
  deleteButtonLabelIsVisible?: boolean;
  // --- Callbacks
  /** Invoked when clicking the "Cancel" button */
  onCancel?: () => void;
  /** Invoked when clicking the "Delete" button */
  onDelete?: () => void;
  // --- Submitting & Submit Success / Error
  /** Hide the submit button for this wizard (makes sense if the Wizard will be extended dynamically. And while the wizard is filled, the submit button is hidden)
   */
  noNextOrSubmitButton?: boolean;
  /** Is the form currently submitting? Causes the "submit" button to bubble */
  isSubmitting?: boolean;
  sendNotification: (
    title: string,
    description: string,
    type: string,
    descriptionValues?: { [value: string]: string },
    titleValues?: { [value: string]: string },
    progress?: number
  ) => void;
};

export type ContainerProps<FormValues, ErrorType> = {
  /** Injected by MultiPageWizard.container */
  // mpwSubmitErrors: FormErrors<FormValues, ErrorType>,
  /** Injected by MultiPageWizard.container */
  mpwSyncErrors?: FormErrors<FormValues, ErrorType>;
  /** Injected by MultiPageWizard.container */
  mpwAsyncErrors?: FormErrors<FormValues, ErrorType>;
};

type State = {
  /** Index of the currently active page */
  activePage?: number;

  /** To decide whether to render a shadow at the top or the bottom */
  showShadowTop?: boolean;
  showShadowBottom?: boolean;
};

class MultiPageWizard<FormValues, ErrorType> extends Component<
  Props<FormValues, ErrorType> &
    WrappedComponentProps &
    InjectedFormProps<FormValues, Props<FormValues, ErrorType>> &
    ContainerProps<FormValues, ErrorType>,
  State
> {
  bodyRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    // --- Labels
    submitButtonLabel: { id: 'no-id', defaultMessage: 'Submit' },
    cancelButtonLabel: { id: 'no-id', defaultMessage: 'Cancel' },
    deleteButtonLabel: {
      id: 'no-id',
      defaultMessage: 'Delete',
    },
    nextButtonLabel: { id: 'no-id', defaultMessage: 'Next' },
    prevButtonLabel: { id: 'no-id', defaultMessage: 'Previous' },
  };

  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
    };
  }

  componentDidMount() {
    const { touch, initialValues } = this.props;
    if (typeof initialValues === 'object') touch(...Object.keys(initialValues));
    this.measureBodyHeight();
  }

  componentDidUpdate(prevProps) {
    const { touch, initialValues } = this.props;
    if (
      typeof initialValues === 'object' &&
      !_.isEqual(initialValues, prevProps.initialValues)
    ) {
      touch(...Object.keys(initialValues));
    }
    if (this.props.children !== prevProps.children) {
      this.measureBodyHeight();
    }
  }

  measureBodyHeight() {
    const current = this.bodyRef.current;
    if (!current) return;

    const { clientHeight, scrollTop, scrollHeight } = current;
    const higherThanContainer = scrollHeight > clientHeight;

    const TOLERANCE = 3;
    const showShadowTop = higherThanContainer && scrollTop > TOLERANCE;
    const showShadowBottom =
      higherThanContainer &&
      scrollTop < scrollHeight - clientHeight - TOLERANCE;

    this.setState({
      showShadowBottom,
      showShadowTop,
    });
  }

  getActivePageChild() {
    const { children } = this.props;

    if (Array.isArray(children)) {
      return (children || [])
        .filter((_) => _)
        .find((child, childIndex) => childIndex === this.state.activePage);
    } else if (React.isValidElement(children)) {
      return children;
    } else {
      return null;
    }
  }

  /**
   * Renders the currently active WizardPage
   */
  renderActiveWizardPage() {
    const activeChild = this.getActivePageChild();

    if (React.isValidElement(activeChild)) {
      return React.cloneElement<WizardPageProps>(activeChild);
    } else {
      return null;
    }
  }

  /**
   * Return a message if a field on the active page has an error or is async validating
   */
  errorsOnThisPage(): MessageDescriptor | undefined {
    const { asyncValidating, mpwSyncErrors, mpwAsyncErrors } = this.props;

    const syncAndAsyncErrors = {
      ...(mpwSyncErrors || {}),
      ...(mpwAsyncErrors || {}),
    };

    let fieldsOnThisPage = [];
    const activePage = this.getActivePageChild();
    let stepsOfActivePage =
      activePage?.props?.children ||
      ([] as React.ReactElement<WizardStepProps<unknown, unknown>>[]);
    if (!Array.isArray(stepsOfActivePage)) {
      stepsOfActivePage = [stepsOfActivePage];
    }
    fieldsOnThisPage = _.flatMap(stepsOfActivePage, (wizardStep) => {
      if (wizardStep.type === React.Fragment) {
        // Fragment
        if (Array.isArray(wizardStep.props?.children)) {
          return (wizardStep.props?.children || []).map(
            (fragmentChild) => fragmentChild.props?.fieldName
          );
        } else {
          return []; // Is this correct?
        }
      } else {
        // No fragment, but a <WizardStep />
        return [wizardStep.props?.fieldName];
      }
    });

    // If an async validation is currently going on: Disable the button
    if (
      asyncValidating &&
      fieldsOnThisPage.includes(asyncValidating as string)
    ) {
      return {
        id: 'no-id', // TODO
        defaultMessage: 'One of the fields is still validating',
      };
    }

    // Iterate over the fields and once an error is found, return it.
    for (const fieldName of fieldsOnThisPage) {
      if (syncAndAsyncErrors[fieldName]) {
        return {
          id: 'no-id', // TODO
          defaultMessage: 'There are invalid fields on this page',
        };
      }
    }

    // No error found: return undefined
    return undefined;
  }

  getAmountPages() {
    const { children } = this.props;

    // .filter(_ => _) is required since there might be some children that simply are "false"
    //   for example if something like this is rendered: { false && <div /> }
    if (Array.isArray(children)) {
      return (children || []).filter((_) => _).length;
    } else if (React.isValidElement(children)) {
      return 1;
    } else {
      return 0;
    }
  }

  renderPagesInfo() {
    const { activePage } = this.state;
    const amountPages = this.getAmountPages();
    const activePageNumber = activePage + 1;

    const bubbleConnection = (i) =>
      i + 1 < amountPages ? (
        <span className={classNames(styles.pageBubbleConnection)} />
      ) : null;

    return (
      <div className={styles.pageInfo}>
        {_.range(amountPages).map((i) => {
          if (i + 1 > activePageNumber) {
            return (
              <Fragment key={i}>
                <span
                  key={i}
                  className={classNames(
                    styles.pageBubble,
                    styles.pageBubbleUpcoming
                  )}
                />
                {bubbleConnection(i)}
              </Fragment>
            );
          } else if (i + 1 === activePageNumber) {
            return (
              <Fragment key={i}>
                <span
                  key={i}
                  className={classNames(
                    styles.pageBubble,
                    styles.pageBubbleCurrent
                  )}
                />
                {bubbleConnection(i)}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={i}>
                <span
                  key={i}
                  className={classNames(
                    styles.pageBubble,
                    styles.pageBubbleDone
                  )}
                >
                  <FiCheck size={12} />
                </span>
                {bubbleConnection(i)}
              </Fragment>
            );
          }
        })}
      </div>
    );
  }

  renderFooter() {
    const {
      submitButtonLabel,
      cancelButtonLabel,
      nextButtonLabel,
      prevButtonLabel,
      deleteButtonLabel,
      deleteButtonLabelIsVisible,
      onDelete,
      onCancel,
      handleSubmit, // Injected by reduxForm(), uses the passed onSubmit function by default after validating
      valid,
      isSubmitting,
      noNextOrSubmitButton,
      asyncValidating,
      sendNotification,
    } = this.props;
    const { activePage } = this.state;
    // For some brilliant reason redux-form wants you to reject promises if async validation fails but if submission fails due to that
    // it then throws that rejection as an error into nirvana
    //We also need to check if handleSubmit(e) is undefined, otherwise we can't catch the error
    const safeHandleSubmit = (e) =>
      handleSubmit(e)?.catch((e) => {
        // show a notification in case an augur with the same name was created (by a different user or due to a server error) after the name was checked on first page
        if (e.augurName) {
          sendNotification(
            notificationsMsgs.msgTitleNewAugurExists.id,
            notificationsMsgs.msgDescriptionNewAugurExists.id,
            errorType,
            { augurName: e.augurName.name }
          );
        }
      });

    const amountPages = this.getAmountPages();

    const activeChild = this.getActivePageChild();
    const pageTitle = activeChild?.props?.pageTitle;

    const errorsOnThisPage = this.errorsOnThisPage();

    return (
      <div className={styles.footerContainer}>
        {this.state.showShadowBottom && (
          <div className={styles.shadowBodyBottom} />
        )}
        <div className={styles.buttonsLeft}>
          {amountPages > 1 && (
            <Button
              buttonColor={'blue'}
              additionalClassNames={[styles.buttonPrev]}
              buttonLabelId={prevButtonLabel.id as string}
              buttonLabelDefault={prevButtonLabel.defaultMessage as string}
              withLink={false}
              disabled={activePage === 0 || isSubmitting}
              onClick={() => this.setState({ activePage: activePage - 1 })}
            />
          )}
          <Button
            buttonColor={'white'}
            additionalClassNames={[styles.buttonCancel]}
            buttonLabelId={cancelButtonLabel.id as string}
            buttonLabelDefault={cancelButtonLabel.defaultMessage as string}
            withLink={false}
            onClick={() => onCancel && onCancel()}
          />
          {deleteButtonLabelIsVisible && (
            <Button
              buttonColor={'red'}
              additionalClassNames={[styles.buttonCancel]}
              buttonLabelId={deleteButtonLabel.id as string}
              buttonLabelDefault={deleteButtonLabel.defaultMessage as string}
              withLink={false}
              onClick={() => {
                onDelete();
              }}
            />
          )}
        </div>

        <div className={styles.infoCenter}>
          {amountPages && this.renderPagesInfo()}

          {pageTitle && (
            <FormattedMessage {...pageTitle}>
              {(text) => <span className={styles.pageTitle}>{text}</span>}
            </FormattedMessage>
          )}
        </div>

        {noNextOrSubmitButton ? (
          <div className={styles.buttonsRightPlaceholder} />
        ) : (
          <div
            className={styles.buttonsRight}
            data-testingIdentifier={submitButtonLabel.defaultMessage}
          >
            {activePage < amountPages - 1 ? (
              <Button
                buttonColor={'blue'}
                additionalClassNames={[styles.buttonNext]}
                buttonLabelId={nextButtonLabel.id as string}
                buttonLabelDefault={nextButtonLabel.defaultMessage as string}
                withLink={false}
                // This also disables while asyncValidating
                disabled={!!errorsOnThisPage}
                isBusy={Boolean(asyncValidating)}
                // Don't wrap in handleSubmit, which would automatically call (async) validation since we would need
                // to only validate the fields on the current page which is not very well-supported by asyncValidate
                onClick={() => this.setState({ activePage: activePage + 1 })}
              />
            ) : (
              <Button
                buttonColor={'green'}
                additionalClassNames={[styles.buttonSubmit]}
                buttonLabelId={submitButtonLabel.id as string}
                buttonLabelDefault={submitButtonLabel.defaultMessage as string}
                withLink={false}
                // With onBlur fields, this will often validate twice 1. for the onBlur of the field 2. again for the whole form
                disabled={!valid || isSubmitting}
                // Can't set isBusy={... || Boolean(asyncValidating)} like in the other button because that also disables it
                // Scenario: 1. You are inside a text field with asyncValidation that validates onBlur 2. You click this submit button
                // What happens: The async validation is triggered before the onClick can be called
                isBusy={isSubmitting}
                // @ts-ignore
                onClick={safeHandleSubmit}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { wizardHeadline } = this.props;

    return (
      <div className={styles.multiPageWizard}>
        {wizardHeadline && (
          <div className={styles.headlineContainer}>
            <FormattedMessage {...wizardHeadline} />
            {this.state.showShadowTop && (
              <div className={styles.shadowBodyTop} />
            )}
          </div>
        )}
        <div
          className={classNames(styles.bodyContainer)}
          ref={this.bodyRef}
          onScroll={(e) => {
            this.measureBodyHeight();
          }}
        >
          {this.renderActiveWizardPage()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default reduxForm<
  {} /* This should be the generic: FormValues */,
  Props<{}, {}>
>({
  destroyOnUnmount: false, // preserve form data (Not because of the wizard nature, but because of multiple MultiPageWizards for each module)
  touchOnChange: true,
})(injectIntl<'intl', Props<{}, {}> & WrappedComponentProps>(MultiPageWizard));
