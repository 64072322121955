import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { Field, reduxForm } from 'redux-form';
import { createBranchForm } from './createBranch.form';
import FieldCommitMessage from '../../git-commit/FieldCommitMessage';
import infoStyles from './../../../info/styles.module.scss';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class CreateBranchModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      buttonClicked: false,
    };
  }

  handleClick() {
    const {
      pristine,
      valid,
      infoFilePath,
      branchName,
      gitCreateBranch,
      remote,
    } = this.props;

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---
    let group, repoName;
    if (remote) {
      const partsRemote = remote.pathname.substr(1).split('/');
      group = partsRemote[0];
      repoName = partsRemote.slice(1).join('/').replace('.git', '');
    }
    if (!pristine && valid && !this.state.buttonClicked) {
      this.setState({ buttonClicked: true });
      gitCreateBranch(repositoryPath, branchName, group, repoName);
    }
  }

  render() {
    const { isCreateBranchModalOpen, hideCreateBranchModal, valid, pristine } =
      this.props;

    return (
      <ReactModal
        isOpen={isCreateBranchModalOpen}
        contentLabel='Create Branch Modal'
        onRequestClose={hideCreateBranchModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
           that the modal will be attached to. */
        }
      >
        <div className={infoStyles.modalCommitParent}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={hideCreateBranchModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={infoStyles.modalCommitElements}>
            <Field
              name={createBranchForm.fieldBranchName}
              label={'Branch Name'}
              placeholder={'Branch Name'}
              component={FieldCommitMessage}
            />
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <div
              className={`button ${
                pristine || !valid || this.state.buttonClicked
                  ? 'button-disabled'
                  : ''
              }`}
              onClick={this.handleClick}
            >
              Create
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

CreateBranchModal.propTypes = {
  isCreateBranchModalOpen: PropTypes.bool,
  hideCreateBranchModal: PropTypes.func.isRequired,
  gitCreateBranch: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  pristine: PropTypes.bool,
};

CreateBranchModal.defaultProps = {
  isOpen: false,
  valid: false,
  pristine: true,
};

export default reduxForm(createBranchForm)(CreateBranchModal);
