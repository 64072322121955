import React, { Component } from 'react';
import InputSlider from 'react-input-slider';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor,
} from 'react-intl';
import './styles.scss';
import InputError from '../../input-error/InputError';

/**
 * Rounds the number according to the step size.
 * The problem is, that for example for step size 0.1, the float precision leads to values like 6.6000000000000005
 * "Cutting" according to the step size removes this problem.
 * @param number
 * @param lowerBound
 * @param upperBound
 * @param stepSize
 */
function roundToStepSize(
  number: number,
  lowerBound: number,
  upperBound: number,
  stepSize: number
): number {
  if (!number || !stepSize || !lowerBound || !upperBound) return number;
  const amountDecimals = (x: number) => {
    const isFloat = x.toString().includes('.');
    if (!isFloat) {
      // The number has no decimal places
      return 0;
    } else {
      // Determine the amount of decimal places and "cut" the float
      return x.toString().length - 1 - x.toString().indexOf('.');
    }
  };

  // Calculate the maximum amount of decimal places of the stepSize, lower bound and upper bound
  // Doing so for the stepSize alone wouldn't be enough, since for example lowerBound = 0.5 stepSize = 1 upperBound = 2.5
  // would otherwise give the values [0,1,2] instead of [0.5, 1.5, 2.5]
  const maxDecimals = Math.max(
    amountDecimals(lowerBound),
    amountDecimals(stepSize),
    amountDecimals(upperBound)
  );
  return Number(number.toFixed(maxDecimals));
}

export interface Props {
  /** ID of the input element */
  id?: string;
  /** Name of the input field */
  name?: string;

  /** Has the field been touched? */
  touched: boolean;
  /** Has the field an error? */
  error?: string | { id: string; values: object };

  /** Render a label for the input field? */
  hasLabel: boolean;
  /** Intl specs for the label */
  label?: MessageDescriptor;
  /** Function that gets passed the current value of the slider and returns a MessageDescriptor for the value preview */
  valuePreview: (value: number) => MessageDescriptor;

  /** Value of the input field */
  value: number;
  /** onChange callback */
  onChange: (value: number) => void;
  /** onBlur callback */
  onBlur: (value: number) => void;

  intl: IntlShape;

  lowerBound: number;
  upperBound: number;
  stepSize: number;
}

class NumericSliderInput extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      lowerBound,
      upperBound,
      stepSize,
      value,
      onChange,
      hasLabel,
      intl,
      label,
      touched,
      error,
      valuePreview,
    } = this.props;

    let formattedLabel = '';
    if (hasLabel) {
      formattedLabel = intl.formatMessage({
        ...label,
        id: label?.id || 'no-id',
      });
    }

    let preview = valuePreview(value) || ({} as MessageDescriptor);
    preview = { ...preview, id: preview.id || 'no-id' }; // Make sure the id is at least not undefined

    return (
      <div className={'NumericSliderInput'}>
        <div className={'NumericSliderInput--header'}>
          {hasLabel && (
            <p className={'NumericSliderInput--label'}>{formattedLabel}</p>
          )}
          <div className='NumericSliderInput--error'>
            {error && <InputError touched={touched} error={error} />}
          </div>
        </div>

        <div className={'NumericSliderInput--preview'}>
          <FormattedMessage {...preview} />
        </div>

        <div className={'NumericSliderInput--input'}>
          <InputSlider
            xmin={lowerBound}
            xmax={upperBound + 0.00000001}
            xstep={stepSize}
            x={value}
            onChange={({ x }) =>
              onChange(roundToStepSize(x, lowerBound, upperBound, stepSize))
            }
            axis='x'
            styles={{
              active: { backgroundColor: '#224E90' },
            }}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(NumericSliderInput);
