import React, { FC } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { resetEventSource } from '../../../../redux/workbench/modules/connection.module';
import MainContainer from '../../../pages/main-container/MainContainer';

const ServerIsStopping: FC<Record<string, any>> = (props) => {
  const dispatch = useDispatch();
  dispatch(resetEventSource());
  return (
    <MainContainer
      additionalClassname={'StartWorkbench'}
      transparent={true}
      fullWidth={false}
    >
      <div className={'button-container'}>
        <div className={'start-server-parent'}>
          <div className={'start-server-headline-parent'}>
            <span className={'start-server-headline'}>
              AI Workbench Server Is shutting down...
            </span>
          </div>
          <div className={'start-server-button-parent'}>
            <ReactLoading
              className={'starting-stopping-spinner'}
              type={'cylon'}
              color={'#224e90'}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ServerIsStopping;
