import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SingleEditorPane from './SingleEditorPane.container';
import PanelGroup from 'react-panelgroup';

import { FiMaximize, FiMinimize } from 'react-icons/fi';

export const PANE_TYPE = {
  EDITOR: 'editor',
  PANES: 'panes',
};

export default class EditorParent extends Component {
  renderPane(paneElement) {
    const { panes, resizeSplitPane } = this.props;
    if (!paneElement) {
      console.log('Undefined paneElement');
      return undefined;
    }

    const { type, id, split, children } = paneElement;
    if (!type || ![PANE_TYPE.EDITOR, PANE_TYPE.PANES].includes(type)) {
      console.log('Undefined or unknown type ' + type);
      return undefined;
    }

    // type = editor
    if (type === PANE_TYPE.EDITOR) {
      return <SingleEditorPane key={id} paneId={id} />;
    }

    if (type === PANE_TYPE.PANES) {
      return (
        <PanelGroup
          key={id}
          direction={split === 'vertical' ? 'row' : 'column'}
          panelWidths={(panes[id] || {}).measures}
          onResizeEnd={(newMeasures) => resizeSplitPane(id, newMeasures)}
        >
          {children.map((element) => this.renderPane(element))}
        </PanelGroup>
      );
    }
  }

  render() {
    const { paneHierarchy, fullscreen, setFullscreen } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <div
          title={'Maximize'}
          className={'fullscreen-button'}
          onClick={() => setFullscreen(!fullscreen)}
        >
          {fullscreen ? (
            <FiMinimize className={'fullscreen-icon'} size={'20px'} />
          ) : (
            <FiMaximize className={'fullscreen-icon'} size={'20px'} />
          )}
        </div>

        {this.renderPane(paneHierarchy)}
      </div>
    );
  }
}

EditorParent.propTypes = {
  // type editor: { type, id }
  // type panes: { type, split, children }
  paneHierarchy: PropTypes.shape({}),
  panes: PropTypes.arrayOf(
    PropTypes.shape({
      measures: PropTypes.shape({}),
    })
  ),
  fullscreen: PropTypes.bool,
  setFullscreen: PropTypes.func.isRequired,
  resizeSplitPane: PropTypes.func.isRequired,
};
