import React, { Component, Fragment } from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import './styles.scss';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Bars from './Bars';
import Sizer from '../../../atoms/sizer/Sizer';
import { MessageDescriptor } from 'react-intl';
import { WrappedComponentProps } from 'react-intl/src/components/injectIntl';
import Axis from '../line-chart/Axis';
import { max, min } from 'd3-array';
import { toLinkWhitelistQuery } from '../../../../utils';
import { AugurDetailsWhitelistQuery } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { RouteComponentProps } from 'react-router';

function getX(item: [number, number]): number {
  return +item[0];
}

export type DataType = [number, number][];

type InnerBarChartProps = {
  /** Injected by the <Sizer> component */
  width: number;
  /** Injected by the <Sizer> component */
  height: number;
  /** Injected by the <Sizer> component */
  left: number;
  /** Force the sizer to measure again (called after drawing the SVG for the first time) */
  requestSize: () => void;
} & BarChartProps;

/**
 * This component is not exported. Instead it is wrapped in a <Sizer> which cares about measuring the width, height and
 * position on the screen. This wrapped component in turn is exported.
 */
class InnerBarChart extends Component<InnerBarChartProps, {}> {
  componentDidMount() {
    const { requestSize } = this.props;
    requestSize();
  }

  render() {
    const { data, width, height, colors, yLabel, xLabel } = this.props;

    const margin = {
      top: 10,
      right: 15,
      bottom: xLabel ? 35 : 20,
      left: yLabel ? 50 : 35,
    };

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const yLabelPadding = 40;
    const xLabelPadding = 30;

    const translate = `translate(${margin.left}, ${margin.top})`;

    const yScale = scaleLinear().range([chartHeight, 0]);
    const xScale = scaleBand().range([0, chartWidth]).paddingInner(0.2);
    const xTickScale = scaleLinear()
      .range([0, chartWidth])
      .domain([min(data, getX), max(data, getX)]);

    if (!data || data.length === 0) {
      // --- No data available
      return (
        <div className={'BarChart--empty'} style={{ width, height }}>
          <span>No data available</span>
        </div>
      );
      // --- Data available
    } else {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <svg
            width={width}
            height={height}
            style={{ width: '100%', height: '100%' }}
          >
            <g transform={translate}>
              <Bars
                data={data}
                xScale={xScale}
                yScale={yScale}
                colorBar={colors.bar}
              />
              <Axis
                ticks={3}
                scale={yScale}
                tickSize={chartWidth}
                helplineType={'dashed'}
                direction={'left'}
              />
              <g transform={`translate(0,${chartHeight})`}>
                <Axis
                  scale={xTickScale}
                  ticks={6}
                  tickSize={chartHeight}
                  direction={'bottom'}
                />
              </g>
              {xLabel && (
                <text
                  className='accuracy-axis__label'
                  x={chartWidth / 2}
                  y={chartHeight + xLabelPadding}
                  textAnchor={'middle'}
                >
                  <FormattedMessage
                    id={xLabel.id || 'no-id'}
                    defaultMessage={xLabel.defaultMessage}
                  />
                </text>
              )}
              {yLabel && (
                <g
                  transform={`translate(${-yLabelPadding},${chartHeight / 2})`}
                >
                  <text
                    className='accuracy-axis__label'
                    textAnchor={'middle'}
                    transform={`rotate(-90)`}
                  >
                    <FormattedMessage
                      id={yLabel.id || 'no-id'}
                      defaultMessage={yLabel.defaultMessage}
                    />
                  </text>
                </g>
              )}
            </g>
          </svg>
        </div>
      );
    }
  }
}

// ---
export type BarChartProps = {
  /** CSS prop for the height of the chart */
  height: string;
  /** CSS prop for the width of the chart */
  width: string;
  /** Input data for the line chart */
  data?: DataType;
  /** Optional Horizontal base line */
  baseLine?: number;
  /** Optional Line that is drawn into the chart - for example the diagonal [[0,0], [1,1]] for an ROC Chart */
  helpLine?: [[number, number], [number, number]];
  /** Optional title for the chart */
  title?: MessageDescriptor;
  /** Optional label for the y-axis */
  yLabel?: MessageDescriptor;
  /** Optional label for the x-axis */
  xLabel?: MessageDescriptor;
  link?: {
    to: string;
    onClick: () => void;
  };
  /** Render a border around the chart? */
  withBorder?: boolean;
  colors?: {
    bar: string;
  };
};

class BarChart extends Component<BarChartProps & RouteComponentProps> {
  static defaultProps = {
    colors: {
      bar: '#6AA0EB',
    },
  };

  renderInner() {
    const {
      data,
      helpLine,
      title,
      yLabel,
      xLabel,
      withBorder,
      colors,
      baseLine,
    } = this.props;

    return (
      <Fragment>
        {title && (
          <div className={'BarChart--title'}>
            <FormattedMessage
              id={title.id || 'no-id'}
              defaultMessage={title.defaultMessage}
            />
          </div>
        )}
        <div
          className={
            'BarChart--container' +
            (title ? ' BarChart--with-title' : '') +
            (withBorder ? ' BarChart--with-border' : '')
          }
        >
          <Sizer>
            {/* @ts-ignore */}
            <InnerBarChart
              data={data}
              helpLine={helpLine}
              colors={colors}
              baseLine={baseLine}
              yLabel={yLabel}
              xLabel={xLabel}
            />
          </Sizer>
        </div>
      </Fragment>
    );
  }

  render() {
    const { link, width, height, location } = this.props;

    if (link) {
      // --- Render the component wrapped into a <Link>
      return (
        <div className={'BarChart'} style={{ width, height }}>
          <Link
            className='BarChart--link'
            to={toLinkWhitelistQuery(
              link.to,
              location,
              AugurDetailsWhitelistQuery
            )}
            onClick={link.onClick || (() => {})}
          >
            {this.renderInner()}
          </Link>
        </div>
      );
    } else {
      // --- Render the component without a <Link>
      return (
        <div className={'BarChart'} style={{ width, height }}>
          {this.renderInner()}
        </div>
      );
    }
  }
}

export default withRouter(BarChart);
