import React from 'react';

const SvgPython310 = (props) => (
  <svg viewBox='0 0 512 512' width='1em' height='1em' {...props}>
    <path
      d='M512 256c0 25.976-3.866 51.054-11.065 74.679 0 .01 0 .01-.01.021a254.947 254.947 0 0 1-37.345 75.149v.01c-23.27 32.172-53.844 58.723-89.276 77.218a253.049 253.049 0 0 1-32.507 14.19 253.178 253.178 0 0 1-27.961 8.171h-.01C295.236 509.733 275.895 512 256 512c-41.535 0-80.75-9.885-115.43-27.439-32.423-16.405-60.886-39.508-83.55-67.49-20.605-25.412-36.425-54.847-46.122-86.946C3.814 306.667 0 281.778 0 256 0 114.615 114.615 0 256 0s256 114.615 256 256z'
      fill='#264d6f'
    />
    <path
      d='M372.12 111.783V238.07c0 29.727-24.095 53.823-53.823 53.823H188.928c-37.397 0-67.709 30.323-67.709 67.72v62.025H78.921c-7.659 0-15.015-1.515-21.901-4.566-20.605-25.412-36.425-54.847-46.122-86.946-.763-7.92-1.16-16.374-1.16-25.36 0-78.461 30.981-116.872 69.183-116.872H256.71v-18.223H138.386v-57.887c0-29.393 52.328-53.227 116.872-53.227S372.12 82.39 372.12 111.783z'
      fill='#3876ac'
    />
    <circle cx={189.325} cy={116.621} r={21.506} fill='#f2f2f2' />
    <path
      d='M502.951 298.026c0 11.786-.7 22.674-2.017 32.653 0 .01 0 .01-.01.021a254.947 254.947 0 0 1-37.345 75.149v.01c-9.028 6.029-19.132 9.028-29.8 9.028H255.99v18.233h118.314v49.957a253.049 253.049 0 0 1-32.507 14.19 253.178 253.178 0 0 1-27.961 8.171h-.01C295.236 509.733 275.895 512 256 512c-41.535 0-80.75-9.885-115.43-27.439V364.722c0-29.727 24.106-53.833 53.833-53.833h129.369c37.397 0 67.709-30.313 67.709-67.709v-62.025h42.297c38.202-.001 69.173 37.615 69.173 116.871z'
      fill='#f7cd00'
    />
    <path
      d='M344.879 486.17c0 4.065-1.128 7.868-3.082 11.097a253.178 253.178 0 0 1-27.961 8.171h-.01c-7.084-3.511-11.954-10.825-11.954-19.268 0-11.88 9.624-21.504 21.504-21.504 11.879 0 21.503 9.624 21.503 21.504z'
      fill='#f2f2f2'
    />
    <text
      x='45'
      y='450'
      fontFamily={'Roboto, sans-serif'}
      fontSize='215'
      fontWeight={600}
      letterSpacing={-30}
      fill='white'
      stroke='black'
      strokeWidth='3'
    >
      3.10
    </text>
  </svg>
);

export default SvgPython310;
