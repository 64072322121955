import React, { Component } from 'react';
import Switch from 'react-switch/index';
import { Group } from '../../../../store/admin/state.types';

export interface Props {
  groupsData: Group[];
  usersGroups: Pick<Group, 'id' | 'name' | 'path'>[];
  /** Add a User-Group mapping */
  joinGroup: (userId: string, groupId: string) => void;
  /** Remove a User-Group mapping */
  leaveGroup: (userId: string, groupId: string) => void;
  userId: string;
}

export default class Groups extends Component<Props> {
  render() {
    const { groupsData, usersGroups, userId, joinGroup, leaveGroup } =
      this.props;

    return (
      <div className={'groups-container ct-list'}>
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-280px'}>
            <span>Name</span>
          </div>
          <div className={'ct-col ct-col-350px'} />
          <div className={'ct-col ct-col-80px'}>
            <span>Member</span>
          </div>
        </div>
        {groupsData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((group) => (
            <div className={'ct-row groups-row'}>
              <div className={'ct-col ct-col-280px'}>
                <span>{group.name}</span>
              </div>
              <div className={'ct-col ct-col-350px'} />
              <div className={'ct-col ct-col-80px'}>
                <Switch
                  onChange={() => {
                    if (usersGroups.map((g) => g.id).includes(group.id)) {
                      leaveGroup(userId, group.id);
                    } else {
                      joinGroup(userId, group.id);
                    }
                  }}
                  checked={usersGroups.map((g) => g.id).includes(group.id)}
                  height={20}
                  width={40}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  onColor='#224e90'
                />
              </div>
            </div>
          ))}
      </div>
    );
  }
}
