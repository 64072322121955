import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import { hierarchy } from 'd3-hierarchy';

import Bubble from './Bubble';

export default class DataSeries extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      })
    ).isRequired,
    className: PropTypes.string,
    bubbleClassName: PropTypes.string,
    bubbles: PropTypes.func.isRequired,
    color: PropTypes.func.isRequired,
    radius: PropTypes.func.isRequired,
  };

  _renderBubbleSeries(fontSize) {
    const { bubbles, data } = this.props;

    const root = hierarchy({ children: data }).sum((d) => d.value);

    const nodes = bubbles(root).children;

    return nodes.map(this._renderBubble.bind(this, fontSize));
  }

  _renderBubble(fontSize, { data: { label }, value, x, y, r }) {
    const { bubbleClassName, color } = this.props;
    const title = `${label}: ${value}`;

    return (
      <Bubble
        key={title}
        className={bubbleClassName}
        radius={r}
        x={x}
        y={y}
        fill={color(value)}
        stroke='#1E7BE2'
        strokeWidth={1}
        fontSize={fontSize(r)}
        text={label}
        value={value}
      />
    );
  }

  render() {
    const { className, radius, color, data } = this.props;

    const nodeValues = data.map((node) => node.value);

    const fontSize = scaleLinear()
      .domain([0, radius.range()[1]])
      .range([0.2, 1]);

    const minMax = extent(nodeValues);

    radius.domain(minMax);
    color.domain(minMax);

    return <g className={className}>{this._renderBubbleSeries(fontSize)}</g>;
  }
}
