import { connect } from 'react-redux';
import EditorParent from './EditorParent';
import {
  resizeSplitPane,
  setFullscreen,
} from '../../../../../redux/workbench/modules/notebook.module';

export function mapStateToProps(state) {
  return {
    paneHierarchy: state.workbench.paneHierarchy,
    panes: state.workbench.panes,
    fullscreen: state.workbench.fullscreen,
  };
}

export const mapDispatchToProps = {
  resizeSplitPane,
  setFullscreen,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorParent);
