import React, { FC, useCallback, useState } from 'react';
import JobGroup from '../../molecules/job-groups/job-group/JobGroup';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import Paging, { PagingParams } from '../../molecules/paging/Paging';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';
import Button from '../../atoms/button/Button';
import { FiPlus } from 'react-icons/fi';
import { orchestrationRoutes } from '../routes';
import {
  deleteJobGroupThenFetch,
  fetchJobGroups,
  JobGroupsState,
  runJobGroup,
} from '../../../store/jobGroups/slice';
import qs, { ParsedQs } from 'qs';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import commonMessages from 'common/dist/messages/common';
import errorMessages from 'common/dist/messages/error';
import orchestrationMessages from 'common/dist/messages/orchestration';

export interface ScheduleParams extends ParsedQs {
  name?: string;
  description?: string;
  jobGroupCode?: string;
}
export interface Props {}

const PAGE_SIZE = 10;

const Loaded: FC<JobGroupsState & RouteComponentProps> = (props) => {
  const { data, location } = props;
  const names = useSelector<RootState>((state) => state.names);
  const [showModal, setShowModal] = useState<{
    show: boolean;
    jobGroupCode?: string;
  }>({
    show: false,
    jobGroupCode: undefined,
  });
  const appDispatch = useAppDispatch();

  const queryParameter: PagingParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { offset, limit, search } = queryParameter;
  const offsetInt = Number.isInteger(Number(offset)) ? Number(offset) : 0;
  const limitInt = Number.isInteger(Number(limit)) ? Number(limit) : PAGE_SIZE;

  return (
    <>
      <div
        className={'JobGroups--Header'}
        data-testingIdentifier={'JobGroupsHeader'}
      >
        <strong>Job Group</strong>
        <strong>Name</strong>
        <strong>By</strong>
        <strong>Actions</strong>
      </div>
      {data.map((jg) => {
        const qs_jobGroup: ScheduleParams = {
          name: jg.name,
          description: jg.description,
          jobGroupCode: jg.code,
        };
        return (
          <JobGroup
            key={jg.code}
            jobGroupCode={jg.code}
            jobGroup={jg}
            names={names}
            deleteAction={() =>
              setShowModal({ show: true, jobGroupCode: jg.code })
            }
            runAction={() => {
              // @ts-ignore
              appDispatch(runJobGroup({ jobGroup: jg }));
            }}
            editLink={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editJobGroup.path}/${jg.code}`}
            createScheduleFromJobGroupLink={`${orchestrationRoutes.basePath}/${
              orchestrationRoutes.addSchedule.path
            }${qs.stringify(qs_jobGroup, { addQueryPrefix: true })}`}
          />
        );
      })}
      <ConfirmationModal
        show={showModal.show}
        secure={false}
        payload={showModal.jobGroupCode}
        hideModal={() => setShowModal({ show: false, jobGroupCode: undefined })}
        buttonConfirmAction={(jobGroupCode: string) => {
          appDispatch(
            deleteJobGroupThenFetch({
              jobGroupCode: jobGroupCode,
              offset: offsetInt,
              limit: limitInt,
              search: search,
            })
          );
        }}
        buttonConfirmText={commonMessages.delete}
        buttonConfirmColor={'red'}
        description={orchestrationMessages.jobGroupDeleteModalDescription}
        headline={orchestrationMessages.jobGroupDeleteModalHeadline}
        headlineColor={'red'}
      />
    </>
  );
};

const InnerComponent: FC<JobGroupsState & RouteComponentProps> = (props) => {
  const { loading, data, error } = props;

  if (loading) return <Busy isBusy positionAbsolute />;
  else if (data && data.length === 0)
    // && currentPage === 0)
    return (
      <IndicatorEmpty
        classNameImage={'orchestration-history-empty-pic'}
        headlineId={'todo'}
        headlineDefault={'There are no Job Groups yet'}
        descriptionId={'todo'}
        descriptionDefault={
          'Once a Job Group has been added, it will be shown here'
        }
      />
    );
  else if (error) return <span>Error: {JSON.stringify(error)}</span>;

  return <Loaded {...props} />;
};

/**
 * Function to pass to Paging to render
 * TODO fix to and optional rendering
 */
function pagingHeader(
  currentPage: number,
  offset: number,
  limit: number,
  totalCount?: number,
  currentPageAmount?: number
) {
  return (
    <div className={'JobGroups--Header-paging'}>
      {1 + 1 === 2 && ( // Don't render the headline if there are no jobGroups, can't use hooks here...
        <div data-testingidentifier={'JobGroupCounter'}>
          <span>
            {`Showing Job Groups ${offset + 1} - ` +
              `${
                offset +
                Math.min(
                  ...[limit, PAGE_SIZE, currentPageAmount].filter((x) => x)
                )
              }`}
          </span>
        </div>
      )}
      <div className={'JobGroups--Button'}>
        <Button
          data-testingIdentifier={'Add Job Group'}
          withLink={true}
          linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addJobGroup.path}`}
          buttonColor={'green'}
          buttonLabelId={'scheduling.addSchedule'}
          buttonLabelDefault={'Add Job Group'}
          Icon={() => <FiPlus size={16} />}
        />
      </div>
    </div>
  );
}

const JobGroups: FC<Props & RouteComponentProps> = (props) => {
  const { loading, loaded, data, error } = useSelector<
    RootState,
    JobGroupsState
  >((state) => state.jobGroups);
  const appDispatch = useAppDispatch();
  const updatePagingParameters = useCallback(
    (offset?: number, limit?: number, search?: string) => {
      appDispatch(
        fetchJobGroups({
          offset,
          limit,
          search,
        })
      );
    },
    [appDispatch, fetchJobGroups]
  );

  return (
    <ErrorBoundary
      errorHeadline={errorMessages.headline}
      errorDescription={errorMessages.description}
    >
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content not-scrollable'}>
          <Paging
            itemsPerPage={PAGE_SIZE}
            currentItems={data.length}
            searchEnabled
            updatePagingParameters={updatePagingParameters}
            headline={(
              currentPage: number,
              offset: number,
              limit: number,
              totalCount?: number
            ) =>
              pagingHeader(currentPage, offset, limit, totalCount, data?.length)
            }
          >
            <InnerComponent
              loading={loading}
              loaded={loaded}
              data={data}
              error={error}
              {...props}
            />
          </Paging>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default withRouter(JobGroups);
