import React, { Component } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { debounce } from 'lodash';
import onClickOutside from 'react-onclickoutside';
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import './styles.scss';

import QueryInput from './QueryInput';
import SearchResult from '../search-result/SearchResult';
import { FiX } from 'react-icons/fi';

export type AugurResultType = {
  name: string;
  code: string;
  habitatCode: string;
  moduleType: string;
};

export type DatapoolResultType = {
  name: string;
  code: string;
  habitatCode: string;
  moduleType: string;
};

export type HabitatResultType = {
  code: string;
  name: string;
};

export type CodeCapsuleResultType = {
  name: string;
  code: string;
  habitatCode: string;
};

export type AppResultType = {
  name: string;
  code: string;
};

export type SingleResultType =
  | AugurResultType
  | DatapoolResultType
  | HabitatResultType
  | CodeCapsuleResultType
  | AppResultType;

export type ResultType = {
  augurs: AugurResultType[];
  datapools: DatapoolResultType[];
  habitats: HabitatResultType[];
  codeCapsules: CodeCapsuleResultType[];
  apps: AppResultType[];
};

export type Props = {
  reset: () => void;
  handleSubmit: (query: string) => void;
  loading: boolean;
  loaded: boolean;
  result: ResultType;
  search: (query: string) => void;
  query?: string;
  error?: string;
  intl: IntlShape;
};

type State = {
  closed: boolean;
};

class SearchBar extends Component<
  Props & WrappedComponentProps & InjectedFormProps,
  State
> {
  searchDebounced = null;

  constructor(props) {
    super(props);

    this.state = {
      closed: true,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showSuggestions = this.showSuggestions.bind(this);
    this.closeSuggestions = this.closeSuggestions.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.searchDebounced = debounce(this.props.search, 500);
  }

  handleClickOutside() {
    if (!this.state.closed) {
      this.setState({ closed: true });
    }
  }

  showSuggestions() {
    if (this.state.closed) {
      this.setState({ closed: false });
    }
  }

  closeSuggestions() {
    if (!this.state.closed) {
      this.setState({ closed: true });
    }
  }

  reset() {
    const { reset, search } = this.props;
    reset();
    // @ts-ignore
    search();
  }

  render() {
    const { handleSubmit, query, loading, result, intl } = this.props;
    const { closed } = this.state;

    return (
      <form className='SearchBar' onSubmit={handleSubmit}>
        <div className='SearchBar--input-group'>
          <div className='SearchBar--input-group-button'>
            <button
              type='submit'
              className='SearchBar--button'
              disabled={!query}
            >
              {!loading && <span className='icon-search' />}
              {loading && <span className='icon-refresh icon-spin' />}
            </button>
          </div>
          <Field
            name='query'
            component={QueryInput}
            search={this.searchDebounced}
            showSuggestions={this.showSuggestions}
            intl={intl}
          />
          {query && (
            <div className='SearchBar--input-group-button'>
              <button
                type='button'
                className='SearchBar--button'
                onClick={this.reset}
              >
                <FiX size={16} className={'SearchBar--icon-cross'} />
              </button>
            </div>
          )}
        </div>
        {!closed && query && (
          <SearchResult
            query={query}
            result={result}
            onSuggestionClick={this.closeSuggestions}
          />
        )}
      </form>
    );
  }
}

// @ts-ignore
export default injectIntl(onClickOutside(SearchBar));
