import React, { Component, FC } from 'react';
import { fieldTag, formName } from '../form';
import { WrappedFieldProps } from 'redux-form';
import Table from '../../../../molecules/table/Table';
import { Version as CodeCapsuleVersion } from 'common/dist/types/codeCapsule';
import { versionsRenderColumns } from '../../common';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import ReactLoading from 'react-loading';

export interface Props {
  codeCapsuleCode: string;

  fetchSingleCodeCapsule(codeCapsuleCode: string, habitatCode?: string): void;

  versionsLoaded?: boolean;
  versionsLoading?: boolean;
  versionsError?: string;
  versionsData?: CodeCapsuleVersion[];
}

type CombinedProps = Props & WrappedFieldProps;

const Inner: FC<CombinedProps> = ({
  versionsLoading,
  versionsLoaded,
  versionsData,
  input: { value, onChange, onBlur, onFocus },
  meta: { touched, valid },
}) => {
  const sortedVersions = versionsData;
  if (sortedVersions)
    sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const renderVersions = () => {
    if (versionsLoading) {
      return <ReactLoading type={'cylon'} color={'#224e90'} />;
    } else if (sortedVersions && sortedVersions.length > 0) {
      return (
        <div className={'tags-container'}>
          <Table<CodeCapsuleVersion>
            data={sortedVersions}
            renderColumns={versionsRenderColumns}
            showHeader={true}
            striped={true}
          />
        </div>
      );
    } else {
      return (
        <div>
          <span>There are no Tags for this Code Capsule yet</span>
        </div>
      );
    }
  };

  return (
    <div className={'input-container'}>
      <TextInputLine
        touched={touched}
        validating={versionsLoading}
        valid={valid}
        disabled={versionsLoading}
        hasLabel={false}
        placeholderDefault={'Tag'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <div className={'existing-tags-parent'}>
        <div className={'tags-headline'}>
          Existing Tags for this Code Capsule
        </div>
        {renderVersions()}
      </div>
    </div>
  );
};

export default class StepTag extends Component<Props & WrappedFieldProps> {
  componentDidMount() {
    const { codeCapsuleCode, fetchSingleCodeCapsule } = this.props;
    if (codeCapsuleCode) {
      fetchSingleCodeCapsule(codeCapsuleCode);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchSingleCodeCapsule, codeCapsuleCode } = this.props;
    if (codeCapsuleCode !== prevProps.codeCapsuleCode) {
      fetchSingleCodeCapsule(codeCapsuleCode);
    }
  }

  render() {
    return (
      <GenericFormStep
        fieldName={fieldTag}
        formName={formName}
        component={Inner}
        title={{ id: 'todo', defaultMessage: 'Set Tag' }}
        description={{
          id: 'todo',
          defaultMessage: 'Set the Tag for the Code Capsule',
        }}
        num={1}
        fieldProps={this.props}
      />
    );
  }
}
