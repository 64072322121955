import React from 'react';
import { FileTypeWrapper } from '../FileTypeWrapper';
import NotebookContent from './component/notebook-content/NotebookContent.container';
import {
  AltaSigmaCell,
  Session,
} from '../../../../store/workbench/state.types';

export const Notebook: FileTypeWrapper = {
  // eslint-disable-next-line react/display-name
  component: (file: NotebookFile, paneId: string) => (
    <NotebookContent
      name={file.name}
      path={file.path}
      content={file.content}
      // @ts-ignore
      cells={file.content.cells}
      metadata={file.content.metadata}
      selectedCells={file.selectedCells}
      session={file.session}
      unsavedChanges={file.unsavedChanges}
      parentRepository={file.as_parentRepository}
      showCloseConfirm={file.showCloseConfirm}
      paneId={paneId}
    />
  ),
};

export type NotebookFile = {
  name: string;
  path: string;
  content: {
    metadata: {};
    cells: AltaSigmaCell[];
  };
  metadata: object;
  cells: AltaSigmaCell[];
  selectedCells: string[];
  session: Session;
  unsavedChanges: boolean;
  as_parentRepository: object;
  showCloseConfirm: boolean;
};
