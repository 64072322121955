import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import BinaryClassificationNewAugurWizard, {
  OwnProps,
} from './BinaryClassificationNewAugurWizard';
import { fieldArchetype } from './binaryClassification.form';
import {
  deriveInitialValues,
  extractPipelineTuningSchemasFromState,
} from '../../../../../organisms/pipeline-tuning/pipeline-tuning-selection/formHelpers';
import {
  extractParameterTuningSchemaFromState,
  initialValuesFromSchema,
} from '../../../../../organisms/parameter-tuning/parameter-tuning-selection/formHelper';

function mapStateToProps(state: RootState, { formValueSelector }: OwnProps) {
  const archetypes = state.archetypes.data;

  // --- Get the selected archetype (only required to pick out the initial values for the parameter / pipeline tuning
  // in the next steps)
  const archetype = formValueSelector(fieldArchetype);
  const archetypeCode = archetype?.code;
  const archetypeVersionNumber =
    archetype?.origin === 'stock' ? 'latest' : archetype?.versionNumber;

  // --- Schemas & Initial values for the pipeline tuning
  const pipelineTuningSchemas = extractPipelineTuningSchemasFromState(
    state,
    archetypeCode,
    archetypeVersionNumber
  );
  const pipelineTuningInitialValues = pipelineTuningSchemas
    ? deriveInitialValues(pipelineTuningSchemas)
    : undefined;

  // --- Schemas & Initial values for the parameter tuning
  const parameterTuningSchema = extractParameterTuningSchemaFromState(
    state,
    archetypeCode,
    archetypeVersionNumber
  );
  const parameterTuningInitialValues = parameterTuningSchema
    ? initialValuesFromSchema(parameterTuningSchema)
    : undefined;

  return {
    archetypes,
    pipelineTuningSchemas,
    pipelineTuningInitialValues,
    parameterTuningSchema,
    parameterTuningInitialValues,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinaryClassificationNewAugurWizard);
