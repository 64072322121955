import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getComponentForFileName } from '../../../fileTypes/FileTypeManager';
import { TabContent } from 'react-tabs-redux';
import DropOverlay from './DropOverlay.container';

export default class SingleEditorPaneTabContent extends Component {
  render() {
    const { contentElement, notebook, selectedContent, paneId } = this.props;
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <DropOverlay paneId={paneId} />
        <TabContent
          className={'notebook-tab-content'}
          for={contentElement.path}
          isVisible={contentElement.path === selectedContent}
          renderActiveTabContentOnly={false}
          key={contentElement.path}
        >
          {getComponentForFileName(
            contentElement.name || '',
            contentElement.type || ''
          )(notebook, paneId)}
        </TabContent>
      </div>
    );
  }
}
SingleEditorPaneTabContent.propTypes = {
  contentElement: PropTypes.shape({
    type: PropTypes.string.isRequired, // "notebook"
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  notebook: PropTypes.object,
  selectedContent: PropTypes.string.isRequired,
  paneId: PropTypes.string.isRequired,
};
