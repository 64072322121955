import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KernelSpec from '../../../part-right/wizards/notebook/ui/kernels/KernelSpec';
import { withRouter } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { FiFolderPlus, FiFilePlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

class LauncherCreateNotebook extends Component {
  render() {
    const { kernelspecs, history } = this.props;
    return (
      <div className={'launcher-container'}>
        <div className={'launcher-category launcher-create-notebook'}>
          <div className={'headline'}>Create Notebook</div>
          <div className={'launcher-card-container kernelspecs'}>
            {Object.keys(kernelspecs).map((key) => {
              const entry = kernelspecs[key];
              const name = entry.name;
              let displayName = entry.spec.display_name;
              // Cleanup for the name of the Python kernel
              displayName = displayName.replace('(ipykernel)', '').trim();
              return (
                <KernelSpec
                  name={name}
                  displayName={displayName}
                  key={name}
                  onChange={(value) => {
                    history.push(
                      `${workbenchRoutes.basePath}${workbenchRoutes.createNotebook}?kernel=${value.name}`
                    );
                  }}
                  selectedKernel={{ name: '' }}
                />
              );
            })}
          </div>
        </div>
        <div className={'launcher-category launcher-other'}>
          <div className={'headline'}>Create Other</div>
          <div className={'launcher-card-container kernelspecs'}>
            <Link
              to={`${workbenchRoutes.basePath}${workbenchRoutes.addFile}`}
              style={{ textDecoration: 'none' }}
            >
              <div className={'kernelspec-container'}>
                <div className={'icon-container icon-container-small'}>
                  <FiFilePlus className={'icon'} />
                </div>
                <p className={'display-name'}>Text File</p>
              </div>
            </Link>

            <Link
              to={`${workbenchRoutes.basePath}${workbenchRoutes.addDirectory}`}
              style={{ textDecoration: 'none' }}
            >
              <div className={'kernelspec-container'}>
                <div className={'icon-container icon-container-small'}>
                  <FiFolderPlus className={'icon'} />
                </div>
                <p className={'display-name'}>Directory</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
LauncherCreateNotebook.propTypes = {
  kernelspecs: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      display_name: PropTypes.string,
    })
  ),
  history: PropTypes.object,
};

export default withRouter(LauncherCreateNotebook);
