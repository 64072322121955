import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldAugurName,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newAugur/defaultWizard/default.form';
import { validateDatapool } from '../../../../../organisms/modelManagement/newAugurWizard/datapool/validate';
import { validateArchetype } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/validate';
import { Props } from './NaturalLanguageTaggingNewAugurWizard';
import _ from 'lodash';

// --- Field names
export const fieldDatapool = 'datapool';
export const fieldArchetype = 'archetype';
export const fieldDictionary = 'dictionary';

// --- Type of the form values
export type NaturalLanguageTaggingFormValues = DefaultFormValues & {
  [fieldArchetype]: {
    /** Code of the selected archetype */
    code?: string;
    /** Standard or custom archetype?  */
    origin?: 'stock' | 'custom';
    /** Selected version number */
    versionNumber?: string;
  };
};

// --- Sync validation

export const isValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateDictionary = (dictionary) => {
  if (!dictionary || _.isEmpty(dictionary)) {
    return {
      id: 'no-id',
      defaultMessage: 'Please enter values for your dictionary',
    };
  }
  if (!isValidJsonString(dictionary)) {
    return {
      id: 'no-id',
      defaultMessage: 'Please enter a valid json for your dictionary',
    };
  }

  return undefined;
};

export const naturalLanguageTaggingValidate = (
  values: NaturalLanguageTaggingFormValues,
  props: { _reduxForm: Props }
) => {
  const errors = defaultValidate(values) || {};
  errors[fieldDatapool] = validateDatapool(values[fieldDatapool]);
  errors[fieldArchetype] = validateArchetype(values[fieldArchetype]);

  // Validate dictionary
  errors[fieldDictionary] = validateDictionary(values[fieldDictionary]);

  return errors;
};

// --- Async validation
export function getNaturalLanguageTaggingMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

export const naturalLanguageTaggingAsyncBlurFields = [
  ...defaultAsyncBlurFields,
];
export const naturalLanguageTaggingAsyncChangeFields = [
  ...defaultAsyncChangeFields,
];

// --- Submit summary
export type NaturalLanguageTaggingSubmitSummary = {
  /** Habitat code of the Augur */
  habitatCode: string;
  /** Module type of the Augur */
  augurType: string;
  /** Datapool code of the augur */
  datapoolCode: string;
  /** Code of the selected archetype */
  archetypeCode: string;
  /** Version number of the selected archetype */
  archetypeVersionNumber: string;
  /** Name of the augur */
  augurName: string;

  settings: {
    /** dictionary containing the tags */
    dictionary: Record<string, any>;
  };
};

export const getNaturalLanguageTaggingSubmitSummary = (
  formValues: NaturalLanguageTaggingFormValues,
  habitatCode: string
): NaturalLanguageTaggingSubmitSummary => {
  const archetypeCode = (formValues[fieldArchetype] || {}).code;
  const archetypeVersionNumber = (formValues[fieldArchetype] || {})
    .versionNumber;

  const submitSummary: NaturalLanguageTaggingSubmitSummary = {
    // Habitat Code
    habitatCode,

    // Module type
    augurType: formValues[fieldModuleType],

    // Datpool
    datapoolCode: formValues[fieldDatapool]?.code,

    // Archetype
    archetypeCode,
    archetypeVersionNumber,

    // Augur name
    augurName: formValues[fieldAugurName],

    // --- Settings
    settings: {
      dictionary: formValues[fieldDictionary],
    },
  };

  return submitSummary;
};
