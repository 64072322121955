import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newAugur/newAugur.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardStep from '../../../../../organisms/multi-page-wizard/WizardStep';
import { renderDefaultWizardSteps } from '../../../../../newAugur/defaultWizard/DefaultNewAugurWizard';
import {
  fieldArchetype,
  fieldDatapool,
  fieldDictionary,
  getNaturalLanguageTaggingMpwAsyncValidate,
  getNaturalLanguageTaggingSubmitSummary,
  naturalLanguageTaggingAsyncBlurFields,
  naturalLanguageTaggingAsyncChangeFields,
  NaturalLanguageTaggingSubmitSummary,
  naturalLanguageTaggingValidate,
} from './naturalLanguageTagging.form';
import Archetype from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype.container';
import Datapool from '../../../../../organisms/modelManagement/newAugurWizard/datapool/Datapool.container';
import {
  fieldAugurName,
  fieldModuleType,
} from '../../../../../newAugur/defaultWizard/default.form';
import { ArchetypeValueType } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype';
import { ArchetypeType } from 'common/dist/types/archetype';
import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import Dictionary from '../../../../../organisms/modelManagement/newAugurWizard/dictionary/Dictionary';
import { NaturalLanguageTaggingDatapool } from 'common/dist/types/datapool';
import messages from 'common/dist/messages/augurs';
import { ParsedQs } from 'qs';
import { ModulesState } from '../../../../../../store/modules/state.types';

export type OwnProps = {
  /** Code of the habitat to add this augur to */
  habitatCode: string;
  /** Name of the habitat to add this augur to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Sends a request to create a new augur */
  addAugur: (augurSummary: NaturalLanguageTaggingSubmitSummary) => void;
  /** Navigation on cancel (generally back to the origin) */
  navigateOnCancel: () => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
  resetForm?: () => void;
};

type ContainerProps = {
  /** List of available archetypes */
  archetypes: ArchetypeType[];
};

export type Props = OwnProps & ContainerProps;

const dictionaryInitialValue = JSON.stringify(
  {
    'Zeit Minuten': 'Organisation',
    'zeit übertragung': 'Organisation',
  },
  null,
  2
);

export default class NaturalLanguageTaggingNewAugurWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addAugur,
      navigateOnCancel,
      formValueSelector,
      query,
      archetypes,
      resetForm,
    } = this.props;

    const onSubmit = (values) => {
      const submitSummary = getNaturalLanguageTaggingSubmitSummary(
        values,
        habitatCode
      );
      addAugur(submitSummary);
    };

    // --- Selected values
    const moduleType = MODULE_TYPE.NATURAL_LANGUAGE_TAGGING; // Selected in the DefaultAugurWizard
    const augurName = formValueSelector(fieldAugurName);
    const datapool: NaturalLanguageTaggingDatapool =
      formValueSelector(fieldDatapool);

    // --- Initial values
    const initialValues = {
      // If this isn't set, the first click onto the Module Type in the DefaultNewAugurWizard won't work
      // only the second click will really select the module type and render the correct wizard.
      [fieldModuleType]: moduleType,
      [fieldDictionary]: dictionaryInitialValue,
    };

    // --- Pick out the python archetype since this is the preferred one.
    //   has to be done like this since the archetype codes are not deterministic
    const pythonArchetype = (archetypes || []).find(
      (arch) => arch.name === 'Binary Classification (Python)'
    );
    const mpwInitialArchetype: ArchetypeValueType = pythonArchetype
      ? {
          origin: 'stock',
          code: pythonArchetype.code,
        }
      : undefined;

    // --- Steps
    const renderDatapoolStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgDatapoolTitle.id,
            defaultMessage: messages.msgDatapoolTitle.defaultMessage,
          }}
          description={{
            id: messages.msgDatapoolDescription.id,
            defaultMessage: messages.msgDatapoolDescription.defaultMessage,
            // @ts-ignore
            values: {
              // TODO The "values" work - but why is the type causing trouble?
              moduleName: (modules?.data || []).find(
                (module) => module.type === moduleType
              )?.name,
              b: (chunks) => <b>{chunks}</b>,
            },
          }}
          fieldName={fieldDatapool}
        >
          {/* @ts-ignore */}
          <Datapool
            habitatCode={habitatCode}
            selectedModuleType={moduleType}
            dropdownSelectPortal={document.querySelector('body')}
            initialSelectedDatapoolCode={query?.datapoolCode as string}
          />
        </WizardStep>
      );
    };

    const renderArchetypeStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgSelectArchetypeTitle.id,
            defaultMessage: messages.msgSelectArchetypeTitle.defaultMessage,
          }}
          description={{
            id: messages.msgSelectArchetypeDescription.id,
            defaultMessage:
              messages.msgSelectArchetypeDescription.defaultMessage,
          }}
          fieldName={fieldArchetype}
          mpwInitialValues={mpwInitialArchetype}
        >
          {/* @ts-ignore */}
          <Archetype
            datapool={datapool}
            habitatCode={habitatCode}
            dropdownSelectPortal={document.querySelector('body')}
          />
        </WizardStep>
      );
    };

    // --- Pages
    const renderDictionaryPage = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage pageTitle={{ id: 'no-id', defaultMessage: 'Dictionary' }}>
          <WizardStep
            title={{
              id: messages.msgDictionaryTitle.id,
              defaultMessage: messages.msgDictionaryTitle.defaultMessage,
            }}
            description={{
              id: messages.msgDictionaryDescription.id,
              defaultMessage: messages.msgDictionaryDescription.defaultMessage,
            }}
            stepNumber={stepNumber}
            fieldName={fieldDictionary}
          >
            {/* @ts-ignore */}
            <Dictionary />
          </WizardStep>
        </WizardPage>
      );
    };

    let pageNumber = 3; // Starting at 3, since the first two steps are always the same (coming from the DefaultAugurWizard)
    return (
      <MultiPageWizard
        {...commonReduxFormProps}
        initialValues={initialValues}
        // @ts-ignore
        validate={naturalLanguageTaggingValidate}
        // @ts-ignore
        asyncValidate={getNaturalLanguageTaggingMpwAsyncValidate(habitatCode)}
        asyncBlurFields={naturalLanguageTaggingAsyncBlurFields}
        asyncChangeFields={naturalLanguageTaggingAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={navigateOnCancel}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Module Type & Augur Name',
          }}
        >
          {renderDefaultWizardSteps(modules, resetForm)}

          {renderDatapoolStep(pageNumber++)}
          {renderArchetypeStep(pageNumber++)}
        </WizardPage>

        {renderDictionaryPage(pageNumber++)}
      </MultiPageWizard>
    );
  }
}
