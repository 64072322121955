import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export interface Props {
  dataSourceCode: string;

  fetchBuckets(...args: unknown[]): void;

  fetchS3Permissions(...args: unknown[]): void;
  availableBuckets?: {
    name: string;
  }[];
}

type OptionType = { label: string; value: string };

export default class StepBucket extends Component<Props & WrappedFieldProps> {
  componentDidMount() {
    const { fetchBuckets, dataSourceCode, fetchS3Permissions } = this.props;
    fetchBuckets(dataSourceCode);
    fetchS3Permissions(dataSourceCode);
  }

  render() {
    const {
      availableBuckets,
      meta: { error, touched },
      input: { value, onChange },
    } = this.props;

    const options: OptionType[] = (availableBuckets || [])
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((k) => ({
        label: k.name,
        value: k.name,
      }));

    return (
      <div className={'step-container step-bucket-container'}>
        <div className={'input-container'}>
          <div className={'input'}>
            <div className={'radio-parent'}>
              <label className={'radio'}>
                <input
                  type={'radio'}
                  value={'existing'}
                  checked={value.strategy && value.strategy === 'existing'}
                  onChange={(e) =>
                    onChange({ ...value, strategy: e.target.value })
                  }
                />
                <span>{'Existing'}</span>
              </label>
              <label className={'radio disabled'}>
                <input
                  type={'radio'}
                  value={'create'}
                  checked={value.strategy && value.strategy === 'create'}
                  onChange={(e) =>
                    onChange({ ...value, strategy: e.target.value })
                  }
                  disabled={true}
                />
                <span>{'Create'}</span>
              </label>
            </div>

            {value.strategy && value.strategy === 'existing' && (
              <div className={'dropdown-parent'}>
                {touched && error && (
                  <div className={'error-container'}>
                    <span className={'error'}>{error + ''}</span>
                  </div>
                )}
                <DropdownSelectInput
                  id={'bucket-select'}
                  name={'bucket-select'}
                  options={options}
                  placeholder={{
                    id: 'no-id',
                    defaultMessage: 'Select a Bucket',
                  }}
                  value={options.find((o) => o.value === value.name)}
                  onChange={(option: OptionType) =>
                    onChange({ ...value, name: option.value })
                  }
                  clearable={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
