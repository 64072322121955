import React, { Component } from 'react';
import './styles.scss';
import SingleResultsPerPipeline from './SingleResultsPerPipeline';
import { mapSingleResultsToPipelineAndPaths } from '../_pipeline-tuning-results-common/utils';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import { PipelineTuningResultsType } from 'common/dist/types/mlModel';
import { PipelineResultType } from 'common/dist/types/pipeline';

export type Props = {
  pipelineTuningResults: PipelineTuningResultsType;
};

export default class PipelineTuningResultsSingleResults extends Component<
  Props,
  {}
> {
  render() {
    const { pipelineTuningResults } = this.props;

    if (!pipelineTuningResults) {
      // Only show the component if the pipelineTuningResults are actually there
      return <div className={'PipelineTuningResultsSingleResults'} />;
    }

    const singleResultsMap = mapSingleResultsToPipelineAndPaths(
      pipelineTuningResults.singleResults
    );
    const scoreFormatter = kpiOptionsGrouped[pipelineTuningResults.kpi]?.format;

    return (
      <div className={'PipelineTuningResultsSingleResults'}>
        {pipelineTuningResults.pipelines.map(
          (pipelineResult: PipelineResultType) => (
            <SingleResultsPerPipeline
              pipelineResult={pipelineResult}
              singleResultsMap={singleResultsMap[pipelineResult.id]}
              scoreFormatter={scoreFormatter}
            />
          )
        )}
      </div>
    );
  }
}
