import React, { Component } from 'react';
import Browser from '../../part-left/Browser';
import RightPart from '../../part-right/RightPart';
import Fullscreen from 'react-full-screen';
import PanelGroup from 'react-panelgroup';
import Notifications from './Notifications.container';
import MainContainer from '../../../pages/main-container/MainContainer';
import { defaultBrowserSize } from '../../part-left/BrowserTabLinks';
import classNames from 'classnames';
import fullscreenStyles from '../../fullscreenStyles.module.scss';

type Props = {
  fullscreen: boolean;
  fetchSessions: () => void;
  fetchKernelspecs: () => void;
  setFullscreen: (isFullscreen: boolean) => void;
  toggleBrowser: (isExpanded: boolean) => void;
  browserExpanded: boolean;
  mainBrowserResize: (size: number) => void;
  notebookUser: string;
  resetEventSource: () => void;
  checkWhetherNotebookIsRunning: (notebookUser: string, retry: boolean) => void;
};

export default class WorkbenchMainContainer extends Component<Props> {
  static defaultProps = {
    browserExpanded: true,
  };

  componentDidMount() {
    const {
      checkWhetherNotebookIsRunning,
      fetchSessions,
      fetchKernelspecs,
      notebookUser,
      resetEventSource,
    } = this.props;
    checkWhetherNotebookIsRunning(notebookUser, false);
    fetchSessions();
    fetchKernelspecs();
    resetEventSource();
  }

  render() {
    const {
      toggleBrowser,
      browserExpanded,
      mainBrowserResize,
      fullscreen,
      setFullscreen,
    } = this.props;
    return (
      <Fullscreen
        enabled={fullscreen}
        onChange={(isFull) => setFullscreen(isFull)}
      >
        <MainContainer
          additionalClassname={classNames(
            'Workbench',
            { [fullscreenStyles.fullscreenContainer]: fullscreen },
            { 'browser-collapsed': !browserExpanded }
          )}
          transparent={false}
          fullWidth={true}
        >
          {/* For !browserExpanded add an empty div with size 0, so that the RightPart is not remounted when switching */}
          <PanelGroup
            panelWidths={
              browserExpanded
                ? [
                    {
                      minSize: 240,
                      size: defaultBrowserSize,
                      resize: 'dynamic',
                    },
                    { minSize: 300, resize: 'stretch' },
                  ]
                : [
                    { minSize: 0, size: 0, resize: 'fixed' },
                    { minSize: 300, resize: 'stretch' },
                  ]
            }
            onResizeEnd={(panels) => mainBrowserResize(panels[0].size)}
            spacing={0}
          >
            {browserExpanded ? (
              <Browser toggleBrowser={toggleBrowser} />
            ) : (
              <div />
            )}
            <RightPart
              browserExpanded={browserExpanded}
              toggleBrowser={toggleBrowser}
            />
          </PanelGroup>
          <Notifications />
        </MainContainer>
      </Fullscreen>
    );
  }
}
