import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { dataManagementRoutes } from './routes';
import Cassandra from './cassandra/Cassandra.container';
import S3 from './s3/S3';
import Upload from './cassandra/upload/Upload.container';
import UploadS3 from './s3/upload/Upload.container';
import ManageDataSourcesContainer from './manage-ds/ManageDataSources.container';
import Busy from '../atoms/busy/Busy';
import IndicatorEmpty from '../molecules/indicator-empty/IndicatorEmpty';
import Button from '../atoms/button/Button';
import MainContainer from '../pages/main-container/MainContainer';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import { MenuEntryType } from '../molecules/with-sidemenu/SideMenu';
import { FiDatabase, FiSliders } from 'react-icons/fi';
import { RouteComponentProps } from 'react-router';
import NoPermissions from '../pages/no-permissions/NoPermissions';

type Props = {
  /** Injected by react-router-dom withRouter() */
  history: {
    push: (url: string) => void;
    location: {
      pathname: string;
    };
  };
  dataSources: {
    loading: boolean;
    loaded: boolean;
    error: string;
    /** List of available data sources */
    data: DataSource[];
  };
  fetchDataSources: () => void;
  isAdmin: boolean;
  missingPermissionsError: boolean;
};

class DataManagement extends Component<Props & RouteComponentProps> {
  getSelectedTab() {
    // TODO This is asking for trouble. Find a better implementation to get the active tab!
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/data/', '');
    const parts = rep.split('/');
    const first = parts[0];
    if (
      first === dataManagementRoutes.manage ||
      first === dataManagementRoutes.history
    ) {
      return first;
    } else {
      const type = parts[0];
      const code = parts[1];
      return `${type}/${code}`;
    }
  }

  componentDidMount() {
    const { fetchDataSources } = this.props;
    fetchDataSources();
  }

  renderPermissionsError() {
    return <NoPermissions fullViewHeight />;
  }

  renderLoading() {
    return (
      <MainContainer fullWidth>
        <Busy isBusy />
      </MainContainer>
    );
  }

  renderError() {
    const {
      dataSources: { error },
    } = this.props;
    return (
      <div className='content container--data'>{JSON.stringify(error)}</div>
    );
  }

  renderEmpty() {
    return (
      <MainContainer fullWidth>
        <IndicatorEmpty
          classNameImage={'data-sources-empty-pic'}
          headlineId={'no-id'}
          headlineDefault={'There are no managed Data Sources yet'}
          descriptionId={'no-id'}
          descriptionDefault={'Please add your first Data Source'}
          Actions={() => (
            <Button
              buttonColor={'green'}
              buttonLabelId={'no-id'}
              buttonLabelDefault={'Add Data Source'}
              withLink={true}
              linkTo={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/add`}
            />
          )}
        />
      </MainContainer>
    );
  }

  getMenuEntries(): MenuEntryType[] {
    const { isAdmin, dataSources } = this.props;
    const entries = [];

    // --- Add all data sources
    if (dataSources && dataSources.data && dataSources.data.length > 0) {
      entries.push({
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Data Sources' },
      });

      const sortedDataSources = dataSources.data.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      sortedDataSources.forEach((ds) =>
        entries.push({
          type: 'link',
          id: `${ds.ds_type}/${ds.code}`,
          to: `${dataManagementRoutes.basePath}/${ds.ds_type}/${ds.code}`,
          nameIntlId: 'no-id',
          nameDefault: ds.name,
          icon: FiDatabase,
        })
      );
    }

    // If admin, add the "Manage" page to add and edit Data Sources
    if (isAdmin) {
      entries.push(
        {
          type: 'hr',
        },
        {
          type: 'link',
          id: 'manage',
          to: `${dataManagementRoutes.basePath}/manage`,
          nameIntlId: 'no-id',
          nameDefault: 'Manage',
          icon: FiSliders,
        }
      );
    }

    return entries;
  }

  renderLoaded() {
    const { dataSources, isAdmin } = this.props;
    // const selectedDataSourceCode = this.getActiveDataSource();
    // const handleSelect = (typeAndCode) => history.push(`${dataManagementRoutes.basePath}/${typeAndCode}`);

    return (
      <MainContainer fullWidth additionalClassname={'container--data'}>
        <Switch>
          {/* Upload to Cassandra Wizard */}
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/cassandra/:dataSourceCode/upload`}
            component={Upload}
          />

          {/* Upload to S3 Wizard */}
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/s3/:dataSourceCode/upload`}
            component={UploadS3}
          />

          {dataSources &&
            dataSources.data &&
            dataSources.data.map((ds) => (
              <Route
                key={ds.code}
                path={`${dataManagementRoutes.basePath}/${ds.ds_type}/${ds.code}`}
                component={() => {
                  if (ds.ds_type === 'cassandra') {
                    return (
                      <WithSidemenu
                        menuEntries={this.getMenuEntries()}
                        selectedTab={this.getSelectedTab()}
                        handleSelect={() => {} /* Nothing to do, right? */}
                      >
                        <Cassandra type={'cassandra'} {...ds} />
                      </WithSidemenu>
                    );
                  } else if (ds.ds_type === 's3') {
                    return (
                      <WithSidemenu
                        menuEntries={this.getMenuEntries()}
                        selectedTab={this.getSelectedTab()}
                        handleSelect={() => {} /* Nothing to do, right? */}
                      >
                        <S3 type={'s3'} {...ds} />
                      </WithSidemenu>
                    );
                  } else {
                    return null;
                  }
                }}
              />
            ))}

          {isAdmin && (
            <Route
              path={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}`}
              component={() => (
                <WithSidemenu
                  menuEntries={this.getMenuEntries()}
                  selectedTab={this.getSelectedTab()}
                  handleSelect={() => {} /* Nothing to do, right? */}
                >
                  <ManageDataSourcesContainer />
                </WithSidemenu>
              )}
            />
          )}

          {dataSources && dataSources.data && dataSources.data.length > 0 && (
            <Redirect
              exact
              path={dataManagementRoutes.basePath}
              to={`${dataManagementRoutes.basePath}/${dataSources.data[0].ds_type}/${dataSources.data[0].code}`}
            />
          )}
        </Switch>
      </MainContainer>
    );
  }

  render() {
    const {
      dataSources: { loading, error, data },
      missingPermissionsError,
    } = this.props;

    const isEmpty = data && data.length === 0;
    if (missingPermissionsError) return this.renderPermissionsError();
    else if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (isEmpty) return this.renderEmpty();
    else return this.renderLoaded();
  }
}

export default withRouter(DataManagement);
