import { connect } from 'react-redux';

import SingleTableStatistics from './SingleTableStatistics';

import { DeprecatedRootState } from '../../../store/state.type';
import { fetchLatestDatapoolStatistics } from '../../../redux/modules/datapool.statistics.module';

type OwnProps = {
  tableName: string;
  datapoolCode: string;
};

export function mapStateToProps(
  state: DeprecatedRootState,
  { tableName, datapoolCode }: OwnProps
) {
  const datapoolStatistics =
    ((state.datapoolStatistics || {})[datapoolCode] || {})[tableName] || {};
  const { data, loading, loaded } = datapoolStatistics;
  return {
    habitatCode: state.habitat.code,
    data,
    loading,
    loaded,
  };
}

export const mapDispatchToProps = {
  fetchLatestDatapoolStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleTableStatistics);
