import { ContentElement, ContentElementType } from './GenericFileBrowser';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { metaFilename } from '../../../workbench/part-right/config';

export function getFileType(type: string, name: string): ContentElementType {
  switch (type) {
    case 'notebook':
      return ContentElementType.FILE_NOTEBOOK;
    case 'directory':
      return name.endsWith('.asr')
        ? ContentElementType.DIRECTORY_REPOSITORY
        : ContentElementType.DIRECTORY_PLAIN;
    case 'file':
      // TODO
      if (name.endsWith('.py')) return ContentElementType.FILE_CODE;
      else if (name.endsWith('.txt') || name.endsWith('.csv'))
        return ContentElementType.FILE_TEXT;
      else if (name.endsWith('.md')) return ContentElementType.FILE_MARKDOWN;
      else if (name === metaFilename)
        return ContentElementType.FILE_REPOSITORY_INFO;
      return ContentElementType.FILE_DEFAULT;
  }
}

export function computeFileTypes(
  content: (Omit<ContentElement, 'type'> & { type: string })[]
): ContentElement[] {
  return content.map((c) => ({
    ...c,
    type: getFileType(c.type, c.name),
  }));
}

export function prepareContent(
  content: ToBeRefined,
  selectedDirPath: string[]
): ContentElement[] {
  const selectedContent = computeFileTypes(
    selectedDirPath
      .slice(1)
      // Reduce climbs down the content.root.content tree and returns the final content or empty list by following the dirs in selectedDirPath
      .reduce(
        (acc: ContentElement[], curr) =>
          acc.find((e) => e.name === curr)?.content || [],
        content.root.content
      )
  );
  if (selectedDirPath.length > 1) {
    selectedContent.unshift({
      name: '..',
      path: '',
      type: ContentElementType.SPECIAL_ONE_DIRECTORY_UP,
    });
  }
  return selectedContent;
}
