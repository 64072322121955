import { connect } from 'react-redux';
import StartServer from './StartServer';
import { startServer } from '../../../../redux/workbench/modules/connection.module';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';

export function mapStateToProps(state) {
  return {
    notifications: state.workbench.notifications,
    notebookUser: notebookUser(state),
  };
}

export const mapDispatchToProps = {
  startServer,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartServer);
