import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newAugur/newAugur.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardStep from '../../../../../organisms/multi-page-wizard/WizardStep';
import { renderDefaultWizardSteps } from '../../../../../newAugur/defaultWizard/DefaultNewAugurWizard';
import {
  binaryClassificationAsyncBlurFields,
  binaryClassificationAsyncChangeFields,
  BinaryClassificationSubmitSummary,
  binaryClassificationValidate,
  fieldArchetype,
  fieldDatapool,
  fieldParameterTuning,
  fieldPipelineTuning,
  fieldPredictionTable,
  fieldPredictionTargets,
  fieldSamplingStrategy,
  fieldTrainingTable,
  getBinaryClassificationMpwAsyncValidate,
  getBinaryClassificationSubmitSummary,
} from './binaryClassification.form';
import Archetype from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype.container';
import Datapool from '../../../../../organisms/modelManagement/newAugurWizard/datapool/Datapool.container';
import TrainingTable from '../../../../../organisms/modelManagement/newAugurWizard/trainingTable/TrainingTable';
import {
  fieldAugurName,
  fieldModuleType,
} from '../../../../../newAugur/defaultWizard/default.form';
import TablePreview from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/tablePreview/TablePreview.container';
import InputTable from '../../../../../organisms/modelManagement/newAugurWizard/inputTable/InputTable';
import SamplingStrategy from '../../../../../organisms/modelManagement/newAugurWizard/samplingStrategy/SamplingStrategy';
import PipelineTuning from '../../../../../organisms/modelManagement/newAugurWizard/pipelineTuning/PipelineTuning';
import { ArchetypeValueType } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype';
import { ArchetypeType } from 'common/dist/types/archetype';
import ParameterTuning from '../../../../../organisms/modelManagement/newAugurWizard/parameterTuning/ParameterTuning';
import { ParameterTuningSchema } from '../../../../../organisms/parameter-tuning/parameter-tuning-selection/types';
import { ParameterTuningValue } from '../../../../../organisms/parameter-tuning/parameter-tuning-selection/ParameterTuningSelection';
import TargetHierarchy from '../../../../../organisms/modelManagement/newAugurWizard/targetHierarchy/TargetHierarchy.container';
import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import WithTableSample from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample.container';
import {
  BinaryClassificationDatapool,
  POPULATION_EVENTS,
  TRAINING,
} from 'common/dist/types/datapool';
import messages from 'common/dist/messages/augurs';
import { ModulesState } from '../../../../../../store/modules/state.types';
import { ParsedQs } from 'qs';
import { PipelineTuningValue } from 'common/dist/types/pipeline';
import { PipelineTuningSchemaType } from '../../../../../organisms/pipeline-tuning/_pipeline-tuning-results-common/types';

export type OwnProps = {
  /** Code of the habitat to add this augur to */
  habitatCode: string;
  /** Name of the habitat to add this augur to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Sends a request to create a new augur */
  addAugur: (augurSummary: BinaryClassificationSubmitSummary) => void;
  /** Navigation on cancel (generally back to the origin) */
  navigateOnCancel: () => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
};

type ContainerProps = {
  /** List of available archetypes */
  archetypes: ArchetypeType[];
  /** List of parameter tuning schemas */
  pipelineTuningSchemas?: PipelineTuningSchemaType[];
  /** Initial values for the pipeline tuning */
  pipelineTuningInitialValues?: PipelineTuningValue[];
  /** Schema for the parameter tuning */
  parameterTuningSchema?: ParameterTuningSchema;
  /** Initial values for the parameter tuning */
  parameterTuningInitialValues?: ParameterTuningValue;
  resetForm?: () => void;
};

export type Props = OwnProps & ContainerProps;

export default class BinaryClassificationNewAugurWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addAugur,
      navigateOnCancel,
      formValueSelector,
      query,
      archetypes,
      pipelineTuningSchemas,
      pipelineTuningInitialValues,
      parameterTuningSchema,
      parameterTuningInitialValues,
      resetForm,
    } = this.props;

    /** Invoked when clicking the "Submit" button
     * Could also be an async function that rejects with return Promise.reject(new SubmissionError({[fieldName]: "errorValue"}))
     */
    const onSubmit = (values) => {
      const submitSummary = getBinaryClassificationSubmitSummary(
        values,
        habitatCode
      );
      addAugur(submitSummary);
    };

    // --- Selected values
    const moduleType = MODULE_TYPE.BINARY_NUMERICAL; // Selected in the DefaultAugurWizard
    const augurName = formValueSelector(fieldAugurName);
    const datapool: BinaryClassificationDatapool | undefined =
      formValueSelector(fieldDatapool);
    const archetype: ArchetypeValueType = formValueSelector(fieldArchetype);

    // --- Does the selected archetype support pipeline or parameter tuning?
    let archetypeSupportsPipelineTuning = false;
    let archetypeSupportsParameterTuning = false;
    if (archetype?.origin === 'stock') {
      const selectedArchetype = archetypes.find(
        (arch) => arch.code === archetype?.code
      );
      const { supportsPipelineTuning, supportsParameterTuning } =
        selectedArchetype?.versions?.[0] || {};
      archetypeSupportsPipelineTuning = supportsPipelineTuning;
      archetypeSupportsParameterTuning = supportsParameterTuning;
    }

    // --- Initial values
    const initialValues = {
      // If this isn't set, the first click onto the Module Type in the DefaultNewAugurWizard won't work
      // only the second click will really select the module type and render the correct wizard.
      [fieldModuleType]: moduleType,
      [fieldSamplingStrategy]: {
        strategy: 'automatic',
        trainingSplit: 0.8,
      },
    };

    // --- Pick out the python archetype since this is the preferred one.
    //   has to be done like this since the archetype codes are not deterministic
    const pythonArchetype = (archetypes || []).find(
      (arch) => arch.name === 'Binary Classification (Python)'
    );
    const mpwInitialArchetype: ArchetypeValueType = pythonArchetype
      ? {
          origin: 'stock',
          code: pythonArchetype.code,
        }
      : undefined;

    // --- Steps
    const renderDatapoolStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgDatapoolTitle.id,
            defaultMessage: messages.msgDatapoolTitle.defaultMessage,
          }}
          description={{
            id: messages.msgDatapoolDescription.id,
            defaultMessage: messages.msgDatapoolDescription.defaultMessage,
            values: {
              moduleName: (modules?.data || []).find(
                (module) => module.type === moduleType
              )?.name,
              b: (chunks) => <b>{chunks}</b>,
            },
          }}
          fieldName={fieldDatapool}
        >
          {/* TODO: stop using WrappedFieldProps for components that are not actually wrapped in a Field... */}
          {/* @ts-ignore */}
          <Datapool
            habitatCode={habitatCode}
            selectedModuleType={moduleType}
            dropdownSelectPortal={document.querySelector('body')}
            initialSelectedDatapoolCode={query?.datapoolCode as string}
          />
        </WizardStep>
      );
    };

    const renderArchetypeStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgSelectArchetypeTitle.id,
            defaultMessage: messages.msgSelectArchetypeTitle.defaultMessage,
          }}
          description={{
            id: messages.msgSelectArchetypeDescription.id,
            defaultMessage:
              messages.msgSelectArchetypeDescription.defaultMessage,
          }}
          fieldName={fieldArchetype}
          mpwInitialValues={mpwInitialArchetype}
        >
          {/* TODO: stop using WrappedFieldProps for components that are not actually wrapped in a Field... */}
          {/* @ts-ignore */}
          <Archetype
            datapool={datapool}
            habitatCode={habitatCode}
            dropdownSelectPortal={document.querySelector('body')}
          />
        </WizardStep>
      );
    };

    // --- Pages
    const renderPageSamplingStategy = (stepNumber: number) => {
      return (
        // TODO: where do the InjectedFormProps come from?
        // @ts-ignore
        <WizardPage
          pageTitle={{ id: 'no-id', defaultMessage: 'Sampling Strategy' }}
        >
          <WizardStep
            title={{
              id: messages.msgSamplingStrategyTitle.id,
              defaultMessage: messages.msgSamplingStrategyTitle.defaultMessage,
            }}
            description={{
              id: messages.msgSamplingStrategyDescription.id,
              defaultMessage:
                messages.msgSamplingStrategyDescription.defaultMessage,
            }}
            stepNumber={stepNumber}
            fieldName={fieldSamplingStrategy}
            BottomComponent={TablePreview}
            bottomComponentProps={{
              datapool: datapool,
              tableName:
                formValueSelector(fieldSamplingStrategy)?.strategy === 'table'
                  ? formValueSelector(fieldSamplingStrategy)?.evaluationTable
                  : null,
              labelColumn: undefined,
              primaryKeyColumns: [],
            }}
          >
            {/* TODO: stop using WrappedFieldProps for components that are not actually wrapped in a Field... */}
            {/* @ts-ignore */}
            <SamplingStrategy
              datapool={datapool}
              dropdownSelectPortal={document.querySelector('body')}
              // selectedValue={formValueSelector(fieldPredictionTable)}
            />
          </WizardStep>
        </WizardPage>
      );
    };

    const renderPageTrainingPredictionTable = (
      stepNumberTraining: number,
      stepNumberPrediction: number
    ) => {
      return (
        // TODO: where do the InjectedFormProps come from?
        // @ts-ignore
        <WizardPage pageTitle={{ id: 'no-id', defaultMessage: 'Input Data' }}>
          <WizardStep
            title={{
              id: messages.msgTrainingTableTitle.id,
              defaultMessage: messages.msgTrainingTableTitle.defaultMessage,
            }}
            description={{
              id: messages.msgTrainingTableDescription.id,
              defaultMessage:
                messages.msgTrainingTableDescription.defaultMessage,
            }}
            stepNumber={stepNumberTraining}
            fieldName={fieldTrainingTable}
            BottomComponent={TablePreview}
            bottomComponentProps={{
              datapool: datapool,
              tableName: formValueSelector(fieldTrainingTable)?.tableName,
              labelColumn: formValueSelector(fieldTrainingTable)?.labelColumn,
              primaryKeyColumns:
                formValueSelector(fieldTrainingTable)?.primaryKeyColumns,
            }}
          >
            <WithTableSample
              datapool={datapool}
              tableName={formValueSelector(fieldTrainingTable)?.tableName}
            >
              {/* TODO: stop using WrappedFieldProps for components that are not actually wrapped in a Field... */}
              {/* @ts-ignore */}
              <TrainingTable
                datapool={datapool}
                habitatCode={habitatCode}
                dropdownSelectPortal={document.querySelector('body')}
              />
            </WithTableSample>
          </WizardStep>

          <WizardStep
            title={{
              id: messages.msgPredictionTableTitle.id,
              defaultMessage: messages.msgPredictionTableTitle.defaultMessage,
            }}
            description={{
              id: messages.msgPredictionTableDescription.id,
              defaultMessage:
                messages.msgPredictionTableDescription.defaultMessage,
            }}
            stepNumber={stepNumberPrediction}
            fieldName={fieldPredictionTable}
            BottomComponent={TablePreview}
            bottomComponentProps={{
              datapool: datapool,
              tableName: formValueSelector(fieldPredictionTable),
              labelColumn: undefined,
              primaryKeyColumns: [],
            }}
            // bottomComponentFullWidth
          >
            <WithTableSample
              datapool={datapool}
              tableName={formValueSelector(fieldPredictionTable)}
            >
              {/* TODO: properly declare that WrappedFieldProps are passed from WizardStep through WithTableSample to this component */}
              {/* @ts-ignore */}
              <InputTable
                datapool={datapool}
                habitatCode={habitatCode}
                dropdownSelectPortal={document.querySelector('body')}
                dropdownSelectId={'predictionTable'}
                dropdownSelectName={'predictionTable'}
                dropdownSelectLabel={{
                  id: messages.msgPredictionTableDescription.id,
                  defaultMessage:
                    messages.msgPredictionTableDescription.defaultMessage,
                }}
                dropdownSelectPlaceholder={{
                  id: messages.msgPredictionTableNotSelected.id,
                  defaultMessage:
                    messages.msgPredictionTableNotSelected.defaultMessage,
                }}
              />
            </WithTableSample>
          </WizardStep>
        </WizardPage>
      );
    };

    const renderPagePipelineTuning = (stepNumber: number) => {
      return (
        // TODO: where do the InjectedFormProps come from?
        // @ts-ignore
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Model & Hyperparameter Tuning',
          }}
        >
          <WizardStep
            title={{
              id: messages.msgTuningPipelineTitle.id,
              defaultMessage: messages.msgTuningPipelineTitle.defaultMessage,
            }}
            description={{
              id: messages.msgTuningPipelineDescription.id,
              defaultMessage:
                messages.msgTuningPipelineDescription.defaultMessage,
            }}
            stepNumber={stepNumber}
            fieldName={fieldPipelineTuning}
            mpwInitialValues={pipelineTuningInitialValues}
            BottomComponent={PipelineTuning}
            bottomComponentProps={{
              archetype,
              pipelineTuningSchemas,
            }}
            bottomComponentFullWidth
          />
        </WizardPage>
      );
    };

    const renderPageParameterTuning = (stepNumber: number) => {
      return (
        // TODO: where do the InjectedFormProps come from?
        // @ts-ignore
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Model & Hyperparameter Tuning',
          }}
        >
          <WizardStep
            title={{
              id: messages.msgTuningParameterTitle.id,
              defaultMessage: messages.msgTuningParameterTitle.defaultMessage,
            }}
            description={{
              id: messages.msgTuningParameterDescription.id,
              defaultMessage:
                messages.msgTuningParameterDescription.defaultMessage,
            }}
            stepNumber={stepNumber}
            fieldName={fieldParameterTuning}
            mpwInitialValues={parameterTuningInitialValues}
            BottomComponent={ParameterTuning}
            bottomComponentProps={{
              parameterTuningSchema,
            }}
          />
        </WizardPage>
      );
    };

    const renderPageTargetHierarchy = (
      datapoolCode: string,
      stepNumber: number
    ) => {
      return (
        // TODO: where do the InjectedFormProps come from?
        // @ts-ignore
        <WizardPage
          pageTitle={{ id: 'no-id', defaultMessage: 'Prediction Targets' }}
        >
          <WizardStep
            title={{
              id: messages.msgPredictionTarget.id,
              defaultMessage: messages.msgPredictionTarget.defaultMessage,
            }}
            stepNumber={stepNumber}
            fieldName={fieldPredictionTargets}
          >
            {/* TODO: stop using WrappedFieldProps for components that are not actually wrapped in a Field... */}
            {/* @ts-ignore */}
            <TargetHierarchy
              habitatCode={habitatCode}
              datapoolCode={datapoolCode}
            />
          </WizardStep>
        </WizardPage>
      );
    };

    let stepNumber = 3; // Starting at 3, since the first two steps are always the same (coming from the DefaultAugurWizard)
    return (
      <MultiPageWizard
        {...commonReduxFormProps}
        initialValues={initialValues}
        // @ts-ignore
        validate={binaryClassificationValidate}
        // @ts-ignore
        asyncValidate={getBinaryClassificationMpwAsyncValidate(habitatCode)}
        asyncBlurFields={binaryClassificationAsyncBlurFields}
        asyncChangeFields={binaryClassificationAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={navigateOnCancel}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        // Required as props for the validate function
        pipelineTuningSchemas={pipelineTuningSchemas}
        parameterTuningSchema={parameterTuningSchema}
      >
        {/* TODO: where do the InjectedFormProps come from? */}
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Module Type & Augur Name',
          }}
        >
          {renderDefaultWizardSteps(modules, resetForm)}

          {renderDatapoolStep(stepNumber++)}
          {renderArchetypeStep(stepNumber++)}
        </WizardPage>

        {datapool?.inputFormat === TRAINING &&
          renderPageTrainingPredictionTable(stepNumber++, stepNumber++)}
        {datapool?.inputFormat === TRAINING &&
          renderPageSamplingStategy(stepNumber++)}
        {datapool?.inputFormat === POPULATION_EVENTS &&
          renderPageTargetHierarchy(datapool?.code, stepNumber++)}

        {archetypeSupportsPipelineTuning &&
          renderPagePipelineTuning(stepNumber++)}
        {archetypeSupportsParameterTuning &&
          renderPageParameterTuning(stepNumber++)}
      </MultiPageWizard>
    );
  }
}
