import React, { FC, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import Busy from '../../../../../../components/atoms/busy/Busy';
import InfluencersBubbleChart from './bubbleChart/InfluencersBubbleChart.container';
import InfluencersBarsChart from './barChart/InfluencersBarsChart';
import InfluencersHeader from './InfluencersHeader';
import './styles.scss';
import { getAugurDetailsLink } from '../../../../../index/routes';
import ShapChart from './shapChart/ShapChart';
import qs from 'qs';
import { JobDescriptionBanner } from '../job-banner/JobDescriptionBanner';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../types';
import { useModel } from '../../../../../../core/api/mlModels';
import _ from 'lodash';
import {
  useSettings,
  useUpdateSettings,
} from '../../../../../../core/api/augurs';
import { useAppDispatch } from '../../../../../../store/store';
import * as NOTIFICATION_TYPES from '../../../../../../core/notifications';
import { sendNotification } from '../../../../../../redux/modules/notifications.module';

const Influencers: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const location = useLocation();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const dispatch = useAppDispatch();

  const {
    data: model,
    error,
    isLoading,
    isError,
  } = useModel(habitatCode, augurCode, modelCode);

  const {
    data: settings,
    error: settingsError,
    isLoading: isLoadingSettings,
    isFetching: isFetchingSettings,
    isError: isErrorSettings,
  } = useSettings(habitatCode, augurCode);

  const saveSettings = useUpdateSettings(habitatCode, augurCode);

  const isShapAvailable = !_.isEmpty(model?.details?.shapValues);

  const [featureSearch, setFeatureSearch] = useState('');

  const parts = location.pathname.split('/');
  const activeChart = parts[parts.length - 1];

  const moduleType = 'binary-numerical'; // TODO this should not be hard-coded ...
  const linkBase = `${getAugurDetailsLink({
    habitatCode,
    augurCode,
    moduleType,
    tabId: 'influencers',
  })}`;
  const linkBubbles = `${linkBase}/bubbles`;
  const linkBars = `${linkBase}/bars`;
  const linkShap = `${linkBase}/shap`;

  const header = useMemo(
    () => (
      <InfluencersHeader
        active={activeChart}
        setFeaturesSearchValue={(e) => setFeatureSearch(e)}
        linkBubbles={linkBubbles}
        linkBars={linkBars}
        linkShap={linkShap}
        isShapAvailable={isShapAvailable}
      />
    ),
    [activeChart, isShapAvailable, linkBars, linkBubbles, linkShap]
  );

  // Loading
  if (isLoading || isLoadingSettings) {
    return (
      <div className='Influencers'>
        <Busy isBusy />
      </div>
    );
  }

  // Error
  if (
    isError ||
    _.isEmpty(model?.details) ||
    isErrorSettings ||
    _.isEmpty(settings?.data)
  ) {
    return (
      <div className='Influencers'>
        <div className={'error'}>
          <FormattedMessage
            id='augur.error'
            defaultMessage='Error: {error}'
            values={{ error: error ?? settingsError }}
          />
        </div>
      </div>
    );
  }

  const features = model.details.features ?? [];
  const rejected = settings.data.rejectedFeatures ?? [];
  const forced = settings.data.forcedFeatures ?? [];

  // Loaded
  return (
    <JobDescriptionBanner>
      <div className='Influencers'>
        {header}

        <div className={'Influencers--charts'}>
          <Switch>
            <Route path={linkBubbles}>
              <InfluencersBubbleChart data={features} />
            </Route>
            <Route path={linkBars}>
              <InfluencersBarsChart
                features={features}
                rejectedFeatures={rejected}
                forcedFeatures={forced}
                featuresSearchValue={featureSearch}
                onSave={(rejected, forced) =>
                  saveSettings.mutate(
                    {
                      settings: {
                        rejectedFeatures: rejected,
                        forcedFeatures: forced,
                      },
                      merge: true,
                    },
                    {
                      onSuccess: async () =>
                        dispatch(
                          sendNotification(
                            'notifications.title.augur_updated',
                            // @ts-ignore
                            'notifications.description.augur_updated',
                            NOTIFICATION_TYPES.event
                          )
                        ),
                      onError: async () =>
                        dispatch(
                          sendNotification(
                            'notifications.title.augur_updated.failed',
                            // @ts-ignore
                            'notifications.description.augur_updated.failed',
                            NOTIFICATION_TYPES.error
                          )
                        ),
                    }
                  )
                }
                saving={isFetchingSettings}
              />
            </Route>
            {isShapAvailable && (
              <Route path={linkShap}>
                <ShapChart
                  shapValues={model.details.shapValues}
                  features={model.details.features}
                />
              </Route>
            )}
            <Redirect
              exact
              path={linkBase}
              to={{ pathname: 'influencers/bars', search: location.search }}
            />
          </Switch>
        </div>
      </div>
    </JobDescriptionBanner>
  );
};

export default Influencers;
