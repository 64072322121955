// Use field level validation since the form validation seems to ignore newly added fields

const memoryRegex = /^([0-9]+)([KMGT]?)$/;
// Apparently in jupyter-hub land 1K means 1024 and not 1000
const suffixFactor = {
  K: 1024,
  M: Math.pow(1024, 2),
  G: Math.pow(1024, 3),
  T: Math.pow(1024, 4),
};

function translateMemory(value: string) {
  const match = value.match(memoryRegex);
  if (match === null) {
    return null;
  } else {
    return Number(match[1]) * (match[2] === '' ? 1 : suffixFactor[match[2]]);
  }
}

export function validate(value: string | undefined, allValues, props, name) {
  let error = undefined;

  if (!value) {
    error = 'Please enter a value';
  } else if (
    (name === 'memory_request' || name === 'memory_limit') &&
    value.match(memoryRegex) === null
  ) {
    error =
      'Please enter only digits with an optional suffix of K -> Kilobytes M -> Megabytes G -> Gigabytes T -> Terabytes (Factor 1024 each)';
  } else if (
    (name === 'cpu_request' || name === 'cpu_limit') &&
    !Number.isFinite(Number(value))
  ) {
    error = 'Please enter only a number';
  } else if (
    name === 'cpu_request' &&
    Number.isFinite(Number(allValues['cpu_limit'])) &&
    parseInt(value) > parseInt(allValues['cpu_limit'])
  ) {
    error = 'Please set requests lower than limits';
  } else if (
    name === 'memory_request' &&
    allValues['memory_limit'] &&
    translateMemory(value) > translateMemory(allValues['memory_limit'])
  ) {
    error = 'Please set requests lower than limits';
  }

  return error;
}
