import React, { useState, useEffect, FC } from 'react';
import styles from './styles.module.scss';

type Props = {
  value: string;
  row: { index: number };
  column: { id: string };
  updateMyData: (rowIndex: number, columnId: string, value: string) => void;
};

const EditableCell: FC<Props> = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const [editMode, setEditMode] = useState(false);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
    setEditMode(false);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editMode) {
    return (
      <textarea
        rows={5}
        className={styles.textArea}
        autoFocus
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  } else {
    return (
      <span className={styles.tableValue} onClick={() => setEditMode(true)}>
        {value}
      </span>
    );
  }
};

export default EditableCell;
