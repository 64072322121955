import React, { FC } from 'react';
import { PredictionReportType } from 'common/dist/types/reports';
import styles from './styles.module.scss';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { scaleLinear } from 'd3-scale';
import { formatDate } from 'common/dist/utils/dates';
import { deriveChartDataForHeatmap } from './common';
import { tooltip } from './Tooltip';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  data: PredictionReportType[];
  /** Code of the active model for this augur */
  activeModelCode?: string;
};

const CompareHeatmap: FC<Props> = (props: Props) => {
  const { data, activeModelCode } = props;

  if (!data || data.length === 0) return null; // Early exit if there's no data (yet)

  const { chartData, keys, lookup } = deriveChartDataForHeatmap(data);

  // Build color scale
  const scale = () => {};
  scale.domain = ([min, max]) => {
    return scaleLinear<string>()
      .range(['#eef0f2', '#6aa0eb'])
      .domain([min, max]);
  };

  const marginTop = 140; // The SVG ranges over the full height (to display the tooltip properly) - so the margin makes space for the header component
  const marginBottom = 60;
  const itemHeight = 40;
  const height = (data || []).length * itemHeight + marginBottom + marginTop;

  const renderTick = ({
    opacity,
    textAnchor,
    textBaseline,
    textX,
    textY,
    theme,
    value,
    x,
    y,
  }: ToBeRefined) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline={textBaseline}
          style={{ fontSize: '12px' }}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY - 2})`}
        >
          <tspan x='0' dy='0'>
            {(lookup[value] || {}).inputTable}
          </tspan>
          <tspan x='0' dy='14px'>
            {formatDate(
              new Date((lookup[value] || {}).createdAt),
              'dd.MM.yyyy HH:mm:ss'
            )}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <div
      className={styles.compareHeatmap}
      style={{
        width: '100%',
        height,
      }}
    >
      <ResponsiveHeatMap
        data={chartData}
        indexBy={'code'}
        keys={keys}
        margin={{ top: marginTop, right: 180, bottom: marginBottom, left: 80 }}
        forceSquare={false}
        // @ts-ignore
        colors={scale}
        padding={2}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
        }}
        axisLeft={null}
        axisTop={null}
        axisRight={{
          orient: 'right',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          renderTick: renderTick,
        }}
        cellOpacity={1}
        cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
        animate={true}
        hoverTarget={'row'}
        cellHoverOthersOpacity={0.5}
        enableLabels={false}
        tooltip={tooltip(lookup, activeModelCode, 'yKey')}
      />
    </div>
  );
};

export default CompareHeatmap;
