import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import React from 'react';
import InputDataCategory from './InputDataCategory';

export default class InputDataCategoryWrapper implements ModelSettingsCategory {
  component = InputDataCategory;

  icon: React.ComponentType = () => (
    <span className={'icon-tables'} style={{ fontSize: '17px' }} />
  );

  id = 'input-data';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Input Data',
  };
}
