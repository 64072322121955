import { PANE_TYPE } from '../../../components/workbench/part-right/editor/editor-parent/EditorParent';

export const initialState = {
  content: {
    selectedDirPath: ['root'],
    root: {
      type: 'directory',
      content: [],
    },
    repoMeta: {},
  },
  repoMetas: {},
  paneHierarchy: {
    type: PANE_TYPE.EDITOR,
    id: 'e1',
  },
  mostRecentPaneId: 'e1',
  panes: {
    e1: {
      id: 'e1',
      type: PANE_TYPE.EDITOR,
      content: [{ type: 'launcher', path: 'launcher' }],
      selectedContent: 'launcher',
      loadingContent: false,
    },
  },
  notebooks: {},
  fileUploadNotification: [],
  selectedNotebook: '', // TODO deprecated and should be removed
  /** Decides about the browser shown on the left. 'content' or 'sessions' */
  selectedBrowser: 'content',
  openSockets: {},
  sessions: { data: [] },
  loadingNotebooks: [],
  // serverStarted: 'no',
  fullscreen: false,
  mergerAPI: {
    id: 0,
    status: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    refStatus: {
      loading: false,
      loaded: false,
      data: undefined,
      error: undefined, // Just to make it explicit
    },
    sourceExtended: true,
    targetExtended: true,
  },
  collab: {
    activePath: [{ name: 'root', type: 'directory' }],
    activeType: 'directory',
    branches: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    content: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: [],
    },
    repository: {
      latestCommit: {},
      repoContent: {
        selectedPath: '/',
      },
    },
    merging: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: {},
    },
    showEditMR: false,
    mergeRequest: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: undefined,
    },
    mergeRequests: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: [],
    },
    repositories: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: [],
    },
    groups: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: [],
    },
  },
  notebookRunning: {
    loading: false,
    loaded: false,
    isRunning: false,
    error: undefined, // Just to make it explicit
  },
  notebookStarting: {
    loading: false,
    loaded: false,
    eventSourceAvailable: undefined, // Just to make it explicit
    eventSourceMessages: [],
    error: undefined, // Just to make it explicit
  },
  shutdownWorkbenchModalVisible: false,
  showCloneRepository: {
    repoCode: '',
  },
  showBuildCodeCapsule: {
    repoName: '',
    repoFullPath: '',
  },
  showRepositoryInfo: {
    gitBranches: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    gitFileStatus: {
      loading: false,
      loaded: false,
      data: {},
      error: undefined, // Just to make it explicit
    },
    gitCommits: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    gitNotPushedCommits: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    gitNotPulledCommits: {
      loading: false,
      loaded: false,
      data: [],
      error: undefined, // Just to make it explicit
    },
    activeBranch: 'master',
    isCreateBranchModalOpen: false,
    isFetchBranchModalOpen: false,
    isDeleteBranchModalOpen: false,
  },
  upload: {},
  app: {
    isAppArrangeModalShown: false,
    appRunning: {
      isRunning: false,
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
    },
    appStarting: {
      loading: false,
      loaded: false,
      error: undefined,
      eventSourceAvailable: false,
      eventSourceMessages: [],
    },
    path: '',
    notebook: {},
    executionPlan: [],
    activeStep: 0,
    session: {},
    loading: false,
    loaded: false,
    error: undefined, // Just to make it explicit
  },
  codeCapsule: {
    isConfigureModalShown: false,
  },
  archetype: {
    isConfigureModalShown: false,
  },
  addInputElementModal: {
    active: false,
  },
  editInputElementModal: {
    active: false,
  },
  repoDetails: {
    loading: false,
    loaded: false,
    data: undefined,
    error: undefined,
  },
  showDeleteContent: {
    path: undefined,
    type: undefined,
    permanently: undefined,
  },
  clipboard: {
    data: undefined,
    type: undefined,
  },
  showEditNotebook: {
    name: undefined,
    path: undefined,
    kernel: undefined,
  },
  showWarningOpenFile: {
    path: undefined,
    type: undefined,
  },
  showEditDirectory: {
    name: undefined,
    path: undefined,
  },
};
