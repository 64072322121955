import React, { FC } from 'react';
import NameAndIconLoading from '../name-and-icon/NameAndIconLoading';
import NameAndIcon from '../name-and-icon/NameAndIcon';
import { DatapoolType } from 'common/dist/types/datapool';

type Props = {
  /** Are the augur details loading? */
  loading?: boolean;
  /** Are the augur details loaded? */
  loaded?: boolean;
  /** The augur details */
  datapool?: DatapoolType;
  /** Name of the habitat */
  habitatName: string;
};

const NameContainer: FC<Props> = (props: Props) => {
  const { datapool, loading, loaded, habitatName } = props;

  if (loading || !loaded) {
    return (
      <NameAndIconLoading
        gridColumnStart={1}
        gridColumnEnd={1}
        gridRowStart={1}
        gridRowEnd={'span 2'}
        Icon={() => (
          <span
            className='icon-datapool'
            style={{
              color: '#224e90',
              fontSize: '20px',
            }}
          />
        )}
      />
    );
  }

  return (
    <NameAndIcon
      gridColumnStart={1}
      gridColumnEnd={1}
      gridRowStart={1}
      gridRowEnd={'span 2'}
      Icon={() => (
        <span
          className='icon-augur'
          style={{
            color: '#224e90',
            fontSize: '20px',
          }}
        />
      )}
      name={datapool.name}
      subName={habitatName}
      showHealthBar={false}
    />
  );
};

export default NameContainer;
