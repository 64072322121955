import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import GitCommitModal from '../git-commit/GitCommitModal.container';
import { FiRefreshCw } from 'react-icons/fi';

function speakingChangeType(rawChangeType) {
  if (rawChangeType === 'M') return 'Modified';
  else if (rawChangeType === 'D') return 'Deleted';
  else if (rawChangeType === 'R') return 'Renamed';
  return rawChangeType;
}

function styledPath(f) {
  if (f.change_type === 'R') return `${f.b_path} -> ${f.a_path}`;
  return f.a_path;
}

export default class GitFileStatus extends Component {
  renderLoaded() {
    const {
      data,
      showGitCommitModal,
      gitFileStatus,
      repositoryPath,
      gitPush,
      activeBranch,
      disabled,
    } = this.props;
    const atLeastOneChange =
      data &&
      ((data.staged && data.staged.length) ||
        (data.not_staged && data.not_staged.length > 0) ||
        (data.untracked_files && data.untracked_files.length > 0));
    return (
      <Fragment>
        <GitCommitModal />

        <div className={'git-file-status'}>
          <div
            className={'button button-white button-sm refresh-button'}
            onClick={() => gitFileStatus(repositoryPath, false)}
          >
            <FiRefreshCw size={16} className={'refresh-icon'} />
          </div>

          {data && data.staged && data.staged.length > 0 && (
            <div className={'git-file-status-category staged-files'}>
              <span className={'git-file-status-title'}>
                Changes to be committed:
              </span>
              <div className={'git-file-status-container'}>
                {data.staged &&
                  data.staged.map((f, i) => (
                    <div className={'git-file-status-row staged-files'} key={i}>
                      <div className={'change-type'}>
                        <span>{speakingChangeType(f.change_type)}:</span>
                      </div>
                      <div className={'file-name'}>
                        <span>{styledPath(f)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {data && data.not_staged && data.not_staged.length > 0 && (
            <div className={'git-file-status-category not-staged-files'}>
              <span className={'git-file-status-title'}>
                Changes not staged for commit:
              </span>
              <div className={'git-file-status-container'}>
                {data.not_staged &&
                  data.not_staged.map((f, i) => (
                    <div
                      className={'git-file-status-row not-staged-files'}
                      key={i}
                    >
                      <div className={'change-type'}>
                        <span>{speakingChangeType(f.change_type)}:</span>
                      </div>
                      <div className={'file-name'}>
                        <span>{styledPath(f)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {data && data.untracked_files && data.untracked_files.length > 0 && (
            <div className={'git-file-status-category untracked-files'}>
              <span className={'git-file-status-title'}>Untracked Files:</span>

              <div className={'git-file-status-container'}>
                {data.untracked_files &&
                  data.untracked_files.map((f, i) => (
                    <div
                      className={'git-file-status-row untracked-files'}
                      key={i}
                    >
                      <div className={'file-name'}>
                        <span>{f}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {!atLeastOneChange && (
            <div className={'git-file-status-category'}>
              <span className={'git-file-status-title'}>
                No changes to commit
              </span>
            </div>
          )}
          <div className={'git-button-bar'}>
            <div
              className={`button git-add-and-commit ${
                !disabled && atLeastOneChange ? '' : 'button-disabled'
              }`}
              onClick={() =>
                !disabled && atLeastOneChange && showGitCommitModal()
              }
            >
              Add & Commit
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderLoading() {
    return (
      <div className={'git-file-status'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={'#224e90'}
        />
      </div>
    );
  }

  renderEmpty() {
    return <div className={'git-file-status'}>Empty</div>;
  }

  renderError() {
    const { error } = this.props;
    return <div className={'git-file-status'}>{error}</div>;
  }

  render() {
    const { loading, loaded, error } = this.props;
    if (loading) return this.renderLoading();
    if (loaded) return this.renderLoaded();
    if (error) return this.renderError();
    return this.renderEmpty();
  }
}
GitFileStatus.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.shape({
    staged: PropTypes.array,
    not_staged: PropTypes.array,
    untracked_files: PropTypes.arrayOf(PropTypes.string),
  }),
  error: PropTypes.string,
  repositoryPath: PropTypes.string.isRequired,
  /** Redux action to fetch the git file state (staged, not_staged, untracked)*/
  gitFileStatus: PropTypes.func.isRequired,
  /** Show the git commit modal */
  showGitCommitModal: PropTypes.func.isRequired,
  /** Whether the button is disabled */
  disabled: PropTypes.bool,
};
