import { TargetFunctionValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/OptTargetFunction';
import { validateTargetFunction } from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/validate';

export const formName = 'targetFunction';

export const fieldTargetFunction = 'targetFunction';

export type FormValues = {
  [fieldTargetFunction]: TargetFunctionValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldTargetFunction] = validateTargetFunction(
    values[fieldTargetFunction]
  );

  return errors;
};

export const targetFunctionForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
