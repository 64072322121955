import ModuleClient from '../../common/_interfaces/ModuleClient';
import AugurDetails from '../../common/_interfaces/augur-details/AugurDetails';
import DatapoolDetails from '../../common/_interfaces/datapool-details/DatapoolDetails';
import OptAugurDetails from './augur-details/OptAugurDetails';
import OptDatapoolDetails from './datapool-details/OptDatapoolDetails';
import { AugurType } from 'common/dist/types/augur';
import { IntlShape } from 'react-intl';
import { moduleOpt } from 'common/dist/constants/module_list';

/**
 * Client-side module for the binary classification (event-based) augur
 */
export default class OptClient implements ModuleClient {
  type = moduleOpt.type;
  name = moduleOpt.name;
  intlId = 'common.optimization';
  shortName = moduleOpt.shortName;
  available = moduleOpt.available;
  executionType = moduleOpt.executionType;
  augurDetails: AugurDetails = new OptAugurDetails();
  datapoolDetails: DatapoolDetails = new OptDatapoolDetails();
  augurDetailsDefaultPage = 'constraintExhaustion';
  datapoolDetailsDefaultPage = 'channels';
  inputFormats: object[] = [];

  overviewAugurInfo = (augur: AugurType, intl: IntlShape) => {
    return '';
  };
}
