import {
  PipelineTuningValueNode,
  SelectedStaticParameter,
  SelectedTuningParameter,
} from 'common/dist/types/pipeline';
import React, { Component } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import ToggleInput from '../../../../atoms/input-elements/toggle-input/ToggleInput';
import StaticParameters from './StaticParameters';
import TuningParameters from './TuningParameters';
import {
  NodeType,
  SingleNodeErrorType,
} from '../../_pipeline-tuning-results-common/types';

type Props = {
  selectedNode: NodeType;
  value: PipelineTuningValueNode;
  onChange: (value: PipelineTuningValueNode) => void;
  onBlur: () => void;
  onFocus: () => void;
  error: SingleNodeErrorType;
};

/**
 * Renders the intl formatted description for a tuning / static parameter
 * @param parameterDescription
 */
export function renderParameterDescription(
  parameterDescription: MessageDescriptor
) {
  if (!parameterDescription.id) {
    return (
      <span className={'PipelineTuningSelection--params-param-description'}>
        {parameterDescription.defaultMessage}
      </span>
    );
  } else {
    return (
      <FormattedMessage
        id={parameterDescription.id}
        defaultMessage={parameterDescription.defaultMessage}
      >
        {(text) => (
          <span className={'PipelineTuningSelection--params-param-description'}>
            {text}
          </span>
        )}
      </FormattedMessage>
    );
  }
}

export default class NodeEditor extends Component<Props, {}> {
  /**
   * Renders the description of the node
   * @param description
   */
  renderDescription(description: MessageDescriptor) {
    if (!description.id) {
      return (
        <span className={'PipelineTuningSelection--description'}>
          {description.defaultMessage}
        </span>
      );
    } else {
      return (
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        >
          {(text) => (
            <span className={'PipelineTuningSelection--description'}>
              {text}
            </span>
          )}
        </FormattedMessage>
      );
    }
  }

  /**
   * Renders the activate / deactivate toggle
   * @param isOptional
   */
  renderActive(
    isOptional: boolean,
    value: PipelineTuningValueNode,
    onChange: (value: PipelineTuningValueNode) => void
  ) {
    return (
      <div className={'PipelineTuningSelection--active'}>
        <ToggleInput
          disabled={!isOptional}
          description={{
            id: 'active',
            defaultMessage: 'Active',
          }}
          checked={
            Object.keys(value || {}).includes('isActive')
              ? value.isActive
              : true
          }
          onChange={(isActive: boolean) => onChange({ ...value, isActive })}
        />
      </div>
    );
  }

  render() {
    const { selectedNode, value, onChange, onFocus, onBlur, error } =
      this.props;
    const {
      displayName,
      description,
      isOptional,
      isEditable,
      staticParameters,
      isTuneable,
      tuningParameters,
    } = selectedNode;

    return (
      <div className={'PipelineTuningSelection--node-editor'}>
        <span className={'PipelineTuningSelection--display-name'}>
          {displayName}
        </span>
        {this.renderDescription(description)}
        {this.renderActive(isOptional, value, onChange)}

        <StaticParameters
          isEditable={isEditable}
          staticParameters={staticParameters}
          value={value?.staticParameters}
          onChange={(staticParameters: SelectedStaticParameter[]) =>
            onChange({ ...value, staticParameters })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          error={error?.staticParameters}
        />

        <TuningParameters
          isTuneable={isTuneable}
          tuningParameters={tuningParameters}
          value={value?.tuningParameters}
          onChange={(tuningParameters: SelectedTuningParameter[]) =>
            onChange({ ...value, tuningParameters })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          error={error?.tuningParameters}
        />
      </div>
    );
  }
}
