import ModuleClient from '../../common/_interfaces/ModuleClient';
import AugurDetails from '../../common/_interfaces/augur-details/AugurDetails';
import DatapoolDetails from '../../common/_interfaces/datapool-details/DatapoolDetails';
import BcAugurDetails from './augur-details/BcAugurDetails';
import BcDatapoolDetails from './datapool-details/BcDatapoolDetails';
import { inputFormats } from 'common/dist/constants/datapool';
import { AugurType } from 'common/dist/types/augur';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import { IntlShape } from 'react-intl';
import { moduleBc } from 'common/dist/constants/module_list';

/**
 * Client-side module for the binary classification (event-based) augur
 */
export default class BcClient implements ModuleClient {
  type = moduleBc.type;
  name = moduleBc.name;
  intlId = 'common.binary-numerical';
  shortName = moduleBc.shortName;
  available = moduleBc.available;
  executionType = moduleBc.executionType;
  augurDetails: AugurDetails = new BcAugurDetails();
  datapoolDetails: DatapoolDetails = new BcDatapoolDetails();
  augurDetailsDefaultPage = 'accuracy';
  datapoolDetailsDefaultPage = 'tables';
  inputFormats: object[] = inputFormats;

  overviewAugurInfo = (augur: AugurType, intl: IntlShape) => {
    const kpiFormatter = kpiOptionsGrouped[augur.settings?.data.learningKpi];
    const formattedKpiName = intl.formatMessage({
      id: `kpi.${augur.settings?.data.learningKpi}`,
      defaultMessage: 'Kpi',
    });
    const formattedKpiValue =
      kpiFormatter && augur.learningKpiValue
        ? kpiFormatter.format(augur.learningKpiValue)
        : 'No evaluation yet';

    return `${formattedKpiName}: ${formattedKpiValue}`;
  };
}
