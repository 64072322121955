import P3InputElement, { AsInputElement } from '../_interface/P3InputElement';
import { validateCommonSettings } from '../_interface/input-element.form';
import UploadElement from './UploadElement.container';
import UploadElementSettings from './UploadElementSettings';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';

export const targetBaseDir = ['root', '_upload'];
export const targetBasePrefix = '/workbench/';
export const targetBasePath = `${targetBaseDir.slice(1).join('/')}/`;

export default class UploadElementWrapper extends P3InputElement {
  // --- Elements
  ChildComponent: React.ComponentType = UploadElement;
  AdditionalSettings: any = UploadElementSettings;
  name = 'Upload';
  parentContainerClass = 'upload';
  type = 'upload';
  icon: any = DefaultElementIcon;
  iconColor = '#224e90';

  // --- Functions
  getSource: (asElement: AsInputElement) => string = (
    asElement: AsInputElement
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';
    const targetPathField = asElement.settings.targetPath;
    const targetPath = targetPathField ? targetPathField.inputValue || '' : '';
    let path = targetBasePrefix + targetBasePath + targetPath;
    if (!path.endsWith('/')) path = `${path}/`;

    const uploadedFiles = asElement.data.files;

    if (!uploadedFiles || uploadedFiles.length === 0) {
      return `${asElement.settings.outputVariable.inputValue} = []`;
    }

    return [
      `${asElement.settings.outputVariable.inputValue} = [`,
      ...uploadedFiles.map((f) => `'${path}${f}',`),
      ']',
    ].join(' \n');
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
