import React, { FC, useState } from 'react';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { constraintsForm, fieldConstraints } from './form';
import { Field, reduxForm } from 'redux-form';
import CancelSubmitButtonsWrapper from '../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { ConstraintsValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/OptConstraints';
import OptConstraints from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/OptConstraints.container';
import Button from '../../../../../atoms/button/Button';
import { FiEdit } from 'react-icons/all';
import classNames from 'classnames';
import { AugurSettingsData } from 'common/dist/types/augurSettings';

type Props = {
  saveSettings: (settings: AugurSettingsData, merge: boolean) => void;
  habitatCode: string;
  datapoolCode: string;
};

const constraintsComponent = ({
  input,
  meta,
  datapoolCode,
  habitatCode,
  inEditMode,
}) => {
  if (!datapoolCode) return null;
  return (
    <OptConstraints
      input={input}
      meta={meta}
      datapoolCode={datapoolCode}
      habitatCode={habitatCode}
      inEditMode={inEditMode}
    />
  );
};

const Constraints: FC<Props & InjectedFormProps<FormValueType>> = (props) => {
  const {
    pristine,
    reset,
    submitting,
    handleSubmit,
    saveSettings,
    datapoolCode,
    habitatCode,
    valid,
  } = props;

  const [inEditMode, setInEditMode] = useState(false);

  return (
    <div className={styles.optConstraints}>
      <div className={styles.headlineContainer}>
        <FormattedMessage
          {...moduleOptimizationMessages.msgAugurDetailsConstraintsTabName}
        >
          {(text) => <span className={styles.headline}>{text}</span>}
        </FormattedMessage>
        <Button
          buttonColor={'blue'}
          buttonLabelId={'no-id'}
          buttonLabelDefault={'Edit'}
          withLink={false}
          Icon={() => <FiEdit size={16} />}
          onClick={() => {
            setInEditMode(true);
          }}
          additionalClassNames={[
            classNames(styles.button, {
              [styles.invisible]: inEditMode,
            }),
          ]}
        />
      </div>
      <CancelSubmitButtonsWrapper
        cancelDisabled={!inEditMode || submitting}
        saveDisabled={pristine || submitting || !valid}
        savingButtonBusy={submitting}
        onCancel={() => {
          reset();
          setInEditMode(false);
        }}
        onSubmit={handleSubmit((values) => {
          saveSettings(
            {
              constraints: values[fieldConstraints] || [],
            },
            true
          );
          setInEditMode(false);
        })}
      >
        <Field
          name={fieldConstraints}
          component={constraintsComponent}
          datapoolCode={datapoolCode}
          habitatCode={habitatCode}
          inEditMode={inEditMode}
        />
      </CancelSubmitButtonsWrapper>
    </div>
  );
};

type FormValueType = {
  [fieldConstraints]: ConstraintsValueType;
};

export default reduxForm<FormValueType>(constraintsForm)(Constraints);
