import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputOrOutputStep from './InputOrOutputStep.container';
import Arranger from './Arranger.container';
import Busy from '../../../atoms/busy/Busy';
import IntroStep from './IntroStep.container';

export default class App extends Component {
  /**
   * Renders a beginning step
   * @returns {*}
   */
  renderBeginningStep() {
    const { path, isArrangeEditor } = this.props;
    return <IntroStep path={path} isArrangeEditor={isArrangeEditor} />;
  }

  /**
   * Renders an input step
   * @param activeExecutionPlanStep
   * @param previousStep
   * @param isExecuting
   * @param isFailure
   * @returns {*}
   */
  renderInputStep(
    activeExecutionPlanStep,
    previousStep,
    isExecuting,
    isFailure
  ) {
    const { path, isArrangeEditor, parentType, appVersionCode } = this.props;
    return (
      <InputOrOutputStep
        stepType={'input'}
        activeExecutionPlanStep={activeExecutionPlanStep}
        path={path}
        isExecuting={isExecuting}
        isFailure={isFailure}
        isArrangeEditor={isArrangeEditor}
        parentType={parentType}
        appVersionCode={appVersionCode}
      />
    );
  }

  /**
   * Renders an output step
   * @param activeExecutionPlanStep
   * @param previousStep
   * @param isExecuting
   * @param isFailure
   * @returns {*}
   */
  renderOutputStep(
    activeExecutionPlanStep,
    previousStep,
    isExecuting,
    isFailure
  ) {
    const { path, isArrangeEditor, parentType, appVersionCode } = this.props;
    return (
      <InputOrOutputStep
        stepType={'output'}
        activeExecutionPlanStep={activeExecutionPlanStep}
        path={path}
        isExecuting={isExecuting}
        isFailure={isFailure}
        isArrangeEditor={isArrangeEditor}
        parentType={parentType}
        appVersionCode={appVersionCode}
      />
    );
  }

  /**
   * Renders the loading screen
   */
  renderStepLoading() {
    return (
      <div className={'step-parent step-loading-parent'}>
        <Busy isBusy positionAbsolute />
      </div>
    );
  }

  /**
   * Switch that decides between rendering a beginning, input or output step
   * @returns {*}
   */
  renderExecutionPlanStep() {
    const { executionPlan, activeStep } = this.props;
    if (
      !executionPlan ||
      executionPlan.length === 0 ||
      executionPlan.length < activeStep
    ) {
      return <div />; // Happens when the execution plan wasn't derived yet.
    }
    const previousExecutionPlanStep =
      activeStep > 0 ? executionPlan[activeStep - 1] : undefined;
    const activeExecutionPlanStep = executionPlan[activeStep];

    // --- Is the previous step still executing?
    const isExecuting =
      previousExecutionPlanStep &&
      previousExecutionPlanStep.status === 'executing';

    // --- Was there a failure in the previous execution step?
    const isFailure =
      previousExecutionPlanStep &&
      previousExecutionPlanStep.status === 'failure';

    // --- If the previous step is done executing: Render the step itself
    switch (activeExecutionPlanStep.type) {
      case 'beginning':
        return this.renderBeginningStep(
          activeExecutionPlanStep,
          previousExecutionPlanStep,
          isExecuting,
          isFailure
        );
      case 'input':
        return this.renderInputStep(
          activeExecutionPlanStep,
          previousExecutionPlanStep,
          isExecuting,
          isFailure
        );
      case 'output':
        return this.renderOutputStep(
          activeExecutionPlanStep,
          previousExecutionPlanStep,
          isExecuting,
          isFailure
        );
      default:
        return <div />; // Shouldn't happen, but just to be very sure.
    }
  }

  render() {
    const {
      path,
      isArrangeEditor,
      executionPlan,
      activeStep,
      selectNextStepAndExecute,
      selectPreviousStep,
    } = this.props;
    const activeExecutionPlanStep = executionPlan[activeStep];

    // --- Was there a failure in the previous execution step?
    const previousExecutionPlanStep =
      activeStep > 0 ? executionPlan[activeStep - 1] : undefined;
    const isFailure =
      previousExecutionPlanStep &&
      previousExecutionPlanStep.status === 'failure';

    if (
      !executionPlan ||
      executionPlan.length === 0 ||
      executionPlan.length < activeStep
    ) {
      return <div />; // Happens when the execution plan wasn't derived yet.
    }

    // Is the next button disabled?
    const nextButtonDisabled =
      activeStep >= executionPlan.length - 1 || isFailure;

    return (
      <div
        className={`app-parent ${
          isArrangeEditor ? 'app-arrange-editor' : 'app-static'
        }`}
      >
        <div className={'app-part-main'}>
          <div className={'main-input-output'}>
            {this.renderExecutionPlanStep()}
          </div>
          <div className={'main-button-bar'}>
            <div
              className={`button button-blue button-calculate${
                activeStep <= 0 ? ' button-disabled' : ''
              }`}
              onClick={() => {
                if (!activeStep <= 0) {
                  selectPreviousStep();
                }
              }}
            >
              Previous Step
            </div>
            <div className={'step-info-container'}>
              <span className={'step-type'}>
                {activeExecutionPlanStep.type.toUpperCase()}
              </span>
              <span>
                Step {activeStep + 1} of {executionPlan.length}
              </span>
            </div>
            <div
              className={`button button-blue button-next-page${
                nextButtonDisabled ? ' button-disabled' : ''
              }`}
              onClick={() => {
                if (!nextButtonDisabled) {
                  // Mark the next step as active and execute all required parts
                  selectNextStepAndExecute();
                }
              }}
            >
              Next Step
            </div>
          </div>
        </div>
        {isArrangeEditor && (
          <Arranger
            activeStep={activeStep}
            executionPlan={executionPlan}
            activeStepType={activeExecutionPlanStep.type}
            path={path}
          />
        )}
      </div>
    );
  }
}
App.propTypes = {
  /** Path of the notebook that is shown as app */
  path: PropTypes.string.isRequired,
  /** If this is set, the app is opened in the arrange modal and not for regular execution */
  isArrangeEditor: PropTypes.bool,
  /** The notebook structured into parts to manage which cells can be executed and what is supposed to be shown in the
   * input output screens */
  executionPlan: PropTypes.arrayOf(
    PropTypes.shape({
      /** input|output|beginning */
      type: PropTypes.string.isRequired,
      /** waiting|executing|success|failure */
      status: PropTypes.string.isRequired,
      /** Cells for this executionPlan element (duplicate to the notebook.content.cells */
      cells: PropTypes.arrayOf(PropTypes.shape({})),
      executionErrors: PropTypes.array,
    })
  ),
  /** Index of the active (=visible) execution plan step */
  activeStep: PropTypes.number.isRequired,
  /** Information about the notebook session */
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  /** Select (=display) the next step of the execution plan and execute all required parts */
  selectNextStepAndExecute: PropTypes.func.isRequired,
  /** Select (=display) the previous step of the execution plan */
  selectPreviousStep: PropTypes.func.isRequired,
  /** notebook | app */
  parentType: PropTypes.oneOf(['app', 'notebook']),
  /** Only set if parentType='app' */
  appVersionCode: PropTypes.string,
};
App.defaultProps = {
  isArrangeEditor: false,
  executionPlan: [],
  parentType: 'notebook',
  appVersionCode: '',
};
