import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import {
  joinGroup,
  leaveGroup,
} from '../../../../redux/modules/admin.users.module';
import { ReduxActUnknown2 } from '../../../../store/reduxAct';
import { User as UserType } from 'common/dist/types/users';

export const adminUserPermissions = (userId) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.adminUserDetails.path}/${userId}`;

export type Props = {
  isMember: boolean;
  groupId: string;
  additionalGroup?: { isMember: boolean; groupId: string };
} & UserType;

const User: FC<Props> = ({
  id,
  firstName,
  lastName,
  email,
  realmRoles,
  isMember,
  groupId,
  additionalGroup,
  attributes,
}) => {
  const dispatch = useDispatch();
  const isTechnical = (attributes?.isTechnical || [''])[0] === 'true';
  if (isTechnical) {
    return (
      <div className={'ct-row'}>
        <div className={'ct-col ct-col-200px'} style={{ color: '#9f9f9f' }}>
          [Technical User]
        </div>
      </div>
    );
  }
  return (
    <Link to={adminUserPermissions(id)} style={{ textDecoration: 'none' }}>
      <div className={'ct-row'}>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          {firstName}
        </div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>{lastName}</div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>{email}</div>
        <div className={'ct-col ct-col-80px'}>
          {' '}
          <Switch
            onChange={() =>
              isMember
                ? dispatch((leaveGroup as ReduxActUnknown2)(id, groupId))
                : dispatch((joinGroup as ReduxActUnknown2)(id, groupId))
            }
            checked={isMember}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            onColor='#224e90'
          />
        </div>
        {additionalGroup && (
          <div className={'ct-col ct-col-80px'}>
            {' '}
            <Switch
              onChange={() =>
                additionalGroup.isMember
                  ? dispatch(
                      (leaveGroup as ReduxActUnknown2)(
                        id,
                        additionalGroup.groupId
                      )
                    )
                  : dispatch(
                      (joinGroup as ReduxActUnknown2)(
                        id,
                        additionalGroup.groupId
                      )
                    )
              }
              checked={additionalGroup.isMember}
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
              activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              onColor='#224e90'
            />
          </div>
        )}
      </div>
    </Link>
  );
};
export default User;
