import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import CustomerTable from './CustomerTable';
import { fieldCustomerTable, formName } from './form';
import { formValueSelector } from 'redux-form';
import { ModelSettingsProps } from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    augur: { datapool, habitatCode },
    settings: { customerTable },
  } = ownProps;

  return {
    initialValues: {
      [fieldCustomerTable]: customerTable || {},
    },
    habitatCode,
    datapool,
    valueSelector: (fieldName: string) =>
      formValueSelector(formName)(state, fieldName),
  };
}

export default connect(mapStateToProps)(CustomerTable);
