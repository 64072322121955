import React, { FC, ReactChild, ReactChildren } from 'react';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import messagesForm from 'common/dist/messages/form';

export type Props = {
  /** Child component to render above the buttons */
  children?: ReactChild | ReactChildren;
  /** Show a spinner inside of the saving button? */
  savingButtonBusy?: boolean;
  /** Are both buttons currently disabled? */
  disabled?: boolean;
  /** Callback for when the cancel button is clicked */
  onCancel: () => void;
  /** Callback for when the save button is clicked */
  onSubmit: () => void;
  /** Disable only the cancel button */
  cancelDisabled?: boolean;
  /** Disable only the save button */
  saveDisabled?: boolean;
};

const CancelSubmitButtonsWrapper: FC<Props> = (props) => {
  const {
    children,
    savingButtonBusy,
    disabled,
    onSubmit,
    onCancel,
    cancelDisabled,
    saveDisabled,
  } = props;

  return (
    <div className={styles.cancelSubmitButtonsWrapper}>
      <div className={styles.componentParent}>{children}</div>

      <div className={styles.buttonsParent}>
        <Button
          disabled={disabled || cancelDisabled}
          withLink={false}
          onClick={onCancel}
          buttonColor={'white'}
          buttonLabelId={messagesForm.cancel.id}
          buttonLabelDefault={messagesForm.cancel.defaultMessage}
          buttonId={'btn-table-selection-cancel'} // TODO legacy, might break the e2e tests if changed
        />

        <Button
          disabled={disabled || saveDisabled}
          isBusy={savingButtonBusy}
          withLink={false}
          onClick={onSubmit}
          buttonColor={'green'}
          buttonLabelId={messagesForm.submit.id}
          buttonLabelDefault={messagesForm.submit.defaultMessage}
          buttonId={'btn-table-selection-submit'} // TODO legacy, might break the e2e tests if changed
        />
      </div>
    </div>
  );
};

export default CancelSubmitButtonsWrapper;
