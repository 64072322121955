import React, { FC, Fragment } from 'react';
import ActionButton from '../action-button/ActionButton';
import ActionButtonLoading from '../action-button/ActionButtonLoading';
import { CodeCapsule, Version } from 'common/dist/types/codeCapsule';

type Props = {
  /** Are the code-capsule details loading? */
  loading?: boolean;
  /** Are the code-capsule details loaded? */
  loaded?: boolean;
  /** The code-capsule details */
  codeCapsule?: CodeCapsule;

  triggerRunCodeCapsuleJob: (
    repositoryName: string | undefined,
    repoCode: string,
    capsuleVersionNumber: string,
    capsuleImage: string,
    codeCapsuleCode: string,
    notebooksToExecute: string[],
    resources: Record<string, unknown>,
    parameters: Record<string, unknown>,
    habitatCode: string,
    requestCredentialsFor: string[]
  ) => void;
};

const actionButtons = {
  runLatestVersion: {
    gridColumn: 6,
    gridRow: 1,
    Icon: () => <span className={'icon-play'} />,
    label: {
      id: 'no-id',
      defaultMessage: 'Run Latest Version Now',
    },
  },
};

function getLatestVersion(data: CodeCapsule): Version {
  if (!data || !data.versions) return undefined;

  const sortedVersions = data.versions;
  sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  return sortedVersions.length > 0 ? sortedVersions[0] : ({} as Version);
}

const ActionButtons: FC<Props> = (props: Props) => {
  const { codeCapsule, loading, loaded, triggerRunCodeCapsuleJob } = props;

  if (loading || !loaded)
    return (
      <Fragment>
        {Object.values(actionButtons).map((ab, i) => (
          <ActionButtonLoading {...ab} key={`ab_${i}`} />
        ))}
      </Fragment>
    );

  const latestVersion = getLatestVersion(codeCapsule);

  // Further enhance the action buttons with the information that came with the loaded information now
  const actionButtonsLoaded = {
    runLatestVersion: {
      onClick: () =>
        latestVersion &&
        triggerRunCodeCapsuleJob(
          undefined,
          codeCapsule.repoCode,
          latestVersion.number,
          latestVersion.imageName,
          codeCapsule.code,
          latestVersion.availableNotebooks,
          {},
          {},
          codeCapsule.habitatCode,
          latestVersion.requestCredentialsFor
        ),
    },
  };

  return (
    <Fragment>
      {Object.keys(actionButtons).map((abId) => {
        const ab = actionButtons[abId];
        const abc = actionButtonsLoaded[abId];
        return <ActionButton {...ab} {...abc} />;
      })}
    </Fragment>
  );
};

export default ActionButtons;
