import React, { FC } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import ModuleCard from './ModuleCard';
import _ from 'lodash';
import ModuleCardLoading from './ModuleCardLoading';
import { EnrichedModule } from 'common/dist/constants/module_list';

export type Props = {
  modules?: EnrichedModule[];
  loading?: boolean;
  /** Also call onBlur (additional to onChange) in the onClick listener? */
  blurOnClick?: boolean;
  beforeOnChange?: () => void;
};

const AMOUNT_MODULES_WHILE_LOADING = 3;

const ModuleType: FC<Props & WrappedFieldProps> = (props) => {
  const {
    modules,
    input: { value, onChange, onBlur },
    loading,
    blurOnClick,
    beforeOnChange,
  } = props;

  // Loading
  if (loading) {
    return (
      <div className={styles.moduleType}>
        {_.range(AMOUNT_MODULES_WHILE_LOADING).map((i) => (
          <ModuleCardLoading key={i} />
        ))}
      </div>
    );
  }

  const availableModules = modules
    .filter((a) => a.available === 'yes')
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  // Loaded
  return (
    <div className={styles.moduleType}>
      {availableModules.map((module, index) => (
        <ModuleCard
          {...module}
          key={index}
          onClick={() => {
            beforeOnChange && beforeOnChange();
            onChange(module.type);
            blurOnClick && onBlur(module.type);
          }}
          isSelected={value === module.type}
          explicitlyNotSelected={!!value && value !== module.type}
        />
      ))}
    </div>
  );
};

export default ModuleType;
