import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  fieldCategories,
  fieldColors,
  fieldDefaultCategory,
  fieldData,
} from '../_interface/input-element.form';
import TextAreaCol from '../../../settings-dialog/dialog-settings-cols/TextAreaCol';
import { variableShape } from '../_interface/P3InputElementShape';
import { VARIABLE_TYPES } from '../../variables/variableTypes';
import TextInputCol from '../../../settings-dialog/dialog-settings-cols/TextInputCol';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class SspSettings extends Component {
  render() {
    const { path, cellId, showSelectVariable } = this.props;

    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldCategories}
            label={'Categories'}
            component={TextAreaCol}
            placeholder={'Enter the categories'}
            path={path}
            cellId={cellId}
          />
          <Field
            name={fieldColors}
            label={'Colors (optional)'}
            component={TextAreaCol}
            placeholder={'#224E90'}
            path={path}
            cellId={cellId}
          />
        </div>
        <div className={'input-row'}>
          <Field
            name={fieldDefaultCategory}
            label={'Default Category'}
            component={TextInputCol}
            placeholder={'Default Category'}
            path={path}
            cellId={cellId}
          />
        </div>
        <div className={'input-row'}>
          <Field
            name={fieldData}
            label={'Data'}
            component={TextAreaCol}
            placeholder={'Enter the data'}
            path={path}
            cellId={cellId}
            allowVariable
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.JSON]}
          />
        </div>
      </Fragment>
    );
  }
}

SspSettings.propTypes = {
  categories: PropTypes.shape({
    inputValue: PropTypes.arrayOf(PropTypes.string),
  }),
  variables: PropTypes.arrayOf(variableShape),
  path: PropTypes.string,
  cellId: PropTypes.string,
  showSelectVariable: PropTypes.func.isRequired,
};
SspSettings.defaultProps = {
  selectableValues: [],
};

export default reduxForm(inputModalForm)(SspSettings);
