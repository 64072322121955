import React, { FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';
import { schedulePeriod } from 'common/dist/utils/schedules';
import {
  monthDaysMap,
  weekDaysMap,
} from 'common/dist/constants/schedulePeriods';
import { zeroPad } from 'common/dist/utils/zeroPad';

export type ScheduleType = {
  trigger: 'timed' | 'delayed' | 'rest' | 'manual';
  timedType?: {
    period: 'hourly' | 'daily' | 'weekly' | 'monthly';
    minuteOfHour?: number;
    hourOfDay?: number;
    dayOfWeek?: number;
    dayOfMonth?: number;
  };
  trigDelayed?: string;
};

export type Props = ScheduleType;

const ScheduleInfo: FC<Props> = ({
  trigger,
  trigDelayed,
  timedType: { period, minuteOfHour, hourOfDay, dayOfWeek, dayOfMonth } = {},
}) => {
  const intl = useIntl();

  const renderInfoCaption = () => (
    <Fragment>
      <b>
        <FormattedMessage
          {...(trigger === 'timed'
            ? orchestrationMessages.scheduleRepeating
            : orchestrationMessages.scheduleOneTime)}
        />
      </b>
    </Fragment>
  );

  const renderTimingInfo = () => {
    if (trigDelayed && !period) {
      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionDelayed}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              date: <FormattedDateTime date={new Date(trigDelayed)} />,
            }}
          />
        </Fragment>
      );
    }

    if (period === schedulePeriod.MONTHLY) {
      const dayOfMonthId = monthDaysMap[dayOfMonth];
      const dayFormatted = intl.formatMessage({ id: dayOfMonthId });

      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodMonthly}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              dayOfMonth: dayFormatted,
              hour: zeroPad(hourOfDay, 2),
              minute: zeroPad(minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (period === schedulePeriod.WEEKLY) {
      const dayOfWeekId = weekDaysMap[dayOfWeek - 1];
      const dayFormatted = intl.formatMessage({
        id: dayOfWeekId,
      });

      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodWeekly}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              dayOfWeek: dayFormatted,
              hour: zeroPad(hourOfDay, 2),
              minute: zeroPad(minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (period === schedulePeriod.DAILY) {
      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodDaily}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              hour: zeroPad(hourOfDay, 2),
              minute: zeroPad(minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (period === schedulePeriod.HOURLY) {
      return (
        <FormattedMessage
          {...orchestrationMessages.scheduleDescriptionPeriodHourly}
          values={{
            b: (chunks) => <b>{chunks}</b>,
            minuteOfHour: zeroPad(minuteOfHour, 2),
          }}
        />
      );
    }
  };

  return (
    <div>
      <div>
        <span>{renderInfoCaption()}</span>
      </div>
      {renderTimingInfo()}
    </div>
  );
};

export default ScheduleInfo;
