import { ValueType } from './SamplingStrategy';
import { MessageDescriptor } from 'react-intl';

export type ErrorType = {
  strategy?: MessageDescriptor;
  evaluationTable?: MessageDescriptor;
  trainingSplit?: MessageDescriptor;
};

export function validateSamplingStrategy(
  samplingStrategy: ValueType
): ErrorType {
  if (!samplingStrategy || !samplingStrategy.strategy) {
    return {
      strategy: {
        id: 'newAugur.step.sampling_strategy.not_selected',
        defaultMessage: 'Please select a sampling strategy',
      },
    };
  }

  if (samplingStrategy?.strategy === 'table') {
    const { evaluationTable } = samplingStrategy;
    if (!evaluationTable) {
      return {
        evaluationTable: {
          id: 'newAugur.step.sampling_strategy.no_evaluation_table',
          defaultMessage: 'Please select an evaluation table',
        },
      };
    }
  } else if (samplingStrategy?.strategy === 'automatic') {
    const { trainingSplit } = samplingStrategy;
    if (!trainingSplit) {
      return {
        trainingSplit: {
          id: 'newAugur.step.sampling_strategy.no_training_split_size',
          defaultMessage: 'Please select the training split size',
        },
      };
    }
  }

  return undefined;
}
