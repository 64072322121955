import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Group from './Group';
import Busy from '../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import { FormattedMessage } from 'react-intl';

function sortData(data) {
  if (!data) return [];
  return data.sort((a, b) => {
    if (!a.fullName || !b.fullName) return -1;
    return a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1;
  });
}

export default class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = { searchString: '' };
  }

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchUserSummaryById, data, usersById } = this.props;
    // Gitea specific
    const groupNames = new Set(data.map((group) => group.name));
    const prevGroupNames = new Set(prevProps.data.map((group) => group.name));
    // The groups have changed and we may need to fetch userNames
    if (!_.isEqual(groupNames, prevGroupNames)) {
      const userIdsToFetch = Array.from(groupNames.values()).filter(
        (userId) => !(userId in usersById)
      );
      userIdsToFetch.forEach((userId) => fetchUserSummaryById({ userId }));
    }
  }

  renderHeadline(withBorder = false) {
    return (
      <div className={'headline' + (withBorder ? ' with-border' : '')}>
        <div className={'search'}>
          <input
            placeholder={'Filter by name'}
            type={'text'}
            value={this.state.searchString}
            onChange={(e) => this.setState({ searchString: e.target.value })}
          />
        </div>
        {/*
        <Link to={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.groups.create}`}>
          <div className={'button button-green'}>
            <span>Create Group</span>
          </div>
        </Link>
        */}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'CollaborationSpace--content'}>
        <Busy isBusy />
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'groups'}>
          {this.renderHeadline(true)}
          {this.renderErrorText(error)}
        </div>
      </div>
    );
  }

  renderErrorText(error) {
    if (!error) {
      return <div />;
    } else if (error.id || error.defaultMessage) {
      return (
        <FormattedMessage
          id={error.id || 'no-id'}
          defaultMessage={error.defaultMessage}
        />
      );
    } else {
      return <span>{JSON.stringify(error)}</span>;
    }
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'git-groups-empty-pic'}
        headlineId={'no-id'}
        headlineDefault={'There are no groups yet'}
      />
    );
  }

  renderLoaded() {
    const {
      data,
      usersById /*, deleteRepoConfirm, hideDeleteRepoConfirm, deleteRepository*/,
    } = this.props;
    const searchedData = this.state.searchString
      ? data.filter((group) => group.name.includes(this.state.searchString))
      : data;

    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'groups'}>
          {this.renderHeadline()}

          {sortData(searchedData).map((group, i) => {
            // Gitea specific
            const user = usersById[group.name]?.data;
            const speakingOwner =
              user === undefined
                ? undefined
                : `${user.firstName} ${user.lastName}`;

            return (
              <Group
                key={i}
                id={group.id}
                name={group.name}
                fullName={group.fullName} // Probably missing
                description={group.repoDescription} // Probably missing
                speakingOwner={speakingOwner}
              />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { data, error, loading, loaded } = this.props;
    if (error) return this.renderError();
    else if (loading && (!data || data.length === 0))
      return this.renderLoading();
    else if (loaded && data && data.length === 0) return this.renderEmpty();
    else return this.renderLoaded();
  }
}

Groups.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  /** List of loaded groups */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /** ID of the group */
      id: PropTypes.string,
      /** Name of the group */
      name: PropTypes.string.isRequired,
      /** Full name of the group */
      fullName: PropTypes.string,
      /** Description of the group */
      description: PropTypes.string,
    })
  ),
  fetchUserSummaryById: PropTypes.func.isRequired,
  usersById: PropTypes.object.isRequired,
};
