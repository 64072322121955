import P3InputElement, {
  AsInputElement,
  AsVariable,
} from '../_interface/P3InputElement';
import {
  validateCommonSettings,
  getValueFromSettingsField,
} from '../_interface/input-element.form';
import DropdownSelect from './DropdownSelect';
import DropdownSelectSettings from './DropdownSelectSettings.container';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';

export default class DropdownSelectWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = DropdownSelect;
  AdditionalSettings: any = DropdownSelectSettings;
  name = 'Dropdown Select';
  parentContainerClass = 'dropdown-select';
  type = 'dropdown-select';
  icon: any = DefaultElementIcon;
  iconColor = '#224e90';

  // --- Functions

  getSource: (asElement: AsInputElement, variables: AsVariable[]) => string = (
    asElement,
    variables
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    let selectedValue = asElement.data.selectedValue;
    const defaultValueField = asElement.settings.defaultValue;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );
    if (!selectedValue && defaultValue) {
      // If the value isn't set: Inject the default value
      selectedValue = defaultValue;
    }

    const selectedValueEscaped = selectedValue
      ? selectedValue.replace('"', '\\"')
      : '';
    return `${asElement.settings.outputVariable.inputValue} = "${selectedValueEscaped}"`;
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
