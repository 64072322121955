import React, { Component } from 'react';
import KpiThresholdSelect from './KpiThresholdSelect';
import CancelSubmitButtonsWrapper from '../reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import { reduxForm } from 'redux-form';
import { relearningConditionForm } from './form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { ModelSettingsProps } from '../../modelManagement/modules/common/_interfaces/augur-details/model-settings/ModelSettingsCategory';

export type Props = {
  /** Injected by redux-form, resets the form to the initial values */
  reset: () => void;
  /** Have the form values been changed? */
  pristine: boolean;
  learningKpi: string;
  learningThreshold: number;
};

class RelearningCondition extends Component<
  Props & InjectedFormProps & ModelSettingsProps
> {
  render() {
    const {
      pristine,
      learningKpi,
      learningThreshold,
      reset,
      saveSettings,
      saving,
    } = this.props;
    return (
      <CancelSubmitButtonsWrapper
        disabled={pristine || saving}
        savingButtonBusy={saving}
        onCancel={reset}
        onSubmit={() =>
          saveSettings(
            {
              learningKpi,
              learningThreshold,
            },
            true
          )
        }
      >
        <KpiThresholdSelect
          learningKpi={learningKpi}
          learningThreshold={learningThreshold}
        />
      </CancelSubmitButtonsWrapper>
    );
  }
}

export default reduxForm(relearningConditionForm)(RelearningCondition);
