import { createAction } from 'redux-act';
import { all, call, takeEvery, select, put } from 'redux-saga/effects';
import {
  executeInputCellVariables,
  executeSingleCell,
  executeSingleCellById,
  openSocket,
  openSockets,
} from './notebook.websocket.module';
import { notebookUser } from '../selectors/notebookUser.selector';
import * as NotebookApi from '../../../core/api/workbench/notebook';
import * as SessionApi from '../../../core/api/workbench/session';
import { selectNotebook, updateNotebookCells } from './utils/notebooks';

/**
 * Opens the modal that allows to arrange the app in the Workbench
 * @type {ComplexActionCreator1<unknown, {path: unknown}, {}>}
 */
export const showAppArrangeModal = createAction(
  'show app arrange modal',
  (path) => ({ path })
);

export const hideAppArrangeModal = createAction('hide app arrange modal');

export const addExecutionPlan = createAction(
  'add execution plan',
  (executionPlan) => ({ executionPlan })
);

export const executeExecutionPlanStep = createAction(
  'execute execution plan step',
  (executionPlanStep, session) => ({ executionPlanStep, session })
);

export const selectNextStepAndExecute = createAction(
  ' select next execution plan step and execute'
);

export const selectNextStep = createAction(' select next execution plan step');

export const selectPreviousStep = createAction(
  ' select previous execution plan step'
);

export const updateLayouts = createAction(
  'update layouts',
  (path, cellId, layouts) => ({ path, cellId, layouts })
);

export const updateUnlayoutedElements = createAction(
  'update uplayouted elements',
  (path, cellId, unlayoutedElements) => ({ path, cellId, unlayoutedElements })
);

export const expandArranger = createAction('expand app arranger');

export const collapseArranger = createAction('expand app arranger');

export const fetchNotebookForApp = createAction(
  'fetch notebook for app',
  (path, appVersionCode) => ({ path, appVersionCode })
);

export const fetchNotebookForAppSuccess = createAction(
  'fetch notebook for app - success',
  (notebook) => ({ notebook })
);

export const fetchNotebookForAppFail = createAction(
  'fetch notebook for app - fail',
  (error) => ({ error })
);

export const postSessionForApp = createAction(
  'create session for app',
  (notebookName, notebookPath, kernelName, jupyterUser, serverName) => ({
    notebookName,
    notebookPath,
    kernelName,
    jupyterUser,
    serverName,
  })
);

export const postSessionForAppSuccess = createAction(
  'create session for app - success',
  (success) => success
);

export const postSessionForAppFailure = createAction(
  'create session for app - failure',
  (error) => error
);

/**
 * Updates the breakpoint for the react-grid-layout (for responsiveness)
 * @type {ComplexActionCreator1<unknown, {breakpoint: unknown}, {}>}
 */
export const updateCurrentBreakpoint = createAction(
  'update current breakpoint',
  (breakpoint) => ({ breakpoint })
);

/**
 * Marks a cell as executing, for parentType='app'.
 * This is the "sister"-action to "markCellAsExecuting", which is called for parentType='notebook'
 * @type {ComplexActionCreator2<unknown, unknown, {path: unknown, executingCell: unknown}, {}>}
 */
export const markCellAsExecutingApp = createAction(
  'mark cell as executing - app',
  (path, executingCellId) => ({ path, executingCellId })
);

export const updateAppIntro = createAction(
  'update app intro',
  (path, title, description) => ({ path, title, description })
);

export const reducer = {
  [showAppArrangeModal](state, { path }) {
    const notebook = state.notebooks[path];
    const session = notebook.session; // Explicitly picked out here - since for an app execution such a session might have to be created (instead of picking it from the notebook).
    return {
      ...state,
      app: {
        ...state.app,
        isAppArrangeModalShown: true,
        path,
        notebook,
        session,
        loading: true,
        loaded: false,
        activeStep: 0,
      },
    };
  },
  [hideAppArrangeModal]: (state) => ({
    ...state,
    app: {
      ...state.app,
      isAppArrangeModalShown: false,
    },
  }),
  [addExecutionPlan]: (state, { executionPlan }) => ({
    ...state,
    app: {
      ...state.app,
      executionPlan,
      loading: false,
      loaded: true,
    },
  }),
  [executeExecutionPlanStep]: (state, { executionPlanStep }) => ({
    ...state,
    app: {
      ...state.app,
      executionPlan: (state.app.executionPlan || []).map((es) =>
        es.stepIndex === executionPlanStep.stepIndex
          ? {
              ...es,
              executionErrors: undefined, // Remove the execution erros when re-executing the executionPlanStep
            }
          : es
      ),
    },
  }),
  [selectNextStep](state) {
    const amountExecutionSteps = state.app.executionPlan.length;
    let nextStep = state.app.activeStep + 1;
    if (nextStep >= amountExecutionSteps) {
      // nextStep is an index (ranging from 0 to length -1)
      nextStep = amountExecutionSteps - 1;
    }
    return {
      ...state,
      app: {
        ...state.app,
        activeStep: nextStep,
      },
    };
  },
  [selectPreviousStep](state) {
    let nextStep = state.app.activeStep - 1;
    if (nextStep < 0) {
      // nextStep is an index (ranging from 0 to length -1)
      nextStep = 0;
    }
    return {
      ...state,
      app: {
        ...state.app,
        activeStep: nextStep,
      },
    };
  },
  /**
   * Reducer for updating the react-grid-layout. Called when an element of the layout is dragged around / resized
   * Currently this updates the layouts at 3 places in the state: state.app.executionPlan / state.app.notebook / state.notebooks...
   * TODO This threefold redundancy must be resolved!
   * @param state
   * @param path
   * @param cellId
   * @param layouts
   * @returns {{app: {executionPlan: unknown[], notebook: {content: {cells: *}}}, notebooks: *}|*}
   */
  [updateLayouts](state, { path, cellId, layouts }) {
    const executionPlanStepIndex = state.app.executionPlan.findIndex(
      (step) => step.cells && step.cells.some((c) => c.id === cellId)
    );
    if (executionPlanStepIndex < 0) return state;
    const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

    const updatedExecutionPlanStep = {
      ...executionPlanStep,
      cells: executionPlanStep.cells.map((c) =>
        c.id === cellId
          ? {
              ...c,
              layouts,
            }
          : c
      ),
    };

    const updatedFields = {
      layouts,
    };
    const updatedNotebooks = updateNotebookCells(
      state,
      path,
      cellId,
      updatedFields
    ).notebooks;

    return {
      ...state,
      app: {
        ...state.app,
        executionPlan: [
          // A. Update the execution plan
          ...state.app.executionPlan.slice(0, executionPlanStepIndex),
          updatedExecutionPlanStep,
          ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
        ],
        notebook: {
          // B. Update the notebook for the app
          ...state.app.notebook,
          content: {
            ...state.app.notebook.content,
            cells: state.app.notebook.content.cells.map((c) =>
              c.id === cellId
                ? {
                    ...c,
                    layouts,
                  }
                : { ...c }
            ),
          },
        },
      },
      notebooks: updatedNotebooks,
    };
  },
  [updateAppIntro](state, { path, title, description }) {
    const notebook = selectNotebook(state, path);
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...notebook,
          content: {
            ...notebook.content,
            appIntro: {
              title,
              description,
            },
          },
          unsavedChanges: true,
        },
      },
    };
  },
  [updateUnlayoutedElements](state, { path, cellId, unlayoutedElements }) {
    const executionPlanStepIndex = state.app.executionPlan.findIndex(
      (step) => step.cells && step.cells.some((c) => c.id === cellId)
    );
    if (executionPlanStepIndex < 0) return state;
    const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

    const updatedExecutionPlanStep = {
      ...executionPlanStep,
      cells: executionPlanStep.cells.map((c) =>
        c.id === cellId
          ? {
              ...c,
              unlayoutedElements,
            }
          : c
      ),
    };

    const updatedFields = {
      unlayoutedElements,
    };
    const updatedNotebooks = updateNotebookCells(
      state,
      path,
      cellId,
      updatedFields
    ).notebooks;

    return {
      ...state,
      app: {
        ...state.app,
        executionPlan: [
          // A. Update the execution plan
          ...state.app.executionPlan.slice(0, executionPlanStepIndex),
          updatedExecutionPlanStep,
          ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
        ],
        notebook: {
          // B. Update the notebook for the app
          ...state.app.notebook,
          content: {
            ...state.app.notebook.content,
            cells: state.app.notebook.content.cells.map((c) =>
              c.id === cellId
                ? {
                    ...c,
                    unlayoutedElements,
                  }
                : { ...c }
            ),
          },
        },
      },
      notebooks: updatedNotebooks,
    };
  },
  [collapseArranger]: (state) => ({
    ...state,
    app: {
      ...state.app,
      isArrangerExpanded: false,
    },
  }),
  [expandArranger]: (state) => ({
    ...state,
    app: {
      ...state.app,
      isArrangerExpanded: true,
    },
  }),
  [updateCurrentBreakpoint]: (state, { breakpoint }) => ({
    ...state,
    app: {
      ...state.app,
      currentBreakpoint: breakpoint,
    },
  }),
  [fetchNotebookForApp]: (state, { path }) => ({
    ...state,
    app: {
      ...state.app,
      loading: true,
    },
  }),
  [fetchNotebookForAppSuccess]: (state, { notebook }) => ({
    ...state,
    app: {
      ...state.app,
      loading: false,
      loaded: true,
      notebook,
      error: undefined,
    },
  }),
  [fetchNotebookForAppFail]: (state, { error }) => ({
    ...state,
    app: {
      ...state.app,
      loading: false,
      loaded: false,
      error,
      notebook: undefined,
    },
  }),
  [postSessionForAppSuccess]: (state, response) => ({
    ...state,
    app: {
      ...state.app,
      notebook: {
        ...state.app.notebook,
        session: response,
      },
      session: response,
    },
  }),
  [markCellAsExecutingApp]: (state, { path, executingCellId }) => {
    // TODO this is like the most inefficient way to get the index of the execution plan step for this cell. Better:
    //  Pass that information when executing the cell!
    const executionPlanStepIndex = (state.app.executionPlan || []).findIndex(
      (step) => step.cells && step.cells.some((c) => c.id === executingCellId)
    );

    if (executionPlanStepIndex < 0) return state;
    const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

    const updatedExecutionPlanStep = {
      ...executionPlanStep,
      status: 'executing', // If one cell of the execution plan step is marked as now being executed the whole step is in state 'executing'
      cells: executionPlanStep.cells.map((c) =>
        c.id === executingCellId
          ? {
              ...c,
              executing: true,
            }
          : c
      ),
    };

    /*
     * No: Don't update the notebook. This is an app execution and has nothing to do with the notebook.
    const updatedFields = {
      executing: true,
    };
    const updatedNotebooks = updateNotebookCells(state, path, executingCellId, updatedFields).notebooks;
    */

    return {
      ...state,
      app: {
        ...state.app,
        executionPlan: [
          // A. Update the execution plan
          ...state.app.executionPlan.slice(0, executionPlanStepIndex),
          updatedExecutionPlanStep,
          ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
        ],
        /* TODO Why? There is no reason to do so? The executionPlan is the single source of truth for an app!
        notebook: { // B. Update the notebook for the app
          ...state.app.notebook,
          content: {
            ...state.app.notebook.content,
            cells: state.app.notebook.content.cells.map(c => (c.id === executingCellId ? {
              ...c,
              executing: true,
            } : { ...c })),
          },
        },
        */
      },
    };
  },
};

/**
 * Function that derives the executionPlan for the App from the notebook.
 * Returns an array like: [{ type, cells, status }, ...] where type is (input|output|beginning), cells is an array of
 * cells, including the input/output cell and all successing cells till the next input/output cell (or the end of the notebook).
 * status is (waiting|executing|successful|failure) and describes the status of one "block" of cells (where each "block")
 * begins either with an input or output cell (or the "beginning" of the notebook).
 * @param notebook
 */
function deriveExecutionPlan(notebook) {
  if (!notebook || !notebook.content || !notebook.content.cells) {
    return [];
  }

  const cells = notebook.content.cells;
  let executionPlan = []; // List of executionElements

  // Holder element to collect the cells for the executionElement
  let executionElementCells = [];

  // Assume the first executionElement is a "beginning" element. For the case that the first cell is an input or an output cell it will immediately overwritten and won't be used.
  let executionElementHead = { type: 'beginning', status: 'waiting' };
  cells.forEach((cell) => {
    switch (cell.cell_type) {
      case 'python3-input': // Fall-through intended
      case 'python3-output': {
        // Finish the previous executionElement
        if (executionElementCells.length > 0) {
          executionPlan.push({
            ...executionElementHead,
            cells: executionElementCells,
          });
          executionElementCells = [];
        }
        // Create the new executionElementHead
        executionElementHead = {
          status: 'waiting',
          type: cell.cell_type === 'python3-input' ? 'input' : 'output',
        };
        executionElementCells.push(cell);
        break;
      }
      default: {
        // Found neither an input nor an output cell - put it into the list of cells to execute
        executionElementCells.push(cell);
      }
    }
  });

  // Push the last executionElement too
  if (executionElementCells.length > 0) {
    executionPlan.push({
      ...executionElementHead,
      cells: executionElementCells,
    });
    executionElementCells = [];
  }

  // If there is no beginning element (since the first cell of the notebook is of type input / output) add an artificial
  //   beginning element (so the Welcome screen for the app is shown)
  if (executionPlan[0].type !== 'beginning') {
    executionPlan = [
      { type: 'beginning', status: 'waiting' },
      ...executionPlan,
    ];
  }

  return executionPlan.map((es, i) => ({ ...es, stepIndex: i }));
}

/**
 * Executes one execution plan step by consecutively executing every cell of the step
 * @param executionPlanStep
 * @param session
 * @returns {Generator<*, void, *>}
 */
export function* executeExecutionPlanStepSaga({
  payload: { executionPlanStep, session },
}) {
  const cells = executionPlanStep.cells;
  if (!cells || cells.length === 0) return; // The only case that this happens is when the very first cell of a notebook is either an Input or an Output Cell (and no code cell)

  const sessionId = session.id;
  const socket = openSockets[sessionId]; // TODO Treat that socket is null here and needs to be opened. <- is this really necessary?
  if (!socket) console.log('socket was undefined');
  const kernel = yield select(
    (state) => state.workbench.app.notebook?.content?.metadata?.kernelspec?.name
  );

  // TODO picking the path from session.path might not be a good idea. Probably it works, but it's misleading since the
  //  path refers to the path of the notebook.
  for (const cell of cells) {
    yield call(
      executeSingleCell,
      session.path,
      socket,
      sessionId,
      cell.id,
      cell,
      'app',
      kernel
    );
  }
}

export function* showAppArrangeModalSaga({ payload: { path } }) {
  const notebook = yield select((state) => state.workbench.notebooks[path]);

  // Derive the execution plan
  const executionPlan = deriveExecutionPlan(notebook);
  yield put(addExecutionPlan(executionPlan));
}

export function* watchShowAppArrangeModal() {
  yield takeEvery(showAppArrangeModal.getType(), showAppArrangeModalSaga);
}

export function* selectNextStepAndExecuteSaga() {
  const executionPlan = yield select(
    (state) => state.workbench.app.executionPlan
  );
  const activeStep = yield select((state) => state.workbench.app.activeStep);
  const session = yield select((state) => state.workbench.app.session);
  const kernel = yield select(
    (state) => state.workbench.app.notebook?.content?.metadata?.kernelspec?.name
  );

  // 1. Execute the whole current execution plan step
  //  (this affects especially the code cells after the first input/output cell)
  const activeStepElement = executionPlan[activeStep];
  // Call the plan step execution saga directly instead of via action, which spreads that saga, executes it in this context
  // and makes sure those cells are dispatched before dispatching input/output cells below which require those cells
  yield call(executeExecutionPlanStepSaga, {
    payload: { executionPlanStep: activeStepElement, session },
  });
  yield put(executeExecutionPlanStep(activeStepElement, session));

  // 2. Switch to the new step
  yield put(selectNextStep());

  // 3. Check for executions
  const newStep = yield select((state) => state.workbench.app.activeStep);
  const newStepElement = executionPlan[newStep];
  if (
    newStepElement.type === 'output' &&
    newStepElement.cells &&
    newStepElement.cells.length > 0
  ) {
    // If the new step is of type output: Execute the first cell (which is of type 'output')
    const firstCell = newStepElement.cells[0];
    const socket = openSockets[session.id];
    // TODO Treat that socket is null here and needs to be opened (necessary?)
    if (!socket) console.log('Socket was undefined in 3.1');
    yield call(
      executeSingleCell,
      session.path,
      socket,
      session.id,
      firstCell.id,
      firstCell,
      'app',
      kernel
    );
  } else if (
    newStepElement.type === 'input' &&
    newStepElement.cells &&
    newStepElement.cells.length > 0
  ) {
    // If the new step is of type input: Execute the variables of the first cell (which is of type 'input')
    const firstCell = newStepElement.cells[0];
    const socket = openSockets[session.id];
    // TODO Treat that socket is null here and needs to be opened (necessary?)
    if (!socket) console.log('Socket was undefined in 3.2');
    yield call(
      executeInputCellVariables,
      session.path,
      socket,
      session.id,
      firstCell.id,
      firstCell.as_variables,
      'app'
    );
  }
}

export function* watchSelectNextStepAndExecute() {
  yield takeEvery(
    selectNextStepAndExecute.getType(),
    selectNextStepAndExecuteSaga
  );
}

export function* fetchNotebookForAppSaga({
  payload: { path, appVersionCode },
}) {
  const serverName = appVersionCode.toLowerCase();
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    NotebookApi.fetchNotebookForApp,
    path,
    serverName,
    jupyterUser
  );
  if (response) {
    const notebook = response;
    // Step 1: Put the received notebook into the state
    yield put(fetchNotebookForAppSuccess(notebook));
    // Step 2: Open a session for the notebook
    const kernelName =
      response.content.metadata && response.content.metadata.kernelspec
        ? response.content.metadata.kernelspec.name
        : undefined;
    yield put(
      postSessionForApp(
        response.name,
        response.path,
        kernelName,
        jupyterUser,
        serverName
      )
    );

    // Step 3: Derive the execution plan for the app
    const executionPlan = deriveExecutionPlan(notebook);
    yield put(addExecutionPlan(executionPlan));
  } else {
    yield put(fetchNotebookForAppFail(error));
  }
}

export function* watchFetchNotebookForApp() {
  yield takeEvery(fetchNotebookForApp.getType(), fetchNotebookForAppSaga);
}

export function* postSessionForAppSaga({
  payload: { notebookName, notebookPath, kernelName, jupyterUser, serverName },
}) {
  const { response, error } = yield call(
    SessionApi.postSessionForApp,
    notebookName,
    notebookPath,
    kernelName,
    jupyterUser,
    serverName
  );
  if (response) {
    // Step 1: Put the created / received session into the state
    yield put(postSessionForAppSuccess(response));
    // Step 2: Open the Websocket for code execution / results
    const sessionId = response.id;
    const kernelId = response.kernel.id;
    yield put(openSocket(sessionId, kernelId, 'app', serverName));
  } else {
    yield put(postSessionForAppFailure(error));
  }
}

export function* watchPostSessionForApp() {
  yield takeEvery(postSessionForApp.getType(), postSessionForAppSaga);
}
