import React, { FC } from 'react';
import IconButton, {
  Props as IconButtonProps,
} from '../../../atoms/icon-button/IconButton';
import './styles.scss';
import { DeprecatedRootState } from '../../../../store/state.type';
import { Dispatch } from 'redux-act';
import { useDispatch, useSelector } from 'react-redux';

export type IconButtonBarButton = Omit<
  IconButtonProps,
  'onClick' | 'disabled'
> & {
  onClick?: (state: DeprecatedRootState, dispatch: Dispatch) => void;
  disabled?: (state: DeprecatedRootState) => boolean;
};

export type Props = {
  buttons: IconButtonBarButton[];
};

const IconButtonBar: FC<Props> = (props) => {
  const { buttons } = props;
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useDispatch();
  return (
    <div className={'button-bar'}>
      {buttons
        .map((b) => ({
          ...b,
          onClick: b.onClick ? () => b.onClick(state, dispatch) : undefined,
          disabled: b.disabled ? b.disabled(state) : false,
        }))
        .map((b, i) => (
          <IconButton key={i} size={20} {...b} />
        ))}
    </div>
  );
};

export default IconButtonBar;
