import React, { Component } from 'react';
// @ts-ignore
import Cassandra from './icons/Cassandra';
// @ts-ignore
import S3 from './icons/S3';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { Value } from './DataSourceButtons';

interface Props {
  name: string;
  displayName: string;
  onChange: (v: Value) => ToBeRefined;
  selectedName: string;
  disabled: boolean;
}

export function dataSourceIcon(dsType: string) {
  switch (dsType) {
    case 'cassandra': {
      return <Cassandra className={'icon'} />;
    }
    case 's3': {
      return <S3 className={'icon'} />;
    }
    default:
      return null;
  }
}

export default class DataSourceButton extends Component<Props> {
  render() {
    const { selectedName, name, onChange, displayName, disabled } = this.props;
    return (
      <div
        data-testingIdentifier={displayName}
        className={`kernelspec-container${
          selectedName && name === selectedName ? ' selected' : ''
        }${selectedName && name !== selectedName ? ' unselected' : ''}`}
        onClick={() => {
          if (!disabled) {
            onChange(name);
          }
        }}
      >
        <div className={'icon-container'}>{dataSourceIcon(name)}</div>
        <p className={'display-name'}>{displayName}</p>
      </div>
    );
  }
}
