import { ComponentType } from 'react';
import Constraints from './Constraints.container';
import { DatapoolType } from 'common/dist/types/datapool';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { FiOctagon } from 'react-icons/fi';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { SettingsWrapper } from '../model-settings/ModelSettingsWrapper';
import { ModelSettingsProps } from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

export default class OptConstraintsWrapper extends ComponentAugurDetailsTab {
  type: 'component' = 'component';
  id = 'constraints';
  title = moduleOptimizationMessages.msgAugurDetailsConstraintsTabName;
  to = (
    habitatCode: string,
    augurCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortDatapoolType}/constraints`;

  icon: ComponentType = () => <FiOctagon size={16} />;
  component: ComponentType<ModelSettingsProps> = () =>
    SettingsWrapper(Constraints);

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
