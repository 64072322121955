import ModuleClient from '../../common/_interfaces/ModuleClient';
import AugurDetails from '../../common/_interfaces/augur-details/AugurDetails';
import DatapoolDetails from '../../common/_interfaces/datapool-details/DatapoolDetails';
import NltAugurDetails from './augur-details/NltAugurDetails';
import NltDatapoolDetails from './datapool-details/NltDatapoolDetails';
import { AugurType } from 'common/dist/types/augur';
import { IntlShape } from 'react-intl';
import { moduleNlt } from 'common/dist/constants/module_list';

/**
 * Client-side module for the binary classification (event-based) augur
 */
export default class NltClient implements ModuleClient {
  type = moduleNlt.type;
  name = moduleNlt.name;
  intlId = 'common.natural-language-tagging';
  shortName = moduleNlt.shortName;
  available = moduleNlt.available;
  executionType = moduleNlt.executionType;
  augurDetails: AugurDetails = new NltAugurDetails();
  datapoolDetails: DatapoolDetails = new NltDatapoolDetails();
  augurDetailsDefaultPage = 'model-settings';
  datapoolDetailsDefaultPage = 'settings'; // TODO is this a good choice?
  inputFormats: [];

  overviewAugurInfo = (augur: AugurType, intl: IntlShape) => {
    return '';
  };
}
