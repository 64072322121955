import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { groupBy } from 'lodash';
import AlgorithmTuningResults from './algorithm-tuning-results/AlgorithmTuningResults';
import {
  ParameterTuningSchema,
  SchemaNames,
  ToSchemaNames,
} from '../parameter-tuning-selection/types';
import './styles.scss';
import {
  ParameterTuningResultsType,
  SingleParametersResult,
} from 'common/dist/types/mlModel';

export interface Props {
  modelTuningResults: ParameterTuningResultsType;
  parameterTuningSchema: ParameterTuningSchema;
}

export default class ParameterTuningResults extends Component<Props, {}> {
  speakingNames: SchemaNames;

  constructor(props: Props) {
    super(props);
    this.renderSelectedModel = this.renderSelectedModel.bind(this);
    this.speakingNames = ToSchemaNames(this.props.parameterTuningSchema);
  }

  renderSelectedModel() {
    const {
      modelTuningResults: { kpi, bestModel },
    } = this.props;
    return (
      <div>
        <h2 className={'ModelTuningResults--headline'}>
          <FormattedMessage
            id='story.model_tuning_results.selected_model'
            defaultMessage='Selected Model: '
          />
          {this.speakingNames[bestModel.algorithm].speakingName}
        </h2>
        {bestModel.parameters && (
          <div className={'ModelTuningResults--parameter-block'}>
            <ul>
              {bestModel.parameters.map(({ name, value }, index) => (
                <li key={index}>
                  <span className={'ModelTuningResults--parameter-name'}>
                    {this.speakingNames[bestModel.algorithm].parameters[name]}:
                  </span>
                  <span className={'ModelTuningResults--parameter-value'}>
                    {value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={'ModelTuningResults--parameter-block'}>
          <ul>
            <li key={'kpi'}>
              <span className={'subheader--description__label'}>KPI: </span>
              <span>{kpi}</span>
            </li>
            <li key={'score'}>
              <span>Score: {bestModel.score}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { modelTuningResults } = this.props;
    const algorithmGroups: { [name: string]: SingleParametersResult[] } =
      groupBy(modelTuningResults.singleResults, (smr) => smr.algorithm);
    return (
      <div className={'ModelTuningResults--container'}>
        {this.renderSelectedModel()}
        {Object.entries(algorithmGroups).map(
          ([name, algorithmTuningResults]) => (
            <AlgorithmTuningResults
              algorithmTuningResults={algorithmTuningResults}
              algorithmNames={this.speakingNames[name]}
              maxScore={Math.max(
                ...modelTuningResults.singleResults.map((smr) => smr.score)
              )}
              minScore={Math.min(
                ...modelTuningResults.singleResults.map((smr) => smr.score)
              )}
            />
          )
        )}
      </div>
    );
  }
}
