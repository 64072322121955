import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { K8sEvent } from 'common/dist/types/orchestration/k8sEvent';
import qs from 'qs';
import { useQuery, UseQueryResult } from 'react-query';
import { ProgressSummary } from 'common/dist/types/orchestration/progress';
import { arrayToMap } from 'common/dist/utils/arrayToMap';

// --- Queues
export function fetchPreQueue() {
  return apiRequest('/orchestration/queue/pre/summary');
}

export function cancelJob(jobCode) {
  const body = { jobCode };
  return putApiRequest('/orchestration/queue/job/cancel', body);
}
export function cancelJobGroup(jobGroupCode) {
  const body = { jobGroupCode };
  return putApiRequest('/orchestration/queue/jobgroup/cancel', body);
}

// --- History
export function fetchHistory(offset?: number, limit?: number, search?: string) {
  const q = qs.stringify({ offset, limit, search });
  return apiRequest(`/orchestration/history/summary?${q}`);
}

// --- Schedules
export function fetchSchedules(
  trigger?: string,
  period?: string,
  offset?: number,
  limit?: number,
  search?: string
) {
  const q = qs.stringify({ trigger, period, offset, limit, search });
  return apiRequest(`/orchestration/schedule?${q}`);
}

export function fetchSchedule(scheduleCode) {
  return apiRequest(`/orchestration/schedule/${scheduleCode}`);
}

export function addSchedule(schedule) {
  return postApiRequest('/orchestration/schedule', schedule);
}

export function updateSchedule(schedule) {
  return putApiRequest('/orchestration/schedule', schedule);
}

export function deleteSchedule(scheduleCode) {
  return deleteApiRequest(`/orchestration/schedule/${scheduleCode}`);
}

// --- Job Configuration
export function fetchJobConfigSummary() {
  return apiRequest('/orchestration/jobconfig/summary');
}

export function patchJobConfig(cmName, key, value) {
  const body = {
    configMap: cmName,
    key,
    value,
  };
  return putApiRequest('/orchestration/jobconfig/patch', body);
}

// --- Realtime
/**
 * TODO Still goes to the dashboard backend because modelCode, moduleType, customArchetype, image are added there
 * @param augurCode
 * @param servingType
 * @param datatronName
 */
export function activateRealtimeModel(augurCode, servingType, datatronName) {
  const body = {
    augurCode,
    servingType,
    datatronName,
  };
  return putApiRequest('/api/realtime/model/start', body);
}

export function deactivateRealtimeModel(augurCode, servingType) {
  const body = { augurCode, servingType };
  return putApiRequest('/orchestration/realtime/model/stop', body);
}

export function fetchRealtimeStatus(augurCode) {
  return apiRequest(`/orchestration/realtime/summary/augur/${augurCode}`);
}

export function fetchRealtimeSummary() {
  return apiRequest('/orchestration/realtime/summary');
}

// TODO what's going on with passing the url here?
export function postRealtimeRequest(url, body) {
  return postApiRequest(url, body);
}

// --- JobDetails
export function fetchJobDetails(jobCode) {
  return apiRequest(`/orchestration/job/${jobCode}`);
}

/**
 * Goes to the dashboard backend, since it just talks with opensearch
 * @param jobCode
 */
export function fetchJobLogs(jobCode) {
  return apiRequest(`/api/orchestration/job/${jobCode}/logs`);
}

export function fetchJobKubernetesEvents(
  jobCode: string
): CompletedApiRequest<K8sEvent[]> {
  return apiRequest(`/orchestration/job/${jobCode}/events`);
}

//FIXME: this should be just called fetchJobGroupDetails but that would clash with the endpoint used for the Job Groups tab (which should be renamed to Job Templates in the future)
export function fetchJobGroupDetailsHistory(
  jobGroupCode: string
): CompletedApiRequest<K8sEvent[]> {
  return apiRequest(`/orchestration/jobgroup/${jobGroupCode}`);
}

export function fetchProgressSteps(jobCode: string) {
  return apiRequest(`/orchestration/queue/${jobCode}/progress`);
}

export function fetchProgressSummary(
  includeJobs: boolean
): CompletedApiRequest<ProgressSummary[]> {
  return apiRequest(
    `/orchestration/queue/progress-summary${qs.stringify(
      { includeJobs },
      { addQueryPrefix: true }
    )}`
  );
}

export function useProgressSummary(
  includeJobs = false
): UseQueryResult<Record<string, ProgressSummary>> {
  return useQuery(
    'fetchProgressSummary',
    async () => {
      const res = await fetchProgressSummary(includeJobs);
      if (!res.response) throw Error('Unable to fetch progress summary.');
      return res.response;
    },
    {
      select: (data) =>
        arrayToMap(data, 'jobCode') as Record<string, ProgressSummary>,
      refetchInterval: 10000,
    }
  );
}
