import React, { FC, useCallback } from 'react';
import Button from '../../atoms/button/Button';
import { FiPlus } from 'react-icons/fi';
import Paging from '../../molecules/paging/Paging';
import JobSchedules from './JobSchedules';
import { CProps } from './JobSchedulesPaginated.container';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useAppDispatch } from '../../../store/store';

const links = {
  addSchedule: '/app/orchestration/add-schedule',
};
const JobSchedulesPaginated: FC<CProps> = (props) => {
  const { jobSchedules, fetchSchedules, pageSize, deleteScheduleThenFetch } =
    props;
  const appDispatch = useAppDispatch();
  const updatePagingParameters = useCallback(
    (offset?: number, limit?: number, search?: string) =>
      appDispatch(fetchSchedules(undefined, undefined, offset, limit, search)),
    [appDispatch, fetchSchedules]
  );

  return (
    <div className={styles.OrchestrationContent}>
      <div
        className={classNames(
          styles.OrchestrationInnerContent,
          styles.notScrollable
        )}
      >
        <Paging
          itemsPerPage={pageSize}
          currentItems={jobSchedules.codes?.length}
          searchEnabled
          updatePagingParameters={updatePagingParameters}
          headline={(
            currentPage: number,
            offset: number,
            limit: number,
            totalCount?: number
          ) =>
            renderHeader(
              currentPage,
              offset,
              limit,
              totalCount,
              jobSchedules.codes?.length
            )
          }
        >
          <JobSchedules
            jobSchedules={jobSchedules}
            fetchSchedules={fetchSchedules}
            deleteScheduleThenFetch={deleteScheduleThenFetch}
          />
        </Paging>
      </div>
    </div>
  );
  /**
   * Header is passed to paging component
   * @param currentPage
   * @param offset
   * @param limit
   * @param totalCount
   * @param currentPageAmount
   */
  function renderHeader(
    currentPage: number,
    offset: number,
    limit: number,
    totalCount?: number,
    currentPageAmount?: number
  ) {
    const { pageSize, jobSchedules } = props;
    const codes = jobSchedules?.codes;
    return (
      <div className={styles.JobSchedulesHeaderPaging}>
        {codes &&
          codes.length > 0 && ( // Don't render the headline if there are no schedules
            <div>
              <span>
                {`Showing Job Schedules ${offset + 1} - ` +
                  `${
                    offset +
                    Math.min(
                      ...[limit, pageSize, currentPageAmount].filter((x) => x)
                    )
                  }`}
              </span>
            </div>
          )}
        <div className={styles.JobSchedulesButton}>
          <Button
            withLink={true}
            linkTo={links.addSchedule}
            buttonColor={'green'}
            buttonLabelId={'scheduling.addSchedule'}
            buttonLabelDefault={'Add Schedule'}
            Icon={() => <FiPlus size={16} />}
          />
        </div>
      </div>
    );
  }
};
export default JobSchedulesPaginated;
