import React, { FC, Fragment } from 'react';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  WrappedComponentProps,
} from 'react-intl';
import './styles.scss';
import { ApiError } from 'common/dist/types/responseBodies/errors';

export type Props = {
  /** Has the field been touched (touched=true -> the error will be displayed if there is one) */
  touched: boolean;
  /** Error, either as a string (without intl) or as an object (with intl), last option is legacy and should not be used */
  error:
    | string
    | React.ComponentProps<typeof FormattedMessage>
    | Record<string, unknown>;
};

function isFormattedMessage(
  error:
    | string
    | React.ComponentProps<typeof FormattedMessage>
    | Record<string, unknown>
): error is React.ComponentProps<typeof FormattedMessage> {
  return (
    // The second case only works because of the provided fallback used here
    (error as React.ComponentProps<typeof FormattedMessage>).id !== undefined ||
    (error as React.ComponentProps<typeof FormattedMessage>).defaultMessage !==
      undefined
  );
}

// TODO why all this extra?
function InputError({ touched, error }: Props & WrappedComponentProps) {
  const shown = !!(touched && error);

  if (!shown) {
    return null;
  }

  if (isFormattedMessage(error)) {
    return (
      <small className='InputError'>
        <FormattedMessage
          id={error.id || 'no-id'}
          defaultMessage={error.defaultMessage}
          values={error.values}
        />
      </small>
    );
  } else if (typeof error === 'string') {
    return (
      <small className='InputError'>
        <span>{error}</span>
      </small>
    );
  } else {
    return (
      <small className='InputError'>
        <span>{JSON.stringify(error)}</span>
      </small>
    );
  }
}

InputError.defaultProps = {
  touched: false,
};

export default injectIntl(InputError);

type UnknownError = ApiError | string | Record<string, unknown>;

function isFormattedMessageStrict(
  error:
    | string
    | React.ComponentProps<typeof FormattedMessage>
    | Record<string, unknown>
): error is React.ComponentProps<typeof FormattedMessage> {
  return (
    error instanceof Object &&
    'id' in error &&
    'defaultMessage' in error &&
    'values' in error
  );
}

export const renderError = (error: UnknownError, intl: IntlShape) => {
  // @ts-ignore
  if (isFormattedMessageStrict(error)) {
    return intl.formatMessage(
      { id: error.id, defaultMessage: error.defaultMessage },
      error.values
    );
  } else if (typeof error === 'string') {
    return error;
  } else {
    return JSON.stringify(error);
  }
};

const RenderError: FC<{ error: UnknownError } & WrappedComponentProps> = ({
  error,
  intl,
}) => {
  return <Fragment>{renderError(error, intl)}</Fragment>;
};

export const RenderIntlError = injectIntl(RenderError);
