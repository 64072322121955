import React, { FC } from 'react';
import classNames from 'classnames';
import { Period } from 'common/dist/constants/schedulePeriods';
import { FormattedMessage, IntlShape } from 'react-intl';
import styles from './styles.module.scss';

type Props = {
  namespace: string;
  selectedPeriod: Period;
  className: string;
  intl: IntlShape;
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: {
    warning?: string;
    valid?: boolean;
    touched?: boolean;
  };
  disabled?: boolean;
};

const PeriodTimeField: FC<Props> = (props) => {
  const {
    meta: { warning, valid, touched },
    input,
    selectedPeriod,
    namespace,
    className,
    intl,
    disabled,
  } = props;

  if (!selectedPeriod) {
    return null;
  }

  if (selectedPeriod.hasTime) {
    const fieldClassName = classNames(
      className,
      touched && { error: warning, success: valid }
    );

    const placeholder = intl.formatMessage({
      id: 'common.scheduleSelect.time_placeholder',
      defaultMessage: '(HH:mm)',
    });

    return (
      <div className={styles.fieldParent}>
        {/* @ts-ignore */}
        <FormattedMessage {...selectedPeriod.labelTime}>
          {(text) => <p className={styles.fieldLabel}>{text}</p>}
        </FormattedMessage>
        <input
          className={fieldClassName}
          id={`${namespace}-time`}
          name={`${namespace}-time`}
          type='text'
          placeholder={placeholder}
          minLength={5}
          maxLength={5}
          disabled={disabled}
          value={input.value}
          onChange={(e) => input.onChange(e.target.value)}
        />
      </div>
    );
  }

  return null;
};

export default PeriodTimeField;
