import React, { Component } from 'react';
import { ArchetypeType } from 'common/dist/types/archetype';
import { WrappedFieldProps } from 'redux-form';
import { ArchetypeVersionType } from 'common/dist/types/archetypeVersion';
import styles from './styles.module.scss';
import RadioButtonsInput from '../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import messages from 'common/dist/messages/archetypes';
import { CProps } from './Archetype.container';
import { DatapoolType } from 'common/dist/types/datapool';

type OwnProps = {
  /** Selected datapoolCode */
  datapool: DatapoolType | undefined;
  /** code of the habitat to add this augur to */
  habitatCode: string;
  dropdownSelectPortal?: HTMLElement;
};

export type Props = OwnProps & CProps;

export type ArchetypeValueType = {
  /** Code of the selected archetype */
  code?: string;
  /** Stock or custom archetype? */
  origin?: 'stock' | 'custom';
  /** Selected version number */
  versionNumber?: string;
};

type ArchetypeOption = { value: string; label: string };

function archetypesToOptions(
  datapool: DatapoolType | undefined,
  archetypes: ArchetypeType[],
  archetypeOrigin: 'custom' | 'stock'
): ArchetypeOption[] {
  return archetypes
    .filter((archetype) => {
      if (archetypeOrigin === 'custom') return archetype.custom;
      return !archetype.custom;
    })
    .map((archetype) => ({
      value: archetype.code,
      label: archetype.name,
    }));
}

type VersionOption = { value: string; label: string };

function versionsToOptions(versions: ArchetypeVersionType[]): VersionOption[] {
  return versions
    .sort((a, b) => {
      // Sort Descending
      if (a.number < b.number) return 1;
      else if (a.number > b.number) return -1;
      return 0;
    })
    .map((v) => ({ label: v.number, value: v.number }));
}

export default class Archetype extends Component<Props & WrappedFieldProps> {
  componentDidMount() {
    const { habitatCode, datapool, fetchArchetypes } = this.props;

    if (habitatCode && datapool) {
      fetchArchetypes(habitatCode, datapool.code);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { habitatCode, datapool, fetchArchetypes } = this.props;
    if (habitatCode && datapool && datapool !== prevProps.datapool) {
      fetchArchetypes(habitatCode, datapool.code);
    }
  }

  renderOriginField() {
    const {
      archetypes,
      meta: { error, touched },
      input: { value, onChange, onBlur },
      datapool,
    } = this.props;

    const archetypesData = archetypes?.data || [];

    return (
      <div className={styles.originParent}>
        <RadioButtonsInput
          id={'archetypeOrigin'}
          touched={touched}
          error={error?.origin}
          disabled={!datapool}
          hasLabel={false}
          value={value?.origin}
          onChange={(origin: 'custom' | 'stock') => {
            if (origin !== value.origin) {
              // Re-selecting the same radio button is not supposed to clear the other fields, but ..
              onChange({ origin }); // ... if another radio button is selected, this clears the other input elements on purpose.
            }
          }}
          entriesPerRow={2}
          columnWidth={'200px'}
          radioButtons={[
            {
              value: 'stock',
              labelId: 'no-id',
              labelDefault: 'Stock Archetype',
              // Assumption: There is always at least one stock archetype, so no need to check whether the radio button is disabled like for the "custom" option below
            },
            {
              value: 'custom',
              labelId: 'no-id',
              labelDefault: 'Custom Archetype',
              disabled:
                archetypesToOptions(datapool, archetypesData, 'custom')
                  .length === 0,
            },
          ]}
        />
      </div>
    );
  }

  renderArchetypeCodeField() {
    const {
      archetypes,
      dropdownSelectPortal,
      meta: { error, touched },
      input: { value, onChange, onBlur, onFocus },
      datapool,
    } = this.props;

    const archetypesData = archetypes?.data || [];
    const archetypesLoading = archetypes?.loading;
    const archetypeOptions = archetypesToOptions(
      datapool,
      archetypesData,
      value.origin
    );

    return (
      <div className={styles.archetypeCodeParent}>
        <DropdownSelectInput
          id={'archetype'}
          name={'archetype'}
          error={(error || {}).code}
          touched={touched}
          valid={!error?.code}
          disabled={!datapool}
          label={{
            id: messages.msgSelectArchetypeVersion.id,
            defaultMessage: messages.msgSelectArchetypeVersion.defaultMessage,
          }}
          placeholder={{
            id: messages.msgNoVersionSelected.id,
            defaultMessage: messages.msgNoVersionSelected.defaultMessage,
          }}
          value={archetypeOptions.find((o) => o.value === value.code) || null}
          onChange={(option: ArchetypeOption) => {
            onChange({ ...value, code: option?.value });
            onBlur({ ...value, code: option?.value }); // Not sure why this is necessary, but otherwise the validation is not changing the field to green
          }}
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={archetypesLoading}
          options={archetypeOptions}
          autoSelectIfSingle
          menuPortalTarget={dropdownSelectPortal}
        />
      </div>
    );
  }

  renderArchetypeVersionField() {
    const {
      archetypes,
      dropdownSelectPortal,
      meta: { error, touched },
      input: { value, onChange, onBlur, onFocus },
      datapool,
    } = this.props;

    const archetypesData = archetypes?.data || [];

    const selectedArchetype = !archetypesData
      ? undefined
      : archetypesData.find((arch) => arch.code === value.code);
    const archetypeVersions = !selectedArchetype
      ? []
      : selectedArchetype.versions;
    const archetypeVersionOptions = versionsToOptions(archetypeVersions);

    return (
      <div className={styles.archetypeVersionParent}>
        <DropdownSelectInput
          id={'archetypeVersion'}
          name={'archetypeVersion'}
          touched={touched}
          error={error?.versionNumber}
          valid={!error?.versionNumber}
          disabled={!datapool}
          label={{
            id: messages.msgSelectArchetypeVersion.id,
            defaultMessage: messages.msgSelectArchetypeVersion.defaultMessage,
          }}
          placeholder={{
            id: messages.msgNoVersionSelected.id,
            defaultMessage: messages.msgNoVersionSelected.defaultMessage,
          }}
          value={
            archetypeVersionOptions.find(
              (o) => o.value === value?.versionNumber
            ) || null
          }
          onChange={(option: VersionOption) =>
            onChange({ ...value, versionNumber: option.value })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={false}
          options={archetypeVersionOptions}
          autoSelectIfSingle
          menuPortalTarget={dropdownSelectPortal}
        />
      </div>
    );
  }

  render() {
    const {
      input: { value },
    } = this.props;

    return (
      <div className={styles.archetype}>
        {this.renderOriginField()}
        {value.origin && this.renderArchetypeCodeField()}
        {value.origin === 'custom' && this.renderArchetypeVersionField()}
      </div>
    );
  }
}
