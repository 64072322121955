import React, { Component, Fragment } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import RepositoryStepName from './step-name/RepositoryStepName';
import {
  fieldArchHabitatDatapool,
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldKernel,
  fieldName,
  fieldType,
  formName,
  repositoryForm,
  RepositoryTypeIds,
} from './repository.form';
import RepositoryStepDescription from './step-description/RepositoryStepDescription';
import RepositoryStepType from './step-type/RepositoryStepType.container';
import RepositoryStepHabitat from './step-cc-habitat/RepositoryStepHabitat.container';
import RepositoryStepKernel from './step-ar-kernel/RepositoryStepKernel.container';
import RepositoryStepHabitatDatapool from './step-ar-habitat-datapool/RepositoryStepHabitatDatapool.container';
import { collaborationSpaceRoutes } from '../../routes';
import Wizard from '../../../pages/wizard/Wizard';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import { ButtonProps } from '../../../atoms/button/Button';

class AddRepositoryWizard extends Component<Props & InjectedFormProps> {
  render() {
    const {
      addRepository,
      repositoryName,
      activePath,
      repositoryType,
      repositoryDescription,
      codeCapsuleHabitat,
      archKernel,
      archHabitat,
      archDatapool,
      formValid,
      isAsyncValidating,
    } = this.props;

    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`,
        buttonColor: 'white',
        buttonLabelDefault: 'Cancel',
      },
      {
        withLink: true,
        linkTo: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`,
        buttonColor: 'green',
        buttonLabelDefault: 'Submit',
        disabled: !formValid,
        onClick: () => {
          if (formValid && !isAsyncValidating) {
            addRepository(
              repositoryName,
              activePath,
              repositoryType,
              repositoryDescription,
              codeCapsuleHabitat,
              archKernel,
              archHabitat,
              archDatapool
            );
          }
        },
      },
    ];

    return (
      <Wizard
        withHeadline={true}
        headlineId={'no-intl-id'}
        headlineDefault={'Add new Repository'}
        buttons={buttons}
      >
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          {/* --- Wizard Steps */}
          <GenericFormStep
            fieldName={fieldName}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Enter Name',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Enter a name for the repository.',
            }}
            component={RepositoryStepName}
            num={1}
            renderError={false}
          />

          <GenericFormStep
            fieldName={fieldDescription}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Enter Description',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Enter a description for the repository.',
            }}
            component={RepositoryStepDescription}
            num={2}
            renderError={false}
          />

          <GenericFormStep
            fieldName={fieldType}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select Type',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Select a type for the repository.',
            }}
            // @ts-ignore
            component={RepositoryStepType}
            num={3}
            renderError={false}
          />

          {repositoryType === RepositoryTypeIds.CODE_CAPSULE && (
            <GenericFormStep
              fieldName={fieldCodeCapsuleHabitat}
              formName={formName}
              title={{
                id: 'no-id',
                defaultMessage: 'Select Habitat',
              }}
              description={{
                id: 'no-id',
                defaultMessage:
                  'Select a Habitat this Code Capsule will belong to.',
              }}
              // @ts-ignore
              component={RepositoryStepHabitat}
              num={4}
              renderError={false}
            />
          )}

          {repositoryType === RepositoryTypeIds.ARCHETYPE && (
            <Fragment>
              <GenericFormStep
                fieldName={fieldKernel}
                formName={formName}
                title={{
                  id: 'no-id',
                  defaultMessage: 'Select Kernel',
                }}
                description={{
                  id: 'no-id',
                  defaultMessage: 'Select a Kernel for the Archetype.',
                }}
                component={RepositoryStepKernel}
                num={4}
                renderError={false}
              />

              <GenericFormStep
                fieldName={fieldArchHabitatDatapool}
                formName={formName}
                title={{
                  id: 'no-id',
                  defaultMessage: 'Select Habitat and Datapool',
                }}
                description={{
                  id: 'no-id',
                  defaultMessage:
                    'Select the Habitat and the Datapool this Archetype will be based on.',
                }}
                // @ts-ignore
                component={RepositoryStepHabitatDatapool}
                num={5}
                renderError={false}
              />
            </Fragment>
          )}
        </form>
      </Wizard>
    );
  }
}

export interface Props {
  hideAddRepositoryWizard: () => void;
  addRepository: (...args: unknown[]) => void;
  formValid?: boolean;
  /** Active path in the repository browser */
  activePath?: string;

  /** --- ENTERED FORM VALUES --- */
  /** Entered repository name */
  repositoryName?: string;
  /** Entered repository type */
  repositoryType?: string;
  /** Entered repository description */
  repositoryDescription?: string;
  /** Selected habitat for the code capsule (only set if repositoryType='code-capsule' */
  codeCapsuleHabitat?: string;
  /** Selected kernel for the archetype (only set if repositoryType='archetype' */
  archKernel?: string;
  /** Selected habitat for the archetype (only set if repositoryType='archetype' */
  archHabitat?: string;
  /** Selected datapool for the archetype (only set if repositoryType='archetype' */
  archDatapool?: string;
  /** The code of the currently logged in user */
  currentUserCode: string;

  isAsyncValidating?: boolean;
}

export default reduxForm(repositoryForm)(AddRepositoryWizard);
