import { orchestrationRoutes } from './routes';
import { MessageDescriptor } from '@formatjs/cli';
import orchestrationMessages from 'common/dist/messages/orchestration';

export const ORCHESTRATION_ORIGINS = {
  queue: `${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}/pre`,
  history: `${orchestrationRoutes.basePath}/${orchestrationRoutes.history.path}`,
  groups: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`,
  schedules: `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`,
} as const;
export type OriginsType =
  typeof ORCHESTRATION_ORIGINS[keyof typeof ORCHESTRATION_ORIGINS];

/**
 * Translate the origin (saved in state) to a "speaking name"
 * @param origin
 * @returns {string}
 */
export function originName(origin: OriginsType): MessageDescriptor {
  switch (origin) {
    case ORCHESTRATION_ORIGINS.queue:
      return orchestrationMessages.jobQueueOrigin;
    case ORCHESTRATION_ORIGINS.history:
      return orchestrationMessages.jobHistoryOrigin;
    case ORCHESTRATION_ORIGINS.groups:
      return orchestrationMessages.jobGroupsOrigin;
    case ORCHESTRATION_ORIGINS.schedules:
      return orchestrationMessages.jobSchedulesOrigin;
    default:
      return orchestrationMessages.defaultOrigin;
  }
}
