import React, { FC } from 'react';
import TableSelection from './table-selection/TableSelection.container';
import './styles.scss';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

const InputDataCategory: FC<ModelSettingsProps> = (props) => {
  return (
    <div className={'InputDataCategory'}>
      <TableSelection {...props} />
    </div>
  );
};

export default InputDataCategory;
