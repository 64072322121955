import appendArea from './area';
import appendRoot from '../common/root';
import appendStrokeLine from './strokeLine';
import appendBottomLine from './bottomLine';
import appendLeftLine from './leftLine';
import appendRightLine from './rightLine';
import getScales from '../common/scales';

export default function appendAreaChart(node, width, height, runs, run, maxY) {
  const { g, areaWidth, areaHeight } = appendRoot(node, width, height);

  const { xScale, yScale } = getScales(areaWidth, areaHeight, runs, maxY);

  appendArea(g, run, '#6ca8ed', xScale, yScale, areaHeight);

  appendStrokeLine(g, xScale, yScale, run, '#247FE3');

  appendBottomLine(g, run, '#247FE3', xScale, yScale);

  appendLeftLine(g, run, '#247FE3', xScale, yScale);

  appendRightLine(g, run, '#247FE3', xScale, yScale);
}
