import { apiRequest } from './_tools';

export function search(query) {
  return apiRequest(`/api/search?q=${encodeURIComponent(query)}`).catch(
    ({ error }) => Promise.reject(error)
  );
}

export function getLocales(locale) {
  return apiRequest(`/api/locales/${locale}`).catch(({ error }) =>
    Promise.reject(error)
  );
}

export function getConfig() {
  return apiRequest('/api/config').catch(({ error }) => Promise.reject(error));
}
