import React from 'react';
import { File, FileTypeWrapper } from './FileTypeWrapper';
import { Notebook } from './notebook/Notebook';
import { FileCode } from './fileCode/FileCode';
import { FileText } from './fileText/FileText';
import {
  contentType as repositoryContentType,
  metaFilename as repositoryMetaFilename,
} from '../part-right/config';
import { RepositoryInfo } from './fileRepositoryInfo/RepositoryInfo';
import { FileDefault } from './fileDefault/FileDefault';
import { FileMarkdown } from './fileMarkdown/FileMarkdown';
import { Launcher } from './launcher/Launcher';
import { Repository } from './repository/Repository';
import { Directory } from './directory/Directory';
import { OneDirectoryUp } from './oneDirectoryUp/OneDirectoryUp';
import { getFileEnding } from 'common/dist/utils/workbench/content';

export const fileEndingMapping = {
  NOTEBOOK: ['ipynb'],
  FILE_CODE: ['py'],
  FILE_TEXT: ['txt', 'csv', 'json'],
  // FILE_IMAGE: ['svg', 'jpg', 'JPG', 'jpeg', 'png'],
  FILE_MARKDOWN: ['md', 'MD'],
};

export type ContextType = 'workbenchBrowser' | 'recycleBin' | 'browseOnly';

/**
 * Helper function to pick out the wrapper for the given file name
 * @param fileName
 * @param contentType type of content to show ("notebook" | "launcher")
 * @param asType special directory type TODO Which values are allowed?
 */
function getWrapper(
  fileName: string,
  contentType: string,
  asType: AsType
): FileTypeWrapper {
  // Criteria 1 (early exit): Special file names
  if (fileName === repositoryMetaFilename) return RepositoryInfo;

  // Criteria 2 (early exit): Special contentType
  if (contentType === 'launcher') return Launcher;

  // Criteria 3 (early exit): Repository
  if (contentType === 'directory' && asType === repositoryContentType) {
    return Repository;
  }

  // Criteria 4: Directory
  if (contentType === 'directory') {
    return Directory;
  }

  if (contentType === 'oneDirectoryUp') {
    return OneDirectoryUp;
  }

  // Criteria 4: file ending
  const fileEnding = getFileEnding(fileName);
  if (fileEndingMapping.NOTEBOOK.includes(fileEnding)) return Notebook;
  else if (fileEndingMapping.FILE_CODE.includes(fileEnding)) return FileCode;
  else if (fileEndingMapping.FILE_TEXT.includes(fileEnding)) return FileText;
  else if (fileEndingMapping.FILE_MARKDOWN.includes(fileEnding))
    return FileMarkdown;
  else return FileDefault;
}

/**
 * Returns the editor component for the given fileEnding
 * @param fileName
 * @param contentType type of content to show ("notebook" | "launcher")
 * @param asType special directory type
 */
export function getComponentForFileName(
  fileName: string,
  contentType: string,
  asType: AsType
): (file: File, paneId: string) => JSX.Element {
  const wrapper = getWrapper(fileName, contentType, asType);
  if (wrapper) {
    return wrapper.component;
  } else {
    // eslint-disable-next-line react/display-name
    return (_: File) => <div />;
  }
}

export type AsType =
  | 'directory'
  | 'file'
  | 'oneDirectoryUp'
  | 'repository'
  | undefined;
