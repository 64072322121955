import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { FiRefreshCw } from 'react-icons/fi';
import CreateBranchModal from './create-branch-modal/CreateBranchModal.container';
import DeleteBranchModal from './delete-branch-modal/DeleteBranchModal.container';
import FetchBranchModal from './fetch-branch-modal/FetchBranchModal.container';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import infoStyles from './../../info/styles.module.scss';
export default class GitBranches extends Component {
  getRepoPath() {
    const { infoFilePath } = this.props;

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    return repositoryPath;
  }

  componentDidMount() {
    const { getGitRemote } = this.props;
    const repositoryPath = this.getRepoPath();
    getGitRemote(repositoryPath);
    this.updateBranches();
  }

  updateBranches() {
    const { gitListBranches, remote, fetchBranches } = this.props;
    const repositoryPath = this.getRepoPath();

    gitListBranches(repositoryPath, false);
    if (remote) {
      const parts = remote.pathname.substr(1).split('/');
      const group = parts[0];
      const repoName = parts.slice(1).join('/').replace('.git', '');
      fetchBranches(group, repoName);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.infoFilePath !== this.props.infoFilePath) {
      this.updateBranches();
    }
  }

  renderLoaded() {
    const {
      activeBranch,
      showCreateBranchModal,
      data,
      remoteBranches,
      gitSwitchBranch,
      showDeleteBranchModal,
      infoFilePath,
      showFetchBranchModal,
    } = this.props;
    const bothBranches = data
      .map((branch) => ({
        label: branch.label,
        value: branch.value,
        name: branch.value,
        remote: false,
      }))
      .concat(
        remoteBranches.map((branch) => ({
          label: 'origin/' + branch.name,
          value: 'origin/' + branch.name,
          name: branch.name,
          remote: true,
        }))
      );
    return (
      <div className={'git-branches'}>
        <div
          className={'button button-white button-sm refresh-button'}
          onClick={() => this.updateBranches()}
        >
          <FiRefreshCw size={16} className={'refresh-icon'} />
        </div>

        <FetchBranchModal />
        <CreateBranchModal />
        <DeleteBranchModal />
        <div className={'git-active-branch'}>
          <span>Active Branch:</span>
          <DropdownSelectInput
            id='git-branch-selector'
            name='active-branch'
            options={bothBranches}
            value={bothBranches.find((o) => o.value === activeBranch)}
            onChange={(branch) => {
              if (branch.remote) {
                showFetchBranchModal(branch.name);
              } else {
                // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
                const parts = infoFilePath.split('/');
                const repositoryPath = parts
                  .slice(0, parts.length - 1)
                  .join('/');
                // ---
                gitSwitchBranch(repositoryPath, branch.name);
              }
            }}
            openOnFocus
            className={'git-branch-selector'}
            clearable={false}
          />
        </div>

        <div className={'branch-button-bar'}>
          <div className={`button`} onClick={() => showCreateBranchModal()}>
            Create Branch
          </div>
          <div
            className={classNames('button ', infoStyles.deleteButton, {
              'button-disabled': activeBranch && activeBranch !== 'master',
            })}
            onClick={() => showDeleteBranchModal()}
          >
            Delete Branch
          </div>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={'#224e90'}
        />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no branches for this repository yet
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'git-list-commits'}>
        {error}

        <div
          className={'button button-white button-sm refresh-button'}
          //          onClick={() => gitListCommits(repositoryPath, activeBranch, 20, 0)}
        >
          <FiRefreshCw size={16} className={'refresh-icon'} />
        </div>
      </div>
    );
  }

  render() {
    const { loading, loaded, error } = this.props;
    if (loading) return this.renderLoading();
    if (loaded) return this.renderLoaded();
    if (error) return this.renderError();
    return this.renderEmpty();
  }
}
GitBranches.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  activeBranch: PropTypes.string.isRequired,
  remote: PropTypes.shape({
    // These props might be specific to Gogs. Please make sure what of these props is really required and generic.
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
  }),
  remoteBranches: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, commit: PropTypes.object })
  ),
  showCreateBranchModal: PropTypes.func.isRequired,
  showFetchBranchModal: PropTypes.func.isRequired,
  repoName: PropTypes.string,
  gitListBranches: PropTypes.func.isRequired,
  fetchBranches: PropTypes.func.isRequired,
  gitSwitchBranch: PropTypes.func.isRequired,
  getGitRemote: PropTypes.func.isRequired,
  showDeleteBranchModal: PropTypes.func.isRequired,
  gitActiveBranch: PropTypes.func.isRequired,
  infoFilePath: PropTypes.string,
  /** Injected by react-router */
  location: PropTypes.shape({}),
};
