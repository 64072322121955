import React, { Component, Fragment } from 'react';
import { scaleLinear } from 'd3-scale';
import { max, min } from 'd3-array';

import Area from './Area';
import Axis from './Axis';
import StrokeLine from './StrokeLine';
import HelpLine from './HelpLine';
import StrokeCircles from './StrokeCircles';
import Curtain from './Curtain';
import Crosshair from './crosshair/Crosshair';
import { getX, getY } from './util';
import './styles.scss';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Sizer from '../../../atoms/sizer/Sizer';
import { toLinkWhitelistQuery } from '../../../../utils';
import { AugurDetailsWhitelistQuery } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { RouteComponentProps } from 'react-router';

export type DataType = [number, number][];

type InnerLineChartProps = {
  /** Injected by the <Sizer> component */
  width: number;
  /** Injected by the <Sizer> component */
  height: number;
  /** Injected by the <Sizer> component */
  left: number;
  /** Force the sizer to measure again (called after drawing the SVG for the first time) */
  requestSize: () => void;
} & LineChartProps;

/**
 * This component is not exported. Instead it is wrapped in a <Sizer> which cares about measuring the width, height and
 * position on the screen. This wrapped component in turn is exported.
 */
class InnerLineChart extends Component<InnerLineChartProps, {}> {
  static defaultProps = {
    baseLine: 0,
    colors: {
      shading: ['#ECF7FF', '#FDFEFF'],
      helpLine: '#B5D6FB',
      line: '#247FE3',
      circles: '#207DE3',
      lines: '#ECF0F3',
      ticks: '#A0A7AF',
    },
  };

  componentDidMount() {
    const { requestSize } = this.props;
    requestSize();
  }

  render() {
    const {
      data,
      baseLine,
      helpLine,
      width,
      height,
      left,
      colors,
      yLabel,
      xLabel,
    } = this.props;

    const margin = {
      top: 10,
      right: 15,
      bottom: xLabel ? 40 : 20,
      left: yLabel ? 50 : 35,
    };

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const yLabelPadding = 35;
    const xLabelPadding = 35;

    const crosshairHorizontalOffset = left + margin.left;

    const translate = `translate(${margin.left}, ${margin.top})`;

    const xScale = scaleLinear()
      .domain([min(data, getX), max(data, getX)])
      .range([0, chartWidth]);

    const yScale = scaleLinear()
      .domain([min(data, getY), max(data, getY)])
      .range([chartHeight, 0]);

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <svg style={{ width: '100%', height: '100%' }}>
          <defs>
            <linearGradient id='areaFill' x1='0.5' y1='0' x2='0.5' y2='1'>
              <stop offset='0' stopColor={colors.shading[0]} />
              <stop offset='1' stopColor={colors.shading[1]} />
            </linearGradient>
          </defs>
          <g transform={translate}>
            <rect
              width={chartWidth}
              height={chartHeight}
              fill='none'
              stroke={colors.lines}
            />
            <Area
              fill='url(#areaFill)'
              data={data}
              xScale={xScale}
              yScale={yScale}
              height={chartHeight}
              opacity={1}
            />
            <Axis
              scale={yScale}
              tickSize={chartWidth}
              baseline={baseLine}
              helplineType={'solid'}
              direction={'left'}
            />
            <g transform={`translate(0,${chartHeight})`}>
              <Axis
                scale={xScale}
                tickSize={chartHeight}
                helplineType={'solid'}
                direction={'bottom'}
              />
            </g>
            {xLabel && (
              <text
                className='accuracy-axis__label'
                x={chartWidth / 2}
                y={chartHeight + xLabelPadding}
                textAnchor={'middle'}
              >
                <FormattedMessage
                  id={xLabel.id || 'no-id'}
                  defaultMessage={xLabel.defaultMessage}
                />
              </text>
            )}
            {yLabel && (
              <g transform={`translate(${-yLabelPadding},${chartHeight / 2})`}>
                <text
                  className='accuracy-axis__label'
                  textAnchor={'middle'}
                  transform={`rotate(-90)`}
                >
                  <FormattedMessage
                    id={yLabel.id || 'no-id'}
                    defaultMessage={yLabel.defaultMessage}
                  />
                </text>
              </g>
            )}
            {helpLine && (
              <HelpLine
                color={colors.helpLine}
                helpLine={helpLine}
                xScale={xScale}
                yScale={yScale}
              />
            )}
            <StrokeLine
              stroke={colors.line}
              data={data}
              xScale={xScale}
              yScale={yScale}
            />
            <StrokeCircles
              stroke={colors.circles}
              data={data}
              xScale={xScale}
              yScale={yScale}
            />
            <Curtain width={chartWidth} height={chartHeight} />
          </g>
        </svg>
        <Crosshair
          width={chartWidth}
          height={chartHeight}
          xScale={xScale}
          yScale={yScale}
          left={crosshairHorizontalOffset}
          data={data}
          transform={`translate(${margin.left}px, ${margin.top}px)`}
        />
      </div>
    );
  }
}

// ---
export type LineChartProps = {
  /** CSS prop for the height of the chart */
  height: string;
  /** CSS prop for the width of the chart */
  width: string;
  /** Input data for the line chart */
  data: DataType;
  /** Optional Horizontal base line */
  baseLine?: number;
  /** Optional Line that is drawn into the chart - for example the diagonal [[0,0], [1,1]] for an ROC Chart */
  helpLine?: [[number, number], [number, number]];
  /** Optional title for the chart */
  title?: MessageDescriptor;
  /** Optional label for the y-axis */
  yLabel?: MessageDescriptor;
  /** Optional label for the x-axis */
  xLabel?: MessageDescriptor;
  link?: {
    to: string;
    onClick: () => void;
  };
  /** Render a border around the chart? */
  withBorder?: boolean;
  colors?: {
    line: string;
    shading: [string, string];
    helpLine: string;
    circles: string;
    /** Border and vertical / horizontal lines */
    lines: string;
    /** */
    ticks: string;
  };
};

class LineChart extends Component<LineChartProps & RouteComponentProps> {
  renderInner() {
    const {
      data,
      helpLine,
      title,
      yLabel,
      xLabel,
      withBorder,
      colors,
      baseLine,
    } = this.props;
    return (
      <Fragment>
        {title && (
          <div className={'LineChart--title'}>
            <FormattedMessage
              id={title.id || 'no-id'}
              defaultMessage={title.defaultMessage}
            />
          </div>
        )}
        <div
          className={
            'LineChart--container' +
            (title ? ' LineChart--with-title' : '') +
            (withBorder ? ' LineChart--with-border' : '')
          }
        >
          <Sizer>
            {/* @ts-ignore */}
            <InnerLineChart
              data={data}
              helpLine={helpLine}
              colors={colors}
              baseLine={baseLine}
              yLabel={yLabel}
              xLabel={xLabel}
            />
          </Sizer>
        </div>
      </Fragment>
    );
  }

  render() {
    const { link, width, height, location } = this.props;

    if (link) {
      // --- Render the component wrapped into a <Link>
      return (
        <div className={'LineChart'} style={{ width, height }}>
          <Link
            className='LineChart--link'
            to={toLinkWhitelistQuery(
              link.to,
              location,
              AugurDetailsWhitelistQuery
            )}
            onClick={link.onClick || (() => {})}
          >
            {this.renderInner()}
          </Link>
        </div>
      );
    } else {
      // --- Render the component without a <Link>
      return (
        <div className={'LineChart'} style={{ width, height }}>
          {this.renderInner()}
        </div>
      );
    }
  }
}

export default withRouter(LineChart);
