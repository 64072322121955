import React, {
  Component,
  ComponentType,
  MouseEvent,
  ReactElement,
} from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export type Props = {
  /** The icon to render */
  Icon: ComponentType<{ size?: number }>;
  /** On click listener */
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  linkTo?: string;
  disabled?: boolean;
  /** Title text to display on hover */
  title?: string;
  size?: number;
  /** ID for the button (especially for selecting the buttons in the e2e tests) */
  id?: string;
  /** Additional classes to add to the button */
  additionalClassName?: string;
};

function wrapWithLink(element: ReactElement, linkTo: string): ReactElement {
  return (
    <Link
      className={'ib-link'}
      to={linkTo}
      onClick={(e) => e.stopPropagation()}
    >
      {element}
    </Link>
  );
}

export default class IconButton extends Component<Props> {
  render() {
    const { Icon, onClick, disabled, title, id, linkTo, additionalClassName } =
      this.props;
    let res = (
      <div
        className={classNames('IconButton', additionalClassName, {
          disabled: disabled,
        })}
        title={title}
        onClick={(e) => {
          if (!disabled && onClick) {
            // if there is both a link and an onClick listener, do not stop the event propagation here but instead in the link, otherwise this will trigger a page reload
            if (!linkTo) e.stopPropagation();
            onClick(e);
          }
        }}
        id={id}
      >
        <Icon size={this.props.size} />
      </div>
    );
    if (!disabled && linkTo) res = wrapWithLink(res, linkTo);
    return res;
  }
}
