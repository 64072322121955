import React, { FC, Fragment } from 'react';
import ActionButton from '../action-button/ActionButton';
import ActionButtonLoading from '../action-button/ActionButtonLoading';
import { DatapoolType } from 'common/dist/types/datapool';
import { dataManagementRoutes } from '../../../dataManagement/routes';
import { UPLOAD_WIZARDS } from '../../../dataManagement/cassandra/upload/uploadWizards';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { Props as ActionButtonProps } from '../action-button/ActionButton';
import { buildDatapoolString } from 'common/dist/utils/nameUtils';

type Props = {
  /** Are the augur details loading? */
  loading?: boolean;
  /** Are the augur details loaded? */
  loaded?: boolean;
  /** The augur details */
  datapool?: DatapoolType;
  /** Code of the default cassandra data source */
  dataSource?: DataSource;

  triggerDatapoolStatisticsJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  setActiveUploadWizard: (
    activeWizard: string,
    uploadCode: string | undefined
  ) => void;
};

const actionButtons = {
  uploadData: {
    gridColumn: 6,
    gridRow: 1,
    Icon: () => <span className='icon-upload' style={{ color: '#224e90' }} />,
    label: {
      id: 'details.action_bar.upload_data',
      defaultMessage: 'Upload Data',
    },
  },
  openInDataManagement: {
    gridColumn: 6,
    gridRow: 2,
    Icon: () => <span className='icon-tables' style={{ color: '#224e90' }} />,
    label: {
      id: 'details.action_bar.go_to_data_management',
      defaultMessage: 'Open in Data Management',
    },
  },
};

const ActionButtons: FC<Props> = (props: Props) => {
  const {
    datapool,
    loading,
    loaded,
    triggerDatapoolStatisticsJob,
    dataSource,
    setActiveUploadWizard,
  } = props;

  const habitatCode = datapool.habitatCode;
  const datapoolCode = datapool.code;
  const datapoolName = datapool.name;

  if (loading || !loaded)
    return (
      <Fragment>
        {Object.values(actionButtons).map((ab) => (
          <ActionButtonLoading {...ab} />
        ))}
      </Fragment>
    );

  let uploadLink;
  switch (dataSource.ds_type) {
    case 'cassandra':
      uploadLink = `${dataManagementRoutes.basePath}/${dataSource.ds_type}/${
        dataSource.code
      }/upload?keyspaceName=${buildDatapoolString(
        datapoolCode,
        dataSource.ds_type
      )}`;
      break;
    case 's3':
      uploadLink = `${dataManagementRoutes.basePath}/${dataSource.ds_type}/${
        dataSource.code
      }/upload?bucketName=${buildDatapoolString(
        datapoolCode,
        dataSource.ds_type
      )}`;
      break;
  }

  let openInDataManLink;
  switch (dataSource.ds_type) {
    case 'cassandra':
      openInDataManLink = `${dataManagementRoutes.basePath}/${
        dataSource.ds_type
      }/${dataSource.code}/keyspace/${buildDatapoolString(
        datapoolCode,
        dataSource.ds_type
      )}`;
      break;
    case 's3':
      openInDataManLink = `${dataManagementRoutes.basePath}/${
        dataSource.ds_type
      }/${dataSource.code}/bucket/${buildDatapoolString(
        datapoolCode,
        dataSource.ds_type
      )}/content/`;
      break;
  }

  // Further enhance the action buttons with the information that came with the loaded information now
  let actionButtonsLoaded: Record<string, Partial<ActionButtonProps>> = {
    uploadData: {
      onClick: () => setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined),
      hasLink: true,
      linkTo: uploadLink,
    },
    openInDataManagement: {
      onClick: () => {},
      hasLink: true,
      linkTo: openInDataManLink,
    },
  };
  if (dataSource.ds_type === 'cassandra') {
    actionButtonsLoaded = {
      ...actionButtonsLoaded,
      runDatapoolStatistics: {
        gridColumn: 5,
        gridRow: 1,
        Icon: () => <span className={'icon-play'} />,
        label: {
          id: 'details.action_bar.run_datapool_statistics',
          defaultMessage: 'Run Datapool Statistics Now',
        },
        onClick: () =>
          triggerDatapoolStatisticsJob(habitatCode, datapoolCode, datapoolName),
      },
    };
  }

  return (
    <Fragment>
      {Object.keys(actionButtonsLoaded).map((abId, i) => {
        const ab = actionButtons[abId];
        const abc = actionButtonsLoaded[abId];
        return <ActionButton key={i} {...ab} {...abc} />;
      })}
    </Fragment>
  );
};

export default ActionButtons;
