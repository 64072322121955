import React, { FC } from 'react';
import { PredictionReportType } from 'common/dist/types/reports';
import styles from './styles.module.scss';
import { deriveChartDataForBarchart } from './common';
import { ResponsiveBar } from '@nivo/bar';
import { BoxLegendSvg } from '@nivo/legends';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import { tooltip } from './Tooltip';

type Props = {
  data: PredictionReportType[];
  /** Code of the active model for this augur */
  activeModelCode?: string;
};

const CompareBarChart: FC<Props> = (props: Props) => {
  const { data, activeModelCode } = props;

  if (!data || data.length === 0) return null; // Early exit if there's no data (yet)

  const { chartData, keys, lookup } = deriveChartDataForBarchart(data);

  // Color scale
  const colorScale = scaleLinear<string>()
    .range(['#6aa0eb', '#9f9f9f', '#9fd356'])
    .domain([0, (data.length - 1) / 2, data.length - 1]);

  const colors: string[] = _.shuffle(
    // @ts-ignore
    [...Array(data.length).keys()].map((i) => colorScale(i))
  );

  const BarLegend = ({ height, legends, width }) => (
    <React.Fragment>
      {legends.map((legend) => (
        <BoxLegendSvg
          key={JSON.stringify(legend.data.map(({ id }) => id))}
          {...legend}
          containerHeight={height}
          containerWidth={width}
        />
      ))}
    </React.Fragment>
  );

  return (
    <div
      className={styles.compareBarChart}
      style={{
        width: '100%',
        height: '500px', // TODO good value?
      }}
    >
      <ResponsiveBar
        data={chartData}
        keys={keys}
        indexBy={'interval'}
        margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
        padding={0.2}
        groupMode='grouped'
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // @ts-ignore
        valueFormat={{ format: '', enabled: false }}
        colors={colors}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        tooltip={tooltip(lookup, activeModelCode, 'id')}
        enableLabel={false}
        legends={[
          {
            dataFrom: 'keys',
            data: keys.map((key, index) => ({
              id: key,
              color: colors[index],
              label: lookup[key].inputTable,
            })),
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default CompareBarChart;
