import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldDatapoolName,
  fieldDataSourceCode,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newDatapool/defaultWizard/default.form';
import { moduleType } from 'common/dist/constants/module_list';

import { Props } from './OptimizationNewDatapoolWizard';

import { validateChannels } from '../../../../../organisms/modelManagement/newDatapoolWizard/optChannels/validate';
import { validatePropensities } from '../../../../../organisms/modelManagement/newDatapoolWizard/optPropensities/validate';
import { validateCommunications } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunications/validate';
import { validateCommunicationGroups } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunicationGroups/validate';
import { OptimizationSubmitSummary } from 'common/dist/types/requestBodies/datapools';

// --- Field Names
export const fieldChannels = 'channels';
export const fieldPropensities = 'propensities';
export const fieldCommunications = 'communications';
export const fieldCommunicationGroups = 'communicationGroups';

// --- Type of the form values
export type OptimizationFormValues = DefaultFormValues & {
  [fieldModuleType]: typeof moduleType.CAMPAIGN_OPTIMIZATION;
};

// --- Sync validation
export const optimizationValidate = (
  values: OptimizationFormValues,
  props: { _reduxForm: Props }
) => {
  const errors = defaultValidate(values) || {};
  errors[fieldChannels] = validateChannels(values[fieldChannels]);
  errors[fieldPropensities] = validatePropensities(values[fieldPropensities]);
  errors[fieldCommunications] = validateCommunications(
    values[fieldCommunications]
  );
  errors[fieldCommunicationGroups] = validateCommunicationGroups(
    values[fieldCommunicationGroups]
  );

  return errors;
};

// --- Async validation
export function getOptimizationMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

// --- Warn (used for the "sub forms" for adding channels, propensities, etc.)
export const optimizationWarn = (
  values: OptimizationFormValues,
  props: { _reduxForm: Props }
) => {
  const warnings = {};
  warnings[fieldChannels] = validateChannels(values[fieldChannels]);
  warnings[fieldPropensities] = validatePropensities(values[fieldPropensities]);
  warnings[fieldCommunications] = validateCommunications(
    values[fieldCommunications]
  );
  warnings[fieldCommunicationGroups] = validateCommunicationGroups(
    values[fieldCommunicationGroups]
  );

  return warnings;
};

export const optimizationAsyncBlurFields = [...defaultAsyncBlurFields];
export const optimizationAsyncChangeFields = [...defaultAsyncChangeFields];

export const getOptimizationSubmitSummary = (
  formValues: OptimizationFormValues,
  habitatCode: string
): OptimizationSubmitSummary => {
  const submitSummary: OptimizationSubmitSummary = {
    // Module type
    moduleType: formValues[fieldModuleType],

    // Datapool name
    name: formValues[fieldDatapoolName],

    // Data Source Code
    dataSourceCode: formValues[fieldDataSourceCode],

    // Meta data for the optimization
    meta: {
      channels: formValues[fieldChannels],
      propensities: formValues[fieldPropensities],
      communications: formValues[fieldCommunications],
      communicationGroups: formValues[fieldCommunicationGroups],
    },
  };

  return submitSummary;
};
