import React, { Component } from 'react';
import './styles.scss';
import BestModelChart from './BestModelChart';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PipelineTuningResultsType } from 'common/dist/types/mlModel';
import ModelParameters from './model-parameters/ModelParameters';

export type Props = {
  pipelineTuningResults: PipelineTuningResultsType;
};

export type State = {
  activeNodeId?: string;
};

class PipelineTuningResultsBestModel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { activeNodeId: undefined };
  }

  render() {
    const { pipelineTuningResults } = this.props;

    if (!pipelineTuningResults) {
      // Only show the component if the pipelineTuningResults are actually there
      return <div className={'PipelineTuningResultsBestModel'} />;
    }

    const bestModel = pipelineTuningResults.bestModel;
    const bestModelsPipeline = (pipelineTuningResults.pipelines || []).find(
      (p) => p.id === bestModel.pipelineId
    );
    const bestModelsPipelineSchema = bestModelsPipeline.schema;
    const bestModelsPath = bestModelsPipeline.paths.find(
      (p) => p.pathId === bestModel.pathId
    );
    const bestModelsParameters = bestModel.parameters;

    const kpiMsgId = kpiOptionsGrouped[pipelineTuningResults.kpi]?.label;
    const scoreFormatter = kpiOptionsGrouped[pipelineTuningResults.kpi]?.format;

    return (
      <div className={'PipelineTuningResultsBestModel'}>
        <div className={'PipelineTuningResultsBestModel--infos'}>
          <div className={'PipelineTuningResultsBestModel--info-line'}>
            <span className={'PipelineTuningResultsBestModel--infos-key'}>
              Optimization KPI:
            </span>
            <FormattedMessage
              id={kpiMsgId}
              // @ts-ignore
              defaultValue={pipelineTuningResults?.kpi || ''}
            >
              {(text) => (
                <span className={'PipelineTuningResultsBestModel--infos-value'}>
                  {text}
                </span>
              )}
            </FormattedMessage>
          </div>
          <div className={'PipelineTuningResultsBestModel--info-line'}>
            <span className={'PipelineTuningResultsBestModel--infos-key'}>
              Training-Score:
            </span>
            <span className={'PipelineTuningResultsBestModel--infos-value'}>
              {scoreFormatter(pipelineTuningResults?.bestModel.score)}
            </span>
          </div>
        </div>

        <div className={'PipelineTuningResultsBestModel--model-container'}>
          <BestModelChart
            pipeline={bestModelsPipelineSchema}
            path={bestModelsPath}
            setActiveNodeId={(nodeId: string) =>
              this.setState({ activeNodeId: nodeId })
            }
          />

          <ModelParameters
            pipeline={bestModelsPipelineSchema}
            path={bestModelsPath}
            parameters={bestModelsParameters}
            activeNodeId={this.state.activeNodeId}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(PipelineTuningResultsBestModel);
