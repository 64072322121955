import React, { FC } from 'react';
import styles from './styles.module.scss';
import { IntlShape } from 'react-intl';

type Props = {
  yKey: string; // Timestamp
  xKey: number;
  value: number;
};

export const tooltip: (
  intl: IntlShape,
  getInterval: (bucketIndex: number) => string
) => FC<Props> = (intl, getInterval) => {
  return (props) => {
    const { xKey, yKey, value } = props;
    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Interval:</span>
          <span className={styles.tooltipValue}>{getInterval(xKey)}</span>
        </div>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Amount:</span>
          <span className={styles.tooltipValue}>{value}</span>
        </div>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Evaluation Date:</span>
          <span className={styles.tooltipValue}>
            {intl.formatDate(yKey || '')}&nbsp;
            {intl.formatTime(yKey || '')}
          </span>
        </div>
      </div>
    );
  };
};
