import React, { FC, Fragment, useEffect, useState } from 'react';
import Switch from 'react-switch';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import { CProps } from './ActivateRealtimeToggle.container';
import { useParams } from 'react-router-dom';
import { useAugur } from '../../../../../../core/api/augurs';
import { AugurDetailsRouteParams } from '../types';

export interface Props {
  /** rest | zmq_mqtt */
  servingType: 'rest' | 'zmq_mqtt';
}

const ActivateRealtimeToggle: FC<Props & CProps> = ({
  fetchRealtimeModelStatus,
  activateRealtimeModel,
  deactivateRealtimeModel,
  servingType,
  loading,
  error,
  data = { status: '-' },
  isStarting = false,
  isStopping = false,
}) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);
  const datatronName = augur?.datapool?.datatronName;

  const [poller, setPoller] = useState<ReturnType<typeof setInterval>>();

  const checkStatus = () => {
    if (augurCode) fetchRealtimeModelStatus(augurCode, false);
  };

  const startPoller = () => {
    if (!poller) {
      // No interval running yet: Set it up
      setPoller(setInterval(checkStatus, 2000));
    }
  };

  const stopPoller = () => {
    if (poller) {
      // Make sure reloading the realtime status every 2 seconds is stopped
      clearInterval(poller);
      setPoller(undefined);
    }
  };

  useEffect(() => {
    // @ts-ignore the status field probably exists on all serving type but I only know of REST
    if (['starting', 'stopping'].includes(data?.status)) {
      // The  model is starting or stopping -> check every 2 seconds for the status
      startPoller();
    } else {
      // Model is "stopped" (if the status is not defined) or "running" -> make sure no interval is running
      stopPoller();
    }

    return stopPoller;
  }, [data, startPoller, stopPoller]);

  const renderLoaded = () => {
    const isActive =
      data != null &&
      data?.status != null &&
      (data?.status === 'starting' || data?.status === 'running');

    // TODO why is this hardcoded here, but otherwise comes from the url reported by the model?
    const url = `/orchestration/realtime/score/augur/${augurCode}`;

    return (
      <Fragment>
        <div className='radio--list radio--list--inline'>
          <Switch
            onChange={() => {
              if (isActive) {
                deactivateRealtimeModel(augurCode, servingType);
              } else {
                activateRealtimeModel(augurCode, servingType, datatronName);
              }
            }}
            checked={!!isActive}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            onColor='#224e90'
            // TODO this whole isStarting business just does not work atm
            // disabled={!isStarting && !isStopping}
          />
          <span className='switch-text'>
            {isActive ? (
              <FormattedMessage
                id='dashboard.augur_settings.prediction_realtime_api.active'
                defaultMessage='Active'
              />
            ) : (
              <FormattedMessage
                id='dashboard.augur_settings.prediction_realtime_api.inactive'
                defaultMessage='Inactive'
              />
            )}
          </span>
          <span className='switch-text'>
            {data?.status && `Status: ${data.status}`}
          </span>
          {(isStarting ||
            isStopping ||
            // @ts-ignore the status field probably exists on all serving type but I only know of REST
            ['starting', 'stopping'].includes(data?.status)) && (
            <ReactLoading
              className={'starting-stopping-spinner'}
              type={'cylon'}
              color={'#224e90'}
            />
          )}
        </div>
        <div>
          {isActive && servingType === 'rest' && (
            <input
              type='text'
              value={`${
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '')
              }${url}`}
              readOnly
            />
          )}
        </div>
      </Fragment>
    );
  };

  // Idea: If loading but the data is already there it's a reload. So don't show the loading screen in this case,
  //  Otherwise the Switch will be dropped for 1-2 seconds and brought up again which looks weird.
  if (loading) {
    // TODO Make it look nicer
    return <p>...</p>;
  } else if (error) {
    // TODO Make it look nicer
    return <p>{JSON.stringify(error)}</p>;
  } else {
    return renderLoaded();
  }
};

export default ActivateRealtimeToggle;
