import React, { Component } from 'react';
import { connectedProps } from './RepositoryMeta.container';
import Button from '../../../../../atoms/button/Button';

export interface Props {
  infoFilePath: string;
}

export default class RepositoryMeta extends Component<Props & connectedProps> {
  render() {
    const { showConfigureCodeCapsuleModal, infoFilePath } = this.props;
    return (
      <div className={'repository-meta'}>
        <div className={'repository-meta-buttons'}>
          <Button
            buttonLabelDefault={'Configure Code Capsule'}
            buttonColor={'blue'}
            withLink={false}
            onClick={() => showConfigureCodeCapsuleModal(infoFilePath)}
          />
        </div>
      </div>
    );
  }
}
