import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Busy from '../../../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../../../molecules/indicator-empty/IndicatorEmpty';
import Paging from '../../../../../molecules/paging/Paging';
import AugurBiographyList from '../../../../../organisms/augur-biography-list/AugurBiographyList';
import { CProps } from './AugurBiography.container';
import { AugurDetailsRouteParams } from '../types';
import styles from './styles.module.scss';
import { useAugur } from '../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { DataSources } from '../../../../../../store/dataManagement/state.types';

export type Props = {
  queryParameterPrefix?: string;
  selectable: boolean;
  header: string;
};

const PAGE_SIZE = 20;

const AugurBiography: FC<Props & CProps> = (props) => {
  const {
    data,
    selectable,
    fetchDataSources,
    header,
    error,
    loading,
    fetchAugurBiography,
    queryParameterPrefix,
  } = props;

  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur } = useAugur(habitatCode, augurCode);

  const datapoolCode = augur?.datapoolCode;
  // FIXME: should be replaced by useDatapool
  const dsCode = augur?.datapool?.dataSourceCode;
  const dataSources = useSelector<RootState, DataSources>(
    (state) => state.data.dataSources
  );
  const dsType = dataSources?.data?.find((ds) => ds.code === dsCode)?.ds_type;

  useEffect(() => {
    fetchDataSources();
  }, [fetchDataSources]);

  const updatePagingParams = useCallback(
    (offset: number, limit: number) => {
      fetchAugurBiography({
        habitatCode,
        augurCode,
        offset,
        limit,
      });
    },
    [fetchAugurBiography, habitatCode, augurCode]
  );

  const renderHeader = (currentPage: number, offset: number, limit: number) => {
    return (
      <div className={styles.header}>
        <div>
          <span className={styles.headerTitle}>{header}</span>
          {data && data.totalCount > 0 && (
            <span>
              {`Showing entries ${offset + 1} - ` +
                `${Math.min(
                  data.totalCount,
                  offset + Math.min(limit, PAGE_SIZE)
                )}`}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Busy isBusy />
      </div>
    );
  };

  const renderLoaded = () => {
    return (
      <AugurBiographyList
        biography={data}
        datapoolCode={datapoolCode}
        dsCode={dsCode}
        dsType={dsType}
        selectable={selectable}
      />
    );
  };

  const renderError = () => {
    return (
      <div className={styles.error}>
        <FormattedMessage {...error.formattedMessage} />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className={styles.empty}>
        <IndicatorEmpty
          classNameImage={'wait-for-augur-pic'}
          headlineId={'no-id'}
          headlineDefault={'Augur Biography is not available'}
          descriptionId={'no-id'}
          descriptionDefault={
            'Please wait until the Augur has completed the first job'
          }
        />
      </div>
    );
  };

  const renderInner = () => {
    if (loading) return renderLoading();
    else if (data && (data.entries || []).length === 0) return renderEmpty();
    else if (data && datapoolCode) return renderLoaded();
    else if (error) return renderError();
    else return <div />;
  };

  return (
    <div className={styles.augurBiography}>
      <Paging
        itemsPerPage={PAGE_SIZE}
        totalItems={data?.totalCount}
        showFirstLast
        updatePagingParameters={updatePagingParams}
        headline={renderHeader}
        queryParameterPrefix={queryParameterPrefix}
      >
        {renderInner()}
      </Paging>
    </div>
  );
};
export default AugurBiography;
