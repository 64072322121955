import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { inputValueSafe } from '../../python3-input-cell/elements/_interface/input-element.form';

export default class ToggleCol extends Component {
  render() {
    const {
      label,
      input: { onChange, onBlur, onFocus, value },
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>
        <div className={'form--input-parent'}>
          <Switch
            checked={inputValueSafe(value)}
            onChange={(inputValue) =>
              onChange({
                ...value,
                inputValue,
              })
            } // Construct the settingField (= { origin, variableName, inputValue })
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            onColor='#224e90'
          />
        </div>
      </div>
    );
  }
}

ToggleCol.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
