import React, { FC, useEffect } from 'react';
import {
  newScheduleFormName,
  SelectedSchedule,
  selectedScheduleField,
} from '../../newSchedule.form';
import GenericFormStep from '../../../../../molecules/generic-form-step/GenericFormStep';
import {
  formValueSelector,
  getFormMeta,
  touch,
  WrappedFieldProps,
} from 'redux-form';
import ScheduleSelect from '../../../../../molecules/schedule-select/ScheduleSelect.container';
import { useDispatch, useSelector } from 'react-redux';
import stepMessage from 'common/dist/messages/orchestration';
import styles from '../../../../../organisms/modelManagement/newAugurWizard/archetype/styles.module.scss';
import RadioButtonsInput from '../../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import { DateTimePicker } from 'react-widgets';

const selector = formValueSelector(newScheduleFormName);

interface Props {
  /** Index of this step */
  step: number;
}

/**
 * The inner/right side component in the form step
 * @param props
 * @constructor
 */
const StepComponent: FC<Props & WrappedFieldProps> = (props) => {
  function renderTriggerOptionsField() {
    const {
      meta: { error, touched },
      input: { value, onChange, onBlur, onFocus },
    } = props;

    return (
      <div className={styles.originParent}>
        <RadioButtonsInput
          id={'trigger'}
          labelDefault={'.trigger'}
          touched={touched}
          error={error?.trigger}
          disabled={false}
          hasLabel={false}
          value={value?.trigger}
          onChange={(trigger: 'timed' | 'delayed') => {
            if (trigger !== value.trigger) {
              // Re-selecting the same radio button is not supposed to clear the other fields, but ..
              onChange({ trigger }); // ... if another radio button is selected, this clears the other input elements on purpose.
            }
          }}
          entriesPerRow={2}
          columnWidth={'200px'}
          radioButtons={[
            {
              value: 'timed',
              labelId: 'no-id',
              labelDefault: 'Repeating Schedule',
            },
            {
              value: 'delayed',
              labelId: 'no-id',
              labelDefault: 'One-Time Schedule',
            },
          ]}
        />
      </div>
    );
  }
  /**
   * Safely extracts the user input value from a settingField = (= { origin, variableName, inputValue })
   * @param settingField
   * @returns {undefined|*}
   */
  const inputValueSafe = (value) => {
    if (!value) return undefined;
    return value.trigDelayed;
  };
  function renderTimedScheduleField() {
    return (
      <ScheduleSelect
        selector={selector}
        namespace={selectedScheduleField}
        untouch={() => {}}
        label={'Period'} // the props also contain a label with the field as content ("selectedSchedule")
      />
    );
  }

  function renderDelayedScheduleField() {
    const upperBound = '2100-01-01 00:00:00';

    const {
      meta: { error, touched },
      input: { value, onChange },
    } = props;
    const inputValue = inputValueSafe(value);
    const valueOrDefault = inputValue ? new Date(inputValue) : undefined;
    return (
      <div className={'datetime-picker'}>
        <DateTimePicker
          label={`.datetime`}
          selector={selector}
          namespace={selectedScheduleField}
          onChange={(trigDelayed) => {
            if (trigDelayed !== value.trigDelayed) {
              onChange({ ...value, trigDelayed });
            }
          }}
          value={valueOrDefault}
          min={new Date()}
          max={new Date(upperBound)}
          placeholder={'Select a Date & Time'}
          time={true}
          date={true}
          name={`.datetime`}
          error={error?.trigDelayed}
          touched={touched}
          untouch={() => {}}
        />
      </div>
    );
  }
  // Touch the root of the composite selectedScheduleField after the first field/child has been touched
  // This is necessary because GenericFormStep checks only selectedScheduleField, which contains the correct
  // errors but not the correct touched state
  const newScheduleFormMeta = useSelector(getFormMeta(newScheduleFormName));
  const dispatch = useDispatch();
  const touchedRadioButton =
    newScheduleFormMeta?.[selectedScheduleField]?.trigger !== undefined;
  useEffect(() => {
    if (touchedRadioButton) {
      dispatch(touch(newScheduleFormName, selectedScheduleField));
    }
  }, [dispatch, touchedRadioButton]);
  const {
    meta: { error, touched },
    input: { value },
  } = props;
  return (
    <div className={styles.originParent}>
      {renderTriggerOptionsField()}
      {value.trigger === 'timed' && renderTimedScheduleField()}
      {value.trigger === 'delayed' && renderDelayedScheduleField()}
    </div>
  );
};

const PeriodStep: FC<Props> = (props) => {
  const { step } = props;

  return (
    <GenericFormStep<SelectedSchedule, Record<string, unknown>>
      fieldName={selectedScheduleField}
      formName={newScheduleFormName}
      component={StepComponent}
      title={{
        id: stepMessage.msgStepPeriodName.id,
        defaultMessage: stepMessage.msgStepPeriodName.defaultMessage,
      }}
      description={{
        id: stepMessage.msgStepPeriodDescription.id,
        defaultMessage: stepMessage.msgStepPeriodDescription.defaultMessage,
      }}
      num={step}
      renderError={false}
    />
  );
};

export default PeriodStep;
