import React, { FC } from 'react';
import NameAndIconLoading from '../name-and-icon/NameAndIconLoading';
import NameAndIcon from '../name-and-icon/NameAndIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { useAugur } from '../../../../core/api/augurs';

const NameContainer: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isSuccess } = useAugur(habitatCode, augurCode);

  //FIXME: remove
  const habitatName = useSelector<RootState>(
    (state) => state.dashboard.habitats[habitatCode].name
  ) as string;

  if (!isSuccess) {
    return (
      <NameAndIconLoading
        gridColumnStart={1}
        gridColumnEnd={1}
        gridRowStart={1}
        gridRowEnd={'span 2'}
        Icon={() => (
          <span
            className='icon-augur'
            style={{
              color: '#224e90',
              fontSize: '20px',
            }}
          />
        )}
      />
    );
  }

  return (
    <NameAndIcon
      gridColumnStart={1}
      gridColumnEnd={1}
      gridRowStart={1}
      gridRowEnd={'span 2'}
      Icon={() => (
        <span
          className='icon-augur'
          style={{
            color: '#224e90',
            fontSize: '20px',
          }}
        />
      )}
      name={augur.name}
      subName={habitatName}
      showHealthBar
      health={augur.health}
    />
  );
};

export default NameContainer;
