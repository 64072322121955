import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldDatapoolName,
  fieldDataSourceCode,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newDatapool/defaultWizard/default.form';

import { Props } from './NaturalLanguageTaggingNewDatapoolWizard';
import { NaturalLanguageTaggingSubmitSummary } from 'common/dist/types/requestBodies/datapools';
import { moduleType } from 'common/dist/constants/module_list';

// --- Type of the form values
export type NaturalLanguageTaggingFormValues = DefaultFormValues & {
  [fieldModuleType]: typeof moduleType.NATURAL_LANGUAGE_TAGGING;
};

// --- Sync validation
export const naturalLanguageTaggingValidate = (
  values: NaturalLanguageTaggingFormValues,
  props: { _reduxForm: Props }
) => {
  const errors = defaultValidate(values) || {};

  return errors;
};

// --- Async calidation
export function getNaturalLanguageTaggingMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

export const naturalLanguageTaggingAsyncBlurFields = [
  ...defaultAsyncBlurFields,
];
export const naturalLanguageTaggingAsyncChangeFields = [
  ...defaultAsyncChangeFields,
];

export const getNaturalLanguageTaggingSubmitSummary = (
  formValues: NaturalLanguageTaggingFormValues,
  habitatCode: string
): NaturalLanguageTaggingSubmitSummary => {
  const submitSummary: NaturalLanguageTaggingSubmitSummary = {
    // Module type
    moduleType: formValues[fieldModuleType],

    // Datapool name
    name: formValues[fieldDatapoolName],

    // Data Source Code
    dataSourceCode: formValues[fieldDataSourceCode],
  };

  return submitSummary;
};
