import React, { FC } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { formatDate } from 'common/dist/utils/dates';
import styles from './styles.module.scss';
import { FiAlertCircle } from 'react-icons/fi';

type Props = {};

export const tooltip: (
  lookup: ToBeRefined,
  activeModelCode: string,
  keyColumn: 'yKey' | 'id'
) => FC<Props> = (lookup, activeModelCode, keyColumn) => {
  return (props) => {
    const id = props[keyColumn];

    const createdAt = (lookup[id] || {}).createdAt;
    const formattedRunDate = !createdAt
      ? ''
      : formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss');
    const modelCode = (lookup[id] || {}).modelCode;

    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Run Date:</span>
          <span className={styles.tooltipValue}>{formattedRunDate}</span>
        </div>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Input Table:</span>
          <span className={styles.tooltipValue}>
            {(lookup[id] || {}).inputTable}
          </span>
        </div>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Output Table:</span>
          <span className={styles.tooltipValue}>
            {(lookup[id] || {}).outputTable}
          </span>
        </div>
        <div className={styles.tooltipLine}>
          <span className={styles.tooltipKey}>Model Code:</span>
          <span className={styles.tooltipValue}>{modelCode}</span>
          {activeModelCode && activeModelCode !== modelCode && (
            <div className={styles.tooltipAlertIcon}>
              <FiAlertCircle size={14} />
            </div>
          )}
        </div>
      </div>
    );
  };
};
