import React, { FC, Fragment, useEffect, useState } from 'react';
import Button from '../../../../../../../../../../atoms/button/Button';
import { AsOutputElement } from '../_interface/P3OutputElement';
import { download } from '../../../../../../../../../../organisms/workbench-browser/context-menu/download';
import { AltaSigmaCell } from '../../../../../../../../../../../store/workbench/state.types';

type Props = {
  path: string;
  cellId: string;
  element: AsOutputElement;
  cell: AltaSigmaCell;
  notebookUser: string;
  parentType: 'notebook' | 'app';
  appVersionCode: string;
};

const DownloadFile: FC<Props> = (props: Props) => {
  const {
    path,
    cellId,
    element,
    cell,
    notebookUser,
    parentType,
    appVersionCode,
  } = props;
  const downloadPath = element?.settings?.downloadPath?.inputValue;
  const defaultButtonLabel = 'Download File';
  const buttonLabel =
    element?.settings?.downloadButtonLabel?.inputValue || defaultButtonLabel;

  const [notFoundError, setNotFoundError] = useState(undefined);
  useEffect(() => {
    // Reset the error when the element changes.
    setNotFoundError(undefined);
  }, [element]);

  return (
    <Fragment>
      <div className={'output-element-download-button'}>
        <Button
          buttonLabelDefault={buttonLabel}
          buttonColor={'blue'}
          withLink={false}
          disabled={!downloadPath}
          onClick={() => {
            let downloadPathAdjusted: string = undefined;
            if ((downloadPath || '').startsWith('/')) {
              // It's an absolute path
              //   -> Remove the leading /workbench/

              // TODO: Does an absolute path even make sense here? The repository is under a different path when deployed as an app
              //   -> All paths that were working in the notebook probably won't work in the app.
              downloadPathAdjusted = (downloadPath || '').replace(
                /^(\/workbench\/)/,
                ''
              );
            } else {
              // It's a relative path (relative to the root directory of the app)
              //   -> Add the path of the repository
              const parts = path.split('/');
              const repositoryBasePath = parts.slice(0, -1).join('/');
              downloadPathAdjusted = `${repositoryBasePath}/${downloadPath}`;
              // Replace the leading slash (if it exists)
              downloadPathAdjusted = downloadPathAdjusted.replace(/^\//, '');
            }

            const url =
              parentType === 'app'
                ? `/jupyterapp/user/${notebookUser}/${appVersionCode.toLowerCase()}/files/${downloadPathAdjusted}`
                : `/jupyter/user/${notebookUser}/files/${downloadPathAdjusted}`;

            const parts = downloadPathAdjusted.split('/');
            const name = parts[parts.length - 1];
            download(name, url);
          }}
        />
      </div>
      {notFoundError && (
        <div className={'output-element-not-found-error'}>{notFoundError}</div>
      )}
    </Fragment>
  );
};

export default DownloadFile;
