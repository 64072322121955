import { cleanErrorObject } from 'common/dist/validation/helpers';

/**
 * Validation for the "sub form": add channel
 * @param value
 */
import {
  ContactPoliciesErrorType,
  ContactPoliciesValueType,
} from './OptContactPolicies';
import {
  validateDescription,
  validateName,
} from '../../newDatapoolWizard/optChannels/validate';
import { ContactPolicy } from 'common/dist/types/module.optimization';
import { MessageDescriptor } from 'react-intl';
import { numberRegex } from '../../newDatapoolWizard/optCommunications/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import _ from 'lodash';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';

const validateContactPolicyType = (
  contactPolicyType: ContactPolicy['contactPolicyType']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (!contactPolicyType) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoType;
  }
};

const validateOperator = (
  operator: ContactPolicy['operator']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (!operator) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoOperator;
  }
};

export const validateValue = (value?: string): MessageDescriptor | null => {
  // The name field is required
  if (!value) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoValue;
  }

  // Valid characters
  if (!numberRegex.test(value)) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrValueInvalid;
  }

  return null;
};

// ---

export const validateContactPolicies = (
  value: ContactPoliciesValueType
): ContactPoliciesErrorType => {
  const error = {};

  // Is there at least one contact policy added?
  //TODO: at least one
  if (!value || (value || []).length === 0) {
    error[_globalValidationError] =
      moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoContactPolicies;
  } else {
    value?.forEach((contactPolicy) => {
      const contactPolicyError = validateSingleContactPolicies(
        contactPolicy,
        value
      );
      if (!_.isEmpty(contactPolicyError)) {
        error[contactPolicy.id] = contactPolicyError;
      }
    });
  }

  return cleanErrorObject(error);
};

export const validateSingleContactPolicies = (
  contactPolicy: Partial<ContactPolicy>,
  allContactPolicies?: Partial<ContactPolicy>[]
): ContactPoliciesErrorType => {
  const error = {};
  // Validate name
  error['name'] = validateName(
    contactPolicy.name,
    (allContactPolicies || [])
      .filter((x) => x.id !== contactPolicy.id)
      .map((ch) => ch.name)
  );
  // Validate description
  error['description'] = validateDescription(contactPolicy.description);

  // Validate contactPolicyType
  error['contactPolicyType'] = validateContactPolicyType(
    contactPolicy.contactPolicyType
  );

  // Validate operator
  error['operator'] = validateOperator(
    // @ts-ignore
    contactPolicy.operator
  );

  // Validate value
  error['value'] = validateValue(
    // @ts-ignore
    contactPolicy.value
  );

  return cleanErrorObject(error);
};
