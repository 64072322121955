import React, { FC } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { HabitatType } from 'common/dist/types/habitat';
import styles from '../styles.module.scss';

type Props = {
  isActive: boolean;
  habitat: HabitatType;
  setActive: () => void;
  isDisabled: boolean;
};

const AugursTab: FC<Props> = (props) => {
  const { isActive, habitat, setActive, isDisabled } = props;
  const augursCount = (habitat.augurs || []).length;

  function handleClick(event) {
    event.preventDefault();
    setActive();
  }
  return (
    <div
      data-testingIdentifier={'AugursTab'}
      className={classNames(styles.headerTab, {
        [styles.headerTabActive]: isActive,
        [styles.headerTabDisabled]: isDisabled,
      })}
      onClick={handleClick}
    >
      <div className={styles.headerContent}>
        <span
          className={classNames('icon-augur', styles.headerIcon)}
          style={{ fontSize: '17px' }}
        />
        <div className={styles.headerText}>
          <FormattedMessage
            id='dashboard.header.augurs'
            defaultMessage='Augurs'
          >
            {(text) => (
              <span
                className={styles.headerTitle}
                data-testingIdentifier={'HabitatTabTitle'}
              >
                {text}
              </span>
            )}
          </FormattedMessage>
          <FormattedMessage
            id='dashboard.header.augurs_count'
            defaultMessage='{augursCount, plural,
                          =0 {No Augurs}
                          one {# Augur}
                          other {# Augurs}
                          }'
            values={{ augursCount }}
          >
            {(text) => <span className={styles.headerSubTitle}>{text}</span>}
          </FormattedMessage>
        </div>
      </div>
    </div>
  );
};

export default AugursTab;
