import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { DropTarget, useDrop } from 'react-dnd';
import { DND_TYPES } from './dndConstants';
import { editorMainCollect, elementEditorMain } from './dndContracts';

const commonPropTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  paneId: PropTypes.string.isRequired,
  splitPane: PropTypes.func,
  setNotebookTabDragging: PropTypes.func,
};

// --- LEFT
function DropOverlayLeft({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) {
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: DND_TYPES.NOTEBOOK_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, sourcePaneId, paneId, 'vertical', 'first');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-left'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderTop: height * 0.25 + 'px solid transparent',
          left: '0',
          height: '100%',
          width: '0',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-left'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '50%',
            backgroundColor: '#6AA0EB66',
            border: '2px dashed #6AA0EB',
          }}
        />
      )}
    </div>
  );
}
DropOverlayLeft.propTypes = commonPropTypes;

// --- RIGHT
function DropOverlayRight({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) {
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: DND_TYPES.NOTEBOOK_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, sourcePaneId, paneId, 'vertical', 'last');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-right'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderRight: width * 0.25 + 'px solid transparent',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderTop: height * 0.25 + 'px solid transparent',
          right: '0',
          height: '100%',
          width: '0',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-right'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '50%',
            backgroundColor: '#6AA0EB66',
            border: '2px dashed #6AA0EB',
            right: '0',
          }}
        />
      )}
    </div>
  );
}
DropOverlayRight.propTypes = commonPropTypes;

// --- TOP
function DropOverlayTop({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) {
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: DND_TYPES.NOTEBOOK_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, sourcePaneId, paneId, 'horizontal', 'first');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-top'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderTop: height * 0.25 + 'px solid transparent',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderRight: width * 0.25 + 'px solid transparent',
          top: '0',
          height: '0',
          width: '100%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '50%',
            width: '100%',
            backgroundColor: '#6AA0EB66',
            border: '2px dashed #6AA0EB',
          }}
        />
      )}
    </div>
  );
}

DropOverlayTop.propTypes = commonPropTypes;

// --- BOTTOM
function DropOverlayBottom({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) {
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: DND_TYPES.NOTEBOOK_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, sourcePaneId, paneId, 'horizontal', 'last');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-bottom'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderRight: width * 0.25 + 'px solid transparent',
          bottom: '0',
          height: '0',
          width: '100%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '50%',
            width: '100%',
            backgroundColor: '#6AA0EB66',
            border: '2px dashed #6AA0EB',
            bottom: '0',
          }}
        />
      )}
    </div>
  );
}

DropOverlayBottom.propTypes = commonPropTypes;

// --- CENTER
function DropOverlayCenter({
  width,
  height,
  paneId,
  splitPane,
  moveNotebookToAnotherPane,
}) {
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: DND_TYPES.NOTEBOOK_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name } = item;
      moveNotebookToAnotherPane(path, sourcePaneId, paneId);
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-center'}
        style={{
          position: 'absolute',
          zIndex: '6',
          // backgroundColor: "#5ec33355",
          height: '50%',
          width: '50%',
          left: '25%',
          top: '25%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '100%',
            backgroundColor: '#6AA0EB66',
            border: '2px dashed #6AA0EB',
          }}
        />
      )}
    </div>
  );
}

DropOverlayCenter.propTypes = {
  ...commonPropTypes,
  moveNotebookToAnotherPane: PropTypes.func.isRequired,
};

// ---
class DropOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  render() {
    const {
      isNotebookTabDragging,
      connectDropTarget,
      splitPane,
      moveNotebookToAnotherPane,
      paneId,
      setNotebookTabDragging,
    } = this.props;

    return connectDropTarget(
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          visibility: isNotebookTabDragging ? 'visible' : 'hidden',
        }}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({
              width: contentRect.bounds.width,
              height: contentRect.bounds.height,
            });
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className={'notebook-tab-content-drop-overlay'}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <DropOverlayTop
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayBottom
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayLeft
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayRight
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayCenter
                width={this.state.width}
                height={this.state.height}
                moveNotebookToAnotherPane={moveNotebookToAnotherPane}
                paneId={paneId}
              />
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
DropOverlay.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  splitPane: PropTypes.func.isRequired,
  moveNotebookToAnotherPane: PropTypes.func.isRequired,
  paneId: PropTypes.string.isRequired,
  isNotebookTabDragging: PropTypes.bool,
  setNotebookTabDragging: PropTypes.func.isRequired,
};

export default DropTarget(
  DND_TYPES.NOTEBOOK_TAB,
  elementEditorMain,
  editorMainCollect
)(DropOverlay);
