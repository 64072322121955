import React, { Component } from 'react';
import SvgPython from './icons/SvgPython';
import SvgSpark from './icons/SvgSpark';
import SvgPySpark from './icons/SvgPySpark';
import SvgR from './icons/SvgR';
import classNames from 'classnames';
import SvgPython310 from './icons/SvgPython310';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';

export interface Props {
  name: string;
  displayName: string;
  onChange(value: JupyterNotebookFormat['metadata']['kernelspec']): unknown;
  selectedKernel?: JupyterNotebookFormat['metadata']['kernelspec'];
}

export default class KernelSpec extends Component<Props> {
  icon() {
    switch (this.props.name) {
      case 'python3': {
        return <SvgPython className={'icon'} />;
      }
      case 'python310': {
        return <SvgPython310 className={'icon'} />;
      }
      case 'sparkkernel': {
        return <SvgSpark className={'icon'} />;
      }
      case 'pysparkkernel': {
        return <SvgPySpark className={'icon'} />;
      }
      case 'ir': {
        return <SvgR className={'icon'} />;
      }
      default:
        return null;
    }
  }

  render() {
    const { name, selectedKernel, displayName } = this.props;
    // Cleanup for the name of the Python kernel
    const cleanedDisplayName = cleanDisplayName(displayName);

    return (
      <div
        className={classNames('kernelspec-container', {
          selected:
            selectedKernel &&
            selectedKernel?.name !== '' &&
            selectedKernel?.name === name,
          unselected:
            selectedKernel &&
            selectedKernel?.name !== '' &&
            selectedKernel?.name !== name,
        })}
        onClick={() =>
          this.props.onChange({
            name: this.props.name,
            display_name: this.props.displayName,
          })
        }
      >
        <div className={'icon-container'}>{this.icon()}</div>
        <p className={'display-name'}>{cleanedDisplayName}</p>
      </div>
    );
  }
}

/**
 * Only used for final display in html. Keep the true display_name in all other places.
 * @param displayName
 */
export const cleanDisplayName = (displayName: string) =>
  displayName.replace('(ipykernel)', '').trim();
