import * as _ from 'lodash';

export const limit = 15;

export const buckets = [
  {
    id: 'truePositives',
    label: {
      id: 'details.misclassification.legend.true_positive',
      defaultMessage: 'True Positive',
    },
    color: '#6AA0EB',
  },
  {
    id: 'trueNegatives',
    label: {
      id: 'details.misclassification.legend.true_negative',
      defaultMessage: 'True Negative',
    },
    color: '#316DCA',
  },
  {
    id: 'falsePositives',
    label: {
      id: 'details.misclassification.legend.false_positive',
      defaultMessage: 'False Positive',
    },
    color: '#999999',
  },
  {
    id: 'falseNegatives',
    label: {
      id: 'details.misclassification.legend.false_negative',
      defaultMessage: 'False Negative',
    },
    color: '#CACACA',
  },
];

export function getValues(item) {
  return [
    item.truePositives,
    item.trueNegatives,
    item.falsePositives,
    item.falseNegatives,
  ];
}

export const colors = [
  buckets[0].color,
  buckets[1].color,
  buckets[2].color,
  buckets[3].color,
];

export function getWholeCount(item) {
  return getValues(item).reduce((prev, next) => prev + next);
}

export function getPercentBuckets(item) {
  const wholeCount = getWholeCount(item);
  return buckets.map((bucket, index) => ({
    percent: `${Math.round((item[bucket.id] / wholeCount) * 100)}%`,
    color: buckets[index].color,
  }));
}

export function getTimestamp(data) {
  return Date.parse(data.time);
}

export function getDomain(data) {
  if (_.isEmpty(data)) {
    return [new Date(0), new Date()];
  }
  return [getTimestamp(data[0]), getTimestamp(data[data.length - 1])];
}

export function isEmpty(data) {
  return !data.truePositives;
}

export function getDataByDomain(data, domain) {
  return data.filter(
    (item) => getTimestamp(item) >= domain[0] && getTimestamp(item) <= domain[1]
  );
}

export function getDefaultFromDate() {
  const now = new Date();
  const month = now.getMonth() - 1;
  now.setMonth(month);
  return now;
}

export function getDefaultToDate() {
  return new Date();
}

export function getNextInterval(fromDate, toDate) {
  let newFromDate = new Date(fromDate);
  newFromDate.setMonth(newFromDate.getMonth() + 1);
  let newToDate = new Date(toDate);
  newToDate.setMonth(newToDate.getMonth() + 1);

  if (newToDate > new Date()) {
    newFromDate = getDefaultFromDate();
    newToDate = getDefaultToDate();
  }

  return { newFromDate, newToDate };
}

export function getPreviousInterval(fromDate, toDate) {
  const newFromDate = new Date(fromDate);
  newFromDate.setMonth(newFromDate.getMonth() - 1);
  const newToDate = new Date(toDate);
  newToDate.setMonth(newToDate.getMonth() - 1);

  return { newFromDate, newToDate };
}

export function getBucketTimestamp({ timestamp }) {
  return Date.parse(timestamp);
}

export function isOlderDataAvailable(misclassification) {
  return (
    misclassification.findIndex(
      (m) =>
        m.truePositives === null &&
        m.trueNegatives === null &&
        m.falsePositives === null &&
        m.falseNegatives === null
    ) === -1
  );
}
