import {
  apiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
} from './_tools';
import { PostAddHabitatBody } from 'common/dist/types/requestBodies/habitats';

export function searchHabitats(query) {
  return apiRequest(`/api/habitats/search?q=${encodeURIComponent(query)}`);
}

export function getHabitat(habitatCode: string) {
  return apiRequest(`/api/habitats/${habitatCode}`);
}

export function fetchHabitats() {
  return apiRequest('/api/habitats');
}

export function checkHabitatNameExists(habitatName: string) {
  return postApiRequest(`/api/habitats/exists`, { habitatName });
}

export function addHabitat(name: string) {
  const body: PostAddHabitatBody = { name };
  return postApiRequest('/api/habitats', body);
}
export function updateHabitat(habitatCode: string, name: string) {
  return putApiRequest(`/api/habitats/${habitatCode}`, {
    name,
  });
}
export function deleteHabitat(habitatCode: string, name: string) {
  return deleteApiRequest(`/api/habitats/${habitatCode}`);
}
