import { ValueType as CustomerTableValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/OptCustomerTable';
import { validateCustomerTable } from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/validate';

export const formName = 'customerTable';

export const fieldCustomerTable = 'customerTable';

export type FormValues = {
  [fieldCustomerTable]: CustomerTableValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldCustomerTable] = validateCustomerTable(
    values[fieldCustomerTable]
  );

  return errors;
};

export const customerTableForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
