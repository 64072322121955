import React, { Component } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import {
  BuildArchetypeData,
  BuildArchetypeError,
  fieldTag,
  form,
} from './form';
import StepTag from './step-tag/StepTag.container';
import { withRouter } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../../routes';
import { RepoDetails } from '../../../../store/workbench/state.types';
import { RouteComponentProps } from 'react-router';
import Wizard from '../../../pages/wizard/Wizard';
import { Version } from 'common/dist/types/app';

export interface Props {
  tag?: string;
  valid: boolean;

  triggerArchetypeBuild(...args: unknown[]): unknown;

  fetchRepoDetails(...args: unknown[]): unknown;

  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: RepoDetails;
  versions?: Version[];
}

interface State {
  buttonClicked: boolean;
}

export interface RouteParams {
  group?: string;
  repositoryName?: string;
}

class BuildArchetypeWizard extends Component<
  Props &
    InjectedFormProps<
      BuildArchetypeData,
      Props & RouteComponentProps<RouteParams>,
      BuildArchetypeError
    > &
    RouteComponentProps<RouteParams>,
  State
> {
  constructor(props) {
    super(props);
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    const { fetchRepoDetails } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;

    fetchRepoDetails(group, repositoryName);
  }

  render() {
    const {
      valid,
      tag,
      triggerArchetypeBuild,
      data,
      handleSubmit,
      loading,
      history,
    } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const { code: repoCode, archetypeCode } = data || {};

    return (
      <form
        onSubmit={handleSubmit((values) => {
          this.setState({ buttonClicked: true });
          triggerArchetypeBuild(
            repoCode, // repositoryCode
            archetypeCode,
            tag // capsuleVersionNumber,
          );
          history.push(
            `${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`
          );
        })}
      >
        <Wizard
          withHeadline={true}
          headlineDefault={'Build Code Capsule'}
          buttons={[
            {
              buttonId: 'wizard.cancel',
              buttonLabelDefault: 'Cancel',
              buttonColor: 'white',
              withLink: true,
              linkTo: `${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`,
            },
            {
              buttonId: 'todo',
              buttonLabelDefault: 'Build',
              buttonColor: 'green',
              isSubmitButton: true,
              disabled: loading || !valid || this.state.buttonClicked,
              withLink: false,
            },
          ]}
        >
          <Field
            name={fieldTag}
            label={'Archetype Tag'}
            component={StepTag}
            archetypeCode={archetypeCode}
          />
        </Wizard>
      </form>
    );
  }
}

export default withRouter(
  reduxForm<
    BuildArchetypeData,
    Props & RouteComponentProps<RouteParams>,
    BuildArchetypeError
  >(form)(BuildArchetypeWizard)
);
