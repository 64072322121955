import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import StatisticsList from './StatisticsList';
import Button from '../../../../../../components/base/form/button/Button';
import * as Api from '../../../../../../core/api';
import Busy from '../../../../../../components/atoms/busy/Busy';
import SingleTableStatistics from '../../../../../organisms/single-table-statistics/SingleTableStatistics.container';
import IndicatorEmpty from '../../../../../molecules/indicator-empty/IndicatorEmpty';
import { CProps } from './Statistics.container';
import { RouteComponentProps } from 'react-router';

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  if (!a.every((e) => b.includes(e))) return false;
  if (!b.every((e) => a.includes(e))) return false;

  return true;
}

export interface Props {
  datapoolCode: string;
  habitatCode: string;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  /** List of tables for this datapool */
  data?: {
    /** Name of the table */
    name: string;
  }[];
  /** from react-router-dom */
  match: object;
  selectedTables: string[];
  initialSelectedTables: string[];
  updateSelectedTables(...args: unknown[]): unknown;
  updateDatapool(...args: unknown[]): unknown;
  /** The cassandra data source used. To check whether it is defined */
  defaultCassandraDs?: object;
  fetchDataSources?(...args: unknown[]): unknown;
  /** Fetch list of all Keyspaces */
  fetchCassandraTables(...args: unknown[]): unknown;
}

interface State {
  showStatistics: boolean;
  statisticsTable: string;
}

class Statistics extends Component<
  Props & CProps & RouteComponentProps,
  State
> {
  static defaultProps = {
    loading: false,
    loaded: false,
    tables: [],
  };

  constructor(props) {
    super(props);
    this.handleTriggerDatapoolStatisticsRun =
      this.handleTriggerDatapoolStatisticsRun.bind(this);
    this.onShowStats = this.onShowStats.bind(this);
    this.onBack = this.onBack.bind(this);

    this.state = {
      showStatistics: false,
      statisticsTable: '',
    };
  }

  componentDidMount() {
    const { fetchCassandraTables, dataSourceCode, keyspaceName } = this.props;
    if (dataSourceCode && keyspaceName) {
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchCassandraTables, dataSourceCode, keyspaceName } = this.props;
    if (
      dataSourceCode &&
      keyspaceName &&
      (keyspaceName !== prevProps.keyspaceName ||
        dataSourceCode !== prevProps.dataSourceCode)
    ) {
      // This case happens when the data sources are loaded during component did mount. Then the dataSourceCode is
      //  updated, so now the Cassandra tables can finally be loaded.
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  handleTriggerDatapoolStatisticsRun() {
    const { habitatCode, datapoolCode } = this.props;
    Api.datapools.triggerStatisticsRun(habitatCode, datapoolCode);
  }

  /**
   * Switches to the statistics view
   *
   * @param table
   */
  onShowStats(table) {
    this.setState({
      showStatistics: true,
      statisticsTable: table,
    });
  }

  onBack() {
    this.setState({
      showStatistics: false,
      statisticsTable: '',
    });
  }

  renderEmpty() {
    return (
      <div className={'tables-empty'}>
        <div className={'tables-empty-elements'}>
          <div className={'tables-empty-pic'}>&nbsp;</div>
          <span className={'headline'}>
            There is no information about the Datapool yet
          </span>
          <span className={'description'}>
            Run the Datapool Statistics job to derive information about the
            Datapool tables and statistics.
          </span>
          <div className={'button-container'}>
            <div
              className={'button'}
              onClick={this.handleTriggerDatapoolStatisticsRun}
            >
              Run Datapool Statistics Job
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTables() {
    const {
      habitatCode,
      datapoolCode,
      data,
      initialSelectedTables,
      selectedTables,
      updateSelectedTables,
      updateDatapool,
    } = this.props;

    return (
      <Fragment>
        {data.length > 0 && (
          <div className={'table-list-wrapper'}>
            <FormattedMessage
              id='datapoolDetails.statistics_tables'
              defaultMessage='Statistics Tables'
            >
              {(txt) => <span className='tables-headline'>{txt}</span>}
            </FormattedMessage>
            <StatisticsList
              tables={data}
              selectedTables={selectedTables}
              updateSelectedTables={updateSelectedTables}
              onShowStats={this.onShowStats}
            />
          </div>
        )}
        <div className='form-buttons--right tables-buttons'>
          <Button
            size='medium'
            disabled={arraysEqual(initialSelectedTables, selectedTables)}
            onClick={() => updateSelectedTables(initialSelectedTables)}
          >
            <FormattedMessage
              id='common.cancel'
              defaultMessage='Cancel'
              values={{}}
            />
          </Button>
          <Button
            use='success'
            size='medium'
            disabled={arraysEqual(initialSelectedTables, selectedTables)}
            onClick={() =>
              updateDatapool(habitatCode, datapoolCode, {
                calcStatsFor: selectedTables,
              })
            }
          >
            <FormattedMessage
              id='common.save_changes'
              defaultMessage='Save changes'
              values={{}}
            />
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const { data, loading, loaded, error, datapoolCode, defaultCassandraDs } =
      this.props;

    if (!defaultCassandraDs) {
      // --- The Cassandra Data Source with role "default_cassandra" has not ben registered yet
      return (
        <IndicatorEmpty
          classNameImage={'data-sources-empty-pic'}
          headlineId={'no-id'}
          headlineDefault={'No Cassandra Data Source'}
          descriptionId={'no-id'}
          descriptionDefault={
            'Please add a Cassandra instance with role Default Cassandra'
          }
        />
      );
    }

    if (!this.state.showStatistics) {
      return (
        <Busy isBusy={loading}>
          <div className='h100p tables-container'>
            {!!error && (
              <FormattedMessage
                id='augur.error'
                defaultMessage='Error: {error}'
                values={{ error }}
              />
            )}
            {loaded &&
              (data.length > 0 ? this.renderTables() : this.renderEmpty())}
          </div>
        </Busy>
      );
    }
    return (
      <SingleTableStatistics
        datapoolCode={datapoolCode}
        tableName={this.state.statisticsTable}
        // @ts-ignore
        onBack={this.onBack}
      />
    );
  }
}

export default Statistics;
