import { Location, LocationDescriptorObject } from 'history';
import qs from 'qs';
import {
  EventHandler,
  EventOrValueHandler,
  WrappedFieldInputProps,
} from 'redux-form/lib/Field';
import { WrappedFieldProps } from 'redux-form';
import { ChangeEvent, FocusEvent } from 'react';

export type Result<T, E> = Ok<T, E> | Err<T, E>;

export class Ok<T, E> {
  public constructor(public readonly value: T) {}

  public isOk(): this is Ok<T, E> {
    return true;
  }

  public isErr(): this is Err<T, E> {
    return false;
  }
}

export class Err<T, E> {
  public constructor(public readonly error: E) {}

  public isOk(): this is Ok<T, E> {
    return false;
  }

  public isErr(): this is Err<T, E> {
    return true;
  }
}

/**
 * Construct a new Ok result value.
 */
export const ok = <T, E>(value: T): Ok<T, E> => new Ok(value);

/**
 * Construct a new Err result value.
 */
export const err = <T, E>(error: E): Err<T, E> => new Err(error);

/**
 * Take an object apply the function to each element and return the resulting object
 * @param object
 * @param mapFn
 */
export function mapObject<T, R, O>(
  object: { [key in keyof Required<O>]: T },
  mapFn: (key: keyof Required<O>, val: T) => R
): { [key in keyof Required<O>]: R } {
  const result: { [key in keyof Required<O>]: R } = {} as any;
  // @ts-ignore
  Object.keys(object).forEach((key) => (result[key] = mapFn(key, object[key])));
  return result;
}

export type LoadableData<Data, Error> = {
  loading: boolean;
  loaded: boolean;
  data?: Data;
  // TODO shouldn't this be optional?
  error: Error;
};

export function mapData<Data, Error, Result>(
  loadableData: LoadableData<Data, Error>,
  transform: (data: Data) => Result
): LoadableData<Result, Error> {
  if (loadableData.data)
    return {
      ...loadableData,
      data: transform(loadableData.data),
    };
  else
    return {
      ...loadableData,
      data: undefined,
    };
}

/**
 * Update the passed location by adding in the passed query object
 * @param location
 * @param updatedQuery
 */
export function locationWithUpdatedQuery(
  location: Location,
  updatedQuery: Record<string, string>
): Location {
  return {
    ...location,
    search: qs.stringify({
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      ...updatedQuery,
    }),
  };
}

/**
 * Update the passed location by removing the passed keys from the query object
 * @param location
 * @param removedQuery
 */
export function locationWithRemovedQuery(
  location: Location,
  removedQuery: string[]
): Location {
  const remaining = Object.entries(
    qs.parse(location.search, { ignoreQueryPrefix: true })
  ).filter(([key, value]) => !removedQuery.includes(key));
  return {
    ...location,
    search: qs.stringify(Object.fromEntries(remaining)),
  };
}

export function locationWithWhitelistQuery(
  location: Location,
  whitelistQuery: string[]
): Location {
  const remaining = Object.entries(
    qs.parse(location.search, { ignoreQueryPrefix: true })
  ).filter(([key, value]) => whitelistQuery.includes(key));
  return {
    ...location,
    search: qs.stringify(Object.fromEntries(remaining)),
  };
}

export function toLinkWhitelistQuery(
  to: string,
  location: Location,
  whitelistQuery: string[]
): LocationDescriptorObject {
  return {
    pathname: to,
    search: locationWithWhitelistQuery(location, whitelistQuery).search,
  };
}
export interface TypedEventOrValueHandler<Event, T>
  extends EventHandler<Event> {
  (value: T): void;
}

interface TypedWrappedFieldInputProps<T> extends WrappedFieldInputProps {
  value: T;
  onBlur: TypedEventOrValueHandler<FocusEvent, T>;
  onChange: TypedEventOrValueHandler<ChangeEvent, T>;
}
export interface TypedWrappedFieldProps<T> extends WrappedFieldProps {
  input: TypedWrappedFieldInputProps<T>;
}
