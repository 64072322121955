import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import { ReactIntlErrorCode } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { history } from '../../store/store';
import RootRoutes from './RootRoutes';
import { ToBeRefined } from 'common/dist/types/todo_type';
import ErrorBoundary from '../pages/error-boundary/ErrorBoundary';
import ClipboardJS from 'clipboard';
import Button from '../atoms/button/Button';
import AppInitializing from '../pages/appInitializing/AppInitializing';
import errorMessages from 'common/dist/messages/error';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Create a client
const queryClient = new QueryClient();

type Props = {
  store: ToBeRefined;
  /** Default route for the model management */
  mainTabsDefaultLink: string;

  /** Has the dashboard been successfully been initialized? */
  initialized: boolean;
};

const App: FC<Props> = (props) => {
  const { store, mainTabsDefaultLink, initialized } = props;
  new ClipboardJS('.clipboard');

  if (!initialized) {
    return <AppInitializing />;
  }

  return (
    <Provider store={store}>
      {/* @ts-ignore */}
      <IntlProvider
        // Wrap Provider/IntlProvider manually to pass onError through react-intl-redux to react-intl, because the missing translations spam the error log
        // https://github.com/formatjs/formatjs/issues/465#issuecomment-643887472 | https://github.com/ratson/react-intl-redux#provider-vs-intlprovider
        onError={(err) => {
          // @ts-ignore
          if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
            // @ts-ignore
            console.warn('Missing translation', err.message);
            return;
          }
          throw err;
        }}
      >
        <ErrorBoundary
          errorHeadline={errorMessages.headline}
          errorDescription={errorMessages.description}
          fullScreenHeight={true}
          actions={() => (
            <Button
              buttonLabelDefault={'Back to Dashboard'}
              buttonColor={'blue'}
              withLink={true}
              linkTo={'/'}
            />
          )}
        >
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history}>
              <DndProvider backend={HTML5Backend}>
                <RootRoutes mainTabsDefaultLink={mainTabsDefaultLink} />
              </DndProvider>
            </ConnectedRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ErrorBoundary>
      </IntlProvider>
    </Provider>
  );
};

export default App;
