import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Busy from '../atoms/busy/Busy';
import MainContainer from '../pages/main-container/MainContainer';
import ElementCard from '../molecules/element-card/ElementCard';
import { appsRoutes } from './routes';
import { colorBlueHighlight } from '../../../scss/base/var.module.scss';

export default class AppsOverview extends Component {
  componentDidMount() {
    const { fetchApps } = this.props;
    fetchApps('false');
  }

  renderLoaded() {
    const { data } = this.props;
    const appsWithVersions = data.filter((app) => app.versions.length > 0);
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={true}
        fullWidth={false}
        scrollableY={true}
      >
        <div className={'apps-overview'}>
          {appsWithVersions.map((app) => {
            const amountVersions = app.versions ? app.versions.length : 0;
            const amountVersionsText = `${amountVersions} ${
              amountVersions === 1 ? 'Version' : 'Versions'
            }`;

            const sortedVersions = app.versions;
            sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
            const latestVersion = sortedVersions[0];

            return (
              <ElementCard
                header={app.name}
                subHeader={amountVersionsText}
                info={`Current Version: ${latestVersion.number}`}
                barColor={colorBlueHighlight}
                linkTo={`${appsRoutes.basePath + appsRoutes.runPath}/${
                  app.code
                }/${latestVersion.code}`}
                key={app.code}
              />
            );
          })}
        </div>
      </MainContainer>
    );
  }

  renderLoading() {
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={true}
        fullWidth={false}
      >
        <div className={'apps-loading'}>
          <Busy isBusy />
        </div>
      </MainContainer>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={false}
      >
        <div className={'apps-overview'}>
          <span>Error: {JSON.stringify(error)}</span>
        </div>
      </MainContainer>
    );
  }

  renderEmpty() {
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={false}
      >
        <div className={'apps-empty'}>
          <div className={'apps-empty-elements'}>
            <div className={'apps-empty-pic'}>&nbsp;</div>
            <span className={'headline'}>There are no Apps yet.</span>
            <span className={'description'}>
              Please create and build an App in the Workbench or ask an
              Administrator to grant permissions on an existing App to you
            </span>
          </div>
        </div>
      </MainContainer>
    );
  }

  renderContent() {}

  render() {
    const { loading, error, data } = this.props;

    const filteredData = (data || []).filter(
      (app) => app.versions && app.versions.length > 0
    );

    if (loading && filteredData.length === 0) return this.renderLoading();
    else if (error) return this.renderError();
    else if (filteredData.length === 0) return this.renderEmpty();
    return this.renderLoaded();
  }
}

AppsOverview.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      repoCode: PropTypes.string,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.string,
          imageName: PropTypes.string,
          notebooksToExecute: PropTypes.arrayOf(PropTypes.string),
          createdAt: PropTypes.string,
        })
      ),
    })
  ),
  fetchApps: PropTypes.func.isRequired,
};
AppsOverview.defaultProps = {
  data: [],
};
