import React, { FC, Fragment, MouseEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { JobGroup } from '../../newSchedule.form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { NamesState } from '../../../../../../store/names/state.types';
import JobGroupTopologyChart from '../../../../../molecules/job-groups/job-group-topology-chart/JobGroupTopologyChart';
import Button from '../../../../../atoms/button/Button';
import styles from './styles.module.scss';

/**
 * StepComponent for inside a GenericFormStep, which shows the current edited job group and can open an editor with passed
 * function
 * @param props - props.onClick: What function/action to call, when clicking the surrounding div (which includes the Open Editor button)
 * @constructor
 */
export const StepComponent: FC<
  { onClick: (e: MouseEvent) => void } & WrappedFieldProps
> = (props) => {
  const { onClick, input } = props;
  const value: JobGroup = input.value;
  const { augurNames, datapoolNames, codeCapsuleNames } = useSelector<
    RootState,
    NamesState
  >((state) => state.names);
  return (
    <div className={styles.JobGroupStep}>
      <div className={styles.topology} onClick={onClick}>
        {(value?.jobs || []).length > 0 ? (
          <Fragment>
            <JobGroupTopologyChart
              jobs={value.jobs || []}
              jobGroupTopology={value.jobGroupTopology || []}
              augurNames={augurNames}
              datapoolNames={datapoolNames}
              codeCapsuleNames={codeCapsuleNames}
              showJobStatus={false}
              slim={true}
            />
            <span className={styles.editorHint}>Click to open the Editor</span>
          </Fragment>
        ) : (
          <div className={styles.emptyContainer}>
            <Button
              buttonLabelDefault={'Open Editor'}
              buttonColor={'green'}
              withLink={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
