import React, { Component, FC } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import {
  Data,
  ErrorType,
  fieldGpu,
  fieldJobName,
  fieldNotebooks,
  fieldParameters,
  fieldResources,
  fieldVersionNumber,
} from './runCodeCapsule.form';
import Modal from '../organisms/modal/Modal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import RfWrappedDropdownSelectInput from '../atoms/input-elements/dropdown-select-input/RfWrappedDropdownSelectInput';
import { ButtonProps } from '../atoms/button/Button';
import { Version } from 'common/dist/types/codeCapsule';
import {
  CodeCapsuleParameters,
  CodeCapsuleResources,
} from 'common/dist/types/job';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import stylesError from '../../../scss/partials/errors.module.scss';
import NotebookInputField from './NotebookInputField';
import { FormattedMessage, injectIntl } from 'react-intl';
import ccMsgs from 'common/dist/messages/codeCapsules';
import DropdownSelectInput from '../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { useNodeInfo } from '../../core/api/codeCapsules';

// --------------- RESOURCE INPUT FIELD -------------------------------------

interface ResourceInputFieldProps {
  subField: 'cpu' | 'memory' | 'gpu';
  gpus?: string[];
}

const ResourceInputField: FC<WrappedFieldProps & ResourceInputFieldProps> = (
  props
) => {
  const {
    input: { value, onChange },
    subField,
    meta: { initial, error },
  } = props;
  const nodeInfo = useNodeInfo();
  const initialSafe = initial || {};
  const NAME_MAP = {
    cpu: 'CPU',
    memory: 'RAM',
    gpu: 'GPU',
  };

  const name = NAME_MAP[subField];
  return (
    <div
      className={classNames(
        styles.resourcesColumn,
        { [styles.cpu]: subField === 'cpu' },
        { [styles.memory]: subField === 'memory' },
        { [styles.gpu]: subField === 'gpu' }
      )}
    >
      <span className={styles.resourceTitle}>{name}</span>
      <input
        type={'text'}
        value={value[subField]}
        placeholder={name}
        onChange={(e) => {
          const v = e.target.value;
          onChange({ ...value, [subField]: v });
        }}
        defaultValue={initialSafe[subField]}
        disabled={
          subField === 'gpu' &&
          (nodeInfo.data?.nodes || []).find(
            (n) => n.resources.gpu.allocatable > 0
          ) === undefined
        }
      />
      {error && error[subField] && (
        <p className={stylesError.error}>{error[subField]}</p>
      )}
    </div>
  );
};

type OptionType = {
  value: string;
  label: string;
};

type GpuSelectProps = {
  gpus?: string[];
};

const GpuProductSelect: FC<GpuSelectProps & WrappedFieldProps> = (props) => {
  const {
    input: { value, onChange, onBlur },
    meta: { error, touched, valid },
  } = props;

  const nodeInfo = useNodeInfo();
  const gpuProductOptions = (nodeInfo.data?.nodes || [])
    .map((node) => ({
      value: node.resources.gpu.model,
      label: node.resources.gpu.model,
    }))
    .filter((x) => !!x.value);

  return (
    <div className={styles.resourcesColumn}>
      <DropdownSelectInput
        id={'gpu'}
        name={'gpu'}
        touched={touched}
        error={error}
        valid={valid}
        disabled={
          (nodeInfo.data?.nodes || []).find(
            (n) => n.resources.gpu.allocatable > 0
          ) === undefined
        }
        label={{
          id: 'no-id',
          defaultMessage: 'Optional: Select the GPU type',
        }}
        placeholder={{
          id: 'no-id',
          defaultMessage: 'No GPU type selected',
        }}
        value={gpuProductOptions.find((o) => o.value === value)}
        onChange={(option: OptionType) => onChange(option.value)}
        onBlur={() => onBlur(value)}
        isLoading={false}
        options={gpuProductOptions}
        clearable
      />
    </div>
  );
};

// --------------- PARAMETER INPUT FIELD ------------------------------------

type ParameterValueType = Record<string, string>;

interface ParameterInputFieldProps {
  /** Injected by the redux-form Field component */
  input: {
    value: ParameterValueType;
    onChange: (...args: unknown[]) => void;
  };
  /** Injected by the redux-form Field component */
  meta: {
    initial: ParameterValueType;
  };
  /** Key of the Parameter to set */
  parameterKey: string;
}

class ParameterInputField extends Component<ParameterInputFieldProps> {
  render() {
    const {
      input: { value, onChange },
      parameterKey,
      meta: { initial },
    } = this.props;

    return (
      <input
        className={styles.parameterValue}
        type={'text'}
        value={value[parameterKey]}
        placeholder={parameterKey}
        onChange={(e) => {
          onChange({
            ...value,
            [parameterKey]: e.target.value,
          });
        }}
      />
    );
  }
}

// --------------- RESOURCE INPUT FIELD -------------------------------------

class JobNameField extends Component<WrappedFieldProps> {
  render() {
    const {
      input: { value, onChange },
      meta: { initial, error },
    } = this.props;
    return <input type={'text'} value={value} onChange={onChange} />;
  }
}

// --------------- MAIN COMPONENT: RUN CODE CAPSULE MODAL -------------------
// TODO Challenge the props passed here ...
export interface Props {
  versions: Version[];
  repoName?: string;
  repoCode: string;
  codeCapsuleCode: string;
  codeCapsule: {
    data: {
      habitatCode: string;
      // TODO ...
    };
    // TODO loading, loaded, error
  };

  /** The function to execute with the selected run configuration for the cc. May execute directly or do further work */
  onSubmit: (
    repoName: string,
    repoCode: string,
    versionNumber: string,
    versionImageName: string,
    codeCapsuleCode: string,
    notebooksToExecute: string[],
    parsedResourceValues: CodeCapsuleResources,
    parameterMap: CodeCapsuleParameters,
    habitatCode: string,
    requestCredentialsFor: string[],
    name: string,
    gpu?: string
  ) => void;
  close: (...args: unknown[]) => void;
  formValues: Data;
  /** Injected by redux-form: true if the form is valid */
  valid: boolean;
  /** Optional prop, with previous behavior of always open */
  isOpen: boolean;
  /** Label of the "Submit" button */
  submitButtonLabelDefaultMessage: string;
}

class RunCodeCapsuleModal extends Component<
  Props & InjectedFormProps<Data, Props, ErrorType>
> {
  static defaultProps = {
    isOpen: true,
    submitButtonLabelDefaultMessage: 'Run',
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const {
      formValues: { resources, parameters, notebooksToExecute, gpu },
    } = this.props;
    const {
      onSubmit,
      repoCode,
      repoName,
      versions,
      codeCapsuleCode,
      close,
      formValues,
      codeCapsule,
    } = this.props;
    const versionNumber = formValues?.[fieldVersionNumber];
    const singleVersion = versions.length === 1;
    const version = singleVersion
      ? versions[0]
      : versions.find((version) => version.number === versionNumber);

    const parsedCpu =
      resources && resources.cpu ? Number(resources.cpu) : undefined;

    const parsedGpu =
      resources && resources.gpu ? Number(resources.gpu) : undefined;

    const parsedResourceValues = {
      ...(resources || {}),
      cpu: parsedCpu,
      gpu: parsedGpu,
    };

    onSubmit(
      repoName,
      repoCode,
      version.number,
      version.imageName,
      codeCapsuleCode,
      notebooksToExecute,
      parsedResourceValues,
      parameters,
      codeCapsule?.data?.habitatCode,
      version.requestCredentialsFor,
      formValues?.[fieldJobName],
      gpu
    );
    close();
  }

  render() {
    const {
      close,
      versions,
      valid,
      isOpen,
      formValues,
      submitButtonLabelDefaultMessage,
    } = this.props;
    const versionNumber = formValues?.[fieldVersionNumber];
    const singleVersion = versions.length === 1;
    const version = singleVersion
      ? versions[0]
      : versions.find((v) => v.number === versionNumber);
    const params = version?.availableParameters || [];

    const buttons: ButtonProps[] = [
      {
        withLink: false,
        buttonColor: 'white',
        buttonLabelDefault: 'Cancel',
        onClick: close,
      },
      {
        withLink: false,
        buttonColor: 'blue',
        buttonLabelDefault: submitButtonLabelDefaultMessage,
        onClick: this.handleSubmit,
        disabled: !valid,
      },
    ];

    return (
      <Modal
        show={isOpen}
        headline={{
          id: 'no-id',
          defaultMessage: 'Run Code Capsule',
        }}
        hideModal={close}
        buttons={buttons}
        alwaysFullHeight
        alwaysFullWidth
      >
        <div className={styles.modalParent}>
          {!singleVersion && (
            <>
              <div className={styles.modalHeader}>
                <div
                  className={classNames(
                    styles.modalGroupParent,
                    styles.versionsParent
                  )}
                >
                  <span className={styles.modalHeadline}>
                    <FormattedMessage {...ccMsgs.msgCcRunModalVersionHeader} />
                  </span>
                  <span className={styles.modalSubHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalVersionSubheader}
                    />
                  </span>
                  <div
                    className={classNames(
                      styles.fieldParent,
                      styles.notebooksParent
                    )}
                  >
                    <Field
                      name={fieldVersionNumber}
                      component={RfWrappedDropdownSelectInput}
                      className={styles.versionDropdownSelect}
                      // Props for DropdownSelectInput (all props are passed and only and input and meta prop are added)
                      disabled={false}
                      hasLabel={false}
                      placeholderDefault={'Version'}
                      isLoading={false}
                      clearable={false}
                      simpleValue={true}
                      multi={false}
                      openOnFocus={true}
                      options={versions
                        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                        .map((version) => ({
                          label: version.number,
                          value: version.number,
                        }))}
                    />
                  </div>
                </div>
              </div>
              {version && <hr className={styles.sep} />}
            </>
          )}

          {true && (
            <div className={styles.innerContainer}>
              <div className={styles.modalColumn}>
                <div
                  className={classNames(
                    styles.modalGroupParent,
                    styles.resourcesParent
                  )}
                >
                  <span className={styles.modalHeadline}>
                    <FormattedMessage {...ccMsgs.msgCcRunModalJobNameHeader} />
                  </span>
                  <span className={styles.modalSubHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalJobNameSubheader}
                    />
                  </span>
                  <div className={styles.jobNameParent}>
                    <Field component={JobNameField} name={fieldJobName} />
                  </div>
                </div>

                <div
                  className={classNames(
                    styles.modalGroupParent,
                    styles.resourcesParent
                  )}
                >
                  <span className={styles.modalHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalResourcesHeader}
                    />
                  </span>
                  <span className={styles.modalSubHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalResourcesSubheader}
                    />
                  </span>
                  <div className={styles.resourcesInputParent}>
                    <Field
                      component={ResourceInputField}
                      name={fieldResources}
                      subField={'cpu'}
                    />
                    <Field
                      component={ResourceInputField}
                      name={fieldResources}
                      subField={'memory'}
                    />
                    <Field
                      component={ResourceInputField}
                      name={fieldResources}
                      subField={'gpu'}
                    />
                    <Field component={GpuProductSelect} name={fieldGpu} />
                  </div>
                </div>

                {params && params.length > 0 && (
                  <div
                    className={classNames(
                      styles.modalGroupParent,
                      styles.parametersParent
                    )}
                  >
                    <span className={styles.modalHeadline}>
                      <FormattedMessage
                        {...ccMsgs.msgCcRunModalParametersHeader}
                      />
                    </span>
                    <span className={styles.modalSubHeadline}>
                      <FormattedMessage
                        {...ccMsgs.msgCcRunModalParametersSubheader}
                      />
                    </span>
                    <div
                      className={classNames(
                        styles.fieldParent,
                        styles.parametersParent
                      )}
                    >
                      {params.map((p) => (
                        <div className={styles.parameterRow}>
                          <div className={styles.parameterKey}>
                            <span>{p.key}</span>
                          </div>

                          <Field
                            component={ParameterInputField}
                            name={fieldParameters}
                            parameterKey={p.key}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.modalColumn}>
                <div
                  className={classNames(
                    styles.modalGroupParent,
                    styles.notebooksParent
                  )}
                >
                  <span className={styles.modalHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalNotebooksHeader}
                    />
                  </span>
                  <span className={styles.modalSubHeadline}>
                    <FormattedMessage
                      {...ccMsgs.msgCcRunModalNotebooksSubheader}
                    />
                  </span>
                  <div
                    className={classNames(
                      styles.fieldParent,
                      styles.notebooksParent
                    )}
                  >
                    <Field
                      component={NotebookInputField}
                      name={fieldNotebooks}
                      availableNotebooks={version?.availableNotebooks || []}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default injectIntl(RunCodeCapsuleModal);
