import React, { Component, Fragment } from 'react';
import ReactLoading from 'react-loading';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import CodeCapsulesListView from './CodeCapsulesListView';
import CodeCapsulesCardView from './CodeCapsulesCardView';
import RunCodeCapsuleModal from '../../../runCodeCapsuleModal/RunCodeCapsuleModal.container';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import NoElements from '../no-elements/NoElements';
import { collaborationSpaceRoutes } from '../../../collaborationSpace/routes';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  habitatCode: string;
  isListView: boolean;
  codeCapsules: {
    data: CodeCapsule[];
    loading: boolean;
    loaded: boolean;
    error: string;
  };
  triggerRunCodeCapsuleJob: () => void; // The parameters are not complete
};

type State = {
  runCodeCapsuleModalOpen: boolean;
  codeCapsuleModalProps: ToBeRefined;
};

class CodeCapsules extends Component<Props & WrappedComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      runCodeCapsuleModalOpen: false,
      codeCapsuleModalProps: {},
    };
    this.openRunCodeCapsuleModal = this.openRunCodeCapsuleModal.bind(this);
    this.closeRunCodeCapsuleModal = this.closeRunCodeCapsuleModal.bind(this);
  }

  openRunCodeCapsuleModal(codeCapsuleModalProps: ToBeRefined) {
    this.setState({
      runCodeCapsuleModalOpen: true,
      codeCapsuleModalProps,
    });
  }

  closeRunCodeCapsuleModal() {
    this.setState({
      runCodeCapsuleModalOpen: false,
      codeCapsuleModalProps: {},
    });
  }

  renderLoaded() {
    const { codeCapsules, isListView, habitatCode } = this.props;

    if (!codeCapsules.data || codeCapsules.data.length === 0)
      return (
        <NoElements
          title={{
            id: 'dashboard.code_capsules.no_code_capsules',
            defaultMessage: 'There are no Code Capsules for this Habitat yet.',
          }}
          linkTo={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}?repositoryType=code-capsule&habitatCode=${habitatCode}`}
          buttonLabelId={'common.new_code_capsule'}
          buttonLabelDefault={'New Code Capsule'}
        />
      );

    if (isListView) {
      return (
        <CodeCapsulesListView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={this.openRunCodeCapsuleModal}
        />
      );
    } else {
      return (
        <CodeCapsulesCardView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={this.openRunCodeCapsuleModal}
        />
      );
    }
  }

  renderLoading() {
    return (
      <div>
        <ReactLoading className={'busy'} type={'cylon'} color={'#224E90'} />
      </div>
    );
  }

  renderError() {
    const {
      codeCapsules: { error },
    } = this.props;
    return <div>{JSON.stringify(error)}</div>;
  }

  renderInner() {
    const {
      codeCapsules: { loading, error, loaded },
    } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (loaded) return this.renderLoaded();
    else return null;
  }

  render() {
    const { triggerRunCodeCapsuleJob } = this.props;
    return (
      <Fragment>
        {this.renderInner()}
        {this.state.runCodeCapsuleModalOpen && (
          <RunCodeCapsuleModal
            close={this.closeRunCodeCapsuleModal}
            onSubmit={triggerRunCodeCapsuleJob}
            {...this.state.codeCapsuleModalProps}
          />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(CodeCapsules);
