import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../store/store';
import TaggerDictionary from './TaggerDictionary';
import { fieldDictionary, form, formName } from './dictionary.form';
import { formValueSelector, reduxForm } from 'redux-form';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

const valueSelector = formValueSelector(formName);

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    settings: { dictionary },
  } = ownProps;

  return {
    initialValues: {
      [fieldDictionary]: dictionary,
    },
    dictionary: valueSelector(state, fieldDictionary),
    saving: state.augurSettings?.saving,
  };
}

export default connect(mapStateToProps)(reduxForm(form)(TaggerDictionary));
