import React, { FC } from 'react';
import * as routes from '../../../index/routes';
import { getNewAugurLink, getNewDatapoolLink } from '../../../index/routes';
import ViewSwitch from '../ViewSwitch';
import Order from '../Order';
import DatapoolsTab from './DatapoolsTab';
import AugursTab from './AugursTab';
import CodeCapsulesTab from './CodeCapsulesTab';
import { HABITAT_TAB, OrderType } from '../Habitat';
import { HabitatType, Summary } from 'common/dist/types/habitat';
import styles from '../styles.module.scss';
import Button from '../../../atoms/button/Button';
import classNames from 'classnames';
import { collaborationSpaceRoutes } from '../../../collaborationSpace/routes';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Link } from 'react-router-dom';

type Props = {
  activeTab: HABITAT_TAB;
  habitat: HabitatType;
  /** Set the active tab for this Habitat */
  setActiveTab: (habitatCode: string, activeTab: HABITAT_TAB) => void;
  /** Is the augurs tab disabled? */
  isAugursDisabled: boolean;
  codeCapsulesCount: number;
  isListView: boolean;
  setHabitatView: (isListView: boolean) => void;
  order: OrderType;
  setOrder: (order: OrderType) => void;
};

const Header: FC<Props> = (props) => {
  const {
    activeTab,
    habitat,
    setActiveTab,
    isAugursDisabled,
    codeCapsulesCount,
    isListView,
    setHabitatView,
    order,
    setOrder,
  } = props;
  const summary = useSelector<RootState, Summary>((state) => state.summary);
  const habitatObj = summary.find(
    (h) => h.code === habitat.code
  ) as HabitatType & { allowedScopes: any };
  const perm = habitatObj?.allowedScopes || [];
  const editPermitted = perm?.find((p) => p === 'scopes:edit') !== undefined;

  return (
    <div className={styles.habitatHeader}>
      <div className={styles.headerName} data-testingIdentifier={habitat.name}>
        <span className={classNames('icon-home', styles.habitatIcon)} />
        <span className={styles.habitatName}>{habitat.name}</span>

        {editPermitted && (
          <div data-tooltip={'Edit Habitat'} className={styles.editHabitatIcon}>
            <Link
              to={
                routes.app.prefix +
                routes.app.models +
                '/' +
                routes.app.newHabitat +
                '/' +
                habitat.code
              }
            >
              <FiEdit size={16} />
            </Link>
          </div>
        )}
      </div>
      <div className={styles.headerTabs} data-testingIdentifier={'Tabs'}>
        <AugursTab
          habitat={habitat}
          isActive={activeTab === HABITAT_TAB.AUGURS}
          setActive={() => setActiveTab(habitat.code, HABITAT_TAB.AUGURS)}
          isDisabled={isAugursDisabled}
        />

        <DatapoolsTab
          habitat={habitat}
          isActive={activeTab === HABITAT_TAB.DATAPOOLS}
          setActive={() => setActiveTab(habitat.code, HABITAT_TAB.DATAPOOLS)}
          isDisabled={false}
        />

        <CodeCapsulesTab
          isActive={activeTab === HABITAT_TAB.CODE_CAPSULES}
          setActive={() =>
            setActiveTab(habitat.code, HABITAT_TAB.CODE_CAPSULES)
          }
          isDisabled={false}
          codeCapsulesCount={codeCapsulesCount}
        />
      </div>

      <div className={styles.headerViewType}>
        <ViewSwitch isListView={isListView} setHabitatView={setHabitatView} />
      </div>

      <div className={styles.headerRightPart}>
        {[HABITAT_TAB.AUGURS].includes(activeTab) && (
          <Order order={order} setOrder={setOrder} />
        )}
        {activeTab === HABITAT_TAB.AUGURS && (
          <Button
            buttonId={'newAugurButton'}
            buttonLabelId={'common.new_augur'}
            buttonLabelDefault={'New Augur'}
            buttonColor={'green'}
            withLink={true}
            linkTo={getNewAugurLink(habitat.code)}
            additionalClassNames={[styles.headerButton]}
            additionalTextClassNames={[styles.headerButtonText]}
            Icon={() => (
              <span
                className={classNames(styles.headerButtonIcon, 'icon-plus')}
              />
            )}
          />
        )}
        {activeTab === HABITAT_TAB.DATAPOOLS && (
          <Button
            buttonId={'newDatapoolButton'}
            buttonLabelId={'common.new_datapool'}
            buttonLabelDefault={'New Datapool'}
            buttonColor={'green'}
            withLink={true}
            linkTo={getNewDatapoolLink(habitat.code)}
            additionalClassNames={[styles.headerButton]}
            additionalTextClassNames={[styles.headerButtonText]}
            Icon={() => (
              <span
                className={classNames(styles.headerButtonIcon, 'icon-plus')}
              />
            )}
          />
        )}
        {activeTab === HABITAT_TAB.CODE_CAPSULES && (
          <Button
            buttonId={'newCodeCapsuleButton'}
            buttonLabelId={'common.new_code_capsule'}
            buttonLabelDefault={'New Code Capsule'}
            buttonColor={'green'}
            withLink={true}
            linkTo={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}?repositoryType=code-capsule&habitatCode=${habitat.code}`}
            additionalClassNames={[styles.headerButton]}
            additionalTextClassNames={[styles.headerButtonText]}
            Icon={() => (
              <span
                className={classNames(styles.headerButtonIcon, 'icon-plus')}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
