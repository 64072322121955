import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { Field, reduxForm } from 'redux-form';
import { form, fieldServingTypes } from './form';
import FieldServingTypes from './FieldServingTypes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '600px',
    transform: 'translate(-50%, -50%)',
  },
};

class ConfigureArchetypeModal extends Component {
  render() {
    const {
      isShown,
      hideConfigureArchetypeModal,
      updateArchetypeConfiguration,
      resetForm,
      path,
      formValues,
    } = this.props;
    return (
      <ReactModal
        isOpen={isShown}
        contentLabel='Dialog Modal'
        onRequestClose={() => {
          hideConfigureArchetypeModal();
          resetForm();
        }}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'configure-archetype-modal modal-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={() => {
              hideConfigureArchetypeModal();
              resetForm();
            }}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={'modal-group-parent'}>
            <span className={'modal-headline'}>Available Serving Types</span>
            <span className={'modal-subheadline'}>
              Select the Serving Types you want to offer for your model
            </span>
            <Field name={fieldServingTypes} component={FieldServingTypes} />
          </div>

          <div className={'button-bar'}>
            <div
              className={'button button-blue'}
              onClick={() => {
                updateArchetypeConfiguration(path, formValues);
                hideConfigureArchetypeModal();
              }}
            >
              OK
            </div>
            <div
              className={'button button-white'}
              onClick={() => {
                hideConfigureArchetypeModal();
                resetForm();
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
ConfigureArchetypeModal.propTypes = {
  isShown: PropTypes.bool,
  hideConfigureArchetypeModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  updateArchetypeConfiguration: PropTypes.func.isRequired,
  path: PropTypes.string,
  formValues: PropTypes.shape({
    resources: PropTypes.shape({
      cpu: PropTypes.string,
      memory: PropTypes.string,
    }),
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
};
ConfigureArchetypeModal.defaultProps = {
  isShown: false,
};

export default reduxForm(form)(ConfigureArchetypeModal);
