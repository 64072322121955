import * as React from 'react';
import { FC, useState } from 'react';
import { AuditEvent } from 'common/dist/types/auditLog';
import ExpandCollapseIcon from '../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import { FormattedRelativeTimeConvenient } from '../../atoms/formatted-date-time/FormattedDateTime';
import styles from './styles.module.scss';
import auditLogMsgs from 'common/dist/messages/auditLog';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import * as routes from '../../index/routes';
import { Link } from 'react-router-dom';
import { DateFree } from 'common/dist/types/utils';
import classNames from 'classnames';

export type Props = {
  event: DateFree<AuditEvent>;
  /** the user name that belongs to this event, will be fetched and injected later by AuditLog.tsx */
  userName?: string;
  referencedEvent: DateFree<AuditEvent>;
  relatedEvents: string[];
  disabled?: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

function isComplex(x: unknown): x is Record<string, unknown> | Array<unknown> {
  return !!x && (x.constructor === Array || x.constructor === Object);
}

function isEmpty(x: unknown): boolean {
  return x['length'] === 0 || Object.keys(x).length === 0;
}

const EventDetailsCell: FC<{
  event: Record<string, unknown> | Array<unknown>;
}> = ({ event }) => {
  if (event) {
    return (
      <>
        {Object.entries(event).map(([key, value]) => {
          return (
            <CollapsibleEventDetailsCell event={value} name={key} key={key} />
          );
        })}
      </>
    );
  }
  return <p>{JSON.stringify(event)}</p>;
};

const CollapsibleEventDetailsCell: FC<{
  event: unknown;
  name: string;
}> = ({ event, name }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [ref, setRef] = useState<HTMLDivElement>(undefined);
  return (
    <>
      {collapsed && (
        <div className={styles.detailsRow}>
          <div className={styles.eventDetailsKeyContainer}>
            <span>{name}</span>
            {(ref?.scrollWidth > ref?.clientWidth ||
              (isComplex(event) && !isEmpty(event))) && (
              <ExpandCollapseIcon
                isExpanded={!collapsed}
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
          </div>
          <div
            ref={(r) => setRef(r)}
            className={styles.eventDetailsValuePreview}
          >
            <span>{JSON.stringify(event)}</span>
          </div>
        </div>
      )}
      {!collapsed && (
        <>
          <div className={styles.detailsRow}>
            <div className={styles.eventDetailsKeyContainer}>
              <span>{name}</span>
              <ExpandCollapseIcon
                isExpanded={!collapsed}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.eventDetailsValue}>
              {isComplex(event) && !isEmpty(event) ? (
                <EventDetailsCell event={event} />
              ) : (
                <span className={styles.break}>{JSON.stringify(event)}</span>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const DetailsCell: FC<Props> = ({
  event,
  userName,
  referencedEvent,
  relatedEvents,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className={styles.detailsCell}>
      <table>
        <tr>
          <td>
            <FormattedMessage {...auditLogMsgs.msgUser} />
          </td>
          <td>
            <Link to={`/app/admin/users/user/${event.userId}`}>{userName}</Link>
          </td>
        </tr>
        {relatedEvents.length === 0 && (
          <tr>
            <td>
              <FormattedMessage
                {...(referencedEvent
                  ? auditLogMsgs.msgCausedBy
                  : auditLogMsgs.msgCaused)}
              />
            </td>
            <td>
              {!referencedEvent && (
                <FormattedMessage
                  {...auditLogMsgs.msgRelatedEvents}
                  values={{ relatedEventCount: relatedEvents.length }}
                />
              )}
              {!!referencedEvent && (
                <>
                  {referencedEvent.eventType} (
                  <FormattedMessage
                    {...auditLogMsgs.msgRelatedEvents}
                    values={{ relatedEventCount: relatedEvents.length }}
                  />
                  )
                </>
              )}
            </td>
          </tr>
        )}
        {relatedEvents.length > 0 && (
          <tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <td>
              <FormattedMessage
                {...(referencedEvent
                  ? auditLogMsgs.msgCausedBy
                  : auditLogMsgs.msgCaused)}
              />
            </td>
            <td>
              <Link
                to={`${
                  routes.app.prefix +
                  routes.app.admin.index +
                  routes.app.admin.auditLog
                }?event=${referencedEvent?.uuid || event.uuid}`}
              >
                {!referencedEvent && (
                  <FormattedMessage
                    {...auditLogMsgs.msgRelatedEvents}
                    values={{ relatedEventCount: relatedEvents.length }}
                  />
                )}
                {!!referencedEvent && (
                  <>
                    <span className={styles.monospaced}>
                      {referencedEvent.eventType}
                    </span>{' '}
                    (
                    <FormattedMessage
                      {...auditLogMsgs.msgRelatedEvents}
                      values={{ relatedEventCount: relatedEvents.length }}
                    />
                    )
                  </>
                )}
              </Link>
            </td>
          </tr>
        )}
        <tr>
          <td>
            <div className={styles.eventDetailsKeyContainer}>
              <FormattedMessage {...auditLogMsgs.msgEventDetails} />
              <ExpandCollapseIcon
                isExpanded={!collapsed}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
          </td>
          {collapsed && (
            <td
              className={classNames(
                styles.eventDetailsValuePreview,
                styles.monospaced
              )}
            >
              <span>{JSON.stringify(event.eventDetails)}</span>
            </td>
          )}
          {!collapsed && (
            <td
              className={classNames(
                styles.eventDetailsValue,
                styles.monospaced
              )}
            >
              <table>
                <EventDetailsCell event={event.eventDetails} />
              </table>
            </td>
          )}
        </tr>
      </table>
    </div>
  );
};

const AuditEventRow: FC<Props> = (props) => {
  const { event } = props;
  const date = new Date(event.timestamp);
  return (
    <tr
      className={classNames({
        [styles.disabled]: props.disabled,
      })}
    >
      <td>
        <p>
          <FormattedDate value={date} />{' '}
          <FormattedTime
            value={date}
            hour='numeric'
            minute='numeric'
            second='numeric'
          />{' '}
        </p>
        <FormattedRelativeTimeConvenient
          date={new Date(event.timestamp)}
          tooltip={false}
        />
      </td>
      <td className={classNames(styles.eventType, styles.monospaced)}>
        {event.eventType}
      </td>
      <td>
        <DetailsCell {...props} />
      </td>
    </tr>
  );
};

export default AuditEventRow;
