import P3InputElement, {
  AsInputElement,
  AsVariable,
} from '../_interface/P3InputElement';
import {
  validateCommonSettings,
  getValueFromSettingsField,
} from '../_interface/input-element.form';
import NumberInput from './NumberInput';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import NumberInputSettings from './NumberInputSettings';
import { numberRegex } from '../../variables/handler/helper';

export default class NumberInputWrapper extends P3InputElement {
  // --- Components
  ChildComponent: React.ComponentType = NumberInput;
  AdditionalSettings: any = NumberInputSettings;
  // icon: any = NumberInputIcon;
  icon: any = DefaultElementIcon;
  iconColor = '#224e90';

  // --- AttributesComponent
  name = 'Number Input';
  parentContainerClass = 'number-input';
  type = 'number-input';

  // --- Functions
  getSource: (asElement: AsInputElement, variables: AsVariable[]) => string = (
    asElement,
    variables
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    let number = asElement.data.number;
    const defaultValueField = asElement.settings.defaultValue;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );
    if (!number && defaultValue) {
      // If the number isn't set: Inject the default value
      number = defaultValue;
    }

    return `${asElement.settings.outputVariable.inputValue} = ${number}`;
  };

  /**
   * Checks:
   * - Is the input really a number?
   * TODO incomplete docs here
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => {
    if (!asElement || !asElement.data || !asElement.settings) return undefined;

    const number = asElement.data.number;
    const settings = asElement.settings;

    // --- Is the number filled?
    // @ts-ignore
    if (!number || number.trim() === '') {
      return 'Please enter a number';
    }

    // --- Is the input a valid number?
    // @ts-ignore
    if (!number.match(numberRegex)) {
      return 'Please enter a valid number';
    }

    // --- Check lower and upper bounds
    const { lowerBound: lowerBoundField, upperBound: upperBoundField } =
      settings;
    const lowerBound = getValueFromSettingsField(lowerBoundField, variables);
    const upperBound = getValueFromSettingsField(upperBoundField, variables);

    const parsedLowerBound = Number.parseFloat(lowerBound);
    const parsedUpperBound = Number.parseFloat(upperBound);
    // @ts-ignore
    const parsedNumber = Number.parseFloat(number);

    if (lowerBound && parsedNumber < parsedLowerBound) {
      if (upperBound) {
        return `Number must be between [${lowerBound},${upperBound}]`;
      } else {
        return `Number must be greater or equal than ${lowerBound}`;
      }
    }
    if (upperBound && parsedNumber > parsedUpperBound) {
      if (lowerBound) {
        return `Number must be between [${lowerBound},${upperBound}]`;
      } else {
        return `Number must be lower or equal than ${upperBound}`;
      }
    }

    return undefined;
  };

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (settings: object, variables: object[]) => object = (
    settings,
    variables
  ) => {
    // @ts-ignore
    const commonErrors = validateCommonSettings(settings);

    const specificErrors = {};
    const {
      // @ts-ignore
      lowerBound: lowerBoundField,
      // @ts-ignore
      upperBound: upperBoundField,
      // @ts-ignore
      defaultValue: defaultValueField,
    } = settings;

    const lowerBound = getValueFromSettingsField(lowerBoundField, variables);
    const upperBound = getValueFromSettingsField(upperBoundField, variables);
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );

    // --- Check if the boundaries are proper numbers
    let lowerValidNumber = true;
    let upperValidNumber = true;
    if (lowerBound && !lowerBound.match(numberRegex)) {
      commonErrors.lowerBound = 'Please enter a valid number';
      lowerValidNumber = false;
    }
    if (upperBound && !upperBound.match(numberRegex)) {
      commonErrors.upperBound = 'Please enter a valid number';
      upperValidNumber = false;
    }

    const lowerBoundCasted =
      lowerBound && lowerValidNumber ? parseFloat(lowerBound) : undefined;
    const upperBoundCasted =
      upperBound && upperValidNumber ? parseFloat(upperBound) : undefined;

    // --- Check if the lower boundary is < upper boundary
    if (
      lowerBoundCasted &&
      upperBoundCasted &&
      lowerBoundCasted > upperBoundCasted
    ) {
      commonErrors.lowerBound =
        'Lower Bound must be smaller or equal than the Upper Bound';
    }

    if (defaultValue) {
      let defaultValidNumber = true;
      // --- Check if the default value is a proper number boundaries
      if (!defaultValue.match(numberRegex)) {
        commonErrors.defaultValue = 'Please enter a valid number';
        defaultValidNumber = false;
      }

      const defaultValueCasted =
        defaultValue && defaultValidNumber
          ? parseFloat(defaultValue)
          : undefined;

      // --- Check if the default value satiesfies the boundaries
      if (lowerBoundCasted && defaultValueCasted < lowerBoundCasted) {
        commonErrors.defaultValue =
          'Default Value must be greater or equal the Lower Bound';
      }

      if (upperBoundCasted && defaultValueCasted > upperBoundCasted) {
        commonErrors.defaultValue =
          'Default Value must be lower or equal the Upper Bound';
      }
    }

    return {
      ...commonErrors,
      ...specificErrors,
    };
  };
}
