import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KernelSpecs from './kernels/KernelSpecs.container';
import GenericFormStep from '../../../../../molecules/generic-form-step/GenericFormStep';
import { formName, fieldKernel } from '../notebook.form';

function validateKernel(value) {
  if (!value || !value.name) {
    //No empty names
    return { message: 'Please select a kernel' };
  }
}

export default class Step2 extends Component {
  errorMessage() {
    if (this.props.kernelTouched && this.props.kernelError) {
      return <p className={'error'}>{this.props.kernelError}</p>;
    }
  }

  render() {
    return (
      <GenericFormStep
        fieldName={fieldKernel}
        formName={formName}
        component={KernelSpecs}
        validate={validateKernel}
        title={{
          id: 'workbench.notebook.step.kernel.title',
          defaultMessage: 'Select Kernel',
        }}
        description={{
          id: 'workbench.notebook.step.kernel.description',
          defaultMessage: 'Select a Kernel for the Archetype.',
        }}
        num={2}
        renderError={false}
      />
    );
  }
}

Step2.propTypes = {
  kernel: PropTypes.object,
  kernelTouched: PropTypes.bool,
  kernelError: PropTypes.string,
};
