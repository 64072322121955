import React from 'react';
import { CardOrButtonType } from '../../../organisms/element-card-grid/ElementCardGrid';
import Button from '../../../atoms/button/Button';
import { AugurType, ExtendedAugurType } from 'common/dist/types/augur';
import { getAugurDetailsLink, getNewAugurLink } from '../../../index/routes';
import {
  getModuleName,
  getOverviewAugurInfo,
} from '../../../modelManagement/modules/ModuleManagerClient';
import { IntlShape } from 'react-intl';
import { FiChevronsLeft } from 'react-icons/fi';
import scssColors from '../../../../../scss/base/var.module.scss';
import { ProgressSummary } from 'common/dist/types/orchestration/progress';
import { DiscreteProgressSteps, JobAugur } from 'common/dist/types/job';
import { speakingJobType } from '../../../molecules/job-groups/job/Job';

const deriveBarColor = (health: number | undefined) => {
  switch (health) {
    case undefined:
    case null:
      return scssColors.colorHealthNo;
    case 4:
      return scssColors.colorHealth4;
    case 3:
      return scssColors.colorHealth3;
    case 2:
      return scssColors.colorHealth2;
    case 1:
      return scssColors.colorHealth1;
    case 0:
      return scssColors.colorHealth0;
    default:
      return scssColors.colorDarkGrey;
  }
};

export function deriveElements(
  augurs: ExtendedAugurType[],
  habitatCode: string,
  intl: IntlShape,
  isArchive: boolean,
  restoreAugur: (augurCode: string) => void,
  amountArchivedAugurs: number,
  openArchive: () => void,
  closeArchive: () => void,
  jobProgressSummary?: Record<string, ProgressSummary>
): CardOrButtonType[] {
  if (!augurs) return [];

  const elements: CardOrButtonType[] = [];

  // --- 'Back to active Augurs' button
  if (isArchive) {
    elements.push({
      type: 'button',
      Button: () => (
        <FiChevronsLeft size={24} color={scssColors.colorTextLight} />
      ),
      subHeader: {
        id: 'no-id',
        defaultMessage: 'Back to active Augurs',
      },
      onClick: closeArchive,
    });
  }

  function getProgress(
    jobsProgress: Map<string, ProgressSummary[]>,
    augur: AugurType
  ): string {
    if (jobsProgress && jobsProgress.has(augur.code)) {
      const progress = jobsProgress?.get(augur.code);
      if (progress && progress.length > 1) {
        return `${progress.length} Jobs `;
      }
      return undefined;
    }
  }
  function getDiscreteProgress(
    jobsProgress: Map<string, ProgressSummary[]>,
    augur: AugurType
  ): DiscreteProgressSteps {
    if (jobsProgress.has(augur.code)) {
      const progress = jobsProgress?.get(augur.code);
      //If only one job is running in the whole augur the steps will be passed
      if (progress && progress.length <= 1) {
        const currentSteps = progress.entries().next().value[1];
        const discreteStep: DiscreteProgressSteps = {
          total: currentSteps.total,
          started: currentSteps.started,
          completed: currentSteps.conpleted,
        };
        return discreteStep;
      } else return undefined;
    }
  }
  function getProgressJobType(
    jobsProgress: Map<string, ProgressSummary[]>,
    augur: AugurType
  ): string {
    if (jobsProgress && jobsProgress.has(augur.code)) {
      const progress = jobsProgress?.get(augur.code);
      //If only one job is running in the whole augur the steps will be passed
      if (progress && progress.length <= 1) {
        const currentSteps = progress.entries().next().value[1];
        //Resolves the string to the actually displayed name
        return speakingJobType(currentSteps.job.jobType);
      } else return '';
    }
  }
  //  --- Add the Augurs / Datapools / Code Capsules
  augurs.forEach((augur) => {
    const datapoolDetailsLink = getAugurDetailsLink({
      augurCode: augur.code,
      habitatCode,
      moduleType: augur.type,
      tabId: undefined,
      // tabId: 'settings',
    });

    const isErrorShown = augur.error && !augur.archived;
    const isLastEvaluationDateIsShown =
      (!augur.error || augur.archived) && augur.lastEvaluationDate;

    let message: string;

    if (augur.error && augur.error.message) {
      message =
        augur.error.message.length > 100
          ? `${augur.error.message.substring(0, 100)} ...`
          : augur.error.message;
    }

    let bottomKey: string = undefined;
    let bottomValue: string = undefined;
    if (isErrorShown) {
      bottomValue = intl.formatMessage(
        { id: 'dashboard.augurs.error', defaultMessage: 'Error: {message}' },
        { message }
      );
    } else if (isLastEvaluationDateIsShown) {
      bottomKey = intl.formatMessage({
        id: 'dashboard.augurs.last_run',
        defaultMessage: 'Last evaluation:',
      });
      bottomValue = intl.formatDate(augur.lastEvaluationDate);
    }

    const archiveAugurIcons = [
      {
        Icon: () => (
          <div
            className={'icon-refresh'}
            style={{
              color: scssColors.colorBlue,
              fontSize: 19,
            }}
          />
        ),
        tooltip: {
          id: 'common.restore',
          defaultMessage: 'Restore',
        },
        onClick: (e) => {
          restoreAugur(augur.code);
          e.preventDefault();
        },
      },
    ];

    const regularAugurIcons = [
      {
        Icon: () => (
          <div
            className={'icon-settings'}
            style={{
              color: scssColors.colorTextLight,
              fontSize: '17px',
            }}
          />
        ),
        linkTo: getAugurDetailsLink({
          augurCode: augur.code,
          habitatCode,
          moduleType: augur.type,
          tabId: 'settings',
        }),
        tooltip: {
          id: 'common.settings',
          defaultMessage: 'Settings',
        },
      },
    ];

    const icons = augur.archived ? archiveAugurIcons : regularAugurIcons;
    // Map from augurCode to an array of ProgressSummaries for each job for that augur
    const jobsProgress: Map<string, ProgressSummary<JobAugur>[]> =
      jobProgressSummary
        ? new Map(
            Object.entries(jobProgressSummary)
              .filter(
                // Get only augur jobs. Can't destructure key,value and write a type guard at the same time https://github.com/microsoft/TypeScript/issues/41173
                (keyValue): keyValue is [string, ProgressSummary<JobAugur>] =>
                  keyValue[1].job?.superType === 'augur'
              )
              .map(([_, m]) => [
                m.job.augurCode,
                //This will extract the object per augurCode
                Object.entries(jobProgressSummary)
                  .filter(
                    (
                      keyValue
                    ): keyValue is [string, ProgressSummary<JobAugur>] =>
                      'augurCode' in keyValue[1].job &&
                      keyValue[1].job.augurCode === m.job.augurCode
                  )
                  .map(([k, v]) => v),
              ])
          )
        : undefined;

    elements.push({
      type: 'card',
      linkTo: datapoolDetailsLink,
      header: augur.name,
      subHeader: getModuleName(augur.type),
      info: getOverviewAugurInfo(augur, intl),
      barColor: deriveBarColor(augur.health),
      bottomKey,
      bottomValue,
      alerting: isErrorShown,
      progress: jobsProgress ? getProgress(jobsProgress, augur) : undefined,
      progressSteps: jobsProgress
        ? getDiscreteProgress(jobsProgress, augur)
        : undefined,
      icons,
      greyedOut: augur.archived,
      jobType: getProgressJobType(jobsProgress, augur),
    });
  });

  // --- 'Add Augur' button
  if (!isArchive) {
    elements.push({
      type: 'button',
      Button: () => (
        <Button
          buttonLabelDefault={'New Augur'}
          buttonColor={'green'}
          withLink={false}
          Icon={() => (
            <span
              style={{
                fontSize: '18px',
                margin: '-3px 0px -2px -10px',
                opacity: 0.5,
              }}
              className={'icon-plus'}
            />
          )}
        />
      ),
      linkTo: getNewAugurLink(habitatCode),
    });
  }

  // --- 'x archived Augurs' button
  if (!isArchive && amountArchivedAugurs > 0) {
    const singPluAugur = amountArchivedAugurs === 1 ? 'Augur' : 'Augurs';

    elements.push({
      type: 'button',
      Button: () => (
        <span
          style={{
            fontSize: '24px',
            color: scssColors.colorTextLight,
          }}
          className={'icon-archive'}
        />
      ),
      subHeader: {
        id: 'no-id',
        defaultMessage: `${amountArchivedAugurs} archived ${singPluAugur}`,
      },
      onClick: openArchive,
    });
  }

  return elements;
}
