import React, { Component } from 'react';
import Modal from '../../organisms/modal/Modal';
import { ButtonProps } from '../../atoms/button/Button';
import { Field, InjectedFormProps } from 'redux-form';
import { fieldTable } from './form';
import FieldSelectTable from './FieldSelectTable';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { DatapoolType } from 'common/dist/types/datapool';
import { CProps } from './RunPredictionModal.container';

export type Props = {
  habitatCode: string;
  datapool: DatapoolType;
  augurCode: string;
  augurName: string;
  scoringTable: string;
};

export default class RunPredictionModal extends Component<
  Props & CProps & InjectedFormProps<ToBeRefined, Props>
> {
  componentDidUpdate(prevProps, prevState) {
    const { showModal: showModalPrev } = prevProps;
    const { showModal, datapool, fetchDatapoolTables } = this.props;
    if (!showModalPrev && showModal) {
      // The modal was opened: Fetch the available tables
      fetchDatapoolTables({
        datapoolCode: datapool.code,
        dataSourceCode: datapool.dataSourceCode,
      });
    }
  }

  render() {
    const {
      showModal,
      habitatCode,
      augurCode,
      augurName,
      triggerPredictionJob,
      hideRunPredictionModal,
      isValid,
      isPristine,
      availableTables,
      selectedTable,
    } = this.props;

    let overwritePredictionTable = undefined;
    if (!isPristine) {
      // Only set the overwritePredictionTable parameter when submitting if the form is not pristine.
      //   Otherwise the value would be "overwritten" with the already selected table. This wouldn't be a problem,
      //   but might look confusing in the Job Details.
      overwritePredictionTable = selectedTable;
    }

    const buttons: ButtonProps[] = [
      {
        buttonColor: 'white',
        withLink: false,
        onClick: () => {
          hideRunPredictionModal();
        },
        buttonLabelDefault: 'Cancel',
      },
      {
        buttonColor: 'green',
        withLink: false,
        onClick: () => {
          triggerPredictionJob(
            habitatCode,
            augurCode,
            augurName,
            overwritePredictionTable
          );
          hideRunPredictionModal();
        },
        buttonLabelDefault: 'Run Prediction',
        disabled: !isValid,
        buttonId: 'runPredictionButton',
      },
    ];

    return (
      <Modal
        show={showModal}
        hideModal={hideRunPredictionModal}
        headline={{
          id: 'no-id',
          defaultMessage: 'Run Prediction Job',
        }}
        buttons={buttons}
      >
        <form>
          <Field
            name={fieldTable}
            component={FieldSelectTable}
            availableTables={availableTables}
          />
        </form>
      </Modal>
    );
  }
}
