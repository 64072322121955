import React, { Component, ComponentType, FocusEventHandler } from 'react';
import InputError from '../../input-error/InputError';
import Select, { ActionMeta, GroupBase } from 'react-select';
import {
  FormattedMessage,
  injectIntl,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';

import styles from './styles.module.scss';
import classNames from 'classnames';
import {
  OnChangeValue,
  OptionsOrGroups,
  PropsValue,
} from 'react-select/dist/declarations/src/types';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import DefaultOptionComponent from './DefaultOptionComponent';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';

export type OwnProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  /** ID of the input element */
  id?: string;
  /** Class name for the outer div */
  containerClassName?: string;
  /** Class name for the <Select> component */
  className?: string;
  /** Name of the input field */
  name?: string;
  /** Styling of the dropdown select. Default: regular */
  appearance?: 'regular' | 'small' | 'multiLines';

  /** Is it allowed to select multiple values? */
  multi?: IsMulti;
  /** Is the dropdown clearable? */
  clearable?: boolean;
  /** Is the dropdown searchable? */
  searchable?: boolean;
  /** Scroll into the menu? Default value: true */
  scrollMenuIntoView?: boolean;

  /** Label above the dropdown select. Optional. */
  label?: MessageDescriptor;
  /** Intl ID for the placeholder */
  placeholder?: MessageDescriptor;

  /** Is the field disabled? */
  disabled?: boolean;
  /** show a loading spinner? */
  isLoading?: boolean;

  /** The options for the dropdown menu. The 'value's are of type string */
  options: OptionsOrGroups<Option, Group>;
  /** If there is only a single option is it supposed to be auto-selected? */
  autoSelectIfSingle?: boolean;

  /** Portal for the menu */
  menuPortalTarget?: HTMLElement;

  // --- Custom Components
  customComponents?: {
    Option?: ComponentType<OptionProps<Option, IsMulti, Group>>;
  };
};

export type FormProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  /** Has the field been touched? */
  touched?: boolean;
  /** Has the field an error? */
  error?: string | MessageDescriptor;
  /** Is the field valid? -> Shows a checkmark */
  valid?: boolean;
  /** Value of the input field */
  value?: PropsValue<Option>;
  /** onChange callback */
  onChange: (
    option: OnChangeValue<Option, IsMulti>,
    actionMeta?: ActionMeta<Option>
  ) => void;
  /** onFocus callback */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /** onBlur callback */
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

export type Props<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = OwnProps<Option, IsMulti, Group> & FormProps<Option, IsMulti, Group>;

class DropdownSelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Component<Props<Option, IsMulti, Group> & WrappedComponentProps> {
  static defaultProps = {
    scrollMenuIntoView: true,
    isLoading: false,
    appearance: 'regular',
  };

  componentDidMount() {
    const { autoSelectIfSingle, options, onChange } = this.props;

    if (autoSelectIfSingle && options && options.length === 1) {
      // Find the first option that is really an Option (and not a Group) -> is there a better way to check for this?
      const firstOption = options.find(
        (option) => !Object.keys(option).includes('options')
      ) as Option;
      // @ts-ignore
      onChange(firstOption);
    }
  }

  render() {
    const {
      label,
      intl,
      placeholder,
      touched,
      error,
      id,
      className,
      name,
      value,
      isLoading,
      onChange,
      onFocus,
      onBlur,
      options,
      disabled,
      clearable,
      searchable,
      multi,
      scrollMenuIntoView,
      containerClassName,
      appearance,
      customComponents,
      menuPortalTarget,
    } = this.props;

    if (placeholder && !placeholder.id) {
      console.warn(
        "The prop 'placeholder' doesn't have the key 'id'. Did you pass a string instead of a MessageDescriptor object?"
      );
    }
    const placeholderFormatted =
      placeholder && placeholder.id ? intl.formatMessage(placeholder) : '';

    return (
      <div
        className={classNames(styles.dropdownSelectInput, containerClassName)}
        style={{
          overflow: 'visible',
        }}
      >
        <div className={styles.header}>
          {label && (
            <FormattedMessage {...label}>
              {(text) => <p className={styles.label}>{text}</p>}
            </FormattedMessage>
          )}
          {error && (
            <div className={styles.error}>
              <InputError touched={Boolean(touched)} error={error} />
            </div>
          )}
        </div>

        <Select
          data-testingIdentifier={id}
          id={id}
          name={name}
          className={className}
          styles={customStyles<Option, IsMulti>(appearance)}
          isMulti={multi}
          isClearable={clearable}
          isSearchable={searchable}
          isLoading={isLoading}
          isDisabled={disabled}
          menuShouldScrollIntoView={scrollMenuIntoView}
          placeholder={placeholderFormatted}
          options={options}
          value={value}
          onChange={(selectedOption: OnChangeValue<Option, IsMulti>) => {
            onChange(selectedOption);
          }}
          onFocus={(e) => onFocus && onFocus(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          components={{
            ...(customComponents || {}),
            //@ts-ignore - TODO how to type the DefaultOptionComponent?
            Option: customComponents?.Option || DefaultOptionComponent,
          }}
          menuPortalTarget={menuPortalTarget}
          //menuIsOpen
        />
      </div>
    );
  }
}

// --- CUSTOM STYLING ---
const colorText = '#071b32'; // TODO the colors should better be imported from scss
const colorTextLight = '#a6aeb5';
const colorBlue = '#224e90';
const color35LightenedGreen = '#eff9e4';
const colorGreen = '#9fd356';
const colorLightGrey = '#eef0f2';
const colorRedDark = '#c23030';

const borderRadius = '4px';
const customStyles = <Option, IsMulti extends boolean>(
  appearance?: 'regular' | 'small' | 'multiLines'
): StylesConfig<Option, IsMulti> => {
  // --- Appearance related overrides
  const appearanceOverridesControl: Record<string, unknown> = {};
  const appearanceOverridesValueContainer: Record<string, unknown> = {};
  const appearanceOverridesIndicatorsContainer: Record<string, unknown> = {};
  const appearanceOverridesInput: Record<string, unknown> = {};
  const appearanceOverridesInputInput: Record<string, unknown> = {};
  const appearanceOverridesDropdownIndicator: Record<string, unknown> = {};
  const appearanceOverridesDropdownIndicatorSvg: Record<string, unknown> = {};
  const appearanceOverridesPlaceholder: Record<string, unknown> = {};
  const appearanceOverridesOption: Record<string, unknown> = {};
  const appearanceOverridesOptionAfter: Record<string, unknown> = {};
  if (appearance === 'small') {
    appearanceOverridesControl['height'] = '30px';
    appearanceOverridesControl['minHeight'] = '30px';
    appearanceOverridesControl['minWidth'] = 'unset';
    appearanceOverridesValueContainer['height'] = '28px';
    appearanceOverridesIndicatorsContainer['height'] = '28px';
    appearanceOverridesInput['height'] = '24px';
    appearanceOverridesInputInput['height'] = '20px';
    appearanceOverridesDropdownIndicator['padding'] = '3px';
    appearanceOverridesPlaceholder['height'] = '20px';
    appearanceOverridesOption['paddingTop'] = '4px';
    appearanceOverridesOption['paddingBottom'] = '4px';
    appearanceOverridesOptionAfter['top'] = '0';
    appearanceOverridesDropdownIndicatorSvg['height'] = '18px';
    appearanceOverridesDropdownIndicatorSvg['width'] = '18px';
  } else if (appearance === 'multiLines') {
    appearanceOverridesControl['height'] = 'unset';
    appearanceOverridesValueContainer['height'] = 'unset';
    appearanceOverridesIndicatorsContainer['height'] = 'unset';
    appearanceOverridesInput['height'] = 'unset';
    appearanceOverridesInputInput['height'] = 'unset';
  }

  return {
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${colorTextLight}`,
      '&:hover': {
        borderColor: colorTextLight,
      },
      borderBottomLeftRadius: state.menuIsOpen ? 0 : borderRadius,
      borderBottomRightRadius: state.menuIsOpen ? 0 : borderRadius,
      boxShadow: 'none',
      height: '46px',
      cursor: 'pointer',
      ...appearanceOverridesControl,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '44px',
      ...appearanceOverridesValueContainer,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '44px',
      ...appearanceOverridesIndicatorsContainer,
    }),
    input: (provided, state) => ({
      ...provided,
      height: '40px',
      input: {
        ...((provided.input as Record<string, unknown>) || {}),
        height: '36px',
        ...appearanceOverridesInputInput,
      },
      ...appearanceOverridesInput,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...appearanceOverridesPlaceholder,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    menu: (provided, state) => ({
      ...provided,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      marginTop: 0,
      border: `1px solid ${colorTextLight}`,
      borderTopWidth: 0,
      boxShadow: 'none',
      zIndex: 100,
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      // backgroundColor: 'white',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: colorBlue,
      '&:hover': {
        color: colorBlue,
      },
      svg: {
        width: '24px',
        height: '24px',
        ...appearanceOverridesDropdownIndicatorSvg,
      },
      ...appearanceOverridesDropdownIndicator,
    }),
    option: (provided, state) => ({
      ...provided,
      color: colorText,
      backgroundColor: state.isSelected ? color35LightenedGreen : 'transparent',
      position: 'relative',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: colorLightGrey,
      },

      '&:after': {
        content: state.isSelected ? '"\\e904"' : undefined,
        fontFamily: 'dashboardNG',
        position: 'absolute',
        fontSize: '18px',
        right: '10px',
        top: '4px',
        lineHeight: '23px',
        color: colorGreen,
        ...appearanceOverridesOptionAfter,
      },
      '&:last-child': {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      ...appearanceOverridesOption,
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: colorTextLight,
      paddingRight: 0,
      '&:hover': {
        color: colorRedDark,
      },
      svg: {
        width: '18px',
        height: '18px',
      },
    }),
  };
};

// @ts-ignore
export default injectIntl(DropdownSelectInput);
