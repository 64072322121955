import React, { FC, useEffect } from 'react';
import ParameterTuningResults from './parameter-tuning/ParameterTuningResults';
import PipelineTuningResults from './pipeline-tuning/PipelineTuningResults';
import { CProps } from './ModelTuningResults.container';
import GraphWait from '../../../../../details/GraphWait';
import { FormattedMessage } from 'react-intl';
import { useAugur } from '../../../../../../core/api/augurs';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { ArchetypeVersionType } from 'common/dist/types/archetypeVersion';

export const ModelTuningResultsWait: FC = () => (
  <GraphWait>
    <FormattedMessage
      id='details.model_tuning.not_available'
      defaultMessage='Model Tuning results are not available'
    />
  </GraphWait>
);

const ModelTuningResults: FC<CProps> = ({ fetchArchetypeVersions }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetype?.code || '';
  const archetypeVersions = useSelector<RootState, ArchetypeVersionType[]>(
    (state) => state.archetypeVersions?.[archetypeCode]?.versions || []
  );

  useEffect(() => {
    fetchArchetypeVersions(archetypeCode);
  }, [fetchArchetypeVersions, archetypeCode]);

  const archetypeVersionNumber = augur?.archetypeVersionNumber || '';
  const archetypeVersion = archetypeVersions.find(
    (version) => version.number === archetypeVersionNumber
  );
  const { supportsPipelineTuning, supportsParameterTuning } =
    archetypeVersion || {};

  if (supportsParameterTuning) {
    return <ParameterTuningResults />;
  } else if (supportsPipelineTuning) {
    return <PipelineTuningResults />;
  } else {
    return null;
  }
};

export default ModelTuningResults;
