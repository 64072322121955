import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { RouteComponentProps } from 'react-router';
import styles from '../../styles.module.scss';
import classNames from 'classnames';
import AnimationSpinner from '../../../atoms/animation-spinner/AnimationSpinner';

export interface Props {
  error?: string;
  destroyUploadForm(...args: unknown[]): unknown;
}

class UploadConfirmation extends Component<Props & RouteComponentProps> {
  componentDidMount() {
    const { destroyUploadForm } = this.props;
    destroyUploadForm();
  }

  render() {
    const {
      error,
      match: { params },
    } = this.props;

    const dataSourceCode = (params as Record<string, unknown>)?.dataSourceCode;

    return (
      <div className={classNames(styles.uploadConfirmationParent)}>
        <div className={styles.animationContainer}>
          <AnimationSpinner isSpinning={false} />
        </div>
        <h3>Data transfer completed</h3>
        <Link
          to={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}`}
          style={{ textDecoration: 'none' }}
        >
          <button
            type='button'
            className='button button-lg'
            id='confirmationRunButton'
          >
            Back to Data Management
          </button>
        </Link>
        <br />
        {error && (
          <div className={styles.error}>
            <p>Error: {JSON.stringify(error)}</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(UploadConfirmation);
