import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldDatapoolName,
  fieldDataSourceCode,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newDatapool/defaultWizard/default.form';

import { validateInputFormat } from '../../../../../organisms/modelManagement/newDatapoolWizard/inputFormat/validate';

import { Props } from './BinaryClassificationNewDatapoolWizard';
import { InputFormatIdType } from 'common/dist/types/datapool';
import { BinaryClassificationSubmitSummary } from 'common/dist/types/requestBodies/datapools';
import { moduleType } from 'common/dist/constants/module_list';

// --- Field names
export const fieldInputFormat = 'inputFormat';

// --- Type of the form values
export type BinaryClassificationFormValues = DefaultFormValues & {
  [fieldInputFormat]?: InputFormatIdType;
  [fieldModuleType]: typeof moduleType.BINARY_NUMERICAL;
};

// --- Sync validation
export const binaryClassificationValidate = (
  values: BinaryClassificationFormValues,
  props: { _reduxForm: Props }
) => {
  const errors = defaultValidate(values) || {};
  errors[fieldInputFormat] = validateInputFormat(values[fieldInputFormat]);

  return errors;
};

// --- Async validation
export function getBinaryClassificationMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

export const binaryClassificationAsyncBlurFields = [...defaultAsyncBlurFields];
export const binaryClassificationAsyncChangeFields = [
  ...defaultAsyncChangeFields,
];

export const getBinaryClassificationSubmitSummary = (
  formValues: BinaryClassificationFormValues,
  habitatCode: string
): BinaryClassificationSubmitSummary => {
  const submitSummary: BinaryClassificationSubmitSummary = {
    // Module type
    moduleType: formValues[fieldModuleType],

    // Datapool name
    name: formValues[fieldDatapoolName],

    // Input format
    inputFormat: formValues[fieldInputFormat],

    // Data Source Code
    dataSourceCode: formValues[fieldDataSourceCode],
  };

  return submitSummary;
};
