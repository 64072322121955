import React, { FC, useState } from 'react';
import JobGroupEditorRepository from '../job-group-editor-repository/JobGroupEditorRepository';
import GenericCollapsibleContainer from '../../../organisms/collapsible-container/generic/GenericCollapsibleContainer';
import './styles.scss';
import { WrappedFieldProps } from 'redux-form';
import { JobType } from 'common/dist/types/job';
import { Value } from './form';
import { FlapButton } from '../../../atoms/flap-button/FlapButton';
import { Summary } from 'common/dist/types/habitat';
import { CodeCapsulesPerCode, CodeCapsulesPerHabitatCode } from './types';
import InputError from '../../../atoms/input-error/InputError';
import EditorPane from './EditorPane';
import Modals, { ModalProps } from './Modals';
import { EnrichedModule } from 'common/dist/constants/module_list';

export interface Props {
  summary: Summary;
  modules: EnrichedModule[];
  codeCapsulesPerHabitatCode: CodeCapsulesPerHabitatCode;
  codeCapsulesPerCode: CodeCapsulesPerCode;
  // state.names.augurNames
  augurNames: Record<string, string>;
  datapoolNames: Record<string, string>;
  codeCapsuleNames: Record<string, string>;
  isAdmin: boolean;
}

export const JobGroupEditor: FC<Props & WrappedFieldProps> = (props) => {
  const value: Value = props.input.value;

  // Use reducer?
  const [showModal, setShowModal] = useState<ModalProps>({ isOpen: false });
  const closeModal = () =>
    setShowModal((prevState) => ({ ...prevState, isOpen: false }));
  // Hack to track if the field was really touched, because per design it always touches the field to sync the state
  // Initialize with the form touched state to also deal with remounting this component, after having changed the field
  const [showError, setShowError] = useState(props.meta.touched);
  // Hack to also add jobs by clicking instead of drag events (pass info from sibling JobGroupEditorRepository to EditorPane
  // Is there a better way? Simulating a dropEvent does not seem possible
  const [jobToAdd, setJobToAdd] = useState<JobType>();

  return (
    <div className={'JobGroupEditor'}>
      <div className={'JobGroupEditor--error'}>
        <InputError error={props.meta.error} touched={showError} />
      </div>
      <EditorPane
        jobs={value.jobs || []}
        jobGroupTopology={value.jobGroupTopology || []}
        augurNames={props.augurNames}
        datapoolNames={props.datapoolNames}
        codeCapsuleNames={props.codeCapsuleNames}
        input={props.input}
        meta={props.meta}
        setShowError={setShowError}
        setShowModal={setShowModal}
        closeModal={closeModal}
        jobToAdd={jobToAdd}
      />
      <Modals
        {...showModal}
        codeCapsulesPerHabitatCode={props.codeCapsulesPerHabitatCode}
        codeCapsulesPerCode={props.codeCapsulesPerCode}
      />
      <div className={'JobGroupEditor--expand-collapse'}>
        <GenericCollapsibleContainer
          title={undefined}
          initialCollapsed={false}
          renderHeader={(title, collapsed) => (
            <FlapButton
              collapsed={collapsed}
              style={{ zIndex: 10 }}
              borderColor={'white'}
            />
          )}
          renderBody={() => (
            <div className={'JobGroupEditor--expand-collapse-body'}>
              <JobGroupEditorRepository
                summary={props.summary}
                modules={props.modules}
                codeCapsulesPerHabitatCode={props.codeCapsulesPerHabitatCode}
                isAdmin={props.isAdmin}
                setJobToAdd={setJobToAdd}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};
