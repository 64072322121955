import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import TargetFunction from './TargetFunction';
import { fieldTargetFunction } from './form';
import { ModelSettingsProps } from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    augur: { datapoolCode, habitatCode },
    settings: { targetFunction },
  } = ownProps;

  return {
    initialValues: {
      [fieldTargetFunction]: {
        summandGroups: targetFunction?.summandGroups,
        minimizeOrMaximize: targetFunction?.minimizeOrMaximize,
      },
    },
    habitatCode,
    datapoolCode,
  };
}

export default connect(mapStateToProps)(TargetFunction);
