export const formName = 'user-details-attributes';

export const form = {
  form: formName,
};

// Memory allows the following suffixes: K -> Kilobytes M -> Megabytes G -> Gigabytes T -> Terabytes
// CPU are floats but passed as string
// TODO somehow set these as initialValues when they are added. Optionally also parameterize them from helm
export const editableAttributes = {
  memory_request: '1G',
  cpu_request: '1.0',
  memory_limit: '1G',
  cpu_limit: '1.0',
};

/** Flatten the attribute data as returned from the api/keycloak (each attribute contains a list) */
export function dataToForm(data: { [attr: string]: string[] }): {
  [attr: string]: string;
} {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value[0]])
  );
}
