import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputSlider from 'react-input-slider';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';

/**
 * Rounds the number according to the step size.
 * The problem is, that for example for step size 0.1, the float precision leads to values like 6.6000000000000005
 * "Cutting" according to the step size removes this problem.
 * @param number
 * @param lowerBound
 * @param upperBound
 * @param stepSize
 */
function roundToStepSize(number, lowerBound, upperBound, stepSize) {
  if (!number || !stepSize || !lowerBound || !upperBound) return number;
  const amountDecimals = (x) => {
    const isFloat = x.toString().includes('.');
    if (!isFloat) {
      // The number has no decimal places
      return 0;
    } else {
      // Determine the amount of decimal places and "cut" the float
      return x.toString().length - 1 - x.toString().indexOf('.');
    }
  };

  // Calculate the maximum amount of decimal places of the stepSize, lower bound and upper bound
  // Doing so for the stepSize alone wouldn't be enough, since for example lowerBound = 0.5 stepSize = 1 upperBound = 2.5 would otherwise give the values [0,1,2] instead of [0.5, 1.5, 2.5]
  const maxDecimals = Math.max(
    amountDecimals(lowerBound),
    amountDecimals(stepSize),
    amountDecimals(upperBound)
  );
  return number.toFixed(maxDecimals);
}

export default class NumericSlider extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    const lowerBoundField = element.settings
      ? element.settings.lowerBound
      : undefined;
    const upperBoundField = element.settings
      ? element.settings.upperBound
      : undefined;
    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;
    const stepSizeField = element.settings
      ? element.settings.stepSize
      : undefined;

    const lowerBound = parseFloat(
      getValueFromSettingsField(lowerBoundField, cell.as_variables) || 0.0
    );
    const upperBound = parseFloat(
      getValueFromSettingsField(upperBoundField, cell.as_variables) || 1.0
    );
    const stepSize = parseFloat(
      getValueFromSettingsField(stepSizeField, cell.as_variables) || 0.1
    );
    const defaultValue = parseFloat(
      getValueFromSettingsField(defaultValueField, cell.as_variables)
    );

    const valueOrDefaultOrLowerBound = () => {
      if (element.data && element.data.number != null)
        return parseFloat(element.data.number);
      else if (defaultValue) return defaultValue;
      else return lowerBound;
    };

    return (
      <div className={'inner-input-element numeric-slider-element'}>
        <div className={'value-preview'}>
          <span>{valueOrDefaultOrLowerBound()}</span>
        </div>
        <div className={'numeric-slider'}>
          <InputSlider
            xmin={lowerBound}
            xmax={upperBound + 0.00000001}
            xstep={stepSize}
            x={valueOrDefaultOrLowerBound()}
            onChange={({ x }) =>
              updateDataOfInputElement(path, cellId, element, {
                number: roundToStepSize(x, lowerBound, upperBound, stepSize),
              })
            }
            axis='x'
            styles={{
              active: { backgroundColor: '#224E90' },
            }}
          />
        </div>
      </div>
    );
  }
}

NumericSlider.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
