import { connect, ConnectedProps } from 'react-redux';
import TableSelection, { Props } from './TableSelection';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  fieldPredictionTable,
  FieldPredictionTableValues,
  fieldTrainingTable,
  FieldTrainingTableValues,
  form,
  formName,
  FormValues,
} from './tableSelection.form';
import { fetchDataSources } from '../../../../../../../../../redux/modules/data.dataSources.module';
import {
  fetchCassandraTables,
  fetchCassandraTableSample,
} from '../../../../../../../../../redux/modules/data.cassandra.module';
import { RootState } from '../../../../../../../../../store/store';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

const valueSelector = formValueSelector(formName);

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    augur: { datapool },
    settings: {
      trainingTable: tT,
      primaryKeyColumns,
      labelColumn,
      positiveLabelValue,
      negativeLabelValue,
      scoringTable: sT,
    },
  } = ownProps;

  const trainingTable: FieldTrainingTableValues =
    valueSelector(state, fieldTrainingTable) || {};
  const scoringTable: FieldPredictionTableValues =
    valueSelector(state, fieldPredictionTable) || {};

  return {
    datapool,
    initialValues: {
      [fieldTrainingTable]: {
        tableName: tT,
        primaryKeyColumns: primaryKeyColumns || [],
        labelColumn: labelColumn,
        positiveLabelValue: positiveLabelValue,
        negativeLabelValue: negativeLabelValue,
      },
      [fieldPredictionTable]: {
        tableName: sT,
      },
    },
    trainingTableName: trainingTable.tableName,
    scoringTableName: scoringTable.tableName,
  };
}

export const mapDispatchToProps: {
  /** Fetch all available Data Sources */
  fetchDataSources: () => void;
  /**  Fetch all Cassandra tables for a given Keyspace */
  fetchCassandraTables: (dsCode: string, keyspaceName: string) => void;
  /** Fetch a sample of the selected table (only required for the column names) */
  fetchCassandraTableSample: (
    dsCode: string,
    keyspaceName: string,
    tableName: string
  ) => void;
} = {
  fetchDataSources,
  fetchCassandraTables,
  fetchCassandraTableSample,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
// @ts-ignore
export default connector(reduxForm<FormValues, Props>(form)(TableSelection));
