import React, { Component, Fragment } from 'react';
import Metadata from './Metadata';
import {
  FiPlus,
  FiPlay,
  FiSave,
  FiRotateCw,
  FiFastForward,
  FiLoader,
  FiScissors,
  FiCopy,
  FiClipboard,
  FiTrash2,
  FiArrowDown,
  FiArrowUp,
  FiStopCircle,
  FiSkipForward,
} from 'react-icons/fi';
import {
  notificationCopy,
  notificationCut,
} from '../../../../../../redux/workbench/notifications/notifications';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import {
  AltaSigmaCell,
  AltaSigmaNotebookFormat,
  Session,
} from '../../../../../../store/workbench/state.types';
import { getAvailableCells } from '../notebook-cells/NotebookCellManager';
import { CProps } from './ButtonBar.container';
import { Link } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../../workbench/common/workbenchRoutes';

export type ParentRepoType = {
  repoCode?: string;
  /** Type of the repository: plain|app|code-capsule|archetype */
  repoType?: string;
  codeCapsuleCode?: string;
  archetypeCode?: string;
  appCode?: string;
};

type Props = {
  name: string;
  path: string;
  content: AltaSigmaNotebookFormat;
  selectedCells: string[];
  session?: Session;
  unsavedChanges?: boolean;
  selectCells: (path: string, cellIds: string[]) => void;
  executeCells: (path: string, sessionId: string, cellId: string[]) => void;
  parentRepository?: ParentRepoType;
  paneId: string;
  selectedCellType?: string;
  selectedCellIndex?: number;
  kernelId?: string;
  clipboard?: { type: string; data: object };
};

type OptionType = { label: string; value: string };

export const isAppMainNotebook = (
  name: string,
  kernelspecName: string,
  parentRepository: ParentRepoType
) => {
  // Condition 1: The notebook must be called 'main.ipynb'
  if (name !== 'main.ipynb') return false;

  // Condition 2: The kernel must be Python
  if (kernelspecName !== 'python3') return false;

  // Condition 3: The notebook must belong to a repository of type app
  return parentRepository?.repoType === 'app';
};

export default class ButtonBar extends Component<Props & CProps> {
  renderAppButtonsIfRequired() {
    const { name, path, content, showAppArrangeModal, parentRepository } =
      this.props;

    if (
      !isAppMainNotebook(
        name,
        content?.metadata?.kernelspec?.name,
        parentRepository
      )
    ) {
      return null;
    } else {
      return (
        <div
          className={'workbench-button label-button label-button-app'}
          onClick={() => showAppArrangeModal(path)}
        >
          <span>Arrange App</span>
        </div>
      );
    }
  }

  renderCodeCapsuleButtonsIfRequired() {
    const { name, content, parentRepository } = this.props;

    // Condition 1: The notebook must be called 'main.ipynb'
    const isNamedMain = name === 'main.ipynb';
    if (!isNamedMain) return;

    // Condition 2: The kernel must by Python
    const isPython =
      content.metadata.kernelspec &&
      content.metadata.kernelspec.name === 'python3';
    if (!isPython) return;

    // Condition 3: The notebook must belong to a repository of type app
    const belongsToCodeCapsuleRepository =
      parentRepository && parentRepository.repoType === 'code-capsule';
    if (!belongsToCodeCapsuleRepository) return;

    return <Fragment>{/** TODO this may no longer be needed */}</Fragment>;
  }

  renderArchetypeButtonsIfRequired() {
    const {
      name,
      path,
      content,
      showConfigureArchetypeModal,
      parentRepository,
    } = this.props;

    // Condition 1: The notebook must be called 'main.ipynb'
    const isNamedRealtimePrediction = name === 'realtime_prediction.ipynb';
    if (!isNamedRealtimePrediction) return;

    // Condition 2: The kernel must by Python
    const isPython =
      content.metadata.kernelspec &&
      content.metadata.kernelspec.name === 'python3';
    if (!isPython) return;

    // Condition 3: The notebook must belong to a repository of type app
    const belongsToCodeCapsuleRepository =
      parentRepository && parentRepository.repoType === 'archetype';
    if (!belongsToCodeCapsuleRepository) return;

    return (
      <Fragment>
        <div
          className={'workbench-button label-button label-button-archetype'}
          onClick={() => showConfigureArchetypeModal(path)}
        >
          <span>Configure Archetype</span>
        </div>
      </Fragment>
    );
  }

  getIndexOfFirstSelectedCell() {
    let index = this.props.selectedCellIndex;
    for (let i = 0; i < this.props.selectedCells.length; i++) {
      const selectedCellsKey = this.props.selectedCells[i];
      const foundPosition = this.props.content.cells.findIndex(
        (c) => c.id === selectedCellsKey.toString()
      );
      if (foundPosition < index) index = foundPosition;
    }
    return index;
  }

  getIndexOfLastSelectedCell() {
    let index = this.props.selectedCellIndex;
    for (let i = 0; i < this.props.selectedCells.length; i++) {
      const selectedCellsKey = this.props.selectedCells[i];
      const foundPosition = this.props.content.cells.findIndex(
        (c) => c.id === selectedCellsKey.toString()
      );
      if (foundPosition > index) index = foundPosition;
    }
    return index;
  }

  render() {
    const {
      name,
      content,
      session,
      saveNotebook,
      unsavedChanges,
      selectedCellType,
      changeCellType,
      path,
      selectedCells,
      parentRepository,
      showEditNotebook,
    } = this.props;

    const availableCells = getAvailableCells(
      name,
      content?.metadata?.kernelspec?.name,
      parentRepository
    );

    const atLeastOneCellSelected = selectedCells.length > 0;

    return (
      <div className={'workbench-buttons'}>
        <div
          title={'Save Notebook (Cmd+S)'}
          className={`workbench-button${
            unsavedChanges ? ' unsaved-changes' : ''
          }`}
          onClick={() => saveNotebook(this.props.path, this.props.content)}
        >
          <FiSave className={'icon save-icon'} size={'20px'} />
        </div>
        <div
          title={'Execute selected Cells (Shift+Enter)'}
          className={classNames('workbench-button', {
            disabled: !atLeastOneCellSelected,
          })}
          onClick={() =>
            atLeastOneCellSelected &&
            this.props.executeCells(
              this.props.path,
              this.props.session ? this.props.session.id : null,
              this.props.selectedCells
            )
          }
        >
          <FiPlay className={'icon play-icon'} size={'20px'} />
        </div>
        <div
          title={'Add Cell'}
          className={'workbench-button'}
          onClick={() =>
            this.props.addCell(
              this.props.path,
              this.props.selectedCellIndex + 1
            )
          }
        >
          <FiPlus className={'icon add-cell-icon'} size={'20px'} />
        </div>
        <div
          title={'Move Cell up'}
          className={'workbench-button'}
          onClick={() => {
            const index = this.getIndexOfLastSelectedCell();
            this.props.moveCellsUp(
              this.props.path,
              this.props.selectedCells,
              index
            );
          }}
        >
          <FiArrowUp className={'icon add-cell-icon'} size={'20px'} />
        </div>
        <div
          title={'Move Cell down'}
          className={'workbench-button'}
          onClick={() => {
            const index = this.getIndexOfFirstSelectedCell();
            this.props.moveCellsDown(
              this.props.path,
              this.props.selectedCells,
              index
            );
          }}
        >
          <FiArrowDown className={'icon add-cell-icon'} size={'20px'} />
        </div>
        <div
          title={'Cut'}
          className={'workbench-button'}
          onClick={() => {
            if (!this.props.content.cells) return;
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;

            // Copy cells to clipboard
            const cellArr = this.props.content.cells.filter(
              (c) => selectedCells && selectedCells.includes(c.id)
            );
            this.props.copyCellsToClipboard(cellArr);

            // Select next cell
            const cellId = selectedCells[selectedCells.length - 1];
            const cells = this.props.content.cells;
            const index = cells.findIndex((c) => c.id === cellId);
            if (index < cells.length - 1) {
              // There is a next cell -> select it
              const nextId = cells[index + 1].id;
              this.props.selectCells(this.props.path, [nextId]);
            } else {
              this.props.selectCells(this.props.path, []);
            }

            // Delete cells
            this.props.deleteCells(this.props.path, this.props.selectedCells);
            this.props.infoNotification(notificationCut('cells'));
          }}
        >
          <FiScissors className={'icon cut-icon'} size={'20px'} />
        </div>
        <div
          title={'Copy'}
          className={'workbench-button'}
          onClick={() => {
            if (!this.props.content.cells) return;
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;
            const cellArr = this.props.content.cells.filter(
              (c) => selectedCells && selectedCells.includes(c.id)
            );
            this.props.copyCellsToClipboard(cellArr);
            this.props.infoNotification(notificationCopy('cells'));
          }}
        >
          <FiCopy className={'icon copy-icon'} size={'20px'} />
        </div>
        <div
          title={'Paste'}
          className={`workbench-button${
            !this.props.clipboard || this.props.clipboard.type !== 'cells'
              ? ' disabled'
              : ''
          }`}
          onClick={() => {
            // Check whether there are cells in the clipboard, if not: cancel.
            if (
              !this.props.clipboard ||
              this.props.clipboard.type !== 'cells'
            ) {
              return;
            }

            const selectedCells = this.props.selectedCells;
            let cellIndex;
            if (!selectedCells || selectedCells.length === 0) {
              // No cells selected -> Insert at the end
              cellIndex = this.props.content.cells.length;
            } else {
              const cellId = selectedCells[selectedCells.length - 1];
              cellIndex = this.props.content.cells.findIndex(
                (c) => c.id === cellId
              );
            }
            const data = this.props.clipboard.data;
            this.props.pasteCellsFromClipboard(
              this.props.path,
              data,
              cellIndex + 1
            );
          }}
        >
          <FiClipboard className={'icon paste-icon'} size={'20px'} />
        </div>
        <div
          title={'Delete'}
          className={'workbench-button'}
          onClick={() => {
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;
            // Select next cell
            const cellId = selectedCells[selectedCells.length - 1];
            const cells = this.props.content.cells;
            const index = cells.findIndex((c) => c.id === cellId);
            if (index < cells.length - 1) {
              // There is a next cell -> select it
              const nextId = cells[index + 1].id;
              this.props.selectCells(this.props.path, [nextId]);
            } else {
              this.props.selectCells(this.props.path, []);
            }

            // Delete Cell
            this.props.deleteCells(this.props.path, selectedCells);
          }}
        >
          <FiTrash2 className={'icon delete-icon'} size={'20px'} />
        </div>
        <div className={'dropdown cell-type'} title={'Change Cell type'}>
          <DropdownSelectInput
            options={availableCells}
            value={availableCells.find((o) => o.value === selectedCellType)}
            onChange={(option: OptionType) =>
              changeCellType(path, selectedCells, option.value)
            }
            appearance={'small'}
          />
        </div>
        <div
          title={'Execute all Cells'}
          className={'workbench-button'}
          onClick={() => {
            this.props.clearAllOutputs(this.props.path);
            this.props.executeCells(
              this.props.path,
              this.props.session ? this.props.session.id : null,
              this.props.content.cells /* .filter(c => c.source !== '')*/
                .map((c) => c.id)
            );
          }}
        >
          <FiFastForward className={'icon run-all-icon'} size={'20px'} />
        </div>
        <div
          title={'Execute up until here'}
          className={classNames('workbench-button', {
            disabled: !atLeastOneCellSelected,
          })}
          onClick={() => {
            if (!selectedCells || selectedCells.length === 0) return;
            const cells = this.props.content.cells;
            const index = this.getIndexOfLastSelectedCell();
            const cellsToExecute = [];
            for (let j = 0; j <= index; j++) {
              const nextId = cells[j].id;
              cellsToExecute.push(cells[j]);
            }
            this.props.executeCells(
              this.props.path,
              this.props.session ? this.props.session.id : null,
              cellsToExecute /* .filter(c => c.source !== '')*/
                .map((c) => c.id)
            );
          }}
        >
          <FiSkipForward
            className={'icon run-here-icon'}
            size={'20px'}
            style={{ transform: 'rotate(90deg)' }}
          />
        </div>
        <div
          title={'Clear all Outputs'}
          className={'workbench-button'}
          onClick={() => this.props.clearAllOutputs(this.props.path)}
        >
          <FiLoader className={'icon clear-all-icon'} size={'20px'} />
        </div>
        <div className={'workbench-button-separator'} />
        {this.renderAppButtonsIfRequired()}
        {this.renderCodeCapsuleButtonsIfRequired()}
        {this.renderArchetypeButtonsIfRequired()}
        <div
          title={'Interrupt Kernel'}
          className={'workbench-button'}
          onClick={() =>
            this.props.interruptKernel(
              this.props.kernelId,
              this.props.session?.id,
              this.props.path
            )
          }
        >
          <FiStopCircle
            className={'icon interrupt-kernel-icon'}
            size={'20px'}
          />
        </div>
        <div
          data-testingIdentifier={'Restart Kernel'}
          title={'Restart Kernel'}
          className={'workbench-button'}
          onClick={() =>
            this.props.restartKernel(this.props.path, this.props.kernelId)
          }
        >
          <FiRotateCw className={'icon restart-kernel-icon'} size={'20px'} />
        </div>
        <Link
          to={`${workbenchRoutes.basePath}${workbenchRoutes.editNotebook}`}
          onClick={(evt) => showEditNotebook(name, path)}
          style={{ cursor: 'pointer' }}
        >
          <Metadata
            kernelspec={content.metadata.kernelspec}
            session={session}
          />
        </Link>
      </div>
    );
  }
}
