import React, { Component } from 'react';
import { dataManagementRoutes } from '../../../routes';
import { fieldKeyspace, fieldAddlInfo, fieldTable, fieldUpload } from './form';
import StepKeyspace from './step-keyspace/StepKeyspace.container';
import StepTable from './step-table/StepTable.container';
import StepUpload from './step-upload/StepUpload.container';
import { v4 as uuidv4 } from 'uuid';
import StepAddlInfo from './step-addl-info/StepAddlInfo';
import { ToBeRefined } from 'common/dist/types/todo_type';
import Wizard from '../../../../pages/wizard/Wizard';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import { formName } from '../form';
import { ButtonProps } from '../../../../atoms/button/Button';
import { InjectedFormProps } from 'redux-form';

type Props = {
  /** Code of the data source */
  dataSourceCode: string;
  uploadFile: (
    file: ToBeRefined,
    infos: ToBeRefined,
    uploadCode: string,
    dataSourceCode: string
  ) => void; // TODO
  formValues: {
    keyspace: {
      name: string;
      strategy: string;
    };
    table: {
      name: string;
      strategy: string;
      concatStrategy: string;
    };
    upload: {
      file: object;
    };
  };
  /** Is the whole form valid? */
  valid: boolean;
  uploading: boolean;
  uploaded: boolean;
  progress: number;
  destroyUploadForm: () => void;
};

export default class WizardUpload extends Component<
  Props & InjectedFormProps,
  {}
> {
  render() {
    const {
      dataSourceCode,
      uploadFile,
      formValues,
      valid,
      uploading,
      uploaded,
      destroyUploadForm,
    } = this.props;
    const uploadButtonDisabled = !valid || uploading || uploaded;

    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo: `${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}`,
        buttonColor: 'white',
        buttonLabelId: 'newAugur.cancel',
        buttonLabelDefault: 'Cancel',
        onClick: () => destroyUploadForm(),
      },
      {
        withLink: false,
        buttonColor: 'green',
        buttonLabelId: 'no-id',
        buttonLabelDefault: 'Upload File',
        disabled: uploadButtonDisabled,
        onClick: () => {
          const uploadCode = uuidv4().replaceAll('-', ''); // Replace the '-' from the uuid for nicer HTTP requests later (the uuid is send in GET requests and needs to be part of the URL)
          !uploadButtonDisabled &&
            formValues.upload &&
            uploadFile(
              formValues.upload.file,
              {
                keyspace: formValues[fieldKeyspace],
                table: formValues[fieldTable],
                addlInfo: formValues[fieldAddlInfo],
              },
              uploadCode, // Generate the uploadCode that will be used to identify the file in the next "ping-pong" requests between the File-Management-API and the Dashboard
              dataSourceCode
            );
        },
      },
    ];

    return (
      <Wizard
        additionalClassname={'wizard-parent-upload'}
        withHeadline={true}
        headlineDefault={'Upload File to Cassandra'}
        buttons={buttons}
      >
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          <GenericFormStep
            fieldName={fieldKeyspace}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select Keyspace',
            }}
            description={{
              id: 'no-id',
              defaultMessage:
                'Select a Keyspace you want to upload your data to.',
            }}
            // @ts-ignore
            component={StepKeyspace}
            num={1}
            renderError={false}
            fieldProps={{
              dataSourceCode,
            }}
          />

          <GenericFormStep
            fieldName={fieldTable}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select Table',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Select a Table you want to upload your data to.',
            }}
            // @ts-ignore
            component={StepTable}
            num={2}
            renderError={false}
            fieldProps={{
              dataSourceCode,
              keyspaceName: ((formValues || {})[fieldKeyspace] || {}).name,
            }}
          />

          <GenericFormStep
            fieldName={fieldUpload}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select File',
            }}
            description={{
              id: 'no-id',
              defaultMessage:
                'Select the File you want to upload. When clicking the Upload button, this file will be uploaded into the AltaSigma system, but you will be asked to commit the write into Cassandra in the next Wizard step.',
            }}
            // @ts-ignore
            component={StepUpload}
            num={3}
            renderError={false}
            fieldProps={{
              dataSourceCode,
            }}
          />

          <GenericFormStep
            fieldName={fieldAddlInfo}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Additional Information',
            }}
            description={{
              id: 'no-id',
              defaultMessage:
                'Please provide some additional information about your file.',
            }}
            component={StepAddlInfo}
            num={4}
            renderError={false}
            fieldProps={{
              dataSourceCode,
            }}
          />
        </form>
      </Wizard>
    );
  }
}
