import { connect } from 'react-redux';
import RunCodeCapsuleModal, { Props } from './RunCodeCapsuleModal';
import {
  Data,
  ErrorType,
  fieldParameters,
  fieldResources,
  fieldVersionNumber,
  form,
  formName,
} from './runCodeCapsule.form';
import { getFormValues, reduxForm } from 'redux-form';
import _ from 'lodash';

export const availableParametersToInitialValues = (
  availableParameters: { id: string; key: string; value: string }[]
) =>
  _.chain(availableParameters)
    .keyBy('key')
    .mapValues((x) => x.value || '')
    .value();

export function mapStateToProps(
  state,
  otherProps: Props & { initialValues: Data }
) {
  const formValues = getFormValues(formName)(state);

  // Derive the initialValues for redux-form
  const { versions, codeCapsuleCode } = otherProps;
  let initialValues: Partial<Data> = otherProps.initialValues;

  if (initialValues === undefined) {
    if (versions.length === 1) {
      const version = versions[0];
      const versionNumber = version.number;
      const { availableParameters, proposedResources } = version;
      initialValues = {
        [fieldParameters]:
          availableParametersToInitialValues(availableParameters),
        [fieldResources]: {
          cpu: proposedResources.cpu,
          memory: proposedResources.memory,
        },
        [fieldVersionNumber]: versionNumber,
      };
    } else {
      const versionNumber = formValues?.[fieldVersionNumber];
      const version = versions.find(
        (version) => version.number === versionNumber?.value
      );
      if (version !== undefined) {
        const { availableParameters, proposedResources } = version;
        initialValues = {
          [fieldParameters]:
            availableParametersToInitialValues(availableParameters),
          [fieldResources]: {
            // Set explicitly to "null" if "undefined" to make sure the value is reset.
            // Case: Switch the selected version - the first version had cpu (or memory) defined, the second one doesn't
            // If not setting the value to "" the field wouldn't be cleared ("null" or "undefined" doesn't work unfortunately ...)
            cpu: proposedResources.cpu || '',
            memory: proposedResources.memory || '',
          },
          [fieldVersionNumber]: versionNumber,
        };
      }
    }
  }

  const codeCapsule = state.codeCapsules?.perCode?.[codeCapsuleCode];

  return {
    initialValues,
    formValues,
    codeCapsule,
  };
}

export default connect(mapStateToProps)(
  // @ts-ignore TODO: How to type components that are wrapped twice: reduxForm(injectIntl(<>))?
  reduxForm<Data, Props, ErrorType>(form)(RunCodeCapsuleModal)
);
