import { apiRequest } from './_tools';

/**
 * Returns the latest (based on the 'timestamp' field of the datapool statistics table) datapool statistics for the
 * given datapoolCode and tableName.
 * @param habitatCode
 * @param datapoolCode
 * @param tableName
 */
export function fetchLatestDatapoolStatistics(
  habitatCode,
  datapoolCode,
  tableName
) {
  return apiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}/statistics/table/${tableName}/latest`
  );
}
