import * as React from 'react';
import { Component } from 'react';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import StepName from './StepName';
import {
  fieldDSType,
  fieldName,
  fieldRole,
  fieldSettings,
  formName,
} from './form';
import { dataManagementRoutes } from '../../routes';
import DataSourceButtons from './step-ds-options/DataSourceButtons';
import StepCassandraSettings, {
  Error as errorCassandraSettings,
  Value as valueCassandraSettings,
} from './StepCassandraSettings';
import StepS3Settings, {
  Error as errorS3Settings,
  Value as valueS3Settings,
} from './StepS3Settings';
import {
  buildDataSourceForAdding,
  buildDataSourceForUpdating,
  ConnectedProps,
} from './AddUpdateDataSource.container';
import StepRole from './StepRole';
import _ from 'lodash';
import Wizard from '../../../pages/wizard/Wizard';
import MainContainer from '../../../pages/main-container/MainContainer';
import { ButtonProps } from '../../../atoms/button/Button';
import { InjectedFormProps } from 'redux-form';

export interface Props {
  valid: boolean;
  update: boolean;
  dataSourceCode?: string;
}

export default class AddUpdateDataSource extends Component<
  Props & ConnectedProps & InjectedFormProps
> {
  componentDidMount() {
    const { fetchDataSources } = this.props;
    fetchDataSources(); // Not a child of DataManagement, so we have to fetch it ourselves
  }

  /**
   * After the settings are fetched the form is reinitialized so the touched fields are overwritten again
   * @param prevProps
   */
  componentDidUpdate(prevProps: Props & ConnectedProps & InjectedFormProps) {
    const {
      update,
      initialValues,
      touch,
      fetchDataSourceSettings,
      dataSourceCode,
      dataSources,
    } = this.props;
    if (update && dataSources.loaded && prevProps.dataSources.loading) {
      fetchDataSourceSettings(dataSourceCode);
    }
    if (update && !_.isEqual(initialValues, prevProps.initialValues)) {
      touch(fieldName, fieldDSType, fieldSettings);
    }
  }

  componentWillUnmount() {
    const { update, destroyUploadForm } = this.props;
    // Prevents updating an existing ds, going back and adding a ds, where the form will be filled with the existing ds
    if (update) {
      destroyUploadForm();
    }
  }

  render() {
    const {
      formValues,
      valid,
      destroyUploadForm,
      addDataSource,
      update,
      dataSourceCode,
      updateDataSource,
    } = this.props;
    /* <div className="content container--data"> */

    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo: `${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}`,
        buttonColor: 'white',
        buttonLabelId: 'newAugur.cancel',
        buttonLabelDefault: 'Cancel',
        onClick: () => destroyUploadForm(),
      },
      {
        withLink: true,
        linkTo: `${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}`,
        buttonColor: 'green',
        buttonLabelId: 'newAugur.finish',
        buttonLabelDefault: 'Finish',
        disabled: !valid,
        onClick: () => {
          if (update) {
            const ds = buildDataSourceForUpdating(formValues, dataSourceCode);
            // @ts-ignore
            updateDataSource(dataSourceCode, ds);
          } else {
            const ds = buildDataSourceForAdding(formValues);
            addDataSource(ds);
          }
          destroyUploadForm();
        },
      },
    ];

    return (
      <MainContainer>
        <Wizard
          // @ts-ignore
          id={'WizardAddUpdateDatasourceContainer'}
          withHeadline={true}
          headlineId={'no-id'}
          headlineDefault={`${update ? 'Update' : 'Add'} DataSource`}
          buttons={buttons}
          additionalClassname={'AddUpdateDataSource'}
        >
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            <GenericFormStep<string, string>
              formName={formName}
              fieldName={fieldName}
              title={{
                defaultMessage: 'Specify Name',
              }}
              description={{
                defaultMessage:
                  'Specify a convenient name to give to the data source.',
              }}
              num={1}
              component={StepName}
            />

            <GenericFormStep<string, string>
              formName={formName}
              fieldName={fieldDSType}
              title={{
                defaultMessage: 'Select Data Source Type',
              }}
              description={{
                defaultMessage: 'Select the type of the data source.',
              }}
              num={2}
              component={DataSourceButtons}
              fieldProps={{
                disabled: update,
              }}
            />

            {formValues?.[fieldDSType] && (
              <GenericFormStep<string, string>
                formName={formName}
                fieldName={fieldRole}
                title={{
                  defaultMessage: 'Select Role (Optional)',
                }}
                description={{
                  defaultMessage: 'Select a special role for your Data Source.',
                }}
                num={3}
                component={StepRole}
                fieldProps={{
                  selectedDsType: formValues?.[fieldDSType],
                }}
              />
            )}

            {formValues?.[fieldDSType] === 'cassandra' && (
              <GenericFormStep<valueCassandraSettings, errorCassandraSettings>
                formName={formName}
                fieldName={fieldSettings}
                title={{
                  defaultMessage: 'Specify Cassandra Settings',
                }}
                description={{
                  defaultMessage: 'Specify the settings for the data source.',
                }}
                num={4}
                component={StepCassandraSettings}
                renderError={false}
              />
            )}
            {formValues?.[fieldDSType] === 's3' && (
              <GenericFormStep<valueS3Settings, errorS3Settings>
                formName={formName}
                fieldName={fieldSettings}
                title={{
                  defaultMessage: 'Specify S3 Settings',
                }}
                description={{
                  defaultMessage: 'Specify the settings for the data source.',
                }}
                num={4}
                component={StepS3Settings}
                renderError={false}
              />
            )}
          </form>
        </Wizard>
      </MainContainer>
    );
  }
}
