import React, { FC } from 'react';
import ShapChartComponent from '../../../../../../../components/organisms/shap-chart/ShapChart';
import GraphWait from '../../../../../../details/GraphWait';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';
import { Feature, ShapValueType } from 'common/dist/types/mlModel';

type Props = {
  shapValues: ShapValueType[];
  features: Feature[];
};

const AMOUNT_TOP_FEATURES = 10;

const ShapChart: FC<Props> = ({ shapValues, features }) => {
  if (!features || features.length === 0) {
    return (
      <GraphWait jobType={'learning'}>
        <FormattedMessage {...augurDetailsMsgs.msgInfluencersNotAvailable} />
      </GraphWait>
    );
  }

  const nonDiscardedFeatures = (features || []).filter(
    (f) => !f.discarded || f.discarded.length === 0
  );
  const topFeatures = nonDiscardedFeatures
    .sort((a, b) => (a.importance > b.importance ? -1 : 1))
    .map((f) => f.feature)
    .slice(0, AMOUNT_TOP_FEATURES);

  const filteredShapValues = shapValues.filter((s) =>
    topFeatures.includes(s.feature)
  );

  return (
    <div className={styles.shapChart}>
      <ShapChartComponent
        // @ts-ignore
        data={filteredShapValues}
        ordering={topFeatures}
      />
      {nonDiscardedFeatures.length > AMOUNT_TOP_FEATURES && (
        <div className={styles.shapInfo}>
          <span>Showing only the top {AMOUNT_TOP_FEATURES} features.</span>
        </div>
      )}
    </div>
  );
};

export default ShapChart;
