import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldAugurName,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newAugur/defaultWizard/default.form';
import { validateDatapool } from '../../../../../organisms/modelManagement/newAugurWizard/datapool/validate';
import { validateArchetype } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/validate';
import { Props } from './OptimizationNewAugurWizard';
import { ValueType as CustomerTableValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/OptCustomerTable';
import { validateCustomerTable } from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/validate';
import { ConstraintsValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/OptConstraints';
import { ContactPoliciesValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optContactPolicies/OptContactPolicies';
import { TargetFunctionValueType } from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/OptTargetFunction';
import { validateConstraints } from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/validate';
import { validateContactPolicies } from '../../../../../organisms/modelManagement/newAugurWizard/optContactPolicies/validate';
import { validateTargetFunction } from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/validate';
//Global const to handle validation
export const _globalValidationError = '_globalValidationError';

// --- Field names
export const fieldDatapool = 'datapool';
export const fieldArchetype = 'archetype';
export const fieldCustomerTable = 'customerTable';
export const fieldConstraints = 'constraints';
export const fieldContactPolicy = 'contactPolicy';
export const fieldTargetFunction = 'targetFunction';

// --- Type of the form values
export type OptimizationFormValues = DefaultFormValues & {
  [fieldArchetype]: {
    /** Code of the selected archetype */
    code?: string;
    /** Standard or custom archetype?  */
    origin?: 'stock' | 'custom';
    /** Selected version number */
    versionNumber?: string;
  };
  [fieldCustomerTable]: CustomerTableValueType;
  [fieldConstraints]: ConstraintsValueType;
  [fieldContactPolicy]: ContactPoliciesValueType;
  [fieldTargetFunction]: TargetFunctionValueType;
};

// --- Sync validation
export const optimizationValidate = (values: OptimizationFormValues) => {
  // I'm not really sure why this is nested down into the '_reduxForm' key and not kept at the top level of the object ...
  // const reduxFormProps = props?.['_reduxForm'];
  // const { pipelineTuningSchemas, parameterTuningSchema } = (reduxFormProps || {});
  const errors = defaultValidate(values) || {};
  errors[fieldDatapool] = validateDatapool(values[fieldDatapool]);
  errors[fieldArchetype] = validateArchetype(values[fieldArchetype]);

  errors[fieldCustomerTable] = validateCustomerTable(
    values[fieldCustomerTable]
  );

  // @ts-ignore
  errors[fieldConstraints] = validateConstraints(values[fieldConstraints]);
  // @ts-ignore
  errors[fieldContactPolicy] = validateContactPolicies(
    values[fieldContactPolicy]
  );
  // @ts-ignore
  errors[fieldTargetFunction] = validateTargetFunction(
    values[fieldTargetFunction]
  );

  return errors;
};

// --- Async validation
export function getOptimizationMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

// --- Warn (used for the "sub forms" for adding channels, propensities, etc.)
export const optimizationWarn = (
  values: OptimizationFormValues,
  props: { _reduxForm: Props }
) => {
  const warnings = {};
  // warnings[fieldConstraints] = warnConstraints(values[fieldConstraints]); // TODO!!
  warnings[fieldConstraints] = validateConstraints(values[fieldConstraints]);
  warnings[fieldContactPolicy] = validateContactPolicies(
    values[fieldContactPolicy]
  );
  warnings[fieldTargetFunction] = validateTargetFunction(
    values[fieldTargetFunction]
  );

  return warnings;
};

export const optimizationAsyncBlurFields = [...defaultAsyncBlurFields];
export const optimizationAsyncChangeFields = [...defaultAsyncChangeFields];

// --- Submit summary
export type OptimizationSubmitSummary = {
  /** Habitat code of the Augur */
  habitatCode: string;
  /** Module type of the Augur */
  augurType: string;
  /** Datapool code of the augur */
  datapoolCode: string;
  /** Code of the selected archetype */
  archetypeCode: string;
  /** Version number of the selected archetype */
  archetypeVersionNumber: string;
  /** Name of the augur */
  augurName: string;

  settings: {
    // CustomerTableValueType, but with some mandatory fields
    customerTable: Required<
      Pick<
        CustomerTableValueType,
        'tableName' | 'customerIdColumn' | 'valueIdColumn' | 'valueColumn'
      >
    > &
      CustomerTableValueType;
    constraints: ConstraintsValueType;
    contactPolicies: ContactPoliciesValueType;
    targetFunction: {
      minimizeOrMaximize: TargetFunctionValueType['minimizeOrMaximize'];
      summandGroups: TargetFunctionValueType['summandGroups'];
    };
  };
};

export const getOptimizationSubmitSummary = (
  formValues: OptimizationFormValues,
  habitatCode: string
): OptimizationSubmitSummary => {
  const archetypeCode = (formValues[fieldArchetype] || {}).code;
  const archetypeVersionNumber = (formValues[fieldArchetype] || {})
    .versionNumber;

  const submitSummary: OptimizationSubmitSummary = {
    // Habitat Code
    habitatCode,

    // Module type
    augurType: formValues[fieldModuleType],

    // Datpool
    datapoolCode: formValues[fieldDatapool]?.code,

    // Archetype
    archetypeCode,
    archetypeVersionNumber,

    // Augur name
    augurName: formValues[fieldAugurName],

    // --- Settings
    settings: {
      // @ts-ignore
      customerTable: formValues[fieldCustomerTable],
      constraints: formValues[fieldConstraints],
      contactPolicies: formValues[fieldContactPolicy],
      targetFunction: {
        minimizeOrMaximize: formValues[fieldTargetFunction]?.minimizeOrMaximize,
        summandGroups: formValues[fieldTargetFunction]?.summandGroups,
      },
    },
  };

  return submitSummary;
};
