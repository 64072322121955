import { apiRequest } from './_tools';

export function getArchetypeVersions(archetypeCode) {
  // TODO is the hard coded limit of 100 a good idea?
  return apiRequest(
    `/api/archetypes/${archetypeCode}/versions?offset=0&limit=100`
  );
}

export function getArchetypeVersionsPaged(archetypeCode, offset, limit) {
  return apiRequest(
    `/api/archetypes/${archetypeCode}/versions?offset=${offset}&limit=${limit}`
  );
}
