import React, { FC, useEffect, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import './styles.scss';
import { SettingsBlock } from '../../settings-block/SettingsBlock';
import Button from '../../../atoms/button/Button';
import { Locale } from 'common/dist/types/locales';

export type Props = {
  /** Currently saved language */
  userLocale: Locale;
  intlLocale: Locale;
  /** Select a new language (Save to user and load new language) */
  saveLanguage: (locale: Locale) => void;
};

const LOCALES: Locale[] = ['en'];

type OptionType = { value: Locale; label: string };

const Language: FC<Props & WrappedComponentProps> = ({
  intl,
  userLocale,
  saveLanguage,
  intlLocale,
}) => {
  const [language, setLanguage] = useState(intlLocale);
  // Needed because initialization does not wait for user/userLocale to be set in redux
  useEffect(() => {
    setLanguage(userLocale);
  }, [userLocale]);
  const isLanguageChanged = language !== intlLocale;

  const localesLocalized: OptionType[] = LOCALES.map((locale) => ({
    label: intl.formatMessage({ id: `locales.${locale}` }),
    value: locale,
  }));

  return (
    <div className={'Language'}>
      <SettingsBlock
        title={{
          id: 'account.language.title',
          defaultMessage: 'Language',
        }}
        color={'white'}
        settingsElements={[
          {
            title: {
              id: 'account.language.change_language.title',
              defaultMessage: 'Change Language',
            },
            description: {
              id: 'account.language.change_language.description_one',
              defaultMessage:
                'Here you can change your dashboard into your preferred language',
            },
            Component: (
              <>
                <DropdownSelectInput
                  touched={true}
                  error={undefined}
                  valid={true}
                  disabled={false}
                  id={'language-select'}
                  name={'language-select'}
                  label={{
                    id: 'account.language.your-language',
                    defaultMessage: 'Your Language',
                  }}
                  placeholder={{
                    id: 'todo',
                    defaultMessage: 'Language',
                  }}
                  value={localesLocalized.find((l) => l.value === language)}
                  onChange={(option: OptionType) => setLanguage(option.value)}
                  onBlur={() => setLanguage(language)}
                  clearable={false}
                  options={localesLocalized}
                />
                <div className={'buttons'}>
                  <Button
                    buttonLabelDefault={'Cancel'}
                    buttonColor={'white'}
                    withLink={false}
                    disabled={!isLanguageChanged}
                    onClick={() => setLanguage(userLocale)}
                  />
                  <Button
                    buttonLabelDefault={'Save'}
                    buttonColor={'green'}
                    withLink={false}
                    disabled={!isLanguageChanged}
                    onClick={() => saveLanguage(language)}
                  />
                </div>
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default injectIntl(Language);
