import React, { ComponentType, FC, useMemo } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import IconButtonBar, {
  IconButtonBarButton,
} from '../icon-button-bar/IconButtonBar';
import SelectedDirs from '../../../molecules/selected-dirs/SelectedDirs';
import DiskUsage from '../../../molecules/disk-usage/DiskUsage.container';
import ContentList from '../content-list/ContentList';
import { mapObject, Result } from '../../../../utils';
import LoadError from '../../../workbench/part-left/LoadError';
import RepositoryWarning from '../../../workbench/part-left/content/RepositoryWarning.container';
import { DeprecatedRootState } from '../../../../store/state.type';
import { Dispatch } from 'redux-act';
import ContextMenu from '../context-menu/ContextMenu.container';
import { ContextMenuEntry } from '../context-menu/ContextMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/store';
import { jumpToDirectory } from '../../../../redux/workbench/modules/content.module';

const GenericFileBrowser: FC<Props> = (props) => {
  const {
    buttons,
    path,
    content,
    showDiskSpace,
    dnd,
    icons,
    showFileSizes,
    contextMenuEntries,
    width,
    onClickListeners,
  } = props;

  /**
   * Create a mapping from type -> ContextMenu for the specific file browser depending on the passed contextMenuEntries props
   * If the specific file browser contextMenuEntries looks like this, then for the first type a ContextMenu with two entries
   * will be returned from the mapping and for the second no ContextMenu (=undefined)
   * [ContentElementType.DIRECTORY_REPOSITORY]: [OpenDir, Delete],
   * [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
   */
  const contextMenus = useMemo(
    () =>
      mapObject<
        ContextMenuEntry[],
        ComponentType<{
          menuId: string;
          name: string;
          path: string;
          type: ContentElementType;
        }>,
        typeof contextMenuEntries
      >(contextMenuEntries, (_, val) => {
        if (val && val.length > 0)
          return ({ menuId, name, path, type }) => (
            <ContextMenu
              menuId={menuId}
              name={name}
              path={path}
              type={type}
              entries={val}
            />
          );
        else return undefined;
      }),
    [contextMenuEntries]
  );
  const dispatch = useAppDispatch();
  const dispatchJumpToDirectory = (dirs: string[]) =>
    dispatch(jumpToDirectory(dirs));
  return (
    <div className={'container-content'}>
      {buttons && buttons.length > 0 && <IconButtonBar buttons={buttons} />}
      {path && (
        <SelectedDirs
          dirs={path.split('/')}
          highlightOnHover
          showBottomBorder
          onDirectoryClick={dispatchJumpToDirectory}
        />
      )}
      {content && content.isErr() && <LoadError message={content.error} />}
      {content && content.isOk() && (
        <RepositoryWarning selectedContent={content.value} />
      )}
      {content && content.isOk() && (
        <ContentList
          //@ts-ignore
          content={content.value.map((ce) => {
            return {
              ...ce,
              ...dnd[ce.type],
              Icon: icons[ce.type],
              size: showFileSizes ? ce.size : undefined,
              ContextMenu: contextMenus[ce.type],
              onClick: onClickListeners ? onClickListeners[ce.type] : undefined,
              width,
            };
          })}
        />
      )}

      {showDiskSpace && <DiskUsage />}
    </div>
  );
};

export default GenericFileBrowser;

export enum ContentElementType {
  FILE_NOTEBOOK,
  /** Python scripts,... */
  FILE_CODE,
  FILE_DEFAULT,
  /** .txt, .csv, ... */
  FILE_TEXT,
  FILE_MARKDOWN,
  FILE_REPOSITORY_INFO,
  DIRECTORY_REPOSITORY,
  DIRECTORY_PLAIN,
  SPECIAL_ONE_DIRECTORY_UP,
  SPECIAL_LAUNCHER,
}

export type ContentElement = {
  /** Name of the file */
  name: string;
  /** Path of the file */
  path: string;
  /** Size of the content element */
  size?: number;
  /** Type of the content element*/
  type: ContentElementType;
  /** Last modify date */
  last_modified?: string;
  /** Created date */
  created?: string;
  /** Is the file writable? false for example if the file is owned by the root user */
  writable?: boolean;
  /** List of contents for type directory */
  content?: ContentElement[] | null;
};

export type OnClickListener = (
  state: DeprecatedRootState,
  dispatch: Dispatch,
  path: string,
  name: string,
  type: ContentElementType,
  history: RouteComponentProps['history'],
  element: Omit<ContentElement, 'content'>
) => void;

export type Props = {
  /** List of button "specs" -> <IconButton /> */
  buttons?: IconButtonBarButton[];
  /** render the active path? */
  path?: string;
  /** render the available disk space in the bottom? */
  showDiskSpace?: boolean;
  /** Show the file sizes for entries */
  showFileSizes?: boolean;

  /** ... */
  content: Result<ContentElement[], string>;
  /** ... */
  onClickListeners: {
    [type in ContentElementType]: OnClickListener;
  };
  /** List of context menu entries per content type -> no entry for a file type = no context menu */
  contextMenuEntries: { [type in ContentElementType]: ContextMenuEntry[] };
  dnd: {
    [type in ContentElementType]: {
      isDraggable?: boolean;
      onDragStart?: (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) => (e: ToBeRefined) => void;
      onDrop?: (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) => (e: ToBeRefined) => void;
      onDragOver?: (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) => (e: ToBeRefined) => void;
      onDragEnter?: (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) => (e: ToBeRefined) => void;
      onDragLeave?: (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) => (e: ToBeRefined) => void;
    };
  };
  icons: {
    [type in ContentElementType]: React.ComponentType<{
      className: string;
      size: string;
    }>;
  };
  width: number; //TODO
};
