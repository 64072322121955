import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { NamesState } from '../../../../../store/names/state.types';
import { Summary } from 'common/dist/types/habitat';
import {
  CodeCapsulesPerCode,
  CodeCapsulesPerHabitatCode,
} from '../../../../../store/codeCapsules/state.types';
import { isAdmin as isAdminSelector } from '../../../../../redux/selectors/user.selector';
import { JobGroupEditor } from '../../../../molecules/job-groups/job-group-editor/JobGroupEditor';
import Button from '../../../../atoms/button/Button';
import styles from './styles.module.scss';
import { LoadableData } from '../../../../../utils';
import { EnrichedModule, Module } from 'common/dist/constants/module_list';

export const JobGroupEditorField: FC<
  {
    onClickSave: () => void;
  } & WrappedFieldProps
> = (props) => {
  const { onClickSave } = props;

  const { augurNames, datapoolNames, codeCapsuleNames } = useSelector<
    RootState,
    NamesState
  >((state) => state.names);
  const summary = useSelector<RootState, Summary>((state) => state.summary);
  const {
    data: modules = [],
    loading,
    loaded,
    error,
  } = useSelector<RootState, LoadableData<EnrichedModule[], unknown>>(
    (state) => state.modules
  );
  const codeCapsulesPerHabitatCode = useSelector<
    RootState,
    CodeCapsulesPerHabitatCode
  >((state) => state.codeCapsules.perHabitatCode);
  const codeCapsulesPerCode = useSelector<RootState, CodeCapsulesPerCode>(
    (state) => state.codeCapsules.perCode
  );
  const isAdmin = useSelector<RootState, boolean>(isAdminSelector);

  return (
    <div className={styles.container}>
      <JobGroupEditor
        summary={summary}
        modules={modules}
        // Pass just the data down
        codeCapsulesPerHabitatCode={Object.fromEntries(
          Object.entries(codeCapsulesPerHabitatCode).map(([key, value]) => [
            [key],
            value.data,
          ])
        )}
        codeCapsulesPerCode={Object.fromEntries(
          Object.entries(codeCapsulesPerCode).map(([key, value]) => [
            [key],
            value.data,
          ])
        )}
        augurNames={augurNames}
        datapoolNames={datapoolNames}
        codeCapsuleNames={codeCapsuleNames}
        input={props.input}
        meta={props.meta}
        isAdmin={isAdmin}
      />
      <div className={styles.buttonContainer}>
        <Button
          buttonLabelDefault={'Save'}
          buttonColor={'blue'}
          withLink={false}
          onClick={onClickSave}
        />
      </div>
    </div>
  );
};

export default JobGroupEditorField;
