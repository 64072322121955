import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { OptimizationModelDetails } from 'common/dist/types/module.optimization';
import OptConstraintExhaustion from '../../../../../organisms/modelManagement/augurDetails/optConstraintExhaustion/OptConstraintExhaustion';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../types';
import qs from 'qs';
import { apiRequest } from '../../../../../../core/api/_tools';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { useAugur } from '../../../../../../core/api/augurs';
import { useActiveModel } from '../../../../../../core/api/mlModels';

type Props = {
  /** Details of the underlying datapool */
  datapoolDetails?: CampaignOptimizationDatapoolType;
  /** Report generated by the optimization learning job */
  modelDetails: OptimizationModelDetails | undefined;
};

const ConstraintExhaustion: FC<
  Props & RouteComponentProps<AugurDetailsRouteParams>
> = ({
  location,
  match: {
    params: { habitatCode, augurCode },
  },
}) => {
  const { data: augur } = useAugur(habitatCode, augurCode);
  const { data: model } = useActiveModel(habitatCode, augurCode);

  const queryParams: AugurDetailsQueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  // TODO quick and dirty, getting the alternative model only locally
  const [modelDetailsForCode, setModelDetailsForCode] = useState<{
    data: { details: OptimizationModelDetails } | undefined;
    error: ApiError;
  }>({
    data: undefined,
    error: undefined,
  });
  useEffect(() => {
    (async () => {
      if (habitatCode && augurCode && queryParams.modelCode) {
        const { response, error } = await apiRequest<{
          details: OptimizationModelDetails;
        }>(
          `/api/habitats/${habitatCode}/augurs/${augurCode}/models/${queryParams.modelCode}`
        );
        setModelDetailsForCode({ data: response, error });
      }
    })();
  }, [setModelDetailsForCode, queryParams.modelCode, habitatCode, augurCode]);
  const modelDetails = queryParams.modelCode
    ? modelDetailsForCode?.data?.details
    : (model?.details as any as OptimizationModelDetails); //FIXME: use polymorphism

  return (
    <div className={styles.constraintExhaustion}>
      <FormattedMessage
        {...moduleOptimizationMessages.msgAugurDetailsConstraintExhaustionTabName}
      >
        {(text) => <span className={styles.headline}>{text}</span>}
      </FormattedMessage>

      <div className={styles.summaryContainer}>
        <div className={styles.row}>
          <span className={styles.key}>Overall Score:</span>
          {modelDetails?.summary?.overallScore && (
            <FormattedNumber
              value={modelDetails?.summary?.overallScore}
              maximumFractionDigits={2}
            />
          )}
        </div>
        <div className={styles.row}>
          <span className={styles.key}>Quantity of assigned values:</span>
          {modelDetails?.summary?.amountAssigned && (
            <FormattedNumber value={modelDetails?.summary?.amountAssigned} />
          )}
        </div>
      </div>

      <div>
        <OptConstraintExhaustion
          constraintExhaustion={modelDetails?.constraintExhaustion}
          datapoolDetails={
            augur?.datapool as any as CampaignOptimizationDatapoolType
          } //FIXME: use polymorphism
        />
      </div>
    </div>
  );
};

export default withRouter(ConstraintExhaustion);
