import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'common/dist/utils/dates';
import BarChart from '../../../../../../../molecules/charts/bar-chart/BarChart';
import classNames from 'classnames';
import { FormattedRelativeTimeConvenient } from '../../../../../../../atoms/formatted-date-time/FormattedDateTime';
import ReactLoading from 'react-loading';
import IndicatorEmpty from '../../../../../../../molecules/indicator-empty/IndicatorEmpty';
import { useLatestPredictionReport } from '../../../../../../../../core/api/reports';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../types';
import _ from 'lodash';

type Props = {
  /** Code of the active model for this augur */
  activeModelCode?: string;
};

const LatestScoring: FC<Props> = ({ activeModelCode }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const {
    data: report,
    error,
    isLoading,
    isError,
  } = useLatestPredictionReport(habitatCode, augurCode, activeModelCode);

  const renderInner = () => {
    if (isError) return renderError();
    else if (isLoading) return renderLoading();
    else if (_.isEmpty(report)) return renderEmpty();
    else return renderLoaded();
  };

  const renderError = () => {
    return (
      <div className={styles.error}>
        <span>{JSON.stringify(error)}</span>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={'#224e90'}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <IndicatorEmpty
        classNameImage={'wait-for-augur-pic'}
        headlineId={'no-id'}
        headlineDefault={'Score Distribution is not available'}
        descriptionId={'no-id'}
        descriptionDefault={
          'The Augur has either not completed its first prediction session or another model has been selected.'
        }
      />
    );
  };

  const renderLoaded = () => {
    const {
      createdAt,
      data: { inputTable, outputTable, predictedValuesDistribution },
      modelCode,
    } = report;
    return (
      <div className={styles.loaded}>
        <div className={styles.latestScoringChart}>
          <BarChart
            height={'200px'}
            width={'100%'}
            data={predictedValuesDistribution.data}
            withBorder
          />
        </div>
        <div className={styles.latestScoringDetails}>
          <div className={styles.detailsLine}>
            <span className={styles.detailsKey}>Run Date:</span>
            <span className={styles.detailsValue}>
              {createdAt &&
                formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss')}
            </span>
            <span
              className={classNames(
                styles.detailsValue,
                styles.detailsValueAgo
              )}
            >
              <FormattedRelativeTimeConvenient date={new Date(createdAt)} />
            </span>
          </div>
          <div className={styles.detailsLine}>
            <span className={styles.detailsKey}>Input Table:</span>
            <span className={styles.detailsValue}>{inputTable}</span>
          </div>
          <div className={styles.detailsLine}>
            <span className={styles.detailsKey}>Output Table:</span>
            <span className={styles.detailsValue}>{outputTable}</span>
          </div>
          <div className={styles.detailsLine}>
            <span className={styles.detailsKey}>Model Code:</span>
            <span className={styles.detailsValue}>{modelCode}</span>
            {activeModelCode === modelCode && (
              <span
                className={classNames(
                  styles.detailsValue,
                  styles.detailsValueActiveModel
                )}
              >
                Active Model for this Augur
              </span>
            )}
            {activeModelCode && activeModelCode !== modelCode && (
              <span
                className={classNames(
                  styles.detailsValue,
                  styles.detailsValueInactiveModel
                )}
              >
                Model is not active for this Augur anymore
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.latestScoringContainer}>
      <div className={styles.scoreDistributionOverviewHeadline}>
        <FormattedMessage
          id={'no-id'}
          defaultMessage={'Latest Prediction Run for active model'}
        />
      </div>

      {renderInner()}
    </div>
  );
};

export default LatestScoring;
