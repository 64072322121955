import { ToBeRefined } from 'common/dist/types/todo_type';
import { v4 as uuidv4 } from 'uuid';
import {
  Pane,
  PaneHierarchyElement,
  PaneHierarchyElementEditor,
} from '../../../../workbench/types';

/**
 * Removes a pane from the state and returns the paneHierarchy and panes fields that can then be handled by a
 * reducer as new parts of the state.
 * @param state
 * @param paneId
 */
export function removePane(
  state: ToBeRefined,
  paneId: string
): { paneHierarchy: ToBeRefined; panes: { [paneId: string]: Pane } } {
  const { paneHierarchy, panes } = state;

  // Step 1: Filter out the pane from the paneHierarchy
  const { paneHierarchyElement: updatedPaneHierarchy, idsToRemove } =
    removePaneRecursively(paneHierarchy, paneId);

  // Step 2: Filter out the pane from the panes
  const updatedPanes = panes;
  idsToRemove.forEach((paneId) => delete updatedPanes[paneId]);

  return {
    paneHierarchy: updatedPaneHierarchy,
    panes: updatedPanes,
  };
}

/**
 * @param pane
 * @param paneId
 */
export function removePaneRecursively(
  pane: PaneHierarchyElement,
  paneId: string
): { paneHierarchyElement: PaneHierarchyElement; idsToRemove: string[] } {
  // 1. If the panes type is editor, simply return it - and no more panes to remove
  if (pane.type === 'editor') {
    return {
      paneHierarchyElement: pane,
      idsToRemove: [],
    };
  }

  // 2. Check whether one of the children is the paneId looked for
  const foundChild = pane.children.find(
    (childPane) => childPane.type === 'editor' && childPane.id === paneId
  );

  // 3. If none of the children is the pane looked for, continue recursively
  if (!foundChild) {
    // This reducer looks horrible. But the idea is to get from the array [{ children, idsToRemove }] to
    // one cumulated view { paneElement: { children, ... }, idsToRemove }
    const reducer = (
      accumulator: { children: PaneHierarchyElement[]; idsToRemove: string[] },
      childValue: {
        paneHierarchyElement: PaneHierarchyElement;
        idsToRemove: string[];
      }
    ) => ({
      children: [
        ...(accumulator.children || []),
        childValue.paneHierarchyElement,
      ],
      idsToRemove: [
        ...(accumulator.idsToRemove || []),
        ...(childValue.idsToRemove || []),
      ],
    });
    const { children, idsToRemove } = pane.children
      .map((childPane) => removePaneRecursively(childPane, paneId))
      .reduce(reducer, { children: [], idsToRemove: [] });

    return {
      paneHierarchyElement: {
        ...pane,
        children,
      },
      idsToRemove,
    };
  }

  // 4. One of the children is the child looked for to be removed
  const amountChildPanes = pane.children.length;

  if (amountChildPanes > 2) {
    // 4.1 If there are > 2 child panes - simply remove the child (no need to "move" the remaining single child up)
    return {
      paneHierarchyElement: {
        ...pane,
        children: pane.children.filter(
          (childPane) => childPane.type === 'panes' || childPane.id !== paneId
        ),
      },
      idsToRemove: [paneId],
    };
  } else {
    // 4.2 If there are == 2 child panes (< 2 can never happen) - make the single remaining child the parent component
    const foundPane = pane.children.find(
      (childPane) => childPane.type === 'panes' || childPane.id !== paneId
    );
    return {
      paneHierarchyElement: foundPane,
      idsToRemove: [pane.id, paneId],
    };
  }
}

/**
 * Adds an editor pane into the panes / paneHierarchy
 * Selects the paneHierarchy and panes from the state and returns updated objects with the given pane added. These
 * fields can then be handled by a reducer as new parts of the state.
 * @param state
 * @param parentPaneId
 * @param split
 * @param position
 * @param sourcePaneId The pane the notebook was dragged from (required to remove the dragged notebook from there)
 * @param name Name of the dragged notebook
 * @param path Path of the dragged notebook
 */
export function addPane(
  state: ToBeRefined,
  parentPaneId: string,
  split: 'vertical' | 'horizontal',
  position: 'first' | 'last',
  sourcePaneId: string,
  name: string,
  path: string
): {
  paneHierarchy: ToBeRefined;
  panes: { [paneId: string]: Pane };
  newPaneId: string;
} {
  const { paneHierarchy, panes } = state;

  // Step 1: Add the pane to the panes (and already add the notebook into the pane)
  const paneId = uuidv4();
  const paneToAdd: Pane = {
    id: paneId,
    type: 'editor',
    content: [{ type: 'notebook', path, name }],
    selectedContent: path,
    loadingContent: false,
  };
  let updatedPanes = {
    ...panes,
    [paneToAdd.id]: paneToAdd,
  };

  // Step 2: Add the pane into the correct position in the pane hierarchy
  const paneElementToAdd: PaneHierarchyElementEditor = {
    id: paneId,
    type: 'editor',
  };
  let updatedPaneHierarchy: PaneHierarchyElement = addPaneRecursively(
    paneHierarchy,
    paneElementToAdd,
    parentPaneId,
    split,
    position
  );

  // Step 3: Remove the notebook from the sourcePane
  const filteredContent = (updatedPanes[sourcePaneId] || {}).content.filter(
    (c: ToBeRefined) => c.path !== path
  );
  const currentSelected = (updatedPanes[sourcePaneId] || {}).selectedContent;
  const newSelectedContent =
    currentSelected === path
      ? filteredContent.length > 0
        ? filteredContent[filteredContent.length - 1].path
        : ''
      : currentSelected;
  updatedPanes = {
    ...updatedPanes,
    [sourcePaneId]: {
      ...(updatedPanes[sourcePaneId] || {}),
      content: filteredContent,
      selectedContent: newSelectedContent,
    },
  };

  // Step 4: If the sourcePane is empty now (after the dragged notebook was removed from it), remove the pane from the
  // hierarchy!
  if (filteredContent.length === 0) {
    // Remove from the pane hierarchy
    const { paneHierarchyElement, idsToRemove } = removePaneRecursively(
      updatedPaneHierarchy,
      sourcePaneId
    );
    updatedPaneHierarchy = paneHierarchyElement;

    // Remove from the panes
    idsToRemove.forEach((paneId) => delete updatedPanes[paneId]);
  }

  return {
    paneHierarchy: updatedPaneHierarchy,
    panes: updatedPanes,
    newPaneId: paneId,
  };
}

export function addPaneRecursively(
  pane: PaneHierarchyElement,
  paneToAdd: PaneHierarchyElement,
  parentPaneId: string,
  split: 'vertical' | 'horizontal',
  position: 'first' | 'last'
): PaneHierarchyElement {
  // 1. If pane type is "panes", keep on going recursively
  if (pane.type === 'panes') {
    return {
      ...pane,
      children: pane.children.map((childPane) =>
        addPaneRecursively(childPane, paneToAdd, parentPaneId, split, position)
      ),
    };
  }

  // 2. If the panes id is not the one looked for - simply return it as it is
  if (pane.id !== parentPaneId) return pane;

  // 3. This means we found the pane to inject the new pane into
  return {
    id: uuidv4(),
    type: 'panes',
    split,
    children: position === 'first' ? [paneToAdd, pane] : [pane, paneToAdd],
  };
}
