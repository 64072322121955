import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import styles from './styles.module.scss';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { tooltip } from './Tooltip';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import { ChartDataEntry } from '../types';

type Props = {
  data: ChartDataEntry[];
  intl: IntlShape;
};

class PredictedValuesChart extends Component<Props> {
  render() {
    const { data, intl } = this.props;

    const maxHeight = 600;
    const marginTop = 20;
    const marginBottom = 100;
    const itemHeight = 40;
    const amountItems = (data || []).length;
    let height = amountItems * itemHeight + marginBottom + marginTop;
    if (height > maxHeight) height = maxHeight;

    const deriveChartDataForHeatmap = (data: ChartDataEntry[]) => {
      // Create the chart data
      return data.map((singleRunReport) => {
        const obj = {
          timestamp: singleRunReport.time,
        };
        singleRunReport.data.forEach((arr) => {
          obj[String(arr[0])] = arr[1];
        });
        return obj;
      });
    };

    const chartData = deriveChartDataForHeatmap(data);

    const renderTickRight = ({
      opacity,
      textAnchor,
      textBaseline,
      textX,
      textY,
      theme,
      value,
      x,
      y,
    }: ToBeRefined) => {
      return (
        <g transform={`translate(${x},${y})`} style={{ opacity }}>
          <text
            alignmentBaseline={textBaseline}
            style={{ fontSize: '12px' }}
            textAnchor={textAnchor}
            transform={`translate(${textX},${textY - 2})`}
          >
            <tspan x='0' dy='14px'>
              {intl.formatDate(value || '')}&nbsp;
              {intl.formatTime(value || '')}
            </tspan>
          </text>
        </g>
      );
    };

    const bucketKeys = (data[0]?.data || []).map((entry) => entry[0]);
    const minKey = _.min(bucketKeys);
    const maxKey = _.max(bucketKeys);
    const amountBuckets = bucketKeys.length;
    const bucketSize = 1 / amountBuckets;

    const getInterval = (bucketIndex: number) => {
      const bucketLeftNumber = bucketIndex / amountBuckets;
      const bucketLeft = _.round(bucketLeftNumber, 2).toFixed(2);
      const bucketRight = _.round(bucketLeftNumber + bucketSize, 2).toFixed(2);
      return bucketIndex === maxKey
        ? `[${bucketLeft},${bucketRight}]`
        : `[${bucketLeft},${bucketRight})`;
    };

    const renderTickBottom = ({
      opacity,
      textAnchor,
      textBaseline,
      textX,
      textY,
      theme,
      value,
      x,
      y,
    }: ToBeRefined) => {
      const interval = getInterval(value);

      return (
        <g transform={`translate(${x},${y})`} style={{ opacity }}>
          <text
            alignmentBaseline={textBaseline}
            style={{ fontSize: '12px' }}
            textAnchor={'start'}
            transform={`translate(${textX + 5},${textY}) rotate(45)`}
          >
            <tspan x='0' dy='14px'>
              {interval}
            </tspan>
          </text>
        </g>
      );
    };

    // Build color scale
    const scale = () => {};
    scale.domain = ([min, max]) => {
      return (
        scaleLinear()
          // @ts-ignore
          .range(['#eef0f2', '#6aa0eb'])
          .domain([min, max])
      );
    };

    return (
      <div
        className={styles.heatmap}
        style={{
          width: '100%',
          height,
        }}
      >
        <ResponsiveHeatMap
          data={chartData}
          indexBy={'timestamp'}
          keys={bucketKeys.map(String)}
          margin={{
            top: marginTop,
            right: 180,
            bottom: marginBottom,
            left: 80,
          }}
          forceSquare={false}
          // @ts-ignore
          colors={scale}
          padding={2}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            renderTick: renderTickBottom,
          }}
          axisLeft={null}
          axisTop={null}
          axisRight={{
            orient: 'right',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: renderTickRight,
          }}
          cellOpacity={1}
          cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
          animate={true}
          hoverTarget={'row'}
          cellHoverOthersOpacity={0.5}
          enableLabels={false}
          tooltip={tooltip(intl, getInterval)}
        />
      </div>
    );
  }
}

export default injectIntl(PredictedValuesChart);
