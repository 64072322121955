import React, { Component } from 'react';
import SingleResultsPerPath from './SingleResultsPerPath';
import { PipelineResultType } from 'common/dist/types/pipeline';
import _ from 'lodash';
import { SinglePathResult } from 'common/dist/types/mlModel';

type Props = {
  /** Contains the id, displayName, the schema and the paths through this pipeline */
  pipelineResult: PipelineResultType;
  /** Map that contains the single path results per path */
  singleResultsMap: { [pathId: string]: SinglePathResult[] };
  /** Formats the score with respect to the given tuning KPI*/
  scoreFormatter: (score: number) => string;
};

export default class SingleResultsPerPipeline extends Component<Props, {}> {
  render() {
    const { pipelineResult, singleResultsMap, scoreFormatter } = this.props;
    const { paths } = pipelineResult;

    if (!singleResultsMap) {
      // Happens if there are no single results for a pipeline.
      //   This should never be the case, but at least prevent the UI from crashing.
      return null;
    }

    // PathMap
    const pathsMap = _.keyBy(paths, 'pathId');

    // Calculate the highscores: Array of: [highScore, pathId]
    const highestScoresPerPath = Object.keys(singleResultsMap).map((pathId) => {
      const highScore = Math.max(
        ...singleResultsMap[pathId].map((p) => p.score)
      );
      return [highScore, pathId];
    });

    return (
      <div className={'SingleResultsPerPipeline'}>
        {highestScoresPerPath
          // @ts-ignore
          .sort((a, b) => b[0] - a[0])
          .map(([highScore, pathId]) => {
            const path = pathsMap[pathId];

            return (
              <SingleResultsPerPath
                pipelineResult={pipelineResult}
                singleResults={singleResultsMap[pathId]}
                path={path}
                scoreFormatter={scoreFormatter}
                // @ts-ignore
                highestScore={highScore}
              />
            );
          })}
      </div>
    );
  }
}
