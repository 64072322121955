import React, { FC, useState } from 'react';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { communicationGroupsForm, fieldCommunicationGroups } from './form';
import { Field, reduxForm } from 'redux-form';
import OptCommunicationGroups from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunicationGroups/OptCommunicationGroups';
import CancelSubmitButtonsWrapper from '../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import {
  CommunicationGroup,
  Communication,
} from 'common/dist/types/module.optimization';
import Button from '../../../../../atoms/button/Button';
import { FiEdit } from 'react-icons/all';
import classNames from 'classnames';

type Props = {
  initialValues: {
    [fieldCommunicationGroups]: CommunicationGroup[];
  };
  updateDatapool: (
    habitatCode: string,
    datapoolCode: string,
    datapoolUpdates: Record<string, string | {}>,
    mergeMeta: boolean
  ) => void;
  habitatCode: string;
  datapoolCode: string;
  communications: Communication[];
};

const communicationGroupsComponent = ({
  input,
  meta,
  communications,
  inEditMode,
}) => (
  <OptCommunicationGroups
    input={input}
    meta={meta}
    communications={communications}
    inEditMode={inEditMode}
  />
);

const CommunicationGroups: FC<
  Props & InjectedFormProps<FormValueType, Props>
> = (props) => {
  const {
    pristine,
    reset,
    submitting,
    handleSubmit,
    updateDatapool,
    habitatCode,
    datapoolCode,
    communications,
    valid,
  } = props;
  const [inEditMode, setInEditMode] = useState(false);

  return (
    <div className={styles.optCommunicationGroups}>
      <div className={styles.headlineContainer}>
        <FormattedMessage
          {...moduleOptimizationMessages.msgDatapoolDetailsCommunicationGroupsTabName}
        >
          {(text) => <span className={styles.headline}>{text}</span>}
        </FormattedMessage>
        <Button
          buttonColor={'blue'}
          buttonLabelId={'no-id'}
          buttonLabelDefault={'Edit'}
          withLink={false}
          Icon={() => <FiEdit size={16} />}
          onClick={() => {
            setInEditMode(true);
          }}
          additionalClassNames={[
            classNames(styles.button, {
              [styles.invisible]: inEditMode,
            }),
          ]}
        />
      </div>
      <CancelSubmitButtonsWrapper
        cancelDisabled={!inEditMode || submitting}
        saveDisabled={pristine || submitting || !valid}
        savingButtonBusy={submitting}
        onCancel={reset}
        onSubmit={handleSubmit((values) => {
          updateDatapool(
            habitatCode,
            datapoolCode,
            {
              meta: {
                communicationGroups: values[fieldCommunicationGroups] || [],
              },
            },
            true
          );
          setInEditMode(false);
        })}
      >
        <Field
          name={fieldCommunicationGroups}
          component={communicationGroupsComponent}
          communications={communications}
          inEditMode={inEditMode}
        />
      </CancelSubmitButtonsWrapper>
    </div>
  );
};

type FormValueType = {
  [fieldCommunicationGroups]: CommunicationGroup[];
};
export default reduxForm<FormValueType, Props>(communicationGroupsForm)(
  CommunicationGroups
);
