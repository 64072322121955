import { connect, ConnectedProps } from 'react-redux';
import DatapoolDetails from './DatapoolDetails';
import {
  clear,
  fetchDatapool,
} from '../../redux/modules/datapool.details.module';
import { DeprecatedRootState } from '../../store/state.type';
import { fetchDataSources } from '../../redux/modules/data.dataSources.module';

export function mapStateToProps(state: DeprecatedRootState) {
  const {
    loading,
    loaded,
    error,
    datapool: data,
  } = state.datapoolDetails || {};
  const dataSources = state.data.dataSources;
  return {
    loading,
    loaded,
    error,
    data,
    dataSources,
  };
}

export const mapDispatchToProps = {
  fetchDatapool,
  fetchDataSources,
  clearDatapoolDetails: clear,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(DatapoolDetails);
