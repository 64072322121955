import { createAction } from 'redux-act';
import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { putApiRequest } from '../../../core/api/workbench/_apiRequests';
import { notebookUser } from '../selectors/notebookUser.selector';
import { END, eventChannel } from 'redux-saga';
import { fetchContent } from './content.module';
import { ensureDirectoryExistsCall } from './container-interactions.module';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import _ from 'lodash';
import { getJupyterBaseUrl } from './notebookHelper';

export const showUploadModal = createAction('show upload modal');

export const hideUploadModal = createAction('hide upload modal');

export const clearFilesList = createAction(
  'upload - clear files list',
  (uploadId) => ({ uploadId })
);

export const addFiles = createAction(
  'upload - add files',
  (uploadId, filesToAdd) => ({ uploadId, filesToAdd })
);

export const setProgress = createAction(
  'upload - set progress',
  (uploadId, filename, status, percentage) => ({
    uploadId,
    filename,
    status,
    percentage,
  })
);

export const setFileInvalid = createAction(
  'upload - set file invalid',
  (uploadId, filename, message) => ({ uploadId, filename, message })
);

export const uploadFiles = createAction(
  'upload files',
  (
    uploadId,
    files,
    targetDirPath,
    ensureDirectoriesBeforeUpload = false,
    parentType = 'notebook',
    appVersionCode = ''
  ) => ({
    uploadId,
    files,
    targetDirPath,
    ensureDirectoriesBeforeUpload,
    parentType,
    appVersionCode,
  })
);

export const uploadFilesSuccess = createAction(
  'upload files - success',
  (uploadId) => ({ uploadId })
);

export const uploadFilesFailure = createAction(
  'upload files - failure',
  (error, uploadId) => ({ error, uploadId })
);

export const cancelUpload = createAction('cancel upload', (uploadId) => ({
  uploadId,
}));

export const reducer = {
  [showUploadModal]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      isUploadModalOpen: true,
    },
  }),
  [hideUploadModal]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      isUploadModalOpen: false,
    },
  }),
  [clearFilesList]: (state, { uploadId }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        files: [],
        uploadDone: false,
        uploadCancelled: false,
        uploadProgress: {},
      },
    },
  }),
  [addFiles]: (state, { uploadId, filesToAdd }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        files: [
          ...((state.upload[uploadId] ? state.upload[uploadId].files : []) ||
            []), // This is obviously bad style
          ...filesToAdd,
        ],
      },
    },
  }),
  [setProgress]: (state, { uploadId, filename, status, percentage }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        uploadProgress: {
          ...((state.upload[uploadId]
            ? state.upload[uploadId].uploadProgress
            : {}) || {}),
          [filename]: {
            status,
            percentage,
          },
        },
      },
    },
  }),
  [setFileInvalid]: (state, { uploadId, filename, message }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        uploadProgress: {
          ...((state.upload[uploadId]
            ? state.upload[uploadId].uploadProgress
            : {}) || {}),
          [filename]: {
            percentage: 0,
            status: 'invalid',
            message,
          },
        },
      },
    },
  }),
  [uploadFiles]: (state, { uploadId }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        uploadProgress: {},
        uploading: true,
        uploadCancelled: false,
      },
    },
  }),
  [uploadFilesSuccess]: (state, { uploadId }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        uploadDone: true,
        uploading: false,
        uploadCancelled: false,
      },
    },
  }),
  [uploadFilesFailure]: (state, { uploadId, error }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        uploadDone: true,
        uploading: false,
        uploadCancelled: false,
        error,
      },
    },
  }),
  [cancelUpload]: (state, { uploadId }) => ({
    ...state,
    upload: {
      ...state.upload,
      [uploadId]: {
        ...(state.upload[uploadId] || {}),
        // files: [],
        uploadDone: false,
        uploadCancelled: true,
        uploading: false,
      },
    },
  }),
};

export function* uploadFilesSaga({
  payload: {
    files,
    uploadId,
    targetDirPath,
    ensureDirectoriesBeforeUpload,
    parentType,
    appVersionCode,
  },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));

  // --- Derive a list of { file, targetDirPath } (required for checking that the directories exist and uploading)
  const filesAndPaths = files.map((file) => {
    const relativeDirPath = (file.webkitRelativePath || '')
      .split('/')
      .slice(0, -1);
    const fullTargetDirPath = (targetDirPath || []).concat(relativeDirPath);

    return {
      file,
      fullTargetDirPath,
    };
  });

  // --- Make sure the required subdirectories exist
  // 1. selected directory
  const joinedPath = targetDirPath.slice(1).join('/');
  yield call(ensureDirectoryExistsCall, joinedPath, parentType, appVersionCode);

  // 2. If a directory is uploaded: For the "deeper" directories
  const paths = _.uniq(
    filesAndPaths.map((fileAndPath) => fileAndPath.fullTargetDirPath)
  );
  for (let i = 0; i < paths.length; i++) {
    const joinedPath = paths[i].slice(1).join('/');
    yield call(
      ensureDirectoryExistsCall,
      joinedPath,
      parentType,
      appVersionCode
    );
  }

  // --- Upload the files
  for (let i = 0; i < filesAndPaths.length; i++) {
    const fileAndPath = filesAndPaths[i];
    const { file, fullTargetDirPath } = fileAndPath;
    yield call(
      singleUploadRequest,
      uploadId,
      file,
      fullTargetDirPath,
      jupyterUser,
      parentType,
      appVersionCode
    );
  }

  const uploadCancelled = yield select(
    (state) =>
      state.workbench.upload &&
      state.workbench.upload[uploadId]?.uploadCancelled
  );
  if (!uploadCancelled) {
    yield put(uploadFilesSuccess(uploadId)); // TODO This could be improved be returning true / false values whether the upload worked or not.
    yield put(fetchContent(targetDirPath));
  }
}

export function* watchUploadFiles() {
  yield takeEvery(uploadFiles.getType(), uploadFilesSaga);
}

// ------------------------------ UPLOAD HELPER FUNCTIONS -------------------------------------------------------

const SINGLE_CHUNK_SIZE = 1024 * 1024 * 1; // 1 MB

/**
 * Creates the string path from an array of directories
 * @param targetDirPath
 * @param name
 * @returns {string|*}
 */
export function pathFromArray(targetDirPath, name) {
  if (targetDirPath.length <= 1) return name;
  return `${targetDirPath.slice(1).join('/')}/${name}`;
}

/**
 * Processes one single upload request
 * @param uploadId
 * @param file
 * @param fullTargetDirPath
 * @param jupyterUser
 * @param parentType notebook | app
 * @param appVersionCode only required if parentType = 'notebook'
 * @returns {*}
 */
function* singleUploadRequest(
  uploadId,
  file,
  fullTargetDirPath,
  jupyterUser,
  parentType,
  appVersionCode
) {
  const name = file.name;
  const path = pathFromArray(fullTargetDirPath, name);

  // 1. Validate the filename: filename.legnth>0, mustn't start with '.', mustn't exist at the given path.
  if (name.length === 0 || name.startsWith('.')) {
    yield put(
      setFileInvalid(
        uploadId,
        file.webkitRelativePath || file.name,
        'Invalid filename.'
      )
    );
  }

  // 2. Decide about reading the file at once or in chunks.
  //  Criteria: Notebooks and files that are smaller than SINGLE_CHUNK_SIZE MB are read at once. Larger files are read
  //  in chunks.
  const isNotebook = name.endsWith('.ipynb');
  const isSmallFile = file.size <= SINGLE_CHUNK_SIZE;
  if (isNotebook || isSmallFile) {
    // Small file or a notebook -> read the whole file at once
    const channel = yield call(
      readFileAtOnce,
      uploadId,
      file,
      path,
      jupyterUser,
      parentType,
      appVersionCode
    );
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  }
  // Large file -> read in chunks
  const channel = yield call(
    readFileInChunks,
    uploadId,
    file,
    path,
    jupyterUser,
    parentType,
    appVersionCode
  );
  while (true) {
    const uploadCancelled = yield select(
      (state) =>
        state.workbench.upload &&
        state.workbench.upload[uploadId]?.uploadCancelled
    );
    if (uploadCancelled) {
      channel.close();
    }
    const action = yield take(channel);
    if (action === END) break;
    yield put(action);
  }
}

/**
 * Function to read small files - they will be read at once
 * @param uploadId
 * @param file
 * @param path
 * @param jupyterUser
 * @param parentType notebook | app
 * @param appVersionCode
 */
function* readFileAtOnce(
  uploadId,
  file,
  path,
  jupyterUser,
  parentType,
  appVersionCode
) {
  return eventChannel((emitter) => {
    const name = file.name;

    // Instantiate a FileReader and register the event listeners
    const reader = new FileReader();
    reader.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        return emitter(
          setProgress(
            uploadId,
            file.webkitRelativePath || file.name,
            'pending',
            percentage
          )
        );
      }
    });

    if (name.endsWith('.ipynb')) {
      // ------------------------------------------------------------ FILE AT ONCE: NOTEBOOK
      // It's a notebook - read and parse it
      const type = 'notebook';
      const format = 'json';

      reader.onload = async (evt) => {
        const contentRaw = evt.target.result;
        let content = {};
        try {
          content = JSON.parse(contentRaw);
        } catch (error) {
          // TODO -> The notebook json was invalid - handle the error!
          return;
        }
        const { response, error } = await pushSingleFileToJupyter(
          type,
          format,
          name,
          path,
          content,
          jupyterUser,
          parentType,
          appVersionCode
        );
        if (response) {
          emitter(
            setProgress(
              uploadId,
              file.webkitRelativePath || file.name,
              'done',
              100
            )
          );
          // Shut down the emitter
          return emitter(END);
        } else {
          emitter(
            setProgress(
              uploadId,
              file.webkitRelativePath || file.name,
              'error',
              0
            )
          );
          // Shut down the emitter
          return emitter(END);
        }
      };
      reader.readAsText(file, 'UTF-8');
    } else {
      // ----------------------------------------------------------------------------------- FILE AT ONCE: BASE64
      // It's no notebook - read and encode it as base64

      const type = 'file';
      const format = 'base64';

      reader.onload = async (evt) => {
        const contentRaw = evt.target.result;

        // base64-encode binary file data
        let bytes = '';
        const buf = new Uint8Array(contentRaw);
        const nbytes = buf.byteLength;
        for (let i = 0; i < nbytes; i++) {
          bytes += String.fromCharCode(buf[i]);
        }
        const content = btoa(bytes);

        const { response, error } = await pushSingleFileToJupyter(
          type,
          format,
          name,
          path,
          content,
          jupyterUser,
          parentType,
          appVersionCode
        );
        if (response) {
          emitter(
            setProgress(
              uploadId,
              file.webkitRelativePath || file.name,
              'done',
              100
            )
          );
          // Shut down the emitter
          return emitter(END);
        } else {
          emitter(
            setProgress(
              uploadId,
              file.webkitRelativePath || file.name,
              'error',
              0
            )
          );
          // Shut down the emitter
          return emitter(END);
        }
      };
      reader.readAsArrayBuffer(file);
    }

    return () => {};
  });
}

/**
 * Function to read larger files - the will be read in chunks of x MB
 * @param uploadId
 * @param file
 * @param path
 * @param setProgress
 * @param jupyterUser
 */
function readFileInChunks(
  uploadId,
  file,
  path,
  jupyterUser,
  parentType,
  appVersionCode
) {
  return eventChannel((emitter) => {
    const name = file.name;

    const chunkSize = SINGLE_CHUNK_SIZE;
    let offset = 0;
    let chunk = 0;

    const type = 'file';
    const format = 'base64';

    // Instantiate a FileReader and register the event listeners
    const reader = new FileReader();

    reader.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentage = ((offset + event.loaded) / file.size) * 100;
        return emitter(
          setProgress(
            uploadId,
            file.webkitRelativePath || file.name,
            'pending',
            percentage
          )
        );
      }
    });
    reader.addEventListener('error', (event) => {
      emitter(
        setProgress(uploadId, file.webkitRelativePath || file.name, 'error', 0)
      );
      // Shut down the emitter
      return emitter(END);
    });

    let done = false;
    reader.onload = async (evt) => {
      if (evt.target.error == null) {
        offset += chunkSize;
        if (offset >= file.size) {
          chunk = -1;
        } else {
          chunk += 1;
        }

        const contentRaw = evt.target.result;

        // base64-encode binary file data
        let bytes = '';
        const buf = new Uint8Array(contentRaw);
        const nbytes = buf.byteLength;
        for (let i = 0; i < nbytes; i++) {
          bytes += String.fromCharCode(buf[i]);
        }
        const content = btoa(bytes);

        const { response, error } = await pushChunkToJupyter(
          type,
          format,
          name,
          path,
          content,
          chunk,
          jupyterUser,
          parentType,
          appVersionCode
        );

        // Check if there are more slices to read
        if (offset < file.size) {
          const blob = file.slice(offset, chunkSize + offset);
          reader.readAsArrayBuffer(blob);

          const percentage = (offset / file.size) * 100;
          return emitter(
            setProgress(
              uploadId,
              file.webkitRelativePath || file.name,
              'pending',
              percentage
            )
          );
        }
        done = true;
        emitter(
          setProgress(
            uploadId,
            file.webkitRelativePath || file.name,
            'done',
            100
          )
        );
        // Shut down the emitter
        return emitter(END);
      }

      emitter(
        setProgress(uploadId, file.webkitRelativePath || file.name, 'error', 0)
      );
      // Shut down the emitter
      return emitter(END);
    };

    const blob = file.slice(offset, chunkSize + offset);
    reader.readAsArrayBuffer(blob);

    return async () => {
      reader.abort();
      if (!done) {
        const notebookApi = new NotebookApi(jupyterUser);
        await notebookApi.deleteContent(path, false);
      }
    };
  });
}

/**
 * Pushes a single file to Jupyter
 * @param type
 * @param format
 * @param name
 * @param path
 * @param content
 * @param jupyterUser
 * @param parentType notebook | app
 * @param appVersionCode
 * @returns {Promise<*>}
 */
async function pushSingleFileToJupyter(
  type,
  format,
  name,
  path,
  content,
  jupyterUser,
  parentType,
  appVersionCode
) {
  const jupyterBaseUrl = getJupyterBaseUrl(
    parentType,
    jupyterUser,
    appVersionCode
  );
  const url = `${jupyterBaseUrl}/api/contents/${path}`;

  const body = {
    type,
    format,
    name,
    path,
    content,
  };
  return putApiRequest(url, body);
}

/**
 * Pushes a chunk of a file to Jupyter
 * @param type
 * @param format
 * @param name
 * @param path
 * @param content
 * @param chunk
 * @param jupyterUser
 * @param parentType notebook | app
 * @param appVersionCode
 * @returns {Promise<*>}
 */
async function pushChunkToJupyter(
  type,
  format,
  name,
  path,
  content,
  chunk,
  jupyterUser,
  parentType,
  appVersionCode
) {
  const jupyterBaseUrl = getJupyterBaseUrl(
    parentType,
    jupyterUser,
    appVersionCode
  );
  const url = `${jupyterBaseUrl}/api/contents/${path}`;

  const body = {
    type,
    format,
    name,
    path,
    content,
    chunk,
  };
  return putApiRequest(url, body);
}
