import P3InputElement, {
  AsInputElement,
  AsVariable,
} from '../_interface/P3InputElement';
import { validateCommonSettings } from '../_interface/input-element.form';
import EditableTable from './EditableTable';
import EditableTableSettings from './EditableTableSettings.container';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';

export default class EditableTableWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = EditableTable;
  AdditionalSettings: any = EditableTableSettings;
  name = 'Editable Table';
  parentContainerClass = 'editable-table';
  type = 'editable-table';
  icon: any = DefaultElementIcon;
  iconColor = '#224e90';

  // --- Functions
  getSource: (asElement: AsInputElement, variables: AsVariable[]) => string = (
    asElement,
    variables
  ) => {
    if (!asElement.settings?.outputVariable || !asElement.data) return '';

    const tableData = asElement.data.tableData;

    return `
import pandas 
${
  asElement.settings.outputVariable.inputValue
} = pandas.DataFrame.from_records(${JSON.stringify(tableData || {})})
    `;
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
