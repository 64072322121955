import { connect } from 'react-redux';
import TargetList, { Props } from './TargetList';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  fieldPredictionTargets,
  form,
  formName,
  FormValues,
} from './TargetList.form';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { RootState } from '../../../../../../../../../store/store';

export const selector = formValueSelector(formName);

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    settings: { predictionTargets },
  } = ownProps;

  return {
    initialValues: {
      [fieldPredictionTargets]: {
        predictionTargets,
      },
    },
    predictionTargets: selector(state, fieldPredictionTargets),
  };
}
export default connect(mapStateToProps)(
  reduxForm<FormValues, Props>(form)(TargetList)
);
