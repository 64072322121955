import React, { FC } from 'react';
import styles from './styles.module.scss';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { Field } from 'redux-form';
import { fieldDictionary } from './dictionary.form';
import CancelSubmitButtonsWrapper from '../../../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import Dictionary from '../../../../../../../organisms/modelManagement/newAugurWizard/dictionary/Dictionary';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

type Props = {
  dictionary?: Record<string, string>;
};

const TaggerDictionary: FC<Props & InjectedFormProps & ModelSettingsProps> = (
  props
) => {
  const {
    reset,
    pristine,
    saveSettings,
    saving,
    dictionary,
    valid,
    initialValues,
  } = props;

  return (
    <form>
      <div className={styles.taggerDictionary}>
        <div className={styles.taggerDictionaryInner}>
          <CancelSubmitButtonsWrapper
            onCancel={reset}
            onSubmit={() => saveSettings({ dictionary }, true)}
            disabled={pristine || saving || !valid}
            savingButtonBusy={saving}
          >
            <div className={styles.fieldParent}>
              <Field
                name={fieldDictionary}
                component={Dictionary}
                initialValues={initialValues}
              />
            </div>
          </CancelSubmitButtonsWrapper>
        </div>
      </div>
    </form>
  );
};

export default TaggerDictionary;
