import React, { Component } from 'react';
import styles from './styles.module.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import OptionComponent from './OptionComponent';
import { WrappedFieldProps } from 'redux-form';
import _ from 'lodash';
import { DatapoolType } from 'common/dist/types/datapool';

export type OwnProps = {
  habitatCode: string;
  dropdownSelectPortal?: HTMLElement;
  /** Allows to select the datapool based on a datapool code passed in for example from the query string*/
  initialSelectedDatapoolCode?: string;
  /** The module type that was selected some wizard steps before. Filters the available Datapool options. */
  selectedModuleType?: string;
};

type ContainerProps = {
  datapools?: DatapoolType[];
};

export type Props = OwnProps & ContainerProps;

export type OptionType = {
  value: string;
  label: string;
  intlModuleType: string;
  datapool: DatapoolType;
};

class Datapool extends Component<
  Props & WrappedComponentProps & WrappedFieldProps
> {
  componentDidMount() {
    this.setInitialDatapool();
  }

  componentDidUpdate(
    prevProps: Readonly<Props & WrappedComponentProps & WrappedFieldProps>
  ) {
    if (!_.isEqual(this.props.datapools, prevProps.datapools)) {
      this.setInitialDatapool();
    }
  }

  setInitialDatapool() {
    const {
      initialSelectedDatapoolCode,
      datapools,
      input: { onChange, onBlur },
    } = this.props;
    if (initialSelectedDatapoolCode && datapools) {
      const datapool = datapools.find(
        (datapool) => datapool.code === initialSelectedDatapoolCode
      );
      onChange(datapool);
      onBlur(datapool);
    }
  }

  render() {
    const {
      intl,
      datapools,
      input: { onChange, value, onBlur },
      meta: { error, valid, touched },
      dropdownSelectPortal,
      selectedModuleType,
    } = this.props;

    const localizedTypes: OptionType[] = (datapools || [])
      .filter((datapool) => {
        if (selectedModuleType) {
          return datapool.moduleType === selectedModuleType;
        } else {
          // disable the filter for the selectedModuleType if the property is undefined
          return true;
        }
      })
      .map((datapool) => ({
        value: datapool.code,
        label: datapool.name,
        intlModuleType: intl.formatMessage({
          id: `newAugur.stepOne.datapools.${datapool.moduleType}`,
          defaultMessage: datapool.module.moduleName,
        }),
        datapool,
      }))
      .sort((dp1, dp2) => (dp1.datapool.name > dp2.datapool.name ? 1 : -1));

    const optionFilled =
      localizedTypes.find((x) => x.datapool.code === value?.code) || null;

    return (
      <div className={styles.datapool}>
        <DropdownSelectInput
          id={'datapoolCode'}
          name={'datapoolCode'}
          touched={touched}
          error={error}
          valid={valid}
          disabled={false}
          label={{
            id: 'newAugur.stepOne.select_augur’s_type',
            defaultMessage:
              'Select the Datapool that will be the data source for your new Augur.',
          }}
          placeholder={{
            id: 'newAugur.stepOne.no_type_selected',
            defaultMessage: 'No Datapool selected',
          }}
          value={optionFilled}
          onChange={(option: OptionType) => onChange(option.datapool)}
          onBlur={() => onBlur(value)}
          isLoading={false}
          options={localizedTypes}
          customComponents={{
            Option: OptionComponent,
          }}
          menuPortalTarget={dropdownSelectPortal}
        />
      </div>
    );
  }
}

export default injectIntl(Datapool);
