import React, { FC, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Button from '../../atoms/button/Button';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';

type OwnProps = {
  fullViewHeight: boolean;
};

type ContainerProps = {
  fetchComponentPermissions: () => void;
  permissions: Record<string, string[]>;
};

export type Props = OwnProps & ContainerProps;

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const NotEvenOnePermission: FC<Props & RouteComponentProps> = (props) => {
  const { fullViewHeight, permissions, fetchComponentPermissions, history } =
    props;

  const prevPermissions = usePrevious(permissions);
  useEffect(() => {
    // If the permissions have changed, trigger the redirect. If the user has permissions now for a component, the
    //   redirect will land at this page (due to RootRoutes.tsx)
    if (prevPermissions) {
      history.push('/');
    }
  }, [permissions]);

  return (
    <div
      className={classNames(styles.notEvenOnePermission, {
        [styles.fullViewHeight]: fullViewHeight,
      })}
    >
      <div className={styles.subHeaderMock} />
      <span className={styles.title}>
        You do not have any permissions in AltaSigma yet
      </span>
      <span className={styles.description}>
        Please ask an Administrator to grant permissions to you
      </span>

      <div className={styles.buttonContainer}>
        <Button
          buttonColor={'blue'}
          buttonLabelDefault={'Retry'}
          withLink={false}
          onClick={() => fetchComponentPermissions()}
        />
      </div>
    </div>
  );
};

export default withRouter(NotEvenOnePermission);
