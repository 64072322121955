import React, { Component } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import TrainingTableSelectField, {
  Props as TrainingTableSelectFieldProps,
} from './TrainingTableSelectField';
import PredictionTableSelectField, {
  Props as PredictionTableSelectFieldProps,
} from './PredictionTableSelectField';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactModal from 'react-modal';
import {
  fieldPredictionTable,
  fieldTrainingTable,
  FormValues,
} from './tableSelection.form';
import CancelSubmitButtonsWrapper from '../../../../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import TablePreviewModal from '../../../../../../../../organisms/table-preview-modal/TablePreviewModal.container';
import { tablePreviewCustomStyles } from '../../../../../../../../organisms/table-preview-modal/TablePreviewModal';
import { CProps } from './TableSelection.container';
import WithTableSample from '../../../../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample.container';
import { BaseFieldProps } from 'redux-form/lib/Field';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

export type Props = {};

interface State {
  modalIsShown: boolean;
  tableName: string;
}

class TableSelection extends Component<
  Props &
    CProps &
    InjectedFormProps<FormValues, Props> &
    WrappedComponentProps &
    ModelSettingsProps,
  State
> {
  constructor(props) {
    super(props);
    this.handleSaveSettings = this.handleSaveSettings.bind(this);
    this.state = { modalIsShown: false, tableName: undefined };
  }

  handleSaveSettings(data: FormValues) {
    const { saveSettings } = this.props;
    const trainingTable = data[fieldTrainingTable];
    const scoringTable = data[fieldPredictionTable];
    const settings = {
      trainingTable: trainingTable.tableName,
      primaryKeyColumns: trainingTable.primaryKeyColumns || [],
      labelColumn: trainingTable.labelColumn,
      positiveLabelValue: trainingTable.positiveLabelValue,
      negativeLabelValue: trainingTable.negativeLabelValue,
      scoringTable: scoringTable.tableName,
    };
    saveSettings(settings, true);
  }

  render() {
    const {
      datapool,
      intl,
      reset,
      pristine,
      saving,
      handleSubmit,
      trainingTableName,
      scoringTableName,
    } = this.props;

    return (
      <div className={'TableSelection'}>
        <CancelSubmitButtonsWrapper
          onCancel={reset}
          onSubmit={handleSubmit((data) => this.handleSaveSettings(data))}
          disabled={pristine || saving}
          savingButtonBusy={saving}
        >
          <div className={'TableSelection--field-parent'}>
            <WithTableSample datapool={datapool} tableName={trainingTableName}>
              <Field<
                BaseFieldProps<
                  TrainingTableSelectFieldProps & WrappedComponentProps
                >
              >
                name={fieldTrainingTable}
                component={TrainingTableSelectField}
                props={{
                  showTablePreview: () => {
                    this.setState({
                      modalIsShown: true,
                      tableName: trainingTableName,
                    });
                  },
                  intl,
                }}
              />
            </WithTableSample>

            <WithTableSample datapool={datapool} tableName={scoringTableName}>
              <Field<BaseFieldProps<PredictionTableSelectFieldProps>>
                name={fieldPredictionTable}
                component={PredictionTableSelectField}
                props={{
                  showTablePreview: () => {
                    this.setState({
                      modalIsShown: true,
                      tableName: scoringTableName,
                    });
                  },
                }}
              />
            </WithTableSample>
          </div>
        </CancelSubmitButtonsWrapper>

        <ReactModal
          isOpen={this.state.modalIsShown}
          contentLabel='Add Directory before Transfer'
          onRequestClose={() =>
            this.setState({ modalIsShown: false, tableName: scoringTableName })
          }
          style={tablePreviewCustomStyles}
          appElement={document.getElementById('react-app')}
        >
          <TablePreviewModal
            datapool={datapool}
            tableName={this.state.tableName}
            hideTablePreview={() =>
              this.setState({
                modalIsShown: false,
                tableName: scoringTableName,
              })
            }
          />
        </ReactModal>
      </div>
    );
  }
}

export default injectIntl(TableSelection);
