import { ComponentType } from 'react';
import P3OutputElement from '../_interface/P3OutputElement';
import CodeOutput from './CodeOutput';
import { FiCode } from 'react-icons/fi';

export const CODE_OUTPUT_TYPE = 'code-output';

export default class CodeOutputWrapper extends P3OutputElement {
  AdditionalSettings: ComponentType = undefined;
  ChildComponent: ComponentType = CodeOutput;
  hasSettings = false;
  icon: any = () => (
    <div
      style={{
        height: '24px',
        width: '24px',
        padding: '5px', // To center the inner icon
        borderRadius: '50%',
        backgroundColor: '#6AA0EB',
      }}
    >
      <FiCode size={14} color={'#fff'} />
    </div>
  );
  iconColor = '#6AA0EB';
  name = 'Code Output';
  parentContainerClass = 'code-output';
  type = CODE_OUTPUT_TYPE;

  // No settings - so also no need to validate them
  // @ts-ignore
  validateSettings(values: object, variables: object[]): object {
    return undefined;
  }
}
