import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { FormattedDateTime } from '../../../atoms/formatted-date-time/FormattedDateTime';

class LatestCommit extends Component {
  constructor(props) {
    super(props);
    this.checkFetchData = this.checkFetchData.bind(this);
  }

  componentDidMount() {
    this.checkFetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeBranch, repoName } = this.props;
    if (
      prevProps.activeBranch !== activeBranch ||
      prevProps.repoName !== repoName
    ) {
      this.checkFetchData();
    }
  }

  checkFetchData() {
    const { fetchLatestCommit, activeBranch } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    if (repositoryName) {
      fetchLatestCommit(repositoryName, activeBranch || 'master', group);
    }
  }

  renderLoaded() {
    const { data } = this.props;

    if (!data || Object.keys(data).length === 0) {
      return (
        <div className={'repository-latest-commit'}>
          <div className={'commit-line-message'}>
            <span className={'commit-message'}>
              <i>There is no commit for this repository yet</i>
            </span>
          </div>
        </div>
      );
    }

    const commitSafe = data.commit || {};
    const committerSafe = commitSafe.committer || {};
    return (
      <div className={'repository-latest-commit'}>
        <div className={'commit-line-message'}>
          <span className={'commit-message'}>{commitSafe.message}</span>
        </div>
        <div className={'commit-line-committer'}>
          <span className={'opening-text'}>Latest commit by </span>
          <span className={'committer-name'}>{committerSafe.name}</span>
          <span className={'filler-text'}>, authored at </span>
          <span className={'commit-date'}>
            <FormattedDateTime date={committerSafe.date} />{' '}
          </span>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'repository-latest-commit'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={'#224e90'}
        />
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'repository-latest-commit'}>
        <span>{error}</span>
      </div>
    );
  }

  render() {
    const { loading, error } = this.props;
    if (loading) return this.renderLoading();
    if (error) return this.renderError();
    return this.renderLoaded();
  }
}
LatestCommit.propTypes = {
  /** fullName of the repository - required to look it up in Gogs */
  repoFullName: PropTypes.string.isRequired,
  /** name of the active branch */
  activeBranch: PropTypes.string.isRequired,
  /** Fetches the latest commit for a given branch and repository */
  fetchLatestCommit: PropTypes.func.isRequired,
  /** Is the latest commit currently loading? */
  loading: PropTypes.bool,
  /** Was the latest commit loaded? */
  loaded: PropTypes.bool,
  /** Contains the error if there was one while loading the latest commit */
  error: PropTypes.string,
  data: PropTypes.shape({
    // This shape is not complete but only documents the important parts
    sha: PropTypes.string,
    commit: PropTypes.shape({
      /** Commit message */
      message: PropTypes.string,
      committer: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        date: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
LatestCommit.defaultProps = {
  data: {},
};

export default withRouter(LatestCommit);
