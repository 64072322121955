import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import RepoContentElement from './RepoContentElement';
import { Link } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../../../routes';
import { withRouter } from 'react-router-dom';

class RepoContent extends Component {
  constructor(props) {
    super(props);
    this.checkFetchData = this.checkFetchData.bind(this);
  }

  componentDidMount() {
    this.checkFetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeBranch, repoName, selectedPath } = this.props;
    if (
      prevProps.activeBranch !== activeBranch ||
      prevProps.repoName !== repoName ||
      prevProps.selectedPath !== selectedPath
    ) {
      this.checkFetchData();
    }
  }

  checkFetchData() {
    const { fetchRepoContent, activeBranch, selectedPath } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    if (repositoryName) {
      fetchRepoContent(
        repositoryName,
        activeBranch || 'master',
        selectedPath,
        group
      );
    }
  }

  renderLoading() {
    return (
      <div className={'repository-content'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={'#224e90'}
        />
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'repository-content'}>
        <span>{error}</span>
      </div>
    );
  }

  renderEmptyRepository() {
    const { repoCode, repoFullName, repoName } = this.props;
    return (
      <div className={'repository-content'}>
        <i>
          <span className={'empty-repo-text'}>
            This repository is currently empty.{' '}
          </span>
          <Link
            to={`${collaborationSpaceRoutes.basePath}/${repoFullName}/${collaborationSpaceRoutes.repositories.clone}`}
          >
            <div className={'button button-green'}>Clone the repository</div>
          </Link>
          <span className={'empty-repo-text'}>
            {' '}
            into your private notebook environment to add content.
          </span>
        </i>
      </div>
    );
  }

  renderLoaded() {
    const { selectedPath, data } = this.props;
    return (
      <div className={'repository-content'}>
        <span>Selected Path: {selectedPath}</span>

        <div className={'ct-list'}>
          <div className={'ct-headline ct-row'}>
            <div className={'ct-col ct-col-40px'}>Type</div>
            <div className={'ct-col ct-col-flex-grow'}>
              <span>Name</span>
            </div>
          </div>
          {data &&
            data.map((e, i) => (
              <RepoContentElement
                key={i}
                type={e.type}
                name={e.name}
                path={e.path}
                sha={e.sha}
                {...e}
              />
            ))}
        </div>
      </div>
    );
  }

  render() {
    const { loading, error, selectedPath, data } = this.props;
    if (loading) return this.renderLoading();
    if (error) return this.renderError();
    if (selectedPath === '/' && (!data || data.length === 0))
      return this.renderEmptyRepository();
    return this.renderLoaded();
  }
}
RepoContent.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired, // file / dir / symlink / submodule
      encoding: PropTypes.string,
      size: PropTypes.number,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      sha: PropTypes.string.isRequired,
    })
  ),
  error: PropTypes.string,
  selectedPath: PropTypes.string.isRequired,
  /** Name of the repository (to look it up at Gogs) */
  repoName: PropTypes.string,
  activeBranch: PropTypes.string,
  fetchRepoContent: PropTypes.func.isRequired,
  /** Full name of the repository (to look it up at Gogs) */
  repoFullName: PropTypes.string.isRequired,
  repoCode: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(RepoContent);
