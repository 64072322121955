import { connect } from 'react-redux';
import StartServerButton from './StartServerButton';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import {
  checkWhetherNotebookIsRunning,
  startServer,
} from '../../../../redux/workbench/modules/connection.module';

export function mapStateToProps(state) {
  return {
    notebookUser: notebookUser(state),
    loading: state.workbench.notebookStarting.loading,
    loaded: state.workbench.notebookStarting.loaded,
    error: state.workbench.notebookStarting.error,
    eventSourceAvailable: state.workbench.notebookStarting.eventSourceAvailable,
    eventSourceMessages: state.workbench.notebookStarting.eventSourceMessages,
    isSpawning: state.workbench.notebookRunning.isSpawning,
  };
}

export const mapDispatchToProps = {
  startServer,
  checkWhetherNotebookIsRunning,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartServerButton);
