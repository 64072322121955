import React, { FC, useCallback, useEffect } from 'react';
import SubHeader from '../../organisms/sub-header/SubHeader.container';
import OverlayAuditLog from '../../organisms/sub-header/overlay-audit-log/OverlayAuditLog';
import { AuditLogState, fetchAuditLog } from '../../../store/auditLog/slice';
import { useDispatch, useSelector } from 'react-redux';
import { DeprecatedRootState } from '../../../store/state.type';
import AuditlogStory from '../../molecules/audit-log/AuditLog';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import Paging from '../../molecules/paging/Paging';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import auditLogMsgs from 'common/dist/messages/auditLog';
import qs, { ParsedQs } from 'qs';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const PAGE_SIZE = 10;

export interface PagingParams extends ParsedQs {
  event?: string;
}

const Header = () => {
  return (
    <span>
      <FormattedMessage {...auditLogMsgs.msgAuditLog} />
    </span>
  );
};

const AuditLog: FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const auditLogState = useSelector<DeprecatedRootState, AuditLogState>(
    (state) => state.auditLog
  );
  const updatePagingParameters = useCallback(
    (offset: number, limit: number, search?: string) =>
      dispatch(fetchAuditLog({ offset, limit })),
    [dispatch, fetchAuditLog]
  );

  const { event }: PagingParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (event) dispatch(fetchAuditLog({ event: event as string }));
  }, [dispatch, event]);

  if (event) {
    const logWithRelatedEventsIncluded = {
      events: [
        ...auditLogState.data.events,
        ...auditLogState.data.relatedEvents,
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      ),
      relatedEvents: [],
    };
    return (
      <div className={styles.pageParent}>
        <SubHeader hasOverlay Overlay={OverlayAuditLog} />
        <ErrorBoundary>
          <div className={styles.contentParent}>
            <div className={styles.header}>
              <FormattedMessage {...auditLogMsgs.msgEventsRelatedTo} />
              &nbsp;
              {
                logWithRelatedEventsIncluded.events.find(
                  (ev) => ev.uuid === event
                )?.eventType
              }{' '}
              ({event})
            </div>
            <AuditlogStory auditLog={logWithRelatedEventsIncluded} />
          </div>
        </ErrorBoundary>
      </div>
    );
  }

  return (
    <div className={styles.pageParent}>
      <SubHeader hasOverlay Overlay={OverlayAuditLog} />
      <ErrorBoundary>
        <div className={styles.contentParent}>
          <Paging
            itemsPerPage={PAGE_SIZE}
            updatePagingParameters={updatePagingParameters}
            headline={Header}
            currentItems={auditLogState.data.events.length}
          >
            <AuditlogStory auditLog={auditLogState.data} />
          </Paging>
        </div>
      </ErrorBoundary>
    </div>
  );
};
export default withRouter(AuditLog);
