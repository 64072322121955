import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import React from 'react';
import PredictionTargetsCategory from './PredictionTargetsCategory';

export default class PredictionTargetsCategoryWrapper
  implements ModelSettingsCategory
{
  component = PredictionTargetsCategory;
  icon: React.ComponentType = () => (
    <span className={'icon-taxonomy'} style={{ fontSize: '17px' }} />
  );
  id = 'prediction-targets';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Prediction Targets',
  };
}
