import React, { FC } from 'react';
import classNames from 'classnames';
import { flow } from 'lodash';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { Period } from 'common/dist/constants/schedulePeriods';
import { FormattedMessage, IntlShape } from 'react-intl';
import styles from './styles.module.scss';

type Props = {
  namespace: string;
  onValueChange: (value: string) => void;
  selectedPeriod?: Period;
  className: string;
  intl: IntlShape;
  meta: {
    error?: string;
    valid?: boolean;
    touched?: boolean;
  };
  input: {
    value?: string | number;
    onChange: (value: string | number) => void;
  };
  disabled: boolean;
};

type OptionType = { label: string; value: number };

const PeriodValueField: FC<Props> = (props) => {
  const {
    meta: { error, valid, touched },
    input,
    selectedPeriod,
    namespace,
    className,
    onValueChange,
    intl,
    disabled,
  } = props;

  if (!selectedPeriod) {
    return null;
  }

  if (selectedPeriod.hasValue) {
    const fieldClassName = classNames(
      className,
      touched && { error, success: valid }
    );

    return (
      <div className={styles.fieldParent}>
        {/* @ts-ignore */}
        <FormattedMessage {...selectedPeriod.labelValue}>
          {(text) => <p className={styles.fieldLabel}>{text}</p>}
        </FormattedMessage>
        <input
          className={fieldClassName}
          id={`${namespace}-periodValue`}
          name={`${namespace}-periodValue`}
          disabled={disabled}
          {...selectedPeriod.valueOptions}
          value={input.value}
          onChange={(e) => input.onChange(e.target.value)}
        />
      </div>
    );
  }

  if (selectedPeriod.hasOptions) {
    const options: OptionType[] = selectedPeriod.options.map((option) => ({
      value: option.id,
      label: intl.formatMessage({ id: option.label }),
    }));

    return (
      <div className={styles.fieldParent}>
        {/* @ts-ignore */}
        <FormattedMessage {...selectedPeriod.labelOptions}>
          {(text) => <p className={styles.fieldLabel}>{text}</p>}
        </FormattedMessage>
        <DropdownSelectInput
          id={`${namespace}-periodValue`}
          name={`${namespace}-periodValue`}
          className={className}
          options={options}
          placeholder={{ id: 'common.scheduleSelect.select_placeholder' }}
          value={options.find((x) => x.value === input.value)}
          onChange={(option: OptionType) =>
            flow(input.onChange, onValueChange)(option.value)
          }
          disabled={disabled}
        />
      </div>
    );
  }

  return null;
};

export default PeriodValueField;
