import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';
import StartServerButton from './StartServerButton.container';
import MainContainer from '../../../pages/main-container/MainContainer';

export default class StartServer extends Component {
  render() {
    const { notifications } = this.props;
    return (
      <MainContainer
        additionalClassname={'StartWorkbench'}
        transparent={true}
        fullWidth
      >
        <div className={'button-container'}>
          <StartServerButton />
        </div>
        <Notifications notifications={notifications} />
      </MainContainer>
    );
  }
}
StartServer.propTypes = {
  notifications: PropTypes.array,
  /** Code of the user to spawn the notebook for */
  notebookUser: PropTypes.string.isRequired,
  /** Trigger the notebook to start - will cause a spawn request against JupyterHub */
  startServer: PropTypes.func.isRequired,
};
