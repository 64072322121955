import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { orchestrationRoutes } from '../../routes';
import { Link } from 'react-router-dom';
import { CProps } from './CodeCapsuleReport.container';
import { RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';
import jobDetailsStyles from '../styles.module.scss';
import classNames from 'classnames';
import { NotebookStatus } from 'common/dist/types/codeCapsule';

export interface Props {}

export type RProps = RouteComponentProps<{
  jobCode: string;
}>;

export default class CodeCapsuleReport extends Component<
  Props & CProps & RProps
> {
  componentDidMount() {
    const { jobCode, fetchCodeCapsuleReport } = this.props;
    fetchCodeCapsuleReport(jobCode);
  }

  renderError() {
    const { error } = this.props;
    return JSON.stringify(error);
  }

  renderLoading() {
    return <ReactLoading className={'busy'} type={'cylon'} color={'#224E90'} />;
  }

  renderLoaded() {
    const { report, jobCode } = this.props;

    return (
      <>
        <span className={styles.description}>
          Clicking the name of an executed notebook shows the execution results.
        </span>
        <ul className={styles.singleNotebooksList}>
          {report &&
            report.executedNotebooks &&
            report.executedNotebooks.map((notebookName) => {
              const status = report.notebooks[notebookName]?.status;
              return (
                <li>
                  <Link
                    to={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}/ccoutput?notebookName=${notebookName}`}
                    className={styles.singleNotebookEntry}
                  >
                    <div
                      className={classNames(styles.singleNotebookEntryIcon, {
                        [jobDetailsStyles.success]:
                          status === NotebookStatus.SUCCESS,
                        [jobDetailsStyles.failure]:
                          status === NotebookStatus.FAILURE,
                        [jobDetailsStyles.inProgress]:
                          status === NotebookStatus.IN_PROGRESS,
                        [jobDetailsStyles.waiting]: ![
                          NotebookStatus.SUCCESS,
                          NotebookStatus.FAILURE,
                          NotebookStatus.IN_PROGRESS,
                        ].includes(status),
                      })}
                    />
                    <div className={styles.singleNotebookEntryName}>
                      {notebookName}
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </>
    );
  }

  render() {
    const { loading, error } = this.props;
    let inner;
    if (loading) inner = this.renderLoading();
    else if (error) inner = this.renderError();
    else inner = this.renderLoaded();
    return (
      <div className={styles.codeCapsuleReport}>
        <span className={styles.headline}>Executed Notebooks</span>
        {inner}
      </div>
    );
  }
}
