import {
  inputStringToList,
  listToInputString,
  ParameterTuningOutput,
  ParameterTuningSchema,
} from './types';
import { ParameterTuningValue } from './ParameterTuningSelection';
import { RootState } from '../../../../store/store';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function initialValuesFromSchema(
  parameterTuningSchema?: ParameterTuningSchema
): ParameterTuningValue {
  if (!parameterTuningSchema) {
    return null;
  }

  const searchStrategy =
    parameterTuningSchema.availableSearchStrategies[0]?.name;
  const meta = Object.fromEntries(
    parameterTuningSchema.availableAlgorithms.map((algo) => [
      algo.name,
      {
        selected: true,
      },
    ])
  );
  const algorithms = Object.fromEntries(
    parameterTuningSchema.availableAlgorithms.map((algo) => {
      const parameters = Object.fromEntries(
        algo.parameters.map((param) => [
          param.name,
          listToInputString(param.default),
        ])
      );
      return [algo.name, parameters];
    })
  );
  return { searchStrategy, meta, algorithms };
}

export function initialValuesFromOutput(
  parameterTuningOutput: ParameterTuningOutput
): ParameterTuningValue {
  const searchStrategy = parameterTuningOutput.searchStrategy.name;
  const meta = Object.fromEntries(
    parameterTuningOutput.algorithms.map((algo) => [
      algo.name,
      {
        selected: true,
      },
    ])
  );
  const algorithms = Object.fromEntries(
    parameterTuningOutput.algorithms.map((algo) => {
      const parameters = Object.fromEntries(
        algo.parameters.map((param) => [
          param.name,
          listToInputString(param.values),
        ])
      );
      return [algo.name, parameters];
    })
  );
  return { searchStrategy, meta, algorithms };
}

/**
 * Change the format of the chosen parameters from the redux-form version to the json to be saved
 */
export function extractParametersFromForm(
  fieldValue: ParameterTuningValue
): ParameterTuningOutput {
  const valueMeta = fieldValue?.meta;
  const valueAlgorithms = fieldValue?.algorithms;

  const searchStrategy = { name: fieldValue?.searchStrategy };

  const algorithms = Object.entries(valueAlgorithms || {})
    .filter(([algorithm]) => valueMeta?.[algorithm]?.selected)
    .map(([algorithm, parameters]) => {
      const parameterObjects = Object.entries(parameters).map(
        ([parameter, value]) => ({
          name: parameter,
          // The values are still strings and we don't use the type information, just try to strictly parse as Number
          values: inputStringToList(value).map((value) =>
            !Number.isNaN(Number(value)) ? Number(value) : value
          ),
        })
      );
      return { name: algorithm, parameters: parameterObjects };
    });
  return { searchStrategy, algorithms };
}

/**
 * Extract the parameter tuning schema from the settings contained in the combined archetype + archetype_versions data
 */
export function extractParameterTuningSchemaFromState(
  state: RootState,
  archetypeCode: string,
  archetypeVersionNumber: string
): ParameterTuningSchema | undefined {
  const archetypes = state.archetypes.data;

  // Find the settings for the correct archetype and version in the data (if settings are missing in db they are null)
  const archetypeVersionSettings: {
    parameterTuning: ParameterTuningSchema;
  } | null = archetypes
    .find((archetype: ToBeRefined) => archetype.code === archetypeCode)
    ?.versions.find(
      (version: ToBeRefined) => version.number === archetypeVersionNumber
    )?.settings;
  return archetypeVersionSettings?.parameterTuning;
}
