import React, { FC, useEffect } from 'react';
import './styles.scss';
import ModelSettingsCategory from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import TabLineBubbles, {
  Button,
} from '../../../../../molecules/tab-line-bubbles/TabLineBubbles';
import { useLocation, useParams } from 'react-router-dom';
import { CProps } from './ModelSettings.container';
import Busy from '../../../../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../types';
import {
  useAugur,
  useSettings,
  useUpdateSettings,
} from '../../../../../../core/api/augurs';

export type Props = {
  /** List of model settings categories to render */
  modelSettingsCategories: ModelSettingsCategory[];
};

const ModelSettings: FC<Props & CProps> = ({
  fetchArchetypeVersions,
  modelSettingsCategories,
}) => {
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();
  const location = useLocation();

  //TODO: error handling
  const { data: augur, isLoading } = useAugur(habitatCode, augurCode);
  const { data: settings, isLoading: isLoadingSettings } = useSettings(
    habitatCode,
    augurCode
  );

  const saveSettings = useUpdateSettings(habitatCode, augurCode);

  useEffect(
    () => fetchArchetypeVersions(augur?.archetype?.code),
    [fetchArchetypeVersions, augur]
  );

  if (isLoading || isLoadingSettings) {
    return <Busy isBusy />;
  }

  const parts = location.pathname.split('/');
  const tabFromRoute = parts[8];
  const firstId = modelSettingsCategories?.[0]?.id || '';

  // This acts as a fallback when no tab is selected yet, the first tab will be selected (instead of doing a redirect)
  const activeTab = tabFromRoute || firstId;
  const activeCategory = modelSettingsCategories?.find(
    (msc) => msc.id === activeTab
  );

  const buttons: Button[] = modelSettingsCategories?.map((msc) => ({
    id: msc.id,
    to: `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleType}/model-settings/${msc.id}`,
    intlId: msc.label.id as string,
    intlDefault: msc.label.defaultMessage as string,
    icon: msc.icon,
  }));

  return (
    <div className={'ModelSettings'}>
      <div className={'ModelSettings--header'}>
        <TabLineBubbles
          buttons={buttons}
          activeButton={activeTab}
          preserveSearchParamsOnLink={true}
        />
      </div>
      <div className={'ModelSettings--category'}>
        {activeCategory && (
          <activeCategory.component
            augur={augur}
            settings={settings.data}
            saveSettings={(settings, merge) =>
              saveSettings.mutate({
                settings,
                merge,
              })
            }
            saving={saveSettings.isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ModelSettings;
