import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const Button = (props) => {
  const size = {
    small: 'button-sm',
    medium: 'button-md',
  };
  const use = {
    default: 'button-transparent',
    success: 'button-green',
  };
  const className = cn('button', size[props.size], use[props.use], {
    'button-disabled': props.disabled,
  });
  const onClick = () => {
    if (props.disabled) {
      return;
    }

    props.onClick();
  };

  return (
    <a className={className} onClick={onClick}>
      {props.children}
    </a>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  use: PropTypes.oneOf(['default', 'success']),
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  size: 'small',
  disabled: false,
  use: 'default',
};

export default Button;
