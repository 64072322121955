import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { variableShape } from '../python3-input-cell/elements/_interface/P3InputElementShape';

export default class ModalDialogVariables extends Component {
  render() {
    const {
      variables,
      selectVariable: { validTypes },
      input: { onChange, value },
      selectVariable,
      hideSelectVariable,
    } = this.props;

    const sortAscByName = (a, b) => (a.name > b.name ? 1 : -1);
    const validVariables = variables
      .filter((v) => !!v.name && validTypes.includes(v.type))
      .sort(sortAscByName);
    const invalidVariables = variables
      .filter((v) => !!v.name && !validTypes.includes(v.type))
      .sort(sortAscByName);

    const isVariableUsed = value && value.origin === 'variable';
    const variableUsed = isVariableUsed ? value.variableName : '';

    return (
      <div className={'modal-body-right'}>
        <div className={'modal-body-headline'}>
          <span>Select Variable for "{selectVariable.label}"</span>
        </div>
        <div className={'variables-parent'}>
          <div className={'variables-list ct-list'}>
            <div className={'ct-headline ct-row'}>
              <div className={'ct-col ct-col-140px'}>Name</div>
              <div className={'ct-col ct-col-140px'}>Type</div>
              <div className={'ct-col ct-col-200px'}>Value</div>
            </div>

            {validVariables.map((v) => (
              <div
                className={`ct-row valid-variable${
                  variableUsed === v.name ? ' highlight-row' : ''
                }`}
                onClick={() => {
                  onChange({
                    inputValue: value.inputValue,
                    origin: 'variable',
                    variableName: v.name,
                  });
                  hideSelectVariable();
                }}
              >
                <div className={'ct-col ct-col-140px'}>{v.name}</div>
                <div className={'ct-col ct-col-140px'}>{v.type}</div>
                {v.parsedValue && !v.error && (
                  <div className={'ct-col ct-col-200px ct-col-parsed-value'}>
                    {JSON.stringify(v.parsedValue)}
                  </div>
                )}
                {v.error && (
                  <div className={'ct-col ct-col-200px error'}>{v.error}</div>
                )}
              </div>
            ))}
          </div>

          <div className={'variables-list ct-list'}>
            {invalidVariables.map((v) => (
              <div className={'ct-row invalid-variable'}>
                <div className={'ct-col ct-col-140px'}>{v.name}</div>
                <div className={'ct-col ct-col-140px'}>{v.type}</div>
                {v.parsedValue && !v.error && (
                  <div className={'ct-col ct-col-200px'}>
                    {JSON.stringify(v.parsedValue)}
                  </div>
                )}
                {v.error && (
                  <div className={'ct-col ct-col-200px error'}>{v.error}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={'buttons-parent'}>
          <div
            className={`button ${
              isVariableUsed ? 'button-white' : 'button-blue'
            }`}
            onClick={() => {
              onChange({ inputValue: value.inputValue, origin: 'input' });
              hideSelectVariable();
            }}
          >
            Don't overwrite with Variable
          </div>
          <div
            className={'button button-white button-cancel'}
            onClick={hideSelectVariable}
          >
            Cancel
          </div>
        </div>
      </div>
    );
  }
}
ModalDialogVariables.propTypes = {
  /** List of all available variables */
  variables: PropTypes.arrayOf(variableShape),
  selectVariable: PropTypes.shape({
    active: PropTypes.bool,
    path: PropTypes.string,
    cellId: PropTypes.string,
    elementFieldName: PropTypes.string,
    validTypes: PropTypes.array,
    label: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.shape({
      origin: PropTypes.string,
      variableName: PropTypes.string,
      inputValue: PropTypes.string,
    }),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  hideSelectVariable: PropTypes.func.isRequired,
};
ModalDialogVariables.defaultProps = {
  variables: [],
};
