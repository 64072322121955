import { connect, ConnectedProps } from 'react-redux';
import JobDetails, { RProps } from './JobDetails';
import { withRouter } from 'react-router-dom';
import { cancelJob } from '../../../redux/modules/orchestration.queue.module';
import {
  fetchJobDetails,
  fetchJobKubernetesEvents,
  fetchProgressSteps,
} from '../../../redux/modules/orchestration.jobdetails.module';
import { isAdmin } from '../../../redux/selectors/user.selector';
import { fetchCodeCapsuleReport } from '../../../redux/modules/orchestration.codeCapsuleReport.module';
import { RootState } from '../../../store/store';

export const mapStateToProps = (state: RootState, { match }: RProps) => {
  return {
    jobCode: match.params.jobCode,
    data: state.jobDetails.data,
    loading: state.jobDetails.loading,
    loaded: state.jobDetails.loaded,
    error: state.jobDetails.error,
    events: state.jobDetails.events,
    origin: state.jobDetails.origin,
    /** Does the current logged in user have admin rights? (= the admin role) */
    isAdmin: isAdmin(state),
    progressSteps: state.jobDetails.progressSteps,
  };
};

export const mapDispatchToProps: {
  /** Function to cancel a job */
  cancelJob: (jobCode: string) => void;
  /** Fetch the details for a job */
  fetchJobDetails: (jobCode: string) => void;
  fetchCodeCapsuleReport: (jobCode: string) => void;
  fetchJobKubernetesEvents: (jobCode: string) => void;
  fetchProgressSteps: (jobCode: string) => void;
} = {
  cancelJob,
  fetchJobDetails,
  fetchCodeCapsuleReport,
  fetchJobKubernetesEvents,
  fetchProgressSteps,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(JobDetails));
