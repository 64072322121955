import io from 'socket.io-client';
import * as dashboardSummaryModule from './redux/modules/dashboard.summary.module';
import { predictionStarted } from './redux/modules/predictions.module';
import { keyspaceCreated } from './redux/modules/data.cassandra.module';
import socketTypes from 'common/dist/socket/socketTypes';
import keycloak from '../keycloak';
import {
  updateJobKubernetesEvents,
  updateJobStatus,
  updateProgressSteps,
} from './redux/modules/orchestration.jobdetails.module';

const getSocketUrl = () => '/';

export let socket;

export const configureSockets = (store) => {
  // eslint-disable-line
  if (socket) {
    socket.close();
  }

  socket = io(getSocketUrl(), {
    extraHeaders: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });

  socket.on(socketTypes.augur.created, (augur) => {
    store.dispatch(dashboardSummaryModule.newAugurAdded(augur));
  });

  socket.on(socketTypes.habitat.created, (habitat) => {
    store.dispatch(dashboardSummaryModule.newHabitatAdded(habitat));
  });

  socket.on(socketTypes.datapool.created, (datapool) => {
    store.dispatch(dashboardSummaryModule.newDatapoolAdded(datapool));
  });

  socket.on(socketTypes.prediction.started, (prediction) => {
    store.dispatch(predictionStarted(prediction));
  });

  socket.on(socketTypes.augur.progress, (payload) => {
    const { augurCode, progress, inProgress } = payload;
    store.dispatch(
      dashboardSummaryModule.augurProgres({ augurCode, progress, inProgress })
    );
  });

  socket.on(
    socketTypes.cassandraKeyspace.created,
    ({ keyspaceName, keyspaceMessage, keyspaceStatusType }) => {
      store.dispatch(
        keyspaceCreated({ keyspaceName, keyspaceMessage, keyspaceStatusType })
      );
    }
  );

  socket.on(socketTypes.jobProgress.update, (data, roomId) =>
    store.dispatch(updateProgressSteps(roomId.split('-')[1], data))
  );

  socket.on(socketTypes.jobEvents.update, (data, roomId) =>
    store.dispatch(updateJobKubernetesEvents(roomId.split('-')[1], data))
  );

  socket.on(socketTypes.jobStatus.update, (status, roomId) =>
    store.dispatch(updateJobStatus(roomId.split('-')[1], status))
  );
};
