import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';
import { withRouter } from 'react-router-dom';
import Busy from '../../atoms/busy/Busy';
import App from '../../workbench/part-right/app/App.container';

class RunningApp extends Component {
  componentDidMount() {
    const { fetchNotebookForApp, match } = this.props;
    const { appVersionCode } = match.params;

    // Load the notebook -> causes changes in state.workbench.app.loading. Once the notebook is loaded the session will be started too.
    const path = 'main.ipynb'; // This is the fixed entry notebook. Might be made dynamic in a later step.
    fetchNotebookForApp(path, appVersionCode);
  }

  renderLoading() {
    return <Busy isBusy />;
  }

  renderError() {
    const { error } = this.props;
    return <span className={'error'}>{JSON.stringify(error)}</span>;
  }

  renderLoaded() {
    const { match } = this.props;
    const { appVersionCode } = match.params;
    return <App parentType={'app'} appVersionCode={appVersionCode} />;
  }

  renderInnerComponent() {
    const { loaded, loading, error } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (loaded) return this.renderLoaded();
    return <div />; // Might happen for a very short moment only, since the loading is immediately triggered in onComponentDidMount()
  }

  render() {
    const { notifications } = this.props;
    return (
      <div className={'app-main-container'}>
        {this.renderInnerComponent()}
        <Notifications notifications={notifications} />
      </div>
    );
  }
}

RunningApp.propTypes = {
  /** Injected by withRouter */
  match: PropTypes.shape({
    params: PropTypes.shape({
      /** Code of the app version */
      appVersionCode: PropTypes.string.isRequired,
    }),
  }),
  /** Loads a notebook from the Jupyter notebook container */
  fetchNotebookForApp: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
};

export default withRouter(RunningApp);
