import React from 'react';
import P3InputElement from '../_interface/P3InputElement';
import DatetimePicker from './DatetimePicker';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import DatetimePickerSettings from './DatetimePickerSettings';
import {
  getSourceDateTime,
  validate,
  validateSettings,
} from './datetimeCommonFunctions';

export default class DatetimePickerWrapper extends P3InputElement {
  // --- Components
  ChildComponent: React.ComponentType = (props: object) => (
    <DatetimePicker useDate useTime {...props} />
  );
  AdditionalSettings: any = (props: object) => (
    <DatetimePickerSettings
      // @ts-ignore
      useDate
      useTime
      {...props}
    />
  );
  // icon: any = DatetimePickerIcon;
  icon: any = DefaultElementIcon;
  iconColor = '#224e90';

  // --- AttributesComponent
  name = 'Date&Time Picker';
  parentContainerClass = 'datetime-picker';
  type = 'datetime-picker';

  // --- Functions
  getSource = getSourceDateTime;
  validate = validate;
  validateSettings = validateSettings;
}
