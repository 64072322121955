import React, { Component } from 'react';
import { fieldCommitMessage, gitCommitForm } from './gitCommit.form';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import FieldCommitMessage from '../../../../../workbench/part-right/repository/info/git-commit/FieldCommitMessage';

type Props = {
  /** ID of the merge in the merger API */
  mergeId: number;
  /** Is the Commit Modal shown? */
  isShown?: boolean;
  /** Show or hide the commit modal */
  toggleCommitModal: (isShown: boolean) => void;
  /** Is the form valid? */
  valid: boolean;
  /** Is the form pristine? */
  pristine: boolean;
  /** Commit message the user entered */
  commitMessage: string;
  /** Submit the Commit */
  sendResolveConflictCommit: (mergeId: number, commitMessage: string) => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class CommitModal extends Component<
  Props & InjectedFormProps,
  { buttonClicked: boolean }
> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      buttonClicked: false,
    };
  }

  handleClick() {
    const {
      pristine,
      valid,
      commitMessage,
      mergeId,
      sendResolveConflictCommit,
    } = this.props;

    if (!pristine && valid && !this.state.buttonClicked) {
      this.setState({ buttonClicked: true });
      sendResolveConflictCommit(mergeId, commitMessage);
    }
  }

  render() {
    const { isShown, toggleCommitModal, pristine, valid } = this.props;
    return (
      <ReactModal
        isOpen={isShown}
        contentLabel='Commit Resolved Files Modal'
        onRequestClose={() => toggleCommitModal(false)}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'modal-commit-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={() => toggleCommitModal(false)}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={'modal-commit-elements'}>
            <Field
              name={fieldCommitMessage}
              label={'Commit Message'}
              placeholder={'Commit Message'}
              component={FieldCommitMessage}
            />
          </div>

          <div className={'modal-commit-buttons'}>
            <div
              className={`button ${
                pristine || !valid || this.state.buttonClicked
                  ? 'button-disabled'
                  : ''
              }`}
              onClick={this.handleClick}
            >
              Commit
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default reduxForm(gitCommitForm)(CommitModal);
