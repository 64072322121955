import React, { FC } from 'react';
import {
  ErrorType,
  fieldCredentials,
  fieldUserDetails,
  formName,
  FormValues,
} from './addUser.form';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import CredentialsStepComponent from './CredentialsStepComponent';
import UserDetailsStepComponent from './UserDetailsStepComponent';
import { usersRoutes } from '../routes';
import Wizard from '../../../pages/wizard/Wizard';
import { ButtonProps } from '../../../atoms/button/Button';
import messages from 'common/dist/messages/admin';
import { InjectedFormProps } from 'redux-form';
import { ConnectedProps } from '../../../orchestration/job-schedules/add-schedule/NewScheduleWizard.container';
import { useAddUser } from '../../../../core/api/admin';

export interface Props {
  addUser: (values: FormValues, fetchPermissions: boolean) => void;
  filledCompletely?: boolean;
}

export type AddUserSubmitValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isTemporaryPassword: boolean;
};

const AdminAddUserWizard: FC<
  Props & ConnectedProps & InjectedFormProps<FormValues, Props, ErrorType>
> = (props) => {
  const { formValues, filledCompletely } = props;

  const addUserMutation = useAddUser();

  const buttons: ButtonProps[] = [
    {
      withLink: true,
      linkTo: `${usersRoutes.basePath}/${usersRoutes.userList.path}`,
      buttonColor: 'white',
      buttonLabelId: 'todo',
      buttonLabelDefault: 'Cancel',
    },
    {
      withLink: true,
      buttonColor: 'green',
      buttonLabelId: 'todo',
      buttonLabelDefault: 'Finish',
      disabled: !filledCompletely,
      linkTo: `${usersRoutes.basePath}/${usersRoutes.userList.path}`,
      onClick: () => {
        if (filledCompletely) {
          const credentials = formValues[fieldCredentials];
          const userDetails = formValues[fieldUserDetails];
          const submitValues = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: credentials.email,
            password: credentials.password,
            isTemporaryPassword: credentials.isTemporaryPassword,
          };
          addUserMutation.mutate(submitValues);
        }
      },
    },
  ];

  return (
    <Wizard
      additionalClassname={'AddUserWizard'}
      withHeadline={true}
      headlineId={'admin.headline.add_user'}
      headlineDefault={'Add User'}
      buttons={buttons}
    >
      <GenericFormStep // TODO Properly use the generic: GenericFormStep<string, string>
        formName={formName}
        fieldName={fieldCredentials}
        title={{
          id: messages.msgUsersCredentialsTitle.id,
          defaultMessage: messages.msgUsersCredentialsTitle.defaultMessage,
        }}
        // See ./addUser.form.js/validatePassword
        description={{
          id: messages.msgUsersCredentialsDescription.id,
          defaultMessage:
            messages.msgUsersCredentialsDescription.defaultMessage,
        }}
        num={1}
        component={CredentialsStepComponent}
        renderError={false}
      />
      <GenericFormStep // TODO Properly use the generic: GenericFormStep<string, string>
        formName={formName}
        fieldName={fieldUserDetails}
        title={{
          id: messages.msgUserDetailsStepTitle.id,
          defaultMessage: messages.msgUserDetailsStepTitle.defaultMessage,
        }}
        description={{
          id: messages.msgUserDetailsStepDescription.id,
          defaultMessage: messages.msgUserDetailsStepDescription.defaultMessage,
        }}
        num={2}
        component={UserDetailsStepComponent}
        renderError={false}
      />
      ;
    </Wizard>
  );
};
export default AdminAddUserWizard;
