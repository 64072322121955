import React, { FC, Fragment } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  getIntlIdForType,
  getNameForType,
} from '../../../modelManagement/modules/ModuleManagerClient';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import DetailValueLoading from '../detail-value/DetailValueLoading';
import DetailValue from '../detail-value/DetailValue';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { useAugur, useSettings } from '../../../../core/api/augurs';
import { useLatestEvaluationReport } from '../../../../core/api/reports';

const detailValues = {
  moduleType: {
    gridColumn: 2,
    gridRow: 1,
    detailKey: {
      id: 'details.action_bar.augur_type',
      defaultMessage: 'Type:',
    },
  },
  kpi: {
    gridColumn: 2,
    gridRow: 2,
    detailKey: {
      id: 'details.action_bar.kpi',
      defaultMessage: 'KPI:',
    },
    id: 'accuracy',
  },
  lastEvaluation: {
    gridColumn: 3,
    gridRow: 1,
    detailKey: {
      id: 'details.action_bar.last_run',
      defaultMessage: 'Last evaluation:',
    },
  },
};

const DetailValues: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isSuccess } = useAugur(habitatCode, augurCode);

  // get latest kpi settings
  const { data: settings } = useSettings(habitatCode, augurCode);
  const { learningKpi, learningThreshold } = settings?.data || {};

  // get value for selected kpi from latest report
  const { data: report } = useLatestEvaluationReport(habitatCode, augurCode);
  const learningKpiValue =
    report?.data?.performanceDrift?.[learningKpi][0]?.drift;

  if (!isSuccess) {
    return (
      <Fragment>
        {Object.values(detailValues).map((dv, i) => (
          <DetailValueLoading key={i} {...dv} />
        ))}
      </Fragment>
    );
  }

  const kpiFormatter = kpiOptionsGrouped[learningKpi];
  const formattedKpiValue =
    kpiFormatter && learningKpiValue
      ? kpiFormatter.format(learningKpiValue)
      : 'No evaluation yet';
  const formattedThreshold =
    kpiFormatter && learningThreshold
      ? kpiFormatter.format(learningThreshold)
      : '';
  const detailValuesLoaded = {
    moduleType: {
      detailValue: {
        id: getIntlIdForType(augur.type),
        defaultMessage: getNameForType(augur.type),
      },
    },
    kpi: {
      detailValue: augur.archetypeVersion?.supportsEvaluation
        ? {
            id: 'details.action_bar.kpi_value',
            defaultMessage: '{kpi}: {kpiValue} ({threshold})',
            values: {
              kpi: <FormattedMessage id={`kpi.${learningKpi}`} />,
              kpiValue: formattedKpiValue,
              threshold: formattedThreshold,
            },
          }
        : {
            defaultMessage: <span> - </span>,
          },
    },
    lastEvaluation: {
      detailValue: {
        defaultMessage: augur.lastEvaluationDate ? (
          <FormattedDate value={augur.lastEvaluationDate} />
        ) : (
          <span> - </span>
        ),
      },
    },
  };

  return (
    <Fragment>
      {Object.keys(detailValues).map((detId) => {
        const det = detailValues[detId];
        const detL = detailValuesLoaded[detId];
        return <DetailValue key={detId} {...det} {...detL} />;
      })}
    </Fragment>
  );
};

export default DetailValues;
