import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import GraphWait from '../../../../../../../components/details/GraphWait';
import BubbleChart from '../../../../../../../components/molecules/charts/bubble-chart/BubbleChart';
import Sizer from '../../../../../../atoms/sizer/Sizer';
import { Feature } from 'common/dist/types/mlModel';
import _ from 'lodash';

export type Props = {
  data: Feature[];
};

const InfluencersBubbleChart: FC<Props> = (props) => {
  if (_.isEmpty(props.data)) {
    return (
      <GraphWait jobType={'learning'}>
        <FormattedMessage
          id='augur.influencers.not_available'
          defaultMessage='Influencers are not available'
        />
      </GraphWait>
    );
  }

  return (
    <Sizer>
      <BubbleChart {...props} />
    </Sizer>
  );
};

export default InfluencersBubbleChart;
