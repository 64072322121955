import { connect } from 'react-redux';

import ScheduleSelect from './ScheduleSelect';
import { schedulePeriods } from 'common/dist/constants/schedulePeriods';

export function mapStateToProps(state, { selector, namespace }) {
  const period = selector(state, `${namespace}.period`);
  const selectedPeriod = schedulePeriods.find(({ id }) => period === id);

  return {
    selectedPeriod,
    periods: schedulePeriods,
  };
}

export default connect(mapStateToProps)(ScheduleSelect);
