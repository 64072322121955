import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import LogoIconColored from '../../../atoms/icons/logo-colored/LogoIconColored';

export default class StartServerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      clickAnimationFinished: false,
      autoStartPlainAnimation: false,
      processedMessageIndex: -1,
      message: '',
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.startClickAnimation = this.startClickAnimation.bind(this);
    this.startCorrectAnimation = this.startCorrectAnimation.bind(this);
    this.processEventSourceMessage = this.processEventSourceMessage.bind(this);
    this.startOpenAnimation = this.startOpenAnimation.bind(this);
  }

  componentDidMount() {
    if (this.props.isSpawning) this.startClickAnimation();

    const { loaded } = this.props;
    if (loaded) {
      this.startCorrectAnimation();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isSpawning && !prevProps.isSpawning)
      this.startClickAnimation();

    const { loaded, eventSourceMessages } = this.props;
    // Treat 'loaded' switched from false to true
    if (prevProps.loaded === false && loaded === true) {
      this.startCorrectAnimation();
    }

    // Treat new messages
    if (
      eventSourceMessages &&
      eventSourceMessages.length > this.state.processedMessageIndex + 1
    ) {
      const latestIndex = eventSourceMessages.length - 1;
      const latestMessage = eventSourceMessages[latestIndex];
      this.setState({ processedMessageIndex: latestIndex });
      this.processEventSourceMessage(latestMessage);

      this.setState({
        message: latestMessage.raw_event ? latestMessage.raw_event.message : '',
      });
    }
  }

  /**
   * Called by componentDidMount or componendDidUpdate when the spawn request returns (marked by loaded=true)
   */
  startCorrectAnimation() {
    const { eventSourceAvailable } = this.props;

    // Is the previous animation already finished?
    if (this.state.clickAnimationFinished) {
      if (eventSourceAvailable) {
        // Something to do?
      } else {
        this.startPlainAnimation();
      }
    } else {
      if (eventSourceAvailable) {
        // Something to do?
      } else {
        this.setState({ autoStartPlainAnimation: true });
      }
    }
  }

  /**
   * Processes EventSource messages
   * @param message
   */
  processEventSourceMessage(message) {
    // console.log("Message: ", message);
    const { progress, ready } = message;

    // --- Adjust the Progress bar
    const basicTimeline = anime.timeline({
      autoplay: false,
    });
    basicTimeline.add({
      targets: '.start-server-button--progress-bar',
      duration: 1200,
      width: 300 * (progress / 100),
      easing: 'easeInOutSine',
    });
    basicTimeline.play();

    // --- If ready fire the action to check and open the Workbench
    if (ready) {
      this.startOpenAnimation();
    }
  }

  startOpenAnimation() {
    const { checkWhetherNotebookIsRunning, notebookUser } = this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => checkWhetherNotebookIsRunning(notebookUser, true),
    });
    basicTimeline
      .add({
        targets:
          '.start-server-button-parent, .start-server-headline-parent, .start-server-message-parent',
        duration: 500,
        opacity: 0,
        easing: 'easeInOutSine',
      })
      .add({
        targets: '.start-server-parent',
        delay: 500,
        duration: 1000,
        width: window.innerWidth - 30,
        height: document.body.scrollHeight - 151,
        easing: 'easeInOutSine',
      });
    basicTimeline.play();
  }

  /**
   * Animation if the EventSource is not available
   */
  startPlainAnimation() {
    const { checkWhetherNotebookIsRunning, notebookUser } = this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => checkWhetherNotebookIsRunning(notebookUser, true),
    });

    basicTimeline
      .add({
        targets: '.start-server-button--progress-bar',
        duration: 2000,
        width: 300,
        easing: 'linear',
      })
      .add({
        targets: '.start-server-button--button',
        width: 0,
        duration: 1,
      })
      .add({
        targets: '.start-server-button--progress-bar',
        width: 80,
        height: 80,
        delay: 500,
        duration: 750,
        borderRadius: 80,
        backgroundColor: '#224e90',
      })
      .add({
        targets: '.start-server-button--logo',
        opacity: 1,
        delay: 0,
        duration: 1,
      })
      .add({
        targets: '.start-server-button--progress-bar',
        opacity: 0,
        delay: 0,
        duration: 1500,
      });

    basicTimeline.play();
  }

  /**
   * Animation when the button is clicked
   */
  startClickAnimation() {
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => {
        this.setState({ clickAnimationFinished: true });
        if (this.state.autoStartPlainAnimation) {
          // Set to true if loaded became true before the click animation was finished
          this.startPlainAnimation();
        }
      },
    });

    basicTimeline
      .add({
        // Hide the button text
        targets: '.start-server-button--text',
        duration: 1,
        opacity: '0',
      })
      .add({
        // Transform the button to the grey background of the progress bar
        targets: '.start-server-button--button',
        duration: 1300,
        height: 10,
        width: 300,
        backgroundColor: '#dce2eb',
        border: '0',
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      });

    basicTimeline.play();
  }

  handleButtonClick() {
    const { startServer, notebookUser } = this.props;
    if (!this.state.clicked) {
      this.setState({ clicked: true }); // To prevent multiple starts by clicking the button quickly several times
      this.startClickAnimation(); // Start the click animation
      startServer(notebookUser); // Send the spawn request to JupyterHub
    }
  }

  render() {
    return (
      <div className={'start-server-parent'}>
        <div className={'start-server-headline-parent'}>
          <span className={'start-server-headline'}>
            AI Workbench Server not running
          </span>
        </div>
        <div className={'start-server-button-parent'}>
          <div
            className={'start-server-button'}
            onClick={this.handleButtonClick}
          >
            <div className='start-server-button--button'>
              <div className='start-server-button--text'>
                Start AI Workbench Server
              </div>
            </div>
            <div className='start-server-button--progress-bar' />
            <div className={'start-server-button--logo'}>
              <LogoIconColored />
            </div>
          </div>
        </div>
        <div className={'start-server-message-parent'}>
          <span className={'start-server-message'}>{this.state.message}</span>
        </div>
      </div>
    );
  }
}

StartServerButton.propTypes = {
  /** User to start the notebook for */
  notebookUser: PropTypes.string.isRequired,
  /** Sends the spawn request to JupyterHub */
  startServer: PropTypes.func.isRequired,
  /** redux-action to check whether the notebook is running. If the notebook is running, it is marked in the redux
   * which causes Workbench.jsx to switch into the running screen */
  checkWhetherNotebookIsRunning: PropTypes.func.isRequired,
  /** true if the spawn request is pending (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  loading: PropTypes.bool,
  /** true if the spawn request succeeded (only the request - this doesn't have to do anything with the spawning status
   * of the notebook) */
  loaded: PropTypes.bool,
  /** possible error during the spawn request (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  error: PropTypes.string,
  /** Was the event source reachable? */
  eventSourceAvailable: PropTypes.bool,
  /** Array of received eventSource messages (in the ordering they were received by the eventSource) */
  eventSourceMessages: PropTypes.arrayOf(
    PropTypes.shape({
      /** Progress between 0 - 100 */
      progress: PropTypes.number,
      /** Message */
      message: PropTypes.string,
      /** Is the notebook ready? Only set if progress=100, I think */
      ready: PropTypes.bool,
    })
  ),
};
