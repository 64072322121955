import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Field } from 'redux-form';

import PeriodField from './PeriodField';
import PeriodValueField from './PeriodValueField';
import PeriodTimeField from './PeriodTimeField';
import FieldError from '../../_tools/fieldError/FieldError';
import styles from './styles.module.scss';
import { Period } from 'common/dist/constants/schedulePeriods';
import { ToBeRefined } from 'common/dist/types/todo_type';
import classNames from 'classnames';

type ContainerProps = {
  periods: Period[];
  selectedPeriod?: Period;
};

export type OuterProps = {
  namespace: string;
  untouch: (fieldName: string) => void;
  disabled: boolean;
  selector: (state: ToBeRefined, ...field: string[]) => ToBeRefined;
};

type Props = ContainerProps & OuterProps & WrappedComponentProps;

export class ScheduleSelect extends Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.untouch = this.untouch.bind(this);
  }

  componentDidMount() {
    this.untouch();
  }

  untouch() {
    const { untouch, namespace } = this.props;

    ['period', 'periodValue', 'time'].forEach((field) =>
      untouch(`${namespace}.${field}`)
    );
  }

  renderErrors() {
    const { namespace } = this.props;

    return (
      <div className={'error-line'}>
        <Field name={`${namespace}.period`} component={FieldError} />
        <Field name={`${namespace}.periodValue`} component={FieldError} />
        <Field name={`${namespace}.time`} component={FieldError} useWarning />
      </div>
    );
  }

  render() {
    const { namespace, periods, selectedPeriod, intl, disabled } = this.props;

    return (
      <div className={styles.scheduleSelect}>
        <div className={styles.fieldsContainer}>
          <Field
            name={`${namespace}.period`}
            component={PeriodField}
            periods={periods}
            className={classNames(styles.field, styles.fieldPeriod)}
            namespace={namespace}
            onValueChange={this.untouch}
            intl={intl}
            disabled={disabled}
          />
          <Field
            name={`${namespace}.periodValue`}
            component={PeriodValueField}
            className={classNames(styles.field, styles.fieldPeriodValue)}
            namespace={namespace}
            selectedPeriod={selectedPeriod}
            onValueChange={this.untouch}
            normalize={Number}
            intl={intl}
            disabled={disabled}
          />
          <Field
            name={`${namespace}.time`}
            component={PeriodTimeField}
            className={classNames(styles.field, styles.fieldTime)}
            namespace={namespace}
            selectedPeriod={selectedPeriod}
            intl={intl}
            disabled={disabled}
          />
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}

export default injectIntl(ScheduleSelect);
