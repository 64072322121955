import { connect, ConnectedProps } from 'react-redux';
import SamplingStrategy, { Props } from './SamplingStrategy';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  fieldSamplingStrategy,
  form,
  formName,
  FormValues,
} from './samplingStrategy.form';
import { RootState } from '../../../../../../../../../store/store';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps
) {
  const {
    augur: { datapool },
    settings: {
      samplingStrategy: { strategy, trainingSplit, evaluationTable },
    },
  } = ownProps;

  const { evaluationTable: tableName } =
    formValueSelector(formName)(state, fieldSamplingStrategy) || {};

  return {
    datapool,
    initialValues: {
      [fieldSamplingStrategy]: {
        strategy,
        trainingSplit: trainingSplit ?? 0.7,
        evaluationTable,
      },
    },
    tableName,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(reduxForm<FormValues, Props>(form)(SamplingStrategy));
