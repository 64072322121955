import { connect } from 'react-redux';
import PipelineTuning from './PipelineTuning';
import { DeprecatedRootState } from '../../../../../../../../../store/state.type';
import { formValueSelector, reduxForm } from 'redux-form';
import { fieldPipelineTuning, form, formName } from './pipelineTuning.form';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

const valueSelector = formValueSelector(formName);

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: ModelSettingsProps
) {
  const pipelineTuningSettings = ownProps.settings.pipelineTuning;

  return {
    pipelineTuningValue: valueSelector(state, fieldPipelineTuning) || [],
    initialValues: {
      [fieldPipelineTuning]: pipelineTuningSettings,
    },
  };
}

export default connect(mapStateToProps)(reduxForm(form)(PipelineTuning));
