import React, { Component } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { BuildAppData, BuildAppError, fieldTag, form } from './form';
import StepTag from './step-tag/StepTag.container';
import { collaborationSpaceRoutes } from '../../routes';
import { withRouter } from 'react-router-dom';
import { RepoDetails } from '../../../../store/workbench/state.types';
import { RouteComponentProps } from 'react-router';
import Wizard from '../../../pages/wizard/Wizard';
import { Version as AppVersion } from 'common/dist/types/app';
import { LoadableData } from '../../../../utils';

export interface Props {
  tag?: string;
  triggerAppBuild(
    repositoryCode: string,
    appCode: string,
    appVersionNumber: string,
    notebooksToExecute: string[]
  ): void;
  fetchRepoDetails(...args: unknown[]): unknown;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: RepoDetails;
  notebooksToExecute: string[];
  versions: LoadableData<AppVersion[], unknown>;
}

interface State {
  buttonClicked: boolean;
}

interface RouteParams {
  group?: string;
  repositoryName?: string;
}

class BuildAppWizard extends Component<
  Props &
    InjectedFormProps<BuildAppData, Props, BuildAppError> &
    RouteComponentProps<RouteParams>,
  State
> {
  constructor(props) {
    super(props);
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    const { fetchRepoDetails } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;

    fetchRepoDetails(group, repositoryName);
  }

  render() {
    const {
      valid,
      tag,
      triggerAppBuild,
      notebooksToExecute,
      data,
      handleSubmit,
      loading,
      history,
    } = this.props;

    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const { code: repoCode, appCode } = data || {};

    return (
      <form
        onSubmit={handleSubmit((values) => {
          this.setState({ buttonClicked: true });
          triggerAppBuild(
            repoCode, // repositoryCode
            appCode,
            tag, // versionNumber,
            notebooksToExecute
          );
          history.push(
            `${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`
          );
        })}
      >
        <Wizard
          withHeadline={true}
          headlineDefault={'Build App'}
          buttons={[
            {
              buttonId: 'wizard.cancel',
              buttonLabelDefault: 'Cancel',
              buttonColor: 'white',
              withLink: true,
              linkTo: `${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`,
            },
            {
              buttonId: 'todo',
              buttonLabelDefault: 'Build',
              buttonColor: 'green',
              isSubmitButton: true,
              disabled: loading || !valid || this.state.buttonClicked,
              withLink: false,
            },
          ]}
        >
          <Field
            name={fieldTag}
            label={'Capsule Tag'}
            component={StepTag}
            appCode={appCode}
          />
        </Wizard>
      </form>
    );
  }
}

// @ts-ignore
export default withRouter(reduxForm(form)(BuildAppWizard));
